import React from 'react'
import { Tooltip, type BadgeProps } from '@buffer-mono/popcorn'
import { NotifiedBadge } from './NotifiedBadge'
import { NotifyBadge } from './NotifyBadge'
import { MarkedAsPublishedBadge } from './MarkedAsPublishedBadge'
import type {
  NotificationStatus,
  PostStatus,
  SchedulingType,
} from '~publish/gql/graphql'

export const PostNotifyBadge = ({
  schedulingType,
  status,
  notificationStatus,
  ...props
}: {
  schedulingType?: SchedulingType | null
  status?: PostStatus | null
  notificationStatus?: NotificationStatus | null
} & Pick<BadgeProps, 'size'>): JSX.Element | null => {
  if (schedulingType !== 'notification') {
    return null
  }

  if (status === 'sent') {
    if (notificationStatus === 'markedAsPublished') {
      return (
        <Tooltip content="This post was marked as published on the Buffer mobile app.">
          <MarkedAsPublishedBadge {...props} />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip content="A mobile notification was sent to remind you to post yourself.">
          <NotifiedBadge {...props} />
        </Tooltip>
      )
    }
  }

  return (
    <Tooltip content="You'll receive a mobile notification to post yourself.">
      <NotifyBadge {...props} />
    </Tooltip>
  )
}
