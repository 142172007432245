import React from 'react'
import SkeletonList from '../ListCampaigns/SkeletonList'
import {
  Wrapper,
  Main,
  Header,
  TextWithSkeletonStyled,
  StyledList,
  StyledTable,
  ListHeader,
  ListHeaderItem,
  FreeNotice,
  EmptyStateWrapper,
  StyledImage,
  EmptyStateBody,
  EmptyStateLink,
  EmptyStateTextHeader,
  EmptyStateTextBody,
  PaidPlanNoticeText,
} from './styles'
import { useTranslation } from 'react-i18next'
import { TagListItem } from './TagListItem'
import type { Tag } from '~publish/legacy/campaign/types'
import {
  useAccount,
  useOrganizationBilling,
  useOrganizationLimits,
} from '~publish/legacy/accountContext'
import { Notice } from '@bufferapp/ui'
import { Flash } from '@bufferapp/ui/Icon'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { useIdeasCountByTags } from '~publish/legacy/campaigns-list/hooks/useIdeasCountByTags'
import { useDispatch } from 'react-redux'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { TagsUpgradePathLink } from '~publish/legacy/shared-components/TagsUpgradePathLink/TagsUpgradePathLink'
import { Button, PlusIcon, toast } from '@buffer-mono/popcorn'
import { TagEditDialog } from '~publish/components/TagEditDialog'
import { useCreateTag } from '~publish/hooks/useTags'

type TagsListProp = {
  tags: Array<Tag>
  goToAnalyzeReport: (tag: Tag) => void
  showTagActions: boolean
  hideAnalyzeReport: boolean
  isLoading?: boolean
}

type ListProp = {
  tags: Array<Tag>
  ideasCounts: Array<{ tagId: string; count: number }>
  goToAnalyzeReport: (tag: Tag) => void
  hideAnalyzeReport: boolean
  showTagActions: boolean
  showIdeas: boolean
}

const List = ({
  tags,
  ideasCounts,
  goToAnalyzeReport,
  hideAnalyzeReport,
  showTagActions,
  showIdeas,
}: ListProp): JSX.Element => {
  const listItems = tags.map((tag) => (
    <TagListItem
      key={tag.id}
      tag={tag}
      ideasCounts={ideasCounts}
      goToAnalyzeReport={goToAnalyzeReport}
      hideAnalyzeReport={hideAnalyzeReport}
      showTagsAction={showTagActions}
      showIdeas={showIdeas}
    />
  ))
  const headerNames = [
    'Name',
    'Ideas',
    'Drafts',
    'Approvals',
    'Scheduled',
    'Sent',
  ]
  if (!showIdeas) headerNames.splice(1, 1)
  const listHeaders = headerNames.map((name: string) => (
    <ListHeaderItem key={name}>{name}</ListHeaderItem>
  ))
  return (
    <StyledTable>
      <ListHeader>{listHeaders}</ListHeader>
      <StyledList data-testid="tags-list">{listItems}</StyledList>
    </StyledTable>
  )
}

export const TagsList = ({
  tags,
  goToAnalyzeReport,
  showTagActions,
  hideAnalyzeReport,
  isLoading = false,
}: TagsListProp): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { counts } = useIdeasCountByTags()
  const { tags: tagsLimit } = useOrganizationLimits()
  const isAtTagLimit = tagsLimit && tags && tags.length >= tagsLimit
  const account = useAccount()
  const currentOrganization = account?.account?.currentOrganization
  const billing = useOrganizationBilling()
  const isFree = billing?.subscription?.plan?.id === 'free'
  const freeAtLimit = isFree && isAtTagLimit
  const paidAtLimit = !isFree && isAtTagLimit
  const commonTrackingProps =
    currentOrganization?.commonTrackingProperties || null
  const canStartTrial = billing?.canStartTrial
  const upgradePathName = canStartTrial
    ? 'tagsLimit-trial'
    : 'tagsLimit-upgrade'

  const cta = canStartTrial
    ? SEGMENT_NAMES.TAGS_LIST_LIMIT_TRIAL
    : SEGMENT_NAMES.TAGS_LIST_LIMIT_UPGRADE

  const TrackCTAClicked = (): void => {
    BufferTracker.cTAClicked({
      organizationId: currentOrganization?.id || undefined,
      clientName: 'publishWeb',
      cta,
      upgradePathName,
      product: 'publish',
      ...commonTrackingProps,
    })
    dispatch(modalActions.showTagsLimitUpgradeModal())
  }

  const createTag = useCreateTag()
  const handleSaveTag = async (tag: {
    id?: string
    name: string
    color: string
  }): Promise<void> => {
    try {
      await createTag(tag)
    } catch (error) {
      toast.error((error as Error).message)
    }
  }

  return (
    <Wrapper>
      <Header>
        <TextWithSkeletonStyled
          type="h2"
          displaySkeleton={isLoading}
          aria-label={isLoading ? t('common.loading') : null}
        >
          {t('campaigns.common.tagTitle')}
        </TextWithSkeletonStyled>
        {showTagActions && !freeAtLimit && (
          <TagEditDialog onSubmit={handleSaveTag}>
            <Button
              variant="primary"
              size="large"
              disabled={isLoading || !!paidAtLimit}
            >
              <PlusIcon />
              {t('campaigns.common.createTag')}
            </Button>
          </TagEditDialog>
        )}
        {freeAtLimit && (
          <Button
            variant="primary"
            onClick={TrackCTAClicked}
            size="large"
            disabled={isLoading}
          >
            <Flash color="white" />
            {t('campaigns.common.createTag')}
          </Button>
        )}
      </Header>
      {tags && tags.length ? (
        <>
          {paidAtLimit && (
            <Notice type="warning" className={'paidPlanTagsLimitReached'}>
              <PaidPlanNoticeText type="p">
                {t('campaigns.limits.defaultMessage', { tagsLimit })}
              </PaidPlanNoticeText>
            </Notice>
          )}
          {isFree && (
            <FreeNotice>
              {t('campaigns.limits.upgradeMessage', { tagsLimit })}
              <TagsUpgradePathLink />
            </FreeNotice>
          )}
          <Main id="main">
            {!isLoading && (
              <List
                tags={tags}
                ideasCounts={counts}
                goToAnalyzeReport={goToAnalyzeReport}
                hideAnalyzeReport={hideAnalyzeReport}
                showTagActions={showTagActions}
                showIdeas={currentOrganization?.isOneBufferOrganization}
              />
            )}
            {isLoading && <SkeletonList showCampaignActions={showTagActions} />}
          </Main>
        </>
      ) : (
        <EmptyStateWrapper>
          <StyledImage
            src="https://buffer-publish.s3.amazonaws.com/images/empty-tags-overview.png"
            alt="empty tags illustration"
          />
          <EmptyStateTextHeader type="h2">
            {t('campaigns.emptyState.tagCreateHeading')}
          </EmptyStateTextHeader>
          <EmptyStateBody>
            <EmptyStateTextBody type="p">
              {t('campaigns.emptyState.tagCreateSubheading')}
            </EmptyStateTextBody>
          </EmptyStateBody>
          <EmptyStateLink
            href={`https://support.buffer.com/article/585-creating-and-managing-campaigns?${HC_UTM_PARAMS}`}
            newTab
          >
            Learn more about tags
          </EmptyStateLink>
        </EmptyStateWrapper>
      )}
    </Wrapper>
  )
}

export default TagsList
