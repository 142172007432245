import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Text, VisuallyHidden } from '@buffer-mono/popcorn'

import { useTruncatedText } from '~publish/hooks/useTruncatedText'

import { usePostData } from './PostCardContext'
import { PostCardNotice } from './PostCardNotice'

import { usePostDetailsDialog } from '../PostManagementRouter/helpers'
import styles from './PostCardErrorNotice.module.css'

export const PostCardErrorNotice = ({
  clampErrorMessage = false,
}: {
  clampErrorMessage?: boolean
}): JSX.Element | null => {
  const { status, error, id } = usePostData()
  const textRef = React.useRef<HTMLParagraphElement>(null)

  const { constructDetailsDialogLocation } = usePostDetailsDialog({
    postId: id,
  })

  const [truncatedText, didTruncate] = useTruncatedText({
    text: error?.message ?? '',
    lineClamp: 1,
    container: textRef,
    expansionText: '... more',
  })

  const showMoreLink = clampErrorMessage && didTruncate

  const detailsDialogLocation = React.useMemo(() => {
    return constructDetailsDialogLocation({
      cta: 'publish-calendar-postCardError-seeMore-1',
    })
  }, [constructDetailsDialogLocation])

  if (status !== 'error' || !error) {
    return null
  }

  return (
    <PostCardNotice variant="critical">
      <VisuallyHidden>Error: </VisuallyHidden>
      <Text ref={textRef} className={styles.message}>
        {clampErrorMessage ? truncatedText : error.message}
        {showMoreLink && (
          <>
            <span>...&nbsp;</span>
            <Link to={detailsDialogLocation} className={styles.moreLink}>
              more
            </Link>
          </>
        )}
      </Text>

      {error.supportUrl && (
        <PostCardNotice.Action>
          <Button
            variant="critical"
            size="small"
            as="a"
            href={error.supportUrl ?? ''}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn More
          </Button>
        </PostCardNotice.Action>
      )}
    </PostCardNotice>
  )
}

PostCardErrorNotice.displayName = 'PostCardErrorNotice'
