import {
  ClockIcon,
  ExpandIcon,
  Flex,
  IconButton,
  Text,
} from '@buffer-mono/popcorn'
import React from 'react'
import { useDateTimeFormatter } from '~publish/hooks/useDateTimeFormatter'

import { Link } from 'react-router-dom'
import { usePostData } from '~publish/components/PostCard/PostCardContext'
import { usePostDetailsDialog } from '~publish/components/PostManagementRouter/helpers'
import { PostNotifyBadge } from '~publish/components/PostNotifyBadge'
import { PostStatusBadge } from '~publish/components/PostStatusBadge'
import styles from './CalendarPostCardHeader.module.css'

// TODO: we have this same component in PostCardHeader, we should move it to a shared component
const TextSeparator = (): JSX.Element => (
  <Text color="subtle" aria-hidden={true}>
    {' • '}
  </Text>
)

const CustomScheduleLabel = (): JSX.Element => {
  return (
    <Text size="sm" color="subtle" className={styles.customLabel}>
      <TextSeparator />
      <Flex gap="2xs" align="center">
        <ClockIcon size="xsmall" />
        <span>Custom</span>
      </Flex>
    </Text>
  )
}

export const CalendarPostCardHeader = (): JSX.Element => {
  const {
    id,
    dueAt,
    isCustomScheduled,
    status,
    notificationStatus,
    schedulingType,
  } = usePostData()
  const formatDateTime = useDateTimeFormatter()

  const { constructDetailsDialogLocation } = usePostDetailsDialog({
    postId: id,
  })

  const detailsDialogLocation = React.useMemo(
    () =>
      constructDetailsDialogLocation({
        cta: 'publish-calendar-postCardHeader-expandButton-1',
      }),
    [constructDetailsDialogLocation],
  )

  return (
    <header className={styles.header}>
      <Flex gap="sm" align="center">
        <Flex gap="2xs" align="center">
          <Text size="md" weight="medium">
            {dueAt
              ? formatDateTime(dueAt, { includeTimeZone: false })
              : 'No date'}
          </Text>
          {isCustomScheduled && <CustomScheduleLabel />}
        </Flex>
        <PostStatusBadge />
        <PostNotifyBadge
          schedulingType={schedulingType}
          status={status}
          notificationStatus={notificationStatus}
        />
      </Flex>

      <Flex gap="xs">
        {/* TODO: Re-add notes button when notes is supported in details dialog */}
        {/* <IconButton
            as={Link}
            to={url}
            label="Notes"
            size="small"
            variant="tertiary"
          >
            <CommentRoundIcon />
          </IconButton> */}
        <IconButton
          as={Link}
          to={detailsDialogLocation}
          label="Expand"
          size="small"
          variant="tertiary"
        >
          <ExpandIcon />
        </IconButton>
      </Flex>
    </header>
  )
}
