import React from 'react'
import { Link, Notice } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { useAccount } from '~publish/legacy/accountContext'
import { RegisteredBanner } from '../../components/RegisteredBanner'

export const TiktokUSBanBanner = (): JSX.Element | null => {
  type SplitConfig = {
    publishBannerHeader: string
    publishBannerCopy: string
    publishBannerCtaCopy: string
    publishBannerCtaUrl: string
  }

  const { isEnabled: isTikTokUsBanEnabled, config } =
    useSplitEnabled('tiktok-us-ban')

  const bannerId = 'TiktokUSBanBanner'
  const { account } = useAccount()

  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })

  const connectedChannels = account?.currentOrganization?.channels
  const hasTiktokChannelConnected = connectedChannels?.some(
    (channel) => channel.service === 'tiktok',
  )

  const bannerConfig = config as SplitConfig

  const canRenderBanner = banner.isActive && hasTiktokChannelConnected

  if (!isTikTokUsBanEnabled) {
    return null
  }

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <Notice variant="warning" onDismiss={banner.dismiss}>
        {bannerConfig?.publishBannerHeader && (
          <Notice.Heading>{bannerConfig?.publishBannerHeader}</Notice.Heading>
        )}
        {bannerConfig?.publishBannerCopy && (
          <Notice.Text>
            {bannerConfig?.publishBannerCopy}{' '}
            {bannerConfig?.publishBannerCtaCopy &&
              bannerConfig?.publishBannerCtaUrl && (
                <Link
                  color="inherit"
                  href={bannerConfig?.publishBannerCtaUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {bannerConfig?.publishBannerCtaCopy}
                </Link>
              )}
          </Notice.Text>
        )}
      </Notice>
    </RegisteredBanner>
  )
}
