import React, { useState } from 'react'
import {
  CoachMark,
  Button,
  Flex,
  CalendarCheckIcon,
} from '@buffer-mono/popcorn'

import StreakDetailedIcon from '../images/StreakDetailedIcon'
import { StreakHeader } from './StreakHeader'

import styles from './StreakCoachMark.module.css'
import useDismissBanner, {
  BannerTypes,
} from '../../../../../common/hooks/useDismissBanner'

const COACH_MARK_CONTENT = [
  {
    title: 'Build your streak!',
    Icon: StreakDetailedIcon,
    description:
      'Post each week to grow your streak! Miss a week and it resets.',
  },
  {
    title: 'Keep the momentum',
    Icon: CalendarCheckIcon,
    description:
      "Consistent posting helps grow your audience. Pre-schedule posts to keep your streak when you're busy!",
  },
] as const

export const StreakCoachMark = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(0)

  const { isActive, onDismissBanner } = useDismissBanner({
    banner: BannerTypes.streakCoachMark,
  })

  if (!isActive) return <>{children}</>

  const isLastStep = currentStep === COACH_MARK_CONTENT.length - 1
  const currentContent = COACH_MARK_CONTENT[currentStep]

  const handleNext = (): void => {
    if (isLastStep) {
      onDismissBanner()
    } else {
      setCurrentStep((prev) => prev + 1)
    }
  }

  const Icon = currentContent.Icon

  return (
    <CoachMark onDismiss={onDismissBanner}>
      <CoachMark.Overlay>
        {children}
        <CoachMark.Spotlight />
      </CoachMark.Overlay>

      <CoachMark.Content showCloseIcon={false} className={styles.streakContent}>
        <StreakHeader>
          <StreakHeader.Background />
          <StreakHeader.Content>
            <Icon size={40} />
          </StreakHeader.Content>
        </StreakHeader>
        <Flex direction="column" align="center" gap="xs">
          <CoachMark.Title className={styles.streakTitle}>
            {currentContent.title}
          </CoachMark.Title>
          <CoachMark.Description
            className={styles.streakDescription}
            size="sm"
            color="subtle"
            lineHeight="regular"
          >
            {currentContent.description}
          </CoachMark.Description>
        </Flex>
        <CoachMark.Footer>
          {isLastStep ? (
            <CoachMark.Dismiss>
              <Button size="small">Got it</Button>
            </CoachMark.Dismiss>
          ) : (
            <Button size="small" onClick={handleNext}>
              Next
            </Button>
          )}
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
