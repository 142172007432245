import * as React from 'react'

const StreakDetailedIcon = React.forwardRef(
  (
    {
      size,
      stroke,
      ...props
    }: Omit<React.SVGAttributes<SVGElement>, 'children' | 'stroke'> & {
      size?: number
      stroke?: number
    },
    ref: React.ForwardedRef<SVGSVGElement>,
  ) => {
    const iconName = 'streak-detailed'

    return (
      <svg
        strokeWidth={stroke ?? 2}
        width={size ?? 24}
        // To keep the same aspect ratio as the original icon
        // size * (1 + original height / original width)
        // This is due to the ellipse path (plant shadow) that produces a
        // different aspect ratio than the rest of the icon
        height={(size ?? 24) * (1 + 45 / 40)}
        viewBox="0 0 40 45"
        data-icon={iconName}
        aria-hidden="true"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
      >
        <ellipse
          cx="20.966"
          cy="39.7418"
          rx="10.2414"
          ry="5.12069"
          fill="#004042"
          fillOpacity="0.25"
        />

        <g clipPath="url(#clip0_3_726)">
          <path
            d="M40 2.5C40 11.375 33.3906 18.7109 24.8281 19.8438C24.2734 15.6719 22.4375 11.9062 19.7266 8.96094C22.7188 3.61719 28.4375 0 35 0H37.5C38.8828 0 40 1.11719 40 2.5ZM0 7.5C0 6.11719 1.11719 5 2.5 5H5C14.6641 5 22.5 12.8359 22.5 22.5V37.5C22.5 38.8828 21.3828 40 20 40C18.6172 40 17.5 38.8828 17.5 37.5V25C7.83594 25 0 17.1641 0 7.5Z"
            fill="currentColor"
          />
        </g>
        <path
          d="M5 12C5.59259 13.634 7.75556 17.5882 11.6667 20.3333"
          stroke="#D7F0A8"
          strokeWidth="1.7069"
          strokeLinecap="round"
        />
        <defs>
          <clipPath id="clip0_3_726">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  },
)

StreakDetailedIcon.displayName = 'StreakDetailedIcon'

export default StreakDetailedIcon
