import React, { useCallback, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import {
  Flex,
  Heading,
  Separator,
  Switch,
  Text,
  toast,
} from '@buffer-mono/popcorn'

import { graphql } from '~publish/gql'
import type { EmailNotificationPreference } from '~publish/gql/graphql'
import SettingsPageLayout from './components/SettingsPageLayout'

import style from './Settings.module.css'
import EmailNotificationsSkeleton from './components/EmailNotificationsSkeleton'

export const GetEmailNotificationPreferencesQuery = graphql(`
  query getEmailNotificationPreferencesQuery {
    emailNotificationPreferences {
      __typename
      ... on EmailNotificationPreferencesResponse {
        preferences {
          id
          name
          description
          subscribed
          cioTopicId
        }
      }
      ... on EmailNotificationPreferenceError {
        message
      }
    }
  }
`)

export const UpdateEmailNotificationPreferencesMutation = graphql(`
  mutation emailNotificationPreferences(
    $input: [EmailNotificationPreferenceInput!]!
  ) {
    emailNotificationPreferences(input: $input) {
      __typename
      ... on EmailNotificationPreferencesResponse {
        preferences {
          id
          name
          description
          subscribed
          cioTopicId
        }
      }
      ... on EmailNotificationPreferenceError {
        message
      }
    }
  }
`)

type NotificationOptionProps = {
  id: string
  subscribed: boolean
}

const ACCOUNT_ACTIVITIES_GROUP = [
  'postFailure',
  'postPublished', // Check if this is Correct
  'queue',
  'weeklyAnalyticsReport',
  'milestones',
  'dailyPostRecap',
  'collaboration',
  'channelConnectionUpdates',
  'billing',
]
const FROM_BUFFER_GROUP = [
  'gettingStarted',
  'socialPostPromptsAndIdeas',
  'userFeedbackAndResearch',
  'productUpdatesAndNews',
  'newsletter',
  'openBlogNewsletter',
  'bufferCareers',
]

// Filter and sort the preferences based on the order of the GROUP array
function filterAndSortPreferences(
  preferences: EmailNotificationPreference[],
  orderArray: string[],
): EmailNotificationPreference[] {
  return preferences
    ?.filter((preference) => orderArray.includes(preference.id))
    .sort((a, b) => orderArray.indexOf(a.id) - orderArray.indexOf(b.id))
}

const NotificationOption = ({
  preferenceData,
  updateNotificationSetting,
}: {
  preferenceData: EmailNotificationPreference
  updateNotificationSetting: (options: NotificationOptionProps) => void
}): JSX.Element => {
  const { id, subscribed, name, description } = preferenceData
  const [isOn, setIsOn] = useState(subscribed)

  function handleOnSwitchChange(value: boolean): void {
    setIsOn(value)
    updateNotificationSetting({ id, subscribed: value })
  }

  return (
    <div className={style.fullWidth}>
      <Heading as="h2" size="xsmall" id={`${id}-heading`}>
        {name}
      </Heading>
      <Flex
        gap="xs"
        align="start"
        justify="between"
        className={style.fullWidth}
      >
        <Text id={`${id}-desc`}>{description}</Text>
        <Switch
          id={id}
          onCheckedChange={handleOnSwitchChange}
          checked={isOn}
          aria-labelledby={`${id}-heading`}
          aria-describedby={`${id}-desc`}
        />
      </Flex>
    </div>
  )
}

export const EmailNotifications = (): JSX.Element => {
  const { data } = useQuery(GetEmailNotificationPreferencesQuery)
  const [updateEmailNotificationPreferences] = useMutation(
    UpdateEmailNotificationPreferencesMutation,
  )

  const updateNotificationSetting = useCallback(
    async (options: NotificationOptionProps): Promise<void> => {
      try {
        const result = await updateEmailNotificationPreferences({
          variables: {
            input: {
              id: options.id,
              subscribed: options.subscribed,
            },
          },
        })

        // Handle Toast Messages
        if (
          result.data?.emailNotificationPreferences.__typename ===
          'EmailNotificationPreferencesResponse'
        ) {
          toast.success('Great! Your notification settings have been saved')
        }

        if (
          result.data?.emailNotificationPreferences.__typename ===
          'EmailNotificationPreferenceError'
        ) {
          toast.error(result.data.emailNotificationPreferences.message)
        }
      } catch (error) {
        console.error(
          'Sorry! There was an issue updating your email preferences. Please try again.',
          error,
        )
      }
    },
    [updateEmailNotificationPreferences],
  )

  const preferencesList =
    data?.emailNotificationPreferences.__typename ===
    'EmailNotificationPreferencesResponse'
      ? data?.emailNotificationPreferences.preferences
      : null

  const accountActivitiesPreferences = filterAndSortPreferences(
    preferencesList ?? [],
    ACCOUNT_ACTIVITIES_GROUP,
  ).sort(
    (a, b) =>
      ACCOUNT_ACTIVITIES_GROUP.indexOf(a.id) -
      ACCOUNT_ACTIVITIES_GROUP.indexOf(b.id),
  )

  const fromBufferPreferences = filterAndSortPreferences(
    preferencesList ?? [],
    FROM_BUFFER_GROUP,
  )

  return (
    <SettingsPageLayout>
      {preferencesList ? (
        <>
          <section>
            <Flex gap="xl" direction="column">
              <Heading as="h1" size="large">
                Email notifications
              </Heading>
              <Heading as="h2" size="small">
                Account activities
              </Heading>
              {accountActivitiesPreferences?.map(
                (emailNotificationPreference) => (
                  <NotificationOption
                    key={emailNotificationPreference.id}
                    preferenceData={emailNotificationPreference}
                    updateNotificationSetting={updateNotificationSetting}
                  />
                ),
              )}
            </Flex>
          </section>
          <Separator />
          <section style={{ paddingBottom: 'var(--space-2xl)' }}>
            <Flex gap="xl" direction="column">
              <Heading as="h2" size="small">
                From Buffer
              </Heading>
              {fromBufferPreferences?.map((emailNotificationPreference) => (
                <NotificationOption
                  key={emailNotificationPreference.id}
                  preferenceData={emailNotificationPreference}
                  updateNotificationSetting={updateNotificationSetting}
                />
              ))}
            </Flex>
          </section>
        </>
      ) : (
        // Skeleton
        <Flex
          gap="xl"
          direction="column"
          data-testid="email-notifications-skeleton"
        >
          <Heading as="h1" size="large">
            Email notifications
          </Heading>
          {ACCOUNT_ACTIVITIES_GROUP.map((option) => (
            <EmailNotificationsSkeleton key={option} />
          ))}
          {FROM_BUFFER_GROUP.map((option) => (
            <EmailNotificationsSkeleton key={option} />
          ))}
        </Flex>
      )}
    </SettingsPageLayout>
  )
}

export default EmailNotifications
