import { graphql } from '~publish/gql'
import { type ApolloError, type ApolloCache, useQuery } from '@apollo/client'
import { useOrganizationId } from '~publish/legacy/accountContext'
import type {
  GetFeedCollectionsListForSidebarQueryVariables,
  GetFeedCollectionsListForSidebarQuery,
} from '~publish/gql/graphql'

export type Query = GetFeedCollectionsListForSidebarQuery
export type QueryVariables = GetFeedCollectionsListForSidebarQueryVariables

export const GetFeedCollectionsListForSidebar = graphql(/* GraphQL */ `
  query GetFeedCollectionsListForSidebar(
    $feedCollectionLimit: Int!
    $feedCollectionCursor: String
    $organizationId: OrganizationId!
  ) {
    feedCollections(
      first: $feedCollectionLimit
      after: $feedCollectionCursor
      input: { organizationId: $organizationId }
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`)

export type FeedCollection = NonNullable<
  GetFeedCollectionsListForSidebarQuery['feedCollections']['edges']
>[number]['node']

type UseFeedCollectionsResult = {
  items: FeedCollection[]
  loading: boolean
  error?: ApolloError
}

export function useFeedCollections(): UseFeedCollectionsResult {
  const organizationId = useOrganizationId()

  const variables = {
    feedCollectionLimit: 100,
    organizationId,
  }

  const { data, loading, error } = useQuery<Query, QueryVariables>(
    GetFeedCollectionsListForSidebar,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  )

  const items = data?.feedCollections?.edges?.map((edge) => edge.node) ?? []

  return {
    items,
    loading,
    error,
  }
}

export const insertNewFeedCollectionToCache = (
  cache: ApolloCache<Query>,
  newFeedCollection: { name: string; id: string; organizationId: string },
): void => {
  // Read the existing query
  const existingData = cache.readQuery({
    query: GetFeedCollectionsListForSidebar,
    variables: {
      feedCollectionLimit: 100,
      organizationId: newFeedCollection.organizationId,
    },
  })

  // Write back with the new feed collection
  cache.writeQuery({
    query: GetFeedCollectionsListForSidebar,
    variables: {
      feedCollectionLimit: 100,
      organizationId: newFeedCollection.organizationId,
    },
    data: {
      feedCollections: {
        edges: [
          {
            node: {
              id: newFeedCollection.id,
              name: newFeedCollection.name,
              __typename: 'FeedCollection',
            },
          },
          ...(existingData?.feedCollections?.edges || []),
        ],
        __typename: 'FeedCollectionConnection',
      },
    },
  })
}
