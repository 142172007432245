import React, { useEffect } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  Form,
  Paragraph,
  Select,
  Textarea,
} from '@buffer-mono/popcorn'

import style from '../ProfileSettings.module.css'

const ConfirmRemoveAccountModal = ({
  onConfirm,
  close,
  isOpen,
}: {
  onConfirm: (reason: { reason: string; message: string }) => void
  close: () => void
  isOpen: boolean
}): JSX.Element => {
  const [reason, setReason] = React.useState<string | null>(null)
  const [message, setMessage] = React.useState<string | null>(null)
  const [confirmed, setConfirmed] = React.useState<boolean>(false)
  const [canSubmit, setCanSubmit] = React.useState<boolean>(false)

  useEffect(() => {
    setCanSubmit(!!reason && !!confirmed)
  }, [reason, confirmed])

  function handleSubmit(): void {
    if (canSubmit) {
      onConfirm({
        reason: reason || '',
        message: message || '',
      })
    }
  }

  return (
    <Dialog modal open={isOpen}>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>
            You&apos;re about to delete your Buffer account
          </Dialog.Title>
          <Dialog.Description>
            We would love to know more, please choose from the options below.
          </Dialog.Description>
        </Dialog.Header>
        <Dialog.Body>
          <Form name="confirmRemoveAccount">
            <Flex direction="column" gap="md">
              <Form.Field name="reason" className={style.fullWidth}>
                <Form.Label>Why are you deleting your account?</Form.Label>
                <Form.Control>
                  <Select onValueChange={setReason}>
                    <Select.Trigger
                      placeholder="Select a reason"
                      data-testid="reason-select"
                      style={{ justifyContent: 'space-between' }}
                    />
                    <Select.Content>
                      <Select.Item value="extenuating-circumstances">
                        Extenuating Circumstances (e.g. illness, budget cuts,
                        etc.)
                      </Select.Item>
                      <Select.Item value="not-using-anymore">
                        I don&apos;t use the product anymore
                      </Select.Item>
                      <Select.Item value="too-expensive">
                        Too expensive
                      </Select.Item>
                      <Select.Item value="missing-features">
                        Missing features
                      </Select.Item>
                      <Select.Item value="bugs">
                        Bugs or usability issues
                      </Select.Item>
                      <Select.Item value="others">Other</Select.Item>
                    </Select.Content>
                  </Select>
                </Form.Control>
              </Form.Field>
              <Form.Field name="message" className={style.fullWidth}>
                <Form.Label htmlFor="message">
                  Can you provide more detail about your answer above?
                </Form.Label>
                <Form.Control>
                  <Textarea
                    resize="auto"
                    rows={1}
                    onChange={(e): void => {
                      setMessage(e.target.value)
                    }}
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field name="confirmCheckbox" className={style.fullWidth}>
                <Paragraph className={style.confirmDeleteText}>
                  Please keep in mind that{' '}
                  <b>deleting your Buffer account is permanent</b> and will
                  delete all account data stored with Buffer. This includes
                  publishing, engagement, and analytics. However, we will not
                  remove any historical posts made to a channel.
                </Paragraph>
                <Form.Control name="confirmCheckbox">
                  <Checkbox
                    onChange={(checked): void => {
                      setConfirmed(checked === true)
                    }}
                    name="confirmCheckbox"
                  >
                    Yes, I would like to delete my account
                  </Checkbox>
                </Form.Control>
              </Form.Field>
            </Flex>
          </Form>
        </Dialog.Body>
        <Dialog.Footer>
          <Button variant="tertiary" size="large" onClick={close}>
            Cancel
          </Button>

          <Button
            variant="primary"
            size="large"
            onClick={handleSubmit}
            disabled={!canSubmit}
          >
            Delete my account
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

export default ConfirmRemoveAccountModal
