import React from 'react'
import {
  CheckIcon,
  Flex,
  Text,
  Heading,
  Image,
  Paragraph,
  Button,
} from '@buffer-mono/popcorn'

import {
  useCanStartTrial,
  useIsLegacyPlan,
} from '~publish/legacy/accountContext'
import { navigateToMigrationHub } from '~publish/legacy/upgrade-paths/helpersUpgradePaths'
import { useCta } from '~publish/hooks/useCta'

import styles from './ApprovalsUpgradePath.module.css'

const ctaKey = 'publish-postTabs-approvals-upgrade-2'

export const ApprovalsUpgradePath = (): JSX.Element => {
  const canStartTrial = useCanStartTrial()
  const isLegacyPlan = useIsLegacyPlan()

  const upgradePathName = canStartTrial
    ? 'approvals-trial'
    : 'approvals-upgrade'
  const cta = useCta(ctaKey, {
    upgradePathName,
  })

  const handleClick = (): void => {
    cta.clicked()

    if (isLegacyPlan) {
      navigateToMigrationHub(ctaKey)
      return
    }

    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.planSelector, {
      cta: ctaKey,
      upgradePathName,
    })
  }

  return (
    <div className={styles.container}>
      <Flex direction="column" gap="md">
        <Heading size="medium" as="h2">
          Collaboration made easy
        </Heading>
        <Paragraph>
          Say goodbye to the hassle of managing multiple social media channels
          with multiple team mates. With our collaboration features, you can:
        </Paragraph>
        {/* TODO: create List component in popcorn */}
        <ul className={styles.list}>
          <li>
            <CheckIcon color="brand" aria-hidden />
            <Text>
              Choose who can post on each of your social media channels
            </Text>
          </li>
          <li>
            <CheckIcon color="brand" aria-hidden />
            <Text>
              Review posts for quality and brand before hitting publish
            </Text>
          </li>
          <li>
            <CheckIcon color="brand" aria-hidden />
            <Text>Collaborate on ideas</Text>
          </li>
          <li>
            <CheckIcon color="brand" aria-hidden />
            <Text>Stay on top of performance with automated reports</Text>
          </li>
        </ul>
        {canStartTrial ? (
          <Button size="large" onClick={handleClick}>
            Start a Free Trial
          </Button>
        ) : (
          <Button size="large" onClick={handleClick}>
            Discover the Team Plan
          </Button>
        )}
      </Flex>
      <Image
        className={styles.image}
        src="https://buffer-ui.s3.amazonaws.com/upgrade-paths/publish/approvals-upsell-lite.png"
        alt=""
      />
    </div>
  )
}
