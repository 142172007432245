import React, { useMemo, useState, useCallback } from 'react'

import {
  Button,
  Input,
  Label,
  Link,
  Select,
  Text,
  Dialog,
  PlusIcon,
  BellIcon,
  Notice,
  Flex,
} from '@buffer-mono/popcorn'
import { useUpsertFeed } from '../../hooks'
import { useSplitEnabled } from '@buffer-mono/features'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useOrganizationId } from '~publish/legacy/accountContext'

const trackPageView = (): void => {
  BufferTracker.pageViewed({
    name: 'Feed Subscription Dialog',
    product: 'publish',
    path: window.location.pathname,
    clientName: 'publishWeb',
  })
}

type SubscribeToFeedDialogProps = {
  onToggleSubscribe: (id: string, subscribe: boolean) => Promise<void>
  disabledReason: 'limitReached' | null
  existingIds: string[]
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  feedCollectionId: string
}

export const SubscribeToFeedDialog = (
  props: SubscribeToFeedDialogProps,
): JSX.Element => {
  const {
    onToggleSubscribe,
    disabledReason,
    existingIds,
    isOpen,
    setIsOpen,
    feedCollectionId,
  } = props
  const [isErrorState, setIsErrorState] = useState(false)
  const newsEnabled = useSplitEnabled('enable_news_content_feed')
  const organizationId = useOrganizationId()
  const [urlScheme, setUrlScheme] = useState('https://')
  const [feedType, setFeedType] = useState<'news' | 'rssAtom'>('rssAtom')
  const [feedUrlSuffix, setFeedUrl] = useState('')
  const [feedQuery, setFeedQuery] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const feedUrl = useMemo(
    () => `${urlScheme.replace(/https?:\/\//, '')}${feedUrlSuffix}`,
    [urlScheme, feedUrlSuffix],
  )
  const upsertFeed = useUpsertFeed()
  const [isValid, message] = useMemo(() => {
    if (existingIds.includes(feedUrl)) {
      return [false, 'You have already subscribed to this feed']
    }
    try {
      if (feedType === 'news') {
        if (!feedQuery || feedQuery.length === 0) {
          return [false, 'Feed Query is required']
        }
        return [true, null]
      }

      if (!feedUrlSuffix || feedUrlSuffix.length === 0) {
        return [false, 'Feed URL is required']
      }
      if (!feedUrlSuffix.match(/^[^.]+\.[^.]+/)) {
        return [
          false,
          'Url must end in a valid top level domain, such as .com or .io',
        ]
      }
      return [new URL(feedUrl) !== null, null]
    } catch (e) {
      const message = e instanceof Error ? e.message : String(e)
      return [false, message]
    }
  }, [feedUrl, feedUrlSuffix, feedType, feedQuery, existingIds])

  const handleSubmit = useCallback(async (): Promise<void> => {
    let isError = false
    try {
      setIsSubmitting(true)
      const newsSearch = feedType === 'news' ? { query: feedQuery } : undefined
      const rssAtom = feedType === 'rssAtom' ? { url: feedUrl } : undefined
      const feed = await upsertFeed({
        newsSearch,
        organizationId,
        rssAtom,
      })

      if (feed?.__typename === 'FeedActionSuccess') {
        await onToggleSubscribe(feed.feed.id, true)
        setFeedUrl('')

        // Track feed subscription event
        BufferTracker.feedSubscribed({
          product: 'publish',
          organizationId,
          feedId: feed.feed.id,
          feedCollectionId,
          feedUrl: feedType === 'rssAtom' ? feedUrl : undefined,
          feedQuery: feedType === 'news' ? feedQuery : undefined,
          feedType,
          clientName: 'publishWeb',
        })
      } else {
        console.error('Failed to subscribe to feed')
        isError = true
      }
      setIsOpen(false)
    } catch (e) {
      console.error(e)
      isError = true
    }

    setIsSubmitting(false)

    setIsErrorState(isError)
  }, [
    feedUrl,
    onToggleSubscribe,
    organizationId,
    feedType,
    feedQuery,
    upsertFeed,
    setIsOpen,
    feedCollectionId,
  ])

  return (
    <>
      {isOpen && (
        <Dialog
          onOpenChange={(open): void => {
            setIsOpen(open)
            if (open) {
              trackPageView()
            }
          }}
          open={isOpen}
        >
          <Dialog.Content size="medium">
            <Dialog.Header>
              <Dialog.Title>Subscribe to Feed</Dialog.Title>
            </Dialog.Header>
            <Dialog.Body>
              <Flex direction="column" gap="md">
                {isErrorState && (
                  <Notice color="critical">
                    <Notice.Heading>Failed to subscribe to feed</Notice.Heading>
                    <Notice.Text>
                      {`Please check `}
                      <Link href={feedUrl} external>
                        {feedUrl}
                      </Link>
                      {` to ensure it is a valid RSS or Atom feed`}
                    </Notice.Text>
                  </Notice>
                )}
                {newsEnabled && (
                  <div>
                    <Label htmlFor="new-feed-type">Feed Type</Label>
                    <Select
                      value={feedType}
                      onValueChange={(e): void =>
                        setFeedType(e as 'news' | 'rssAtom')
                      }
                    >
                      <Select.Trigger placeholder="Feed Type" />
                      <Select.Content>
                        <Select.Item value="rssAtom">RSS/Atom</Select.Item>
                        <Select.Item value="news">News</Select.Item>
                      </Select.Content>
                    </Select>
                  </div>
                )}
                {feedType === 'rssAtom' && (
                  <div>
                    <Label htmlFor="new-feed-url">Feed URL</Label>
                    <Flex direction="row" gap="sm">
                      <Select
                        value={urlScheme}
                        onValueChange={(e): void =>
                          setUrlScheme(e as 'https://' | 'http://')
                        }
                      >
                        <Select.Trigger placeholder="Feed Type" />
                        <Select.Content>
                          <Select.Item value="https://">https://</Select.Item>
                          <Select.Item value="http://">http://</Select.Item>
                        </Select.Content>
                      </Select>
                      <Input
                        id="new-feed-url"
                        size="large"
                        aria-invalid={!isValid}
                        value={feedUrlSuffix}
                        disabled={isSubmitting}
                        onChange={(e): void => setFeedUrl(e.target.value)}
                      />
                    </Flex>
                  </div>
                )}
                {feedType === 'news' && (
                  <div>
                    <Label htmlFor="new-feed-query">Feed Query</Label>
                    <Input
                      id="new-feed-query"
                      size="large"
                      aria-invalid={!isValid}
                      value={feedQuery}
                      disabled={isSubmitting}
                      onChange={(e): void => setFeedQuery(e.target.value)}
                    />
                  </div>
                )}
                {!isValid && (
                  <Text size="sm" color="critical">
                    {message}
                  </Text>
                )}
              </Flex>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                variant="primary"
                disabled={!isValid || isSubmitting}
                onClick={handleSubmit}
              >
                <BellIcon />
                Subscribe
              </Button>
            </Dialog.Footer>
          </Dialog.Content>
        </Dialog>
      )}
      <Button
        variant="secondary"
        size="small"
        onClick={(): void => setIsOpen(true)}
        disabled={disabledReason !== null}
      >
        <PlusIcon />
        Add Subscription
      </Button>
    </>
  )
}
