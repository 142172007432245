import { useRef, useMemo, type DependencyList } from 'react'

/**
 * A custom React hook that combines useMemo with value caching based on a custom equality function.
 * This hook is useful when you want to memoize a value but need more control over how equality is determined,
 * or when you want to preserve reference equality even when the computed value is technically different but
 * semantically equivalent.
 *
 * @template T - The type of the value being memoized
 * @param {() => T} callback - A function that computes the value to be memoized
 * @param {(a: T, b: T) => boolean} equals - A custom equality function to compare new and cached values
 * @param {DependencyList} deps - An array of dependencies that trigger recomputation when changed
 * @returns {T} The memoized value, which may be either the newly computed value or the cached value
 *              if the equality function determines they are equivalent
 *
 * @example
 * // Example usage with array comparison
 * const memoizedArray = useCachedMemo(
 *   () => computeExpensiveArray(),
 *   (a, b) => a.length === b.length && a.every((item, i) => item === b[i]),
 *   [dependency]
 * );
 */
export const useCachedMemo = <T>(
  callback: () => T,
  equals: (a: T, b: T) => boolean,
  deps: DependencyList,
): T => {
  const lastValue = useRef<T | null>(null)
  return useMemo(() => {
    const value = callback()
    if (lastValue.current && equals(value, lastValue.current))
      return lastValue.current
    lastValue.current = value
    return value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
