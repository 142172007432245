import { useEffect, useState, type DependencyList } from 'react'

const polyfill = (callback: () => void) => setTimeout(() => callback(), 0)
const polyfillCancel = (task: number) => window.clearTimeout(task)
const scheduleTask = window.requestIdleCallback || polyfill
const cancelTask = window.cancelIdleCallback || polyfillCancel

/**
 * A React hook that memoizes a value computed during browser idle time.
 * Similar to `useMemo`, but defers the computation to a low-priority idle callback
 * using `requestIdleCallback` (or setTimeout as a fallback).
 *
 * The hook returns null initially and updates with the computed value once
 * the idle callback executes. This is useful for expensive computations that
 * aren't immediately needed for the initial render.
 *
 * @param callback - Function that computes the value to be memoized
 * @param deps - Dependency array that triggers recomputation when changed
 * @param defaultValue - Optional default value to use before the computation completes
 * @returns The memoized value, or null if not yet computed and no default value provided
 *
 * @example
 * ```tsx
 * // Without default value - returns T | null
 * const expensiveValue = useIdleMemo(
 *   () => computeExpensiveValue(props),
 *   [props]
 * );
 *
 * // With default value - returns T
 * const expensiveValue = useIdleMemo(
 *   () => computeExpensiveValue(props),
 *   [props],
 *   defaultComputedValue
 * );
 * ```
 */
export const useIdleMemo = <T>(
  callback: () => T,
  deps: DependencyList,
): T | null => {
  const [memoizedValue, setMemoizedValue] = useState<T | null>(null)

  useEffect(() => {
    const task = scheduleTask(() => {
      setMemoizedValue(callback())
    })

    return () => {
      cancelTask(task)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return memoizedValue ?? null
}
