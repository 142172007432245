import React from 'react'
import type { Idea } from '~publish/pages/Create/types'
import { MasonryStyled } from './styles'
import { IdeaCard } from '~publish/pages/Create/components/IdeaCard'
import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'

export const IdeasList = ({
  ideas,
  onDelete,
}: {
  ideas: Idea[]
  onDelete: (idea: Idea) => Promise<void>
}): JSX.Element => {
  const { editIdeaWithComposer } = useIdeaComposer()

  return (
    <MasonryStyled
      breakpointCols={{
        default: 7,
        1800: 6,
        1580: 5,
        1300: 4,
        1120: 3,
        860: 2,
        560: 1,
      }}
      className="ideas-masonry-grid"
      columnClassName="ideas-masonry-column"
    >
      {ideas.map((idea) => (
        <IdeaCard
          key={idea.id}
          idea={idea}
          onOpen={(idea) =>
            editIdeaWithComposer({
              ideaId: idea.id,
              source: 'publish-tagsPage-ideas-ideaCard-1',
            })
          }
          onDelete={(idea): Promise<void> => onDelete(idea as Idea)}
        />
      ))}
    </MasonryStyled>
  )
}
