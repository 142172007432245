import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'

import {
  Button,
  CheckIcon,
  CriticalIcon,
  Flex,
  Form,
  Input,
  Notice,
  Text,
  Tooltip,
  WarningIcon,
  toast,
} from '@buffer-mono/popcorn'

import { useAccount } from '~publish/legacy/accountContext'
import { graphql } from '~publish/gql'

import style from '../ProfileSettings.module.css'

import ConfirmPasswordModal from '../components/ConfirmPasswordModal'

export const INITIATE_UPDATE_EMAIL = graphql(/* GraphQL */ `
  mutation InitiateUpdateEmail($input: InitiateUpdateEmailInput!) {
    initiateUpdateEmail(input: $input) {
      __typename
      ... on EmptySuccess {
        _empty
      }
      ... on UnexpectedError {
        message
      }
      ... on InvalidInputError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`)

function isValidEmail(email: string): boolean {
  return !!email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

export const ACCOUNT_INITIATE_EMAIL_VERIFICATION = graphql(/* GraphQL */ `
  mutation AccountInitiateEmailVerification {
    accountInitiateEmailVerification {
      ... on AccountInitiateEmailVerificationResponse {
        success
      }
      ... on AccountInitiateEmailVerificationError {
        userFriendlyMessage
      }
    }
  }
`)

export const EmailVerificationBanner = (): JSX.Element | null => {
  const {
    account: { hasVerifiedEmail },
  } = useAccount()

  const [sent, setSent] = useState(false)
  const [initiateEmailVerification, { data, error }] = useMutation(
    ACCOUNT_INITIATE_EMAIL_VERIFICATION,
  )

  useEffect(() => {
    if (
      data?.accountInitiateEmailVerification?.__typename ===
        'AccountInitiateEmailVerificationResponse' &&
      data.accountInitiateEmailVerification.success
    ) {
      toast.success(
        'An email has been sent to your inbox to verify your email address.',
      )
    } else if (
      data?.accountInitiateEmailVerification?.__typename ===
      'AccountInitiateEmailVerificationError'
    ) {
      setSent(false)
      toast.error(data.accountInitiateEmailVerification.userFriendlyMessage)
    }
    if (error) {
      setSent(false)
      toast.error(
        'An error occurred while sending the verification email. Please try again later.',
      )
    }
  }, [data, error])

  if (hasVerifiedEmail) {
    return null
  }

  return (
    <Notice variant="warning">
      <Notice.Text>
        Verify your email: An email has been sent to your inbox to verify your
        email address.
      </Notice.Text>
      <Notice.Actions>
        {!sent && (
          <Button
            size="small"
            variant="warning"
            onClick={(): void => {
              setSent(true)
              initiateEmailVerification()
            }}
          >
            Re-send Verification Email
          </Button>
        )}
        {sent && <Text>Verification email sent!</Text>}
      </Notice.Actions>
    </Notice>
  )
}

export const Email = (): JSX.Element => {
  const [initiateUpdateEmail] = useMutation(INITIATE_UPDATE_EMAIL)
  const {
    account: { id: accountId, email: currentEmail, hasVerifiedEmail },
    refetchAccount,
  } = useAccount()

  const [newEmail, setNewEmail] = useState(currentEmail)
  const [dirty, setDirty] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [polling, setPolling] = useState(false)
  const [waitingForEmailConfirmation, setWaitingForEmailConfirmation] =
    useState<string | null>(null)

  useEffect(() => {
    setInvalid(false)
    if (waitingForEmailConfirmation) {
      // don't set dirty if we're waiting for the email
      // to be confirmed
      return
    }
    if (newEmail !== currentEmail) {
      setDirty(true)
    } else {
      setDirty(false)
    }
  }, [waitingForEmailConfirmation, newEmail, currentEmail])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null
    if (polling) {
      interval = setInterval(() => {
        if (refetchAccount) {
          refetchAccount()
        }
        if (currentEmail === waitingForEmailConfirmation) {
          setWaitingForEmailConfirmation(null)
          setNewEmail(currentEmail)
          setDirty(false)
          setInvalid(false)
          setPolling(false)
          toast.success(
            'Your email has been successfully confirmed and updated!',
            {
              duration: 15_000,
            },
          )
        }
      }, 2000)
    } else {
      if (interval) clearInterval(interval)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [polling, refetchAccount, currentEmail, waitingForEmailConfirmation])

  const handleSubmit = async (password: string): Promise<void> => {
    if (!accountId || !password || !newEmail) {
      console.log({
        accountId,
        password,
        newEmail,
      })
      toast.error('Unable to update email. Please try again.')
      return
    }

    const result = await initiateUpdateEmail({
      variables: {
        input: {
          accountId,
          password,
          newEmail,
        },
      },
    })

    if (result.data?.initiateUpdateEmail?.__typename !== 'EmptySuccess') {
      toast.error(
        'An error occurred while updating your email. Please try again later.',
      )
    } else {
      toast.success(
        'Email sent! Head to your inbox and click the verification link.',
      )
      setPolling(true)
      setWaitingForEmailConfirmation(newEmail)
    }
  }

  let suffix = null
  if (!dirty) {
    suffix = hasVerifiedEmail ? (
      <Tooltip content="Email is verified" arrow>
        <CheckIcon
          color="currentColor"
          style={{ color: 'var(--color-border-success)' }}
        />
      </Tooltip>
    ) : (
      <Tooltip content="Email is not verified" arrow>
        <WarningIcon
          color="currentColor"
          style={{ color: 'var(--color-border-warning)' }}
        />
      </Tooltip>
    )
  }

  return (
    <Form name="updateEmailForm" className={style.fullWidth}>
      <Flex gap="xs" align="end" className={style.fullWidth}>
        <Form.Field name="newEmail" className={style.fullWidth}>
          <Form.Label htmlFor="newEmail">Email</Form.Label>
          <Form.Control>
            <Input
              id="newEmail"
              className="flex-input"
              defaultValue={currentEmail ?? ''}
              onChange={(event): void => setNewEmail(event.target.value)}
              suffix={suffix}
              aria-invalid={invalid}
              data-1p-ignore="true"
              disabled={waitingForEmailConfirmation !== null}
            />
          </Form.Control>
        </Form.Field>
        <ConfirmPasswordModal
          description="To change your email, please enter your current password."
          onCancel={(): void => {
            setPolling(false)
          }}
          onConfirm={(password): void => {
            handleSubmit(password)
          }}
        >
          <Button
            size="medium"
            variant="secondary"
            disabled={!dirty || invalid || waitingForEmailConfirmation !== null}
            onClick={(e): void => {
              if (!newEmail || !isValidEmail(newEmail)) {
                toast.error('Please enter a valid email address.')
                setInvalid(true)
                e.preventDefault()
              }
            }}
          >
            Save Changes
          </Button>
        </ConfirmPasswordModal>
      </Flex>
      {invalid && (
        <Text color="critical" size="sm">
          <CriticalIcon size="xsmall" style={{ verticalAlign: 'middle' }} />{' '}
          Please enter a valid email address.
        </Text>
      )}
    </Form>
  )
}

export default Email
