import { connect } from 'react-redux'
import TabNavigation from './components/TabNavigation'
import type { RootState } from '../store'

// default export = container
export default connect((state: RootState) => {
  const { hasApprovalFeature, hasStoriesFeature } =
    state.organizations.selected || {}
  return {
    profileNavTabs: state.profileNav.profileNavTabs,
    profileId: state.profileSidebar?.selectedProfile?.id ?? '',
    showNestedSettingsTab: state.profileNav.selectedTabId === 'settings',
    hideStoriesTabs: true,
    draftsNeedApprovalCount: state.profileNav.draftsNeedApprovalCount,
    draftsCount: state.profileNav.draftsCount,
    showApprovalPaywallTag: !hasApprovalFeature,
    shouldShowNavUpgradeCta:
      state.organizations.selected?.shouldShowNavUpgradeCta,
  }
})(TabNavigation)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
