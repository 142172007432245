import React, { useState, useCallback } from 'react'

import { Button, Input, Dialog, Label } from '@buffer-mono/popcorn'
import { useUpdateFeedCollection, type FeedCollection } from '../../hooks'

type RenameCollectionDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  feedCollection: FeedCollection
}

export const RenameCollectionDialog = (
  props: RenameCollectionDialogProps,
): JSX.Element => {
  const { feedCollection, open, onOpenChange } = props

  const updateFeedCollection = useUpdateFeedCollection()

  const [name, setName] = useState(feedCollection.name ?? '')

  const updateName = useCallback(async (): Promise<void> => {
    await updateFeedCollection({
      name,
      feedCollectionId: feedCollection.id,
    })
    onOpenChange(false)
  }, [feedCollection.id, name, updateFeedCollection, onOpenChange])

  if (!open) {
    return <></>
  }

  return (
    <Dialog
      onOpenChange={onOpenChange}
      open={open}
      aria-description="Rename Collection Dialog"
    >
      <Dialog.Content size="small">
        <Dialog.Header>
          <Dialog.Title>Rename Collection “{feedCollection.name}”</Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Label htmlFor="feed-collection-name">Name</Label>
          <Input
            id="feed-collection-name"
            size="large"
            value={name}
            onChange={(e): void => setName(e.target.value)}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <Button variant="tertiary" onClick={(): void => onOpenChange(false)}>
            Cancel
          </Button>
          <Button variant="primary" disabled={!name} onClick={updateName}>
            Save
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
