import React from 'react'
import clsx from 'clsx'
import { useQuery, type QueryResult, gql } from '@apollo/client'

import {
  IconButton,
  Popover,
  Paragraph,
  Heading,
  Button,
  CheckIcon,
  StarIcon,
  WarningIcon,
} from '@buffer-mono/popcorn'

import { useUser } from '../../../../common/context/User'
import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'

import StreakIcon from './images/StreakIcon'
import { StreakCoachMark } from './components/StreakCoachMark'
import StreakDetailedIcon from './images/StreakDetailedIcon'
import { StreakHeader } from './components/StreakHeader'

import styles from './StreakWidget.module.css'

const WEEKLY_POSTING_STREAK_QUERY = gql`
  query WeeklyPostingStreak($input: WeeklyPostingStreakInput!) {
    weeklyPostingStreak(input: $input) {
      count
      status
      periodsAhead
      previousCount
      longestCount
    }
  }
`

interface WeeklyPostingStreakData {
  weeklyPostingStreak: {
    count: number
    status: 'Started' | 'OnTrack' | 'AtRisk' | 'Hit' | 'Missed'
    periodsAhead?: number
    previousCount?: number
    longestCount?: number
  } | null
}

interface WeeklyPostingStreakVars {
  input: {
    organizationId: string
  }
}

export const useWeeklyPostingStreak = (
  organizationId: string,
): QueryResult<WeeklyPostingStreakData, WeeklyPostingStreakVars> => {
  return useQuery<WeeklyPostingStreakData, WeeklyPostingStreakVars>(
    WEEKLY_POSTING_STREAK_QUERY,
    {
      variables: {
        input: {
          organizationId,
        },
      },
    },
  )
}

interface StreakContent {
  heading?: string
  text: string
  textHasHtml?: boolean
  cta?: string
  badge?: {
    icon: React.ReactNode
    text: string
    color?: 'green' | 'yellow' | 'gray'
  }
}

const getStreakContent = (
  streak: WeeklyPostingStreakData['weeklyPostingStreak'] | undefined,
): StreakContent | null => {
  if (!streak) return null

  if (streak.status === 'Started') {
    return {
      heading: 'Keep posting,\nbuild a streak!',
      text: 'Post each week to grow your streak! Miss a week and it resets.',
      cta: 'Schedule a post',
    }
  }
  if (streak.status === 'OnTrack') {
    if (streak.count === 0) {
      return streak.periodsAhead
        ? {
            heading: 'Look at you go!',
            text: "You're on track for next week, too. Keep up the great work.",
            badge: {
              icon: <CheckIcon size={12} />,
              text: 'On track and ahead',
              color: 'green',
            },
          }
        : {
            heading: 'Great job!',
            text: 'Your streak will begin once the post you scheduled is published.',
            badge: {
              icon: <CheckIcon size={12} />,
              text: 'On track',
              color: 'green',
            },
          }
    }

    // streak.count > 0
    return streak.periodsAhead
      ? {
          heading: `${streak.count} week streak`,
          badge: {
            icon: <StarIcon size={12} />,
            text: 'On track and ahead',
            color: 'green',
          },
          text: "Look at you go! You're on track for next week, too.",
        }
      : {
          heading: `${streak.count} week streak`,
          badge: {
            icon: <CheckIcon size={12} />,
            text: 'On track',
            color: 'green',
          },
          text: 'Add to your streak by scheduling a post for next week.',
          cta: 'Schedule post',
        }
  }

  if (streak.status === 'Hit') {
    if (streak.count === 1) {
      return streak.periodsAhead
        ? {
            heading: "You've started a streak! 🎉",
            text: "Look at you go! You're on track for next week. ",
            badge: {
              icon: <CheckIcon size={12} />,
              text: 'Ahead for next week',
              color: 'green',
            },
          }
        : {
            heading: "You've started a streak! 🎉",
            text: 'Schedule at least one post each week to keep your streak going.',
            cta: 'Schedule a post',
          }
    }

    // streak > 1
    return streak.periodsAhead
      ? {
          heading: `${streak.count} week streak`,
          badge: {
            icon: <StarIcon size={12} />,
            text: 'On track and ahead',
            color: 'green',
          },
          text: "Look at you go! You're on track for next week, too.",
        }
      : {
          heading: `${streak.count} week streak`,
          text: 'Great job! Schedule a post for next week to keep the streak going.',
          cta: 'Schedule post',
        }
  }

  if (streak.status === 'AtRisk') {
    return {
      heading: `${streak.count} week streak`,
      text: 'Schedule a post for this week to keep your streak alive! Need ideas? <a href="https://buffer.com/resources/social-media-content-types/" target="_blank" rel="noreferrer">Check out these tips.</a>',
      textHasHtml: true,
      badge: {
        icon: <WarningIcon size={12} />,
        text: 'At risk',
        color: 'yellow',
      },
      cta: 'Schedule a post',
    }
  }

  if (streak.status === 'Missed') {
    return {
      heading: `Start a new streak`,
      text: 'Schedule a post for this week to keep your streak alive! Need ideas? <a href="https://buffer.com/resources/social-media-content-types/" target="_blank" rel="noreferrer">Check out these tips.</a>',
      textHasHtml: true,
      badge: {
        icon: null,
        text: 'Missed',
        color: 'gray',
      },
      cta: 'Schedule a post',
    }
  }

  console.warn('Unexpected streak status', streak)

  return null
}

const StreakWidget: React.FC = () => {
  const { isActive: isAlreadyOnboarded } = useDismissBanner({
    banner: BannerTypes.streakCoachMark,
  })
  const user = useUser()
  const { data: { weeklyPostingStreak: streak } = {} } = useWeeklyPostingStreak(
    user.currentOrganization?.id ?? '',
  )
  const content = getStreakContent(streak)

  if (!streak || !content) return null

  const statusClass = styles[streak.status.toLowerCase()]

  const handleCtaClick = (): void => {
    window.__openComposer?.({
      cta: 'publish-appshell-streakwidget-createPost-1',
    })
  }

  return (
    <Popover>
      <StreakCoachMark>
        <Popover.Trigger>
          <IconButton variant="tertiary" label="View Streak" size="large">
            <div className={styles.popoverTrigger}>
              <StreakIcon />
              {!(streak.status === 'Started' && streak.count === 0) &&
                !isAlreadyOnboarded && (
                  <div className={clsx(styles.widgetCount, statusClass)}>
                    {streak.count}
                  </div>
                )}
            </div>
          </IconButton>
        </Popover.Trigger>
      </StreakCoachMark>
      <Popover.Content align="center" arrow>
        <div className={styles.popoverContent}>
          <StreakHeader>
            {streak.status === 'Started' && <StreakHeader.Background />}
            <StreakHeader.Content>
              {['Started', 'Reset', 'OnTrack'].includes(streak.status) &&
              streak.count === 0 ? (
                <StreakDetailedIcon size={40} />
              ) : (
                <div className={clsx(styles.popoverCount, statusClass)}>
                  {streak.count}
                </div>
              )}
            </StreakHeader.Content>
          </StreakHeader>
          {content.badge && (
            <div
              className={clsx(
                styles.popoverStatus,
                styles[content.badge.color ?? 'green'],
              )}
            >
              {content.badge.icon}
              {content.badge.text}
            </div>
          )}
          <Heading align="center" size="medium">
            {content.heading}
          </Heading>
          <Paragraph color="subtle" size="sm" className={styles.popoverText}>
            {content.textHasHtml ? (
              <div dangerouslySetInnerHTML={{ __html: content.text }} />
            ) : (
              content.text
            )}
          </Paragraph>
          {content.cta && (
            <Button variant="primary" size="small" onClick={handleCtaClick}>
              {content.cta}
            </Button>
          )}
        </div>
      </Popover.Content>
    </Popover>
  )
}

export default StreakWidget
