import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { ORGANIZATION_ACTIONS } from '../events/orgEvents'
import { UPDATE_SUBSCRIPTION_QUANTITY_BY_CHANNEL_IDS } from '../graphql/billing'
import { QUERY_ACCOUNT } from '../graphql/account'
import { prefixWithProduct } from '../utils/prefixWithProduct'

const useUpdateSubscriptionQuantityByChannelIds = ({
  user,
  channelIds,
  cta,
  upgradePathName,
}: any) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState(null)

  const forceRefetchProfilesAfterChannelUnlock = () => {
    // When users connect a channel while at their plan limit, they are
    // prompted to pay in order to unlock that channel. Once they do, they
    // are able to use Publish, however, we can't directly refetch their
    // profiles as it's done by dispatching an action that makes an RPC
    // request. This workaround forces a page refresh to refetch their
    // profiles if they have just recently successfully connected a channel
    // and are in Publish.
    const publishEnvUrls = [
      'publish.local.buffer.com:8888/channels',
      'publish.dev.buffer.com/channels',
      'publish.buffer.com/channels',
    ]

    const isVisitingPublish = publishEnvUrls.some((url) =>
      window.location.href.includes(url),
    )

    if (isVisitingPublish) {
      const justConnectedChannelId = channelIds[0]
      window.location.href = `${window.location.href}?channelId=${justConnectedChannelId}&channelConnectionSuccess=true&tab=queue`
    }
  }

  const [
    updateSubscriptionQuantityByChannelIdsRequest,
    { data, error: mutationError },
  ] = useMutation(UPDATE_SUBSCRIPTION_QUANTITY_BY_CHANNEL_IDS, {
    refetchQueries: [{ query: QUERY_ACCOUNT }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      ORGANIZATION_ACTIONS.billingUpdated({ user })
      setIsProcessing(false)
      forceRefetchProfilesAfterChannelUnlock()
    },
  })

  function updateSubscriptionQuantityByChannelIds() {
    setIsProcessing(true)
    updateSubscriptionQuantityByChannelIdsRequest({
      variables: {
        organizationId: user.currentOrganization.id,
        channelIds,
        attribution: { cta: prefixWithProduct(cta), upgradePathName },
      },
    }).catch((e) => {
      throw new Error('Error: updateSubscriptionQuantityByChannelIds failed', e)
    })
  }

  useEffect(() => {
    if (!user || !channelIds) {
      // eslint-disable-next-line no-console
      console.warn(
        'Warning: updateSubscriptionQuantityByChannelIds: user or channelIds undefined - unexpected behaviour may happen',
      )
    }
  }, [isProcessing])

  useEffect(() => {
    if (mutationError) {
      setError({
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ message: string; }' is not ass... Remove this comment to see the full error message
        message: 'There has been an issue. Please try again.',
      })
    } else if (
      data?.billingIncreaseSubscripionQuantityByChannelIds?.userFriendlyMessage
    ) {
      setError({
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ message: any; }' is not assign... Remove this comment to see the full error message
        message:
          data.billingIncreaseSubscripionQuantityByChannelIds
            .userFriendlyMessage,
      })
      setIsProcessing(false)
    }
  }, [mutationError, data])

  return {
    updateSubscriptionQuantityByChannelIds,
    data,
    error,
    isProcessing,
  }
}

export default useUpdateSubscriptionQuantityByChannelIds
