import React, { forwardRef } from 'react'
import { Badge, NotifyMeIcon, type BadgeProps } from '@buffer-mono/popcorn'
import styles from './PostNotifyBadge.module.css'

export const NotifiedBadge = forwardRef<
  HTMLSpanElement,
  Pick<BadgeProps, 'size'>
>((props: Pick<BadgeProps, 'size'>, forwardedRef): JSX.Element | null => {
  return (
    <Badge
      className={styles.notify}
      size="medium"
      {...props}
      ref={forwardedRef}
    >
      <NotifyMeIcon />
      Notified
    </Badge>
  )
})

NotifiedBadge.displayName = 'NotifiedBadge'
