import React from 'react'
import Bugsnag from '@bugsnag/browser'
import BugsnagPluginReact, {
  type BugsnagErrorBoundary,
  type BugsnagPluginReactResult,
} from '@bugsnag/plugin-react'
import { env } from '../../env'

export const bugsnagClient = Bugsnag.start({
  apiKey: '9edffce18cc87b968f0fcb654c020b65',
  plugins: [new BugsnagPluginReact()],
  releaseStage: env.NODE_ENV,
  appVersion: env.VITE_COMMIT_HASH,
  enabledReleaseStages: ['production', 'staging'],
})

const ErrorBoundary: BugsnagErrorBoundary = (
  Bugsnag.getPlugin('react') as BugsnagPluginReactResult
).createErrorBoundary(React)

export default ErrorBoundary
