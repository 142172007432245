import React from 'react'

import {
  MetricsRefresh,
  MoreAnalyticsIconButton,
  PostMetricsGrid,
  PostMetricsWrapper,
} from '~publish/components/PostDetails/PostMetrics'
import { getAnalyzeUrl, getEngageUrl } from '../helpers'
import { usePostData } from '../PostCardContext'
import styles from './PostCardMetrics.module.css'

export const PostCardMetrics = (): JSX.Element | null => {
  const { metrics, channel } = usePostData()

  const engageUrl = getEngageUrl(channel)
  const analyzeUrl = getAnalyzeUrl(channel)

  return (
    <PostMetricsWrapper className={styles.metricsWrapper}>
      <PostMetricsGrid metrics={metrics ?? []} engageUrl={engageUrl} />
      {analyzeUrl && <MoreAnalyticsIconButton analyzeUrl={analyzeUrl} />}
      <MetricsRefresh />
    </PostMetricsWrapper>
  )
}

PostCardMetrics.displayName = 'PostCardMetrics'
