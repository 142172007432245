import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Flex, Heading, Text, toast } from '@buffer-mono/popcorn'
import { getLogoutUrl } from '@buffer-mono/app-shell/src/common/utils/urls'
import { graphql } from '~publish/gql'
import style from '../ProfileSettings.module.css'

import ConfirmPasswordModal from '../components/ConfirmPasswordModal'
import ConfirmRemoveAccountModal from '../components/ConfirmRemoveAccountModal'

export const REMOVE_ACCOUNT_MUTATION = graphql(/* GraphQL */ `
  mutation RemoveAccount($input: RemoveAccountInput!) {
    removeAccount(input: $input) {
      ... on RemoveAccountSuccess {
        _empty
      }
      ... on UnexpectedError {
        message
      }
      ... on InvalidInputError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on UnauthorizedError {
        message
      }
    }
  }
`)

export const VERIFY_PASSSWORD_MUTATION = graphql(/* GraphQL */ `
  mutation VerifyPassword($input: VerifyPasswordInput!) {
    verifyPassword(input: $input) {
      ... on VerifyPasswordSuccess {
        _empty
      }
      ... on UnexpectedError {
        message
      }
      ... on InvalidInputError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on UnauthorizedError {
        message
      }
    }
  }
`)

export const Delete = (): JSX.Element => {
  const [
    removeAccount,
    {
      data: removeAccountData,
      error: removeAccountError,
      loading: removeAccountLoading,
    },
  ] = useMutation(REMOVE_ACCOUNT_MUTATION)
  const [
    verifyPassword,
    {
      data: verifyPasswordData,
      error: verifyPasswordError,
      loading: verifyPasswordLoading,
    },
  ] = useMutation(VERIFY_PASSSWORD_MUTATION)

  const loading = removeAccountLoading || verifyPasswordLoading
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (
      removeAccountData?.removeAccount?.__typename === 'RemoveAccountSuccess'
    ) {
      toast.success('Your account has been deleted. You will be logged out.')
      window.location.assign(getLogoutUrl(window.location.href))
    } else if (removeAccountError || removeAccountData?.removeAccount) {
      toast.error(
        'An error occurred while deleting your account. ' +
          removeAccountError?.message,
      )
    }
  }, [removeAccountData, removeAccountError])

  useEffect(() => {
    if (
      verifyPasswordData?.verifyPassword?.__typename === 'VerifyPasswordSuccess'
    ) {
      setShowModal(true)
    } else if (verifyPasswordData?.verifyPassword?.__typename) {
      toast.error('Please ensure you have entered the correct password.')
    }
  }, [verifyPasswordData, verifyPasswordError])

  return (
    <Flex
      as="section"
      gap="xs"
      align="start"
      className={style.fullWidth}
      justify="between"
    >
      <div>
        <Heading
          as="h2"
          size="small"
          style={{
            marginBottom: 'var(--space-xs)',
          }}
        >
          Delete your account
        </Heading>
        <Text color="subtle">
          When you delete your account, you lose access to Buffer account
          services, and we permanently delete your personal data.
        </Text>
      </div>
      <div className={style.deleteButtonWrapper}>
        <ConfirmPasswordModal
          description={'To delete your account, please confirm your password.'}
          onConfirm={(password: string): void => {
            verifyPassword({
              variables: {
                input: {
                  password,
                },
              },
            })
          }}
        >
          <Button size="medium" variant="critical" disabled={loading}>
            {loading ? 'Please wait...' : 'Delete Account'}
          </Button>
        </ConfirmPasswordModal>
      </div>
      <ConfirmRemoveAccountModal
        isOpen={showModal}
        close={(): void => setShowModal(false)}
        onConfirm={({ reason, message }): void => {
          setShowModal(false)
          removeAccount({
            variables: {
              input: {
                reason: {
                  reason,
                  message,
                },
              },
            },
          })
        }}
      />
    </Flex>
  )
}

export default Delete
