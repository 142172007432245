import React from 'react'
import clsx from 'clsx'
import { useQuery, type QueryResult, gql } from '@apollo/client'

import {
  IconButton,
  Popover,
  Paragraph,
  Heading,
  Button,
  CheckIcon,
  StarIcon,
  WarningIcon,
} from '@buffer-mono/popcorn'

import { useUser } from '../../../../common/context/User'
import useDismissBanner, {
  BannerTypes,
} from '../../../../common/hooks/useDismissBanner'

import StreakIcon from './images/StreakIcon'
import { StreakCoachMark } from './components/StreakCoachMark'
import StreakDetailedIcon from './images/StreakDetailedIcon'
import { StreakHeader } from './components/StreakHeader'

import styles from './StreakWidget.module.css'

const WEEKLY_POSTING_STREAK_QUERY = gql`
  query WeeklyPostingStreak($input: WeeklyPostingStreakInput!) {
    weeklyPostingStreak(input: $input) {
      count
      status
      periodsAhead
      previousCount
      longestCount
    }
  }
`

interface WeeklyPostingStreakData {
  weeklyPostingStreak: {
    count: number
    status: 'Started' | 'OnTrack' | 'AtRisk' | 'Hit' | 'Missed'
    periodsAhead?: number
    previousCount?: number
    longestCount?: number
  } | null
}

interface WeeklyPostingStreakVars {
  input: {
    organizationId: string
  }
}

export const useWeeklyPostingStreak = (
  organizationId: string,
): QueryResult<WeeklyPostingStreakData, WeeklyPostingStreakVars> => {
  return useQuery<WeeklyPostingStreakData, WeeklyPostingStreakVars>(
    WEEKLY_POSTING_STREAK_QUERY,
    {
      variables: {
        input: {
          organizationId,
        },
      },
    },
  )
}

interface StreakContent {
  heading?: string
  text: string
  textHasHtml?: boolean
  cta?: string
  badge?: {
    icon: React.ReactNode
    text: string
    color?: 'green' | 'yellow' | 'blue'
  }
}

const getStreakContent = (
  streak: WeeklyPostingStreakData['weeklyPostingStreak'],
): StreakContent => {
  if (!streak || (streak.status === 'Started' && streak.count === 0)) {
    return {
      heading: 'Keep posting, build a streak!',
      text: 'Each week you post adds to your streak count!.',
      cta: 'Schedule a post',
    }
  }

  if (streak.status === 'OnTrack' && streak.count === 0) {
    return {
      text: "You're on track to start your streak this week. Great job!",
      badge: {
        icon: <CheckIcon size={12} />,
        text: 'On track',
        color: 'green',
      },
    }
  }

  if (
    streak.status === 'OnTrack' &&
    streak.count === 1 &&
    streak.periodsAhead === 0
  ) {
    return {
      heading: "You've started a streak! 🎉",
      text: 'Schedule at least one post each week to keep your streak going.',
      cta: 'Schedule a post',
    }
  }

  if (streak.status === 'OnTrack' && (streak.periodsAhead ?? 0) >= 1) {
    return {
      heading: 'Week streak 🎉',
      text:
        streak.periodsAhead === 1
          ? "Look at you go! You're on track for next week, too. Keep up the great work."
          : "Look at you go! You're on track for next weeks, too. Keep up the great work.",
      badge: {
        icon: <StarIcon size={12} />,
        text: 'On track and ahead',
        color: 'blue',
      },
    }
  }

  if (streak.status === 'AtRisk') {
    return {
      heading: 'Week streak',
      text: 'Schedule a post for this week to keep your streak alive! Need ideas? <a href="https://buffer.com/resources/social-media-content-types/" target="_blank" rel="noreferrer">Check out these tips.</a>',
      textHasHtml: true,
      badge: {
        icon: <WarningIcon size={12} />,
        text: 'At risk',
        color: 'yellow',
      },
      cta: 'Schedule a post',
    }
  }

  return {
    heading: `Week streak 🎉`,
    text: 'Look at you go! Keep up the great work and plan your next post.',
  }
}

const StreakWidget: React.FC = () => {
  const { isActive: isAlreadyOnboarded } = useDismissBanner({
    banner: BannerTypes.streakCoachMark,
  })
  const user = useUser()
  const { data: { weeklyPostingStreak: streak } = {} } = useWeeklyPostingStreak(
    user.currentOrganization?.id ?? '',
  )

  if (!streak) return null

  const content = getStreakContent(streak)
  const statusClass = styles[streak.status.toLowerCase()]

  const handleCtaClick = (): void => {
    window.__openComposer?.({
      cta: 'publish-appshell-streakwidget-createPost-1',
    })
  }

  return (
    <Popover>
      <StreakCoachMark>
        <Popover.Trigger>
          <IconButton variant="tertiary" label="View Streak" size="large">
            <div className={styles.popoverTrigger}>
              <StreakIcon />
              {!(streak.status === 'Started' && streak.count === 0) &&
                !isAlreadyOnboarded && (
                  <div className={clsx(styles.widgetCount, statusClass)}>
                    {streak.count}
                  </div>
                )}
            </div>
          </IconButton>
        </Popover.Trigger>
      </StreakCoachMark>
      <Popover.Content align="center" arrow>
        <div className={styles.popoverContent}>
          <StreakHeader>
            {streak.status === 'Started' && <StreakHeader.Background />}
            <StreakHeader.Content>
              {['Started', 'Reset', 'OnTrack'].includes(streak.status) &&
              streak.count === 0 ? (
                <StreakDetailedIcon size={40} />
              ) : (
                <div className={clsx(styles.popoverCount, statusClass)}>
                  {streak.count}
                </div>
              )}
            </StreakHeader.Content>
          </StreakHeader>
          {content.badge && (
            <div
              className={clsx(
                styles.popoverStatus,
                styles[content.badge.color ?? 'green'],
              )}
            >
              {content.badge.icon}
              {content.badge.text}
            </div>
          )}
          <Heading size="medium">{content.heading}</Heading>
          <Paragraph color="subtle" size="sm" className={styles.popoverText}>
            {content.textHasHtml ? (
              <div dangerouslySetInnerHTML={{ __html: content.text }} />
            ) : (
              content.text
            )}
          </Paragraph>
          {content.cta && (
            <Button variant="primary" size="small" onClick={handleCtaClick}>
              {content.cta}
            </Button>
          )}
        </div>
      </Popover.Content>
    </Popover>
  )
}

export default StreakWidget
