import React, { forwardRef, useMemo } from 'react'

import {
  ExternalLinkIcon,
  FileTextIcon,
  Flex,
  IconButton,
  IdeasIcon,
  Link,
  NewspaperIcon,
  Text,
} from '@buffer-mono/popcorn'
import { ContentFeedThumbnailImage } from './ContentFeedThumbnailImage'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useOrganizationId } from '~publish/legacy/accountContext'

import { formatDistance } from 'date-fns'
import type { FeedCollectionForDetails } from '../../hooks/useFeedCollection'
import type { FeedItem } from '../../hooks/useFeedItems'
import styles from './ContentFeed.module.css'
import { useScraperData } from '~publish/hooks/resources/useScraperData'

type ContentFeedItemProps = {
  item: FeedItem
  feed?: FeedCollectionForDetails['feeds'][number]
  handleCreatePost: (item: FeedItem & { imageUrls: string[] }) => void
  handleCreateIdea: (item: FeedItem & { imageUrls: string[] }) => void
  feedCollectionId: string
}

export const ContentFeedItem = forwardRef<HTMLDivElement, ContentFeedItemProps>(
  (props, ref) => {
    const { item, handleCreatePost, handleCreateIdea, feed, feedCollectionId } =
      props
    const organizationId = useOrganizationId()
    const itemType = item.feed.type
    const rootUrl = useMemo(() => {
      if (feed?.connectionMetadata.__typename === 'RSSFeedConnectionMetadata') {
        return new URL(feed?.connectionMetadata.url).origin
      }
      return null
    }, [feed?.connectionMetadata])

    // Grab image from scraper cache
    const { data } = useScraperData(item.articleUrl)
    const imageUrls = data?.images?.[0]?.url ? [data.images[0].url] : []

    const onCreatePost = (): void => {
      handleCreatePost({
        ...item,
        imageUrls,
      })

      BufferTracker.feedItemInteracted({
        product: 'publish',
        organizationId,
        feedItemId: item.id,
        feedId: item.feed.id,
        feedCollectionId,
        interactionType: 'createPost',
        clientName: 'publishWeb',
        feedItemContentUrl: item.articleUrl,
      })
    }

    const onCreateIdea = (): void => {
      handleCreateIdea({
        ...item,
        imageUrls,
      })

      BufferTracker.feedItemInteracted({
        product: 'publish',
        organizationId,
        feedItemId: item.id,
        feedId: item.feed.id,
        feedCollectionId,
        interactionType: 'createIdea',
        clientName: 'publishWeb',
        feedItemContentUrl: item.articleUrl,
      })
    }

    const onArticleClick = (): void => {
      BufferTracker.feedItemInteracted({
        product: 'publish',
        organizationId,
        feedItemId: item.id,
        feedId: item.feed.id,
        feedCollectionId,
        interactionType: 'viewArticle',
        clientName: 'publishWeb',
        feedItemContentUrl: item.articleUrl,
      })
    }

    return (
      <article className={styles.articleLayout} ref={ref}>
        <ContentFeedThumbnailImage
          title={item.title}
          articleUrl={item.articleUrl}
        />
        <div className={styles.articleHeader}>
          <div className={styles.headerContainer}>
            <header className={styles.articleHeader}>
              <Link
                className={styles.articleTitle}
                role="heading"
                color="inherit"
                href={item.articleUrl}
                external
                onClick={onArticleClick}
              >
                {item.title}
              </Link>
              {/* todo: add source image via feed */}
              <Text size="sm">
                <Flex gap="xs" align="center">
                  {rootUrl && (
                    <img
                      className={styles.feedNameFavicon}
                      src={`http://www.google.com/s2/favicons?domain=${rootUrl}`}
                      alt={item.feed.name}
                    />
                  )}
                  {!rootUrl && itemType === 'NewsSearch' && (
                    <NewspaperIcon
                      className={styles.feedNameFavicon}
                      color="subtle"
                    />
                  )}
                  <span>{item.feed.name}</span>
                  <span>|</span>
                  <time dateTime={new Date(item.createdAt).toISOString()}>
                    {formatDistance(new Date(item.createdAt), new Date(), {
                      addSuffix: true,
                    }).replace('about ', '')}
                  </time>
                </Flex>
              </Text>
            </header>
            <Flex gap="xs" align="center">
              <IconButton
                tooltip="Open Link"
                label="Open Link"
                variant="tertiary"
                as="a"
                href={item.articleUrl}
                target="_blank"
                rel="noreferrer noopener"
                onClick={onArticleClick}
              >
                <ExternalLinkIcon />
              </IconButton>
              <IconButton
                tooltip="Create New Post"
                label="Create New Post"
                variant="tertiary"
                onClick={onCreatePost}
              >
                <FileTextIcon />
              </IconButton>
              <IconButton
                tooltip="Save as Idea"
                label="Save as Idea"
                variant="tertiary"
                onClick={onCreateIdea}
              >
                <IdeasIcon />
              </IconButton>
            </Flex>
          </div>
          <Text className={styles.articleDescription}>
            {item.contentTextPreview}
          </Text>
        </div>
      </article>
    )
  },
)

ContentFeedItem.displayName = 'ContentFeedItem'
