import { PostTypeCarousel, PostTypePost } from '~publish/legacy/post/constants'
import { AttachmentTypes, MediaAttachmentTypes } from '../attachments'
import { createService } from './createService'

export enum Service {
  Instagram = 'instagram',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Linkedin = 'linkedin',
  Pinterest = 'pinterest',
  Shopify = 'shopify',
  Tiktok = 'tiktok',
  Googlebusiness = 'googlebusiness',
  StartPage = 'startPage',
  Mastodon = 'mastodon',
  Youtube = 'youtube',
  Threads = 'threads',
  Bluesky = 'bluesky',
}

export const ServiceOmni = createService('omni', {
  isOmni: true,
  maxAttachableImagesCount: () => 20,
  canEditLinkAttachment: true,
  unavailableMediaAttachmentTypes: [],
})

export const ServiceTwitter = createService(Service.Twitter, {
  charLimit: (draft) => {
    return draft?.isTwitterPremium ? 25000 : 280
  },
  formattedName: 'Twitter / X',
  unavailableAttachmentTypes: [AttachmentTypes.LINK],
  maxAttachableImagesCount: (): number => 4,
  videoMaxSize: 512 * 1024 * 1024, // 512MB
  videoMaxDurationMs: 140 * 1000, // 140s
  shouldShowDuplicateContentWarning: true,
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.WEBM,
    MediaAttachmentTypes.PDF,
  ],
  transcodeVideo: true,
  imageMaxSize: 5 * 1024 * 1024, // 5MB
  gifMaxSize: 15 * 1024 * 1024, // 15MB,
  supportsThread: true,
  nameOfPost: 'Tweet',
})

export const ServiceThreads = createService(Service.Threads, {
  charLimit: 500,
  formattedName: 'Threads',
  maxAttachableImagesCount: (): number => 20,
  unavailableAttachmentTypes: [AttachmentTypes.RETWEET],
  videoMaxSize: 1024 * 1024 * 1024, // 1GB
  videoMaxDurationMs: 300 * 1000, // 300s
  videoMinFrameRate: 23,
  videoMaxFrameRate: 60,
  videoMaxWidth: 1920,
  shouldShowDuplicateContentWarning: true,
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.WEBM,
    MediaAttachmentTypes.PDF,
    MediaAttachmentTypes.GIF,
  ],
  transcodeVideo: true,
  imageMaxSize: 8 * 1024 * 1024, // 8MB
  supportsThread: true,
  maxThreads: 25,
  canEditLinkAttachment: false,
})

export const ServiceMastodon = createService(Service.Mastodon, {
  charLimit: 500,
  unavailableAttachmentTypes: [AttachmentTypes.LINK, AttachmentTypes.RETWEET],
  unavailableMediaAttachmentTypes: [MediaAttachmentTypes.PDF],
  maxAttachableImagesCount: () => 4,
  videoMaxSize: 40 * 1024 * 1024, // 40MB
  transcodeVideo: false,
  imageMaxSize: 8 * 1024 * 1024, // 8MB
  imageMaxWidth: 10000,
  imageMaxHeight: 10000,
  gifMaxSize: 10 * 1024 * 1024, // 10MB
  supportsThread: false,
  maxThreads: 25,
  isShorteningLinksAvailable: false,
})

export const ServiceFacebook = createService(Service.Facebook, {
  charLimit: 5000,
  unavailableAttachmentTypes: [AttachmentTypes.RETWEET],
  unavailableMediaAttachmentTypes: [MediaAttachmentTypes.PDF],
  maxAttachableImagesCount: (draft) => {
    if (!draft) {
      return 10
    }

    if (draft.isFacebookStoryPost()) {
      return 1
    }

    return 10
  },
  canEditLinkAttachment: true,
  canEditVideoTitle: true,

  videoMinResolution: 540,

  // REELS
  videoMinDurationMsReels: 3 * 1000, // 3 seconds for Reels
  videoMaxDurationMsReels: 90 * 1000, // 90 seconds for Reels
  videoMaxSizeReels: 1024 * 1024 * 1024, // 1 GB for Reels
  videoMinFrameRateReels: 24, // frames per second
  videoMaxFrameRateReels: 60, // frames per second
  videoMinWidthReels: 540,
  videoMinHeightReels: 960,
  videoMinAspectRatioReels: 9 / 16,
  videoMaxAspectRatioReels: 16 / 9,
  // STORIES
  videoMinDurationMsStories: 3 * 1000, // 3 seconds for Stories
  videoMaxDurationMsStories: 60 * 1000, // 60 seconds for Stories
  videoMinWidthStories: 540,
  videoMinHeightStories: 960,
  videoMinAspectRatioStories: 6 / 16,
  videoMaxAspectRatioStories: 9 / 16,
  imageMaxSizeStories: 4 * 1024 * 1024, // 8MB for Stories
  videoMinFrameRateStories: 24, // frames per second
  videoMaxFrameRateStories: 60, // frames per second
  supportsMobileReminders: true,
})

export const ServiceInstagram = createService(Service.Instagram, {
  charLimit: 2200,
  commentCharLimit: 2200,
  formattedName: 'Instagram',
  imageMaxSize: 8 * 1024 * 1024, // 8MB
  unavailableAttachmentTypes: [AttachmentTypes.LINK, AttachmentTypes.RETWEET],
  maxAttachableImagesCount: (draft) => {
    if (!draft) {
      return 10
    }

    if (draft.isStoryPost()) {
      return 10
    }

    if (draft.isReelsPost()) {
      return 1
    }

    return 10
  },
  requiredAttachmentType: AttachmentTypes.MEDIA,
  videoMinDurationMs: 3 * 1000, // 3s
  videoMaxDurationMs: 60 * 1000, // 60s
  videoMaxSize: 1024 * 1024 * 1024, // 1 GB (gets transformed to Reels)
  videoMinFrameRate: 23,
  videoMaxFrameRate: 60,
  usesImageFirstLayout: true,
  maxHashtags: 30,
  maxMentions: 20,
  canHaveLocation: true,
  canHaveUserTags: true,
  canEditVideoAttachment: true,
  canEditVideoTitle: true,
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.WEBM,
    MediaAttachmentTypes.PDF,
    MediaAttachmentTypes.GIF,
  ],
  // REELS
  videoMinDurationMsReels: 3 * 1000, // 3 seconds for Reels
  videoMaxDurationMsReels: 15 * 60 * 1000, // 15 minutes for Reels
  videoMaxSizeReels: 1024 * 1024 * 1024, // 1 GB for Reels
  // STORIES
  videoMinDurationMsStories: 3 * 1000, // 3 seconds for Stories
  videoMaxDurationMsStories: 60 * 1000, // 60 seconds for Stories
  videoMaxSizeStories: 100 * 1024 * 1024, // 100 MB for Stories
  imageMaxSizeStories: 8 * 1024 * 1024, // 8MB for Stories
  isShorteningLinksAvailable: false,
  supportsMobileReminders: true,
})

export const ServiceLinkedIn = createService(Service.Linkedin, {
  allowedPostTypes: [PostTypePost, PostTypeCarousel],
  charLimit: 3000,
  formattedName: 'LinkedIn',
  unavailableAttachmentTypes: [AttachmentTypes.RETWEET],
  canEditLinkAttachment: true,
  canEditVideoAttachment: false,
  maxAttachableImagesCount: () => 9,
  transcodeVideo: false,
  commentCharLimit: 1250,
  gifMaxSize: 10 * 1024 * 1024, // 10MB,
})

export const ServiceGoogleBusiness = createService(Service.Googlebusiness, {
  charLimit: 1500,
  formattedName: 'Google Business Profile',
  unavailableAttachmentTypes: [AttachmentTypes.RETWEET, AttachmentTypes.LINK],
  usesImageFirstLayout: true,
  videoMaxSize: 100 * 1024 * 1024, // 100MB
  imageMaxSize: 25 * 1024 * 1024, // 25MB
  imageMinWidth: 250,
  imageMinHeight: 250,
  maxAttachableImagesCount: () => 1,
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.WEBM,
    MediaAttachmentTypes.M4V,
    MediaAttachmentTypes.GIF,
    MediaAttachmentTypes.MP4,
    MediaAttachmentTypes.VIDEO,
    MediaAttachmentTypes.MOV,
    MediaAttachmentTypes.AVI,
    MediaAttachmentTypes.PDF,
  ],
  attachmentMinSize: 10 * 1024, // 10KB
})

export const ServicePinterest = createService(Service.Pinterest, {
  unavailableAttachmentTypes: [AttachmentTypes.LINK, AttachmentTypes.RETWEET],
  requiredAttachmentType: AttachmentTypes.MEDIA,
  hasSubprofiles: true,
  titleCharLimit: 100,
  charLimit: 500,
  canHaveSourceUrl: true,
  requiresText: false,
  requiresSourceUrl: true,
  usesImageFirstLayout: true,
  canEditVideoAttachment: true,
  canEditVideoTitle: true,
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.WEBM,
    MediaAttachmentTypes.PDF,
  ],
  transcodeVideo: false,
  isShorteningLinksAvailable: false,
})

export const ServiceTiktok = createService(Service.Tiktok, {
  unavailableAttachmentTypes: [AttachmentTypes.LINK, AttachmentTypes.RETWEET],
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.GIF,
    MediaAttachmentTypes.AVI,
    MediaAttachmentTypes.M4V,
    MediaAttachmentTypes.PDF,
  ],
  requiredAttachmentType: AttachmentTypes.MEDIA,
  requiresText: false,
  usesImageFirstLayout: true,
  canEditVideoAttachment: true,
  canEditVideoTitle: false,
  maxAttachableImagesCount: () => 10,
  videoMaxSize: 1024 * 1024 * 1024, // 1GB
  videoMinResolution: 360,
  charLimit: 2200,
  videoMinDurationMs: 3 * 1000, // 3s
  // 10 minutes and 0.9 seconds because a bit more should be allowed
  // to accommodate for videos slightly longer.
  videoMaxDurationMs: 600_900,
  imageMaxSize: 25 * 1024 * 1024, // 25MB
  imageMaxPixelCount: 2_073_600, // 1920x1080 or 1080x1920
  titleCharLimit: 90,
  transcodeVideo: false,
  supportsMobileReminders: true,
})

export const ServiceYoutube = createService(Service.Youtube, {
  unavailableAttachmentTypes: [AttachmentTypes.LINK, AttachmentTypes.RETWEET],
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.JPG,
    MediaAttachmentTypes.JPEG,
    MediaAttachmentTypes.PNG,
    MediaAttachmentTypes.GIF,
    MediaAttachmentTypes.M4V,
    MediaAttachmentTypes.IMAGE,
    MediaAttachmentTypes.PDF,
  ],
  requiredAttachmentType: AttachmentTypes.MEDIA,
  requiresText: false,
  usesImageFirstLayout: false,
  canEditVideoAttachment: false,
  maxAttachableImagesCount: () => 1,
  videoMaxSize: 10 * 1024 * 1024 * 1024, // 10GB
  videoMinResolution: 360,
  charLimit: 5000,
  videoMinDurationMs: 1 * 1000, // 1s
  videoMaxDurationMs: 180 * 1000, // 180s
  transcodeVideo: false,
  onlyAllowsVerticalVideo: true,
  supportsMobileReminders: true,
})

export const ServiceStartPage = createService(Service.StartPage, {
  charLimit: 5000,
  formattedName: 'Start Page',
  unavailableAttachmentTypes: [AttachmentTypes.RETWEET, AttachmentTypes.LINK],
  usesImageFirstLayout: true,
  imageMaxSize: 25 * 1024 * 1024, // 25MB
  maxAttachableImagesCount: () => 1,
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.WEBM,
    MediaAttachmentTypes.M4V,
    MediaAttachmentTypes.MP4,
    MediaAttachmentTypes.VIDEO,
    MediaAttachmentTypes.MOV,
    MediaAttachmentTypes.AVI,
    MediaAttachmentTypes.PDF,
  ],
  attachmentMinSize: 10 * 1024, // 10KB
})

export const ServiceBluesky = createService(Service.Bluesky, {
  charLimit: 300,
  formattedName: 'Bluesky',
  unavailableAttachmentTypes: [AttachmentTypes.RETWEET],
  canEditLinkAttachment: true,
  shouldShowDuplicateContentWarning: true,
  unavailableMediaAttachmentTypes: [
    MediaAttachmentTypes.M4V,
    MediaAttachmentTypes.AVI,
    MediaAttachmentTypes.PDF,
  ],
  maxAttachableImagesCount: () => 4,
  imageMaxSize: 8 * 1024 * 1024, // 8MB
  gifMaxSize: 15 * 1024 * 1024, // 15MB
  videoMaxSize: 50 * 1024 * 1024, // 50MB
  videoMaxDurationMs: 60 * 1000, // 60s
  supportsThread: true,
  maxThreads: 25,
})
