import React from 'react'
import * as RadixSelect from '@radix-ui/react-select'
import GearIcon from '@bufferapp/ui/Icon/Icons/Gear'
import { Tooltip } from '@buffer-mono/popcorn'

import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'

import { SelectItem } from './SelectItem'

import { blue } from '@bufferapp/ui/style/colors'
import * as Styled from './styles'
import { RemindersIcon } from '../../../RemindersIcon'

function ConditionalTooltip({
  children,
  showTooltip,
  content,
}: {
  children: JSX.Element
  showTooltip: boolean
  content: string
}) {
  if (showTooltip && content) {
    return <Tooltip content={content}>{children}</Tooltip>
  }

  return children
}

export function SelectOptions({
  draft,
  disableOptionDescription,
  isAutomaticPostingPossible,
}: {
  draft: Draft
  disableOptionDescription: string
  isAutomaticPostingPossible: boolean
}): React.JSX.Element {
  return (
    <RadixSelect.Portal>
      <Styled.SelectContent>
        <RadixSelect.ScrollUpButton className="SelectScrollButton" />
        <RadixSelect.Viewport className="SelectViewport">
          <RadixSelect.Group>
            <ConditionalTooltip
              showTooltip={!isAutomaticPostingPossible}
              content={disableOptionDescription}
            >
              <SelectItem
                icon={<GearIcon color={blue} />}
                value="automatic"
                selected={!draft.isReminder && isAutomaticPostingPossible}
                disabled={!isAutomaticPostingPossible}
              >
                <Styled.SelectOptionText>
                  <RadixSelect.ItemText>
                    <span className="option-title">Automatic</span>
                  </RadixSelect.ItemText>
                  <span className="description">
                    Buffer will post automatically for you
                  </span>
                </Styled.SelectOptionText>
              </SelectItem>
            </ConditionalTooltip>

            <SelectItem
              icon={<RemindersIcon />}
              value="notifyMe"
              selected={!!draft.isReminder || !isAutomaticPostingPossible}
            >
              <Styled.SelectOptionText>
                <RadixSelect.ItemText>
                  <span className="option-title">Notify Me</span>
                </RadixSelect.ItemText>
                <span className="description">
                  You'll receive a mobile notification to post yourself.
                </span>
              </Styled.SelectOptionText>
            </SelectItem>
          </RadixSelect.Group>
        </RadixSelect.Viewport>
        <RadixSelect.ScrollDownButton className="SelectScrollButton" />
      </Styled.SelectContent>
    </RadixSelect.Portal>
  )
}
