import type { Location } from 'history'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import {
  parseQueryParams,
  serializeQueryParams,
} from '~publish/hooks/useQueryParam'
import { postDetailsRoute } from '~publish/legacy/routes'

export function useOnPostComposerClose(callback: () => void): void {
  const { isOpen } = usePostComposer()
  const [previousIsOpen, setPreviousIsOpen] = useState(isOpen)
  useEffect(() => {
    setPreviousIsOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    setTimeout(() => {
      if (!isOpen && previousIsOpen !== isOpen) {
        callback()
      }
    }, 1000)
  }, [isOpen, previousIsOpen, callback])
}

type OpenDetailsDialogArgs = {
  cta: CTA
  expandText?: 'true' | 'false'
}

export const usePostDetailsDialog = ({
  postId,
}: {
  postId: string
}): {
  constructDetailsDialogLocation: (args: OpenDetailsDialogArgs) => Location
  openDetailsDialog: (args: OpenDetailsDialogArgs) => void
} => {
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  const pathname = `${match.url}${postDetailsRoute.getRoute({ postId })}`

  const constructDetailsDialogLocation = React.useCallback(
    ({ cta, expandText = 'false' }: OpenDetailsDialogArgs) => {
      const searchParams = parseQueryParams(location.search)
      const newSearchParams = serializeQueryParams({
        ...searchParams,
        cta,
        expandText,
      })
      return {
        ...location,
        pathname,
        search: newSearchParams,
      }
    },
    [pathname, location],
  )

  const openDetailsDialog = React.useCallback(
    ({ cta, expandText = 'false' }: OpenDetailsDialogArgs) => {
      history.push(constructDetailsDialogLocation({ cta, expandText }))
    },
    [history, constructDetailsDialogLocation],
  )

  return {
    constructDetailsDialogLocation,
    openDetailsDialog,
  }
}
