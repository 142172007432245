import { graphql } from '~publish/gql'
import { useMutation } from '@apollo/client'
import type { UpdateFeedCollectionInput } from '~publish/gql/graphql'

const UpdateFeedCollection = graphql(/* GraphQL */ `
  mutation UpdateFeedCollection($input: UpdateFeedCollectionInput!) {
    updateFeedCollection(input: $input) {
      __typename
      ... on FeedCollectionActionSuccess {
        feedCollection {
          id
          name
          feeds {
            id
            name
            homepage
            connectionMetadata {
              __typename
              ... on RSSFeedConnectionMetadata {
                url
                lastConnected
              }
              ... on NewsSearchConnectionMetadata {
                query
              }
            }
          }
        }
      }
    }
  }
`)

export const useUpdateFeedCollection = (): ((
  input: UpdateFeedCollectionInput,
) => Promise<void>) => {
  const [updateFeedCollection] = useMutation(UpdateFeedCollection)

  return async (input: UpdateFeedCollectionInput) => {
    await updateFeedCollection({ variables: { input } })
  }
}
