// TODO: It's an established best practice to name the fragment after with an underscore,
// so disabling eslint errors for it for now. We need to figure out a better way to handle these more globally
/* eslint-disable camelcase, @typescript-eslint/naming-convention */

import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import React, { useCallback, useMemo } from 'react'
import type {
  BlueskyPostMetadata,
  InstagramGeolocation,
  MastodonPostMetadata,
  PostCard_PostFragment,
  PostMetadata,
  ThreadedPost,
  ThreadsPostMetadata,
  TwitterPostMetadata,
} from '~publish/gql/graphql' // Import the necessary type or interface declaration
import { isOfType } from '~publish/helpers/typeGuards'
import { useAccountId, useOrganizationId } from '~publish/legacy/accountContext'

// PostCardContext is a React Context that is used to pass data to the sub-components
// of PostCard. It is used to pass the post data to the PostCardHeader, PostCardFooter,
// and other sub-components. It is meant to be used exclusively within the PostCard component.
type PostCardContextValue = {
  // post object
  post: PostCard_PostFragment
}

const defaultValue: PostCardContextValue = {
  // it is not possible for post to be null, but we need to initialize it as null
  post: null as unknown as PostCard_PostFragment,
}

export const PostCardContext =
  React.createContext<PostCardContextValue>(defaultValue)

const PostCardContextProvider = ({
  post,
  children,
}: {
  post: PostCard_PostFragment
  children: React.ReactNode
}): JSX.Element => {
  const contextValue: PostCardContextValue = useMemo(() => ({ post }), [post])

  return (
    <PostCardContext.Provider value={contextValue}>
      {children}
    </PostCardContext.Provider>
  )
}

const usePostCardContext = (): PostCardContextValue => {
  const context = React.useContext(PostCardContext)

  if (context === undefined) {
    throw new Error(
      'usePostCardContext must be used within a PostCardContextProvider',
    )
  }

  if (context === defaultValue) {
    throw new Error('usePostCardContext must have an initialized value')
  }

  return context
}

const usePostData = (): PostCard_PostFragment => {
  const { post } = usePostCardContext()

  return post
}

type CtaIdentifier = `${string}-${number}`

const useTrackActionClicked = (): ((cta: CtaIdentifier) => void) => {
  const { id, channel } = usePostData()
  const accountId = useAccountId()
  const organizationId = useOrganizationId()

  const trackPostAction = useCallback(
    (cta: CtaIdentifier): void => {
      if (!organizationId) return

      BufferTracker.postCardActionClicked({
        organizationId,
        action: cta,
        accountId: accountId || '',
        channelId: channel.id,
        channel: channel.service,
        postId: id,
        clientName: 'publishWeb',
      })
    },
    [accountId, channel.id, channel.service, id, organizationId],
  )

  return trackPostAction
}

const metadataSupportsThreads = (
  metadata: PostMetadata | PostCard_PostFragment['metadata'],
): metadata is
  | TwitterPostMetadata
  | ThreadsPostMetadata
  | MastodonPostMetadata
  | BlueskyPostMetadata => {
  return (
    !!metadata &&
    (isOfType(metadata, 'TwitterPostMetadata') ||
      isOfType(metadata, 'ThreadsPostMetadata') ||
      isOfType(metadata, 'MastodonPostMetadata') ||
      isOfType(metadata, 'BlueskyPostMetadata'))
  )
}

const useMetadataThreadCount = (
  metadata: PostMetadata | PostCard_PostFragment['metadata'],
): number => {
  if (metadataSupportsThreads(metadata)) {
    return metadata.threadCount
  }
  // Returns one because the main post text is always the first thread
  return 1
}

const useMetadataThread = (
  metadata: PostMetadata | PostCard_PostFragment['metadata'],
): Array<ThreadedPost> | undefined => {
  if (metadataSupportsThreads(metadata)) {
    return metadata.thread
  }
}

const useMetadataFirstComment = (
  metadata: PostMetadata | PostCard_PostFragment['metadata'],
): string | undefined => {
  if (
    isOfType(metadata, 'LinkedInPostMetadata') ||
    isOfType(metadata, 'InstagramPostMetadata')
  ) {
    return metadata.firstComment ?? ''
  }
}

const useMetadataGeolocation = (
  metadata: PostMetadata | PostCard_PostFragment['metadata'],
): InstagramGeolocation | undefined => {
  if (isOfType(metadata, 'InstagramPostMetadata') && metadata.geolocation) {
    return metadata.geolocation
  }
}

const useMetadataTitle = (
  metadata: PostMetadata | PostCard_PostFragment['metadata'],
): string | undefined => {
  if (isOfType(metadata, 'GoogleBusinessPostMetadata') && metadata.title) {
    return metadata.title
  }
}

export {
  PostCardContextProvider,
  PostCardContextValue,
  useMetadataFirstComment,
  useMetadataGeolocation,
  useMetadataThread,
  useMetadataThreadCount,
  useMetadataTitle,
  usePostCardContext,
  usePostData,
  useTrackActionClicked,
}
