import React, { forwardRef } from 'react'
import { Badge, NotifyMePublished, type BadgeProps } from '@buffer-mono/popcorn'
import styles from './PostNotifyBadge.module.css'

export const MarkedAsPublishedBadge = forwardRef<
  HTMLSpanElement,
  Pick<BadgeProps, 'size'>
>((props: Pick<BadgeProps, 'size'>, forwardedRef): JSX.Element | null => {
  return (
    <Badge
      className={styles.notify}
      size="medium"
      {...props}
      ref={forwardedRef}
    >
      <NotifyMePublished />
      Published
    </Badge>
  )
})

MarkedAsPublishedBadge.displayName = 'MarkedAsPublishedBadge'
