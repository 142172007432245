import React from 'react'
import {
  GbpEventIcon,
  GbpOfferIcon,
  GbpWhatsNewIcon,
  IgReelIcon,
  IgStoryIcon,
  YtShortIcon,
  Badge,
  type IconProps,
} from '@buffer-mono/popcorn'
import type { PostType } from '~publish/gql/graphql'

const postTypeMap: Partial<
  Record<PostType, { icon: React.ComponentType<IconProps>; label: string }>
> = {
  story: { icon: IgStoryIcon, label: 'Story' },
  reel: { icon: IgReelIcon, label: 'Reel' },
  short: { icon: YtShortIcon, label: 'Short' },
  event: { icon: GbpEventIcon, label: 'Event' },
  offer: { icon: GbpOfferIcon, label: 'Offer' },
  whats_new: { icon: GbpWhatsNewIcon, label: "What's New" },
}

type PostTypeIconProps = IconProps & {
  type?: PostType
}

export const PostTypeIcon = ({
  type,
  ...iconProps
}: PostTypeIconProps): JSX.Element | null => {
  if (type && postTypeMap[type] !== undefined) {
    const { icon: Icon } = postTypeMap[type]

    return <Icon color="subtle" {...iconProps} />
  }

  return null
}

export const PostTypeBadge = ({
  type,
  condensed,
}: {
  type?: PostType
  condensed?: boolean
}): JSX.Element | null => {
  if (type && postTypeMap[type] !== undefined) {
    const { icon: Icon, label } = postTypeMap[type]

    return (
      <Badge>
        <Icon color="subtle" />
        {!condensed && label}
      </Badge>
    )
  }

  return null
}
