import React from 'react'
import { Button, Flex, TrashIcon, VisuallyHidden } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { DeleteIdeasDialog } from '~publish/components/DeleteIdeasDialog'
import { useOrganizationId } from '~publish/legacy/accountContext'

import { useIdeasMultiSelect } from '../IdeasMultiSelect'
import { useDeleteIdea } from '../../hooks/useDeleteIdea'

export const IdeaBatchActions = (): JSX.Element => {
  const currentOrganizationId = useOrganizationId()
  const { selectedCount, selectedIds, clearSelection } = useIdeasMultiSelect()
  const { deleteIdeas } = useDeleteIdea()

  const handleDelete = async (): Promise<void> => {
    await deleteIdeas(selectedIds, {
      source: 'publish-create-batchActions-deleteIdeas-1',
    })

    BufferTracker.ideaBatchDeleted({
      product: 'create',
      count: selectedCount,
      organizationId: currentOrganizationId,
    })

    clearSelection()
  }

  return (
    <Flex gap="sm" align="center">
      <VisuallyHidden role="status">
        {selectedCount} Ideas Selected
      </VisuallyHidden>
      <Button variant="secondary" size="large" onClick={clearSelection}>
        Clear Selection
      </Button>
      <DeleteIdeasDialog count={selectedCount} onDelete={handleDelete}>
        <Button variant="critical" size="large">
          <TrashIcon />
          Delete {selectedCount} Ideas
        </Button>
      </DeleteIdeasDialog>
    </Flex>
  )
}
