import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  ArrowLeftIcon,
  Avatar,
  Button,
  Card,
  ChevronDownIcon,
  CriticalIcon,
  Dialog,
  Heading,
  DropdownMenu,
  Flex,
  IconButton,
  ImageIcon,
  Input,
  Label,
  Text,
  VisuallyHidden,
  Paragraph,
  ImagePlusIcon,
} from '@buffer-mono/popcorn'
import {
  FileUploadConfig,
  UploadSource,
  useUploader,
} from '@buffer-mono/uploader'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import styles from './PersonalProfile.module.css'
import footerStyles from '../Footer/Footer.module.css'

import { useMutation } from '@apollo/client'
import {
  CREATE_CUSTOM_CHANNELS,
  UPDATE_CUSTOM_CHANNEL,
} from '../../../../../../../common/graphql/channels'
import { trackCustomChannelSetupViewed } from '../../../../tracking'

import { UserContext } from '../../../../../../../common/context/User'
import { Service } from '../../../../types'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'

import instagramSelectAccountTypeStyles from '../../InstagramSelectAccountType.module.css'
import { useModalManager } from '../../../../../../../components/ModalManager/hooks/useModalManager'
import { resetChannelConnectionsState } from '../../../../../store/channelConnectionsReducer'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

function validateUserName(name: string): string | undefined {
  if (name.length === 0) {
    return "Username can't be empty"
  }

  if (name.length > 30) {
    return "Username can't be longer than 30 characters"
  }

  if (!/^[a-zA-Z0-9._]*$/.test(name)) {
    return 'Username can only contain letters, numbers, and periods'
  }
}

const fileRestrictions = {
  maxNumberOfFiles: 1,
  allowedFileTypes: ['.jpg', '.jpeg', '.png', '.webp'],
  uploadConfig: FileUploadConfig.IMAGE,
}

const MIN_WIDTH = 180
const MIN_HEIGHT = 180

const MAX_WIDTH = 1024
const MAX_HEIGHT = 1024

const MAX_SIZE = 2 * 1024 * 1024

function getClampedImageSize(
  width: number,
  height: number,
): {
  width: number
  height: number
} {
  if (width > height) {
    if (width > MAX_WIDTH) {
      return {
        width: MAX_WIDTH,
        height: Math.round(height * (MAX_WIDTH / width)),
      }
    }
  } else {
    if (height > MAX_HEIGHT) {
      return {
        width: Math.round(width * (MAX_HEIGHT / height)),
        height: MAX_HEIGHT,
      }
    }
  }

  return { width, height }
}

function isImageTooLarge(image: { width: number; height: number }): boolean {
  const { width, height } = image

  return width > MAX_WIDTH || height > MAX_HEIGHT
}

function getImage(file: File): Promise<{
  file: File
  fileDataUrl: string
  width: number
  height: number
}> {
  return new Promise((resolve) => {
    const fileAsObjectUrl = URL.createObjectURL(file)
    const img = new Image()

    img.onload = async (): Promise<void> => {
      if (
        isImageTooLarge({
          width: img.width,
          height: img.height,
        })
      ) {
        const { width, height } = getClampedImageSize(img.width, img.height)
        const newImg = await createImageBitmap(img, {
          resizeHeight: height,
          resizeWidth: width,
        })
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')

        if (ctx) {
          ctx.drawImage(newImg, 0, 0)

          canvas.toBlob((blob) => {
            if (blob) {
              // Return resized image as a file
              resolve({
                fileDataUrl: canvas.toDataURL(file.type),
                file: new File([blob], file.name, {
                  type: file.type,
                  lastModified: file.lastModified,
                }),
                width,
                height,
              })
            } else {
              // Return original image if getting blob fails
              resolve({
                fileDataUrl: fileAsObjectUrl,
                file,
                width: img.width,
                height: img.height,
              })
            }
          }, file.type)
        } else {
          // Return original image if canvas context is not available
          resolve({
            fileDataUrl: fileAsObjectUrl,
            file,
            width: img.width,
            height: img.height,
          })
        }
      } else {
        // Return original image if it's not too large
        resolve({
          fileDataUrl: fileAsObjectUrl,
          file,
          width: img.width,
          height: img.height,
        })
      }
    }
    img.src = fileAsObjectUrl
  })
}

function validateDimensions(width: number, height: number): boolean {
  return (
    width >= MIN_WIDTH &&
    height >= MIN_HEIGHT &&
    width <= MAX_WIDTH &&
    height <= MAX_HEIGHT
  )
}

export function PersonalProfile({
  onBack,
  onContinue,
}: {
  onBack: () => void
  onContinue: (channelId: string) => void
}): JSX.Element {
  const dispatch = useDispatch()
  const { modalData, dismissModal } = useModalManager()
  const {
    channelId,
    isRefreshingConnection,
    channelName,
    channelAvatar,
    onContinue: modalOnContinue,
  } = modalData ?? {}
  const [name, setName] = useState(channelName ?? '')
  const [avatar, setAvatar] = useState(channelAvatar ?? '')
  const [avatarError, setAvatarError] = useState('')
  const [avatarLoading, setAvatarLoading] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [userNameValidationError, setUserNameValidationError] =
    useState<string>()

  const user = useContext(UserContext)

  const uploader = useUploader({
    id: 'avatar-uploader',
    bucket: 'buffer-channel-avatars-bucket',
    userId:
      user?.products?.find((product) => product.name === 'publish')?.userId ||
      '',
    organizationId: user?.currentOrganization?.id || '',
    fileRestrictions,
  })

  const [upsertCustomChannels, { data, loading }] = useMutation(
    isRefreshingConnection ? UPDATE_CUSTOM_CHANNEL : CREATE_CUSTOM_CHANNELS,
  )

  useEffect(() => {
    if (data) {
      const newChannelId = data.createCustomChannels?.channels?.[0]?.id

      if (newChannelId) {
        onContinue(newChannelId)
      }
    }
  }, [data, onContinue])

  useEffect(() => {
    trackCustomChannelSetupViewed({
      account: user,
      service: Service.instagram,
    })
  }, [user])

  const uploadCallback = useCallback(
    async (file: File): Promise<void> => {
      setAvatar('')
      setAvatarError('')

      if (file) {
        const image = await getImage(file)

        if (image.file.size > MAX_SIZE) {
          setAvatarError('Avatar images must be under 2 MB.')
          return
        }

        if (!validateDimensions(image.width, image.height)) {
          setAvatarError(
            `Avatar must be between ${MIN_WIDTH}x${MIN_HEIGHT} pixels and ${MAX_WIDTH}x${MAX_HEIGHT} pixels.`,
          )
          return
        }

        setAvatar(image.fileDataUrl)
        setAvatarLoading(true)
        const result = await uploader.upload([image.file], {
          source: UploadSource.filePicker(),
        })

        if (result.successful.length) {
          setAvatar(result.successful[0].uploadURL)
          setAvatarError('')
        } else {
          setAvatarError('Avatar upload failed. Please try again.')
        }
      } else {
        setAvatarError('')
        setAvatar('')
      }

      setAvatarLoading(false)
      /**
       * Reset the input value to allow the same file to be uploaded again in case of any errors
       */
      uploader.uppyInstance.reset()
    },
    [uploader],
  )

  return (
    <>
      <Header
        isRefreshingConnection={isRefreshingConnection}
        leftArrow={
          isRefreshingConnection ? undefined : (
            <IconButton
              onClick={onBack}
              label="Go back"
              variant="tertiary"
              size="small"
            >
              <ArrowLeftIcon />
            </IconButton>
          )
        }
      />
      <Dialog.Body
        className={clsx(instagramSelectAccountTypeStyles.body)}
        onDragOver={(event): void => {
          setIsDragging(true)

          event.preventDefault()
        }}
        onDragLeave={(): void => {
          setIsDragging(false)
        }}
        onDrop={(event): void => {
          setIsDragging(false)
          event.preventDefault()

          const item = event.dataTransfer.items[0]
          const file = item.getAsFile()

          if (file && file.type.includes('image')) {
            uploadCallback(file)
          }
        }}
      >
        <div className={instagramSelectAccountTypeStyles.titleSubtitle}>
          {isRefreshingConnection ? (
            <Heading size="large">Edit Instagram Personal Account</Heading>
          ) : (
            <>
              <Heading size="large">
                Add Your Instagram Personal Account to Buffer
              </Heading>
              <Text>
                You&apos;ll be able to plan your posts and set up notifications
                to publish them.
              </Text>
            </>
          )}
        </div>
        <Flex
          className={styles.wrapper}
          gap="lg"
          direction="column"
          align="stretch"
        >
          <Flex gap="2xs" direction="column" align="stretch">
            <Label>Account name (your Instagram username)</Label>
            <Input
              size="large"
              value={name}
              className={userNameValidationError ? styles.hasError : undefined}
              onChange={(event): void => {
                setName(event.target.value.replace(/^@/, ''))
                setUserNameValidationError(undefined)
              }}
            />
            {userNameValidationError ? (
              <Flex gap="2xs" align="center">
                <CriticalIcon size="xsmall" color="critical" />
                <Text size="sm" color="critical">
                  {userNameValidationError}
                </Text>
              </Flex>
            ) : (
              <Text color="subtle" size="sm">
                Find it after the dash: www.instagram.com/*username*
              </Text>
            )}
          </Flex>
          <Flex gap="2xs" direction="column">
            <Label>Avatar (optional)</Label>
            <Card
              className={clsx(
                avatarError && styles.hasError,
                isDragging && instagramSelectAccountTypeStyles.dragging,
              )}
            >
              <Flex gap="xs" align="center">
                <Avatar
                  alt="instagram personal account avatar"
                  src={avatar}
                  className={avatarLoading ? styles.avatarLoading : undefined}
                />
                <Text color="subtle" className={styles.description}>
                  At least {MIN_WIDTH}x{MIN_HEIGHT} pixels, less than 2 MB
                </Text>
                <VisuallyHidden
                  as="input"
                  type="file"
                  id="avatar-uploader"
                  accept={fileRestrictions.allowedFileTypes
                    .map((fileType) => `image/${fileType.replace('.', '')}`)
                    .join(',')}
                  multiple={false}
                  onChange={async (event): Promise<void> => {
                    setAvatar('')
                    setAvatarError('')

                    if (event.target.files?.length) {
                      uploadCallback(event.target.files[0])
                    }

                    event.target.value = ''
                  }}
                ></VisuallyHidden>
                <Button
                  variant="secondary"
                  className={styles.button}
                  as="label"
                  htmlFor="avatar-uploader"
                  disabled={avatarLoading}
                >
                  <ImageIcon />
                  {avatarLoading ? 'Uploading...' : 'Upload Image'}
                </Button>
              </Flex>
            </Card>
            {avatarError && (
              <Flex gap="2xs" align="center">
                <CriticalIcon size="xsmall" color="critical" />
                <Text size="sm" color="critical">
                  {avatarError}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Dialog.Body>
      <Footer>
        <DropdownMenu
          align="start"
          className={footerStyles.menu}
          trigger={
            <Button
              variant="tertiary"
              size="large"
              className={footerStyles.helpButton}
            >
              Help
              <ChevronDownIcon />
            </Button>
          }
        >
          <DropdownMenu.Item asChild>
            <a
              className={footerStyles.helpLink}
              href="https://support.buffer.com/article/658-using-notification-publishing?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
              target="_blank"
              rel="noopener noreferrer"
            >
              Using notification publishing
            </a>
          </DropdownMenu.Item>
        </DropdownMenu>

        <Button
          size="large"
          disabled={loading || avatarLoading}
          onClick={(): void => {
            const userNameValidationError = validateUserName(name)

            if (userNameValidationError) {
              setUserNameValidationError(userNameValidationError)
              return
            }

            BufferTracker.channelConnectionStarted({
              channel: Service.instagram,
              product: 'account',
              channelType: 'profile',
              clientName: 'core',
              organizationId: user?.currentOrganization?.id || '',
            })

            upsertCustomChannels({
              variables: {
                input: isRefreshingConnection
                  ? {
                      channelId,
                      customChannelMetadata: {
                        instagramPersonalProfileMetadata: {
                          name,
                          avatar,
                        },
                      },
                    }
                  : {
                      channels: [
                        {
                          organizationId: user?.currentOrganization?.id,
                          service: Service.instagram,
                          type: 'profile',
                          timezone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                          customChannelMetadata: {
                            instagramPersonalProfileMetadata: {
                              name,
                              avatar,
                            },
                          },
                        },
                      ],
                    },
              },
            }).then(() => {
              if (isRefreshingConnection) {
                dismissModal()
                dispatch(resetChannelConnectionsState({}))

                modalOnContinue?.()
              }
            })
          }}
        >
          {isRefreshingConnection
            ? loading
              ? 'Saving'
              : 'Save'
            : loading
            ? 'Connecting Channel'
            : 'Continue'}
        </Button>
      </Footer>
      <div className={clsx(styles.overlay, isDragging && styles.enabled)}>
        <div className={styles.icon}>
          <ImagePlusIcon size="large" />
        </div>
        <Paragraph weight="bold" size="md">
          Drop 1 image to add as an avatar
        </Paragraph>
      </div>
    </>
  )
}
