import { useSelector } from 'react-redux'
import { startOfWeek, endOfWeek, isSameYear, format } from 'date-fns'
import { selectWeekStartsMonday } from '~publish/legacy/user/selectors'

export function useWeeklyDates(aDate: Date | number): {
  weekStart: Date
  weekEnd: Date
  weekLabel: string
  weekStartsOn: 0 | 1
} {
  const weekStartsOn = useSelector(selectWeekStartsMonday) ? 1 : 0

  const weekStart = startOfWeek(aDate, {
    weekStartsOn,
  })

  const weekEnd = endOfWeek(aDate, {
    weekStartsOn,
  })

  // Deafault: October 11 - 17, 2021
  let startDateFormat = 'MMM d'
  let endDateFormat = ' - d, yyyy'
  if (!isSameYear(weekStart, weekEnd)) {
    // Not same year: December 27, 2021 - January 2, 2022
    startDateFormat = 'MMM d, yyyy'
    endDateFormat = ' - MMM d, yyyy'
  } else if (weekEnd.getMonth() > weekStart.getMonth()) {
    // Not same month: May 30 - June 5, 2022
    endDateFormat = ' - MMM d, yyyy'
  }

  const weekLabel =
    format(weekStart, startDateFormat) + format(weekEnd, endDateFormat)

  return {
    weekStart,
    weekEnd,
    weekLabel,
    weekStartsOn,
  }
}
