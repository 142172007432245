import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Sidebar } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import SettingsSidebar from './SettingsSidebar'
import { preferencesGeneral } from '~publish/legacy/routes'
import ProfileSettings from './ProfileSettings'
import EmailNotifications from './EmailNotifications'
import Preferences from './Preferences'

const Settings = (): JSX.Element => {
  const { isEnabled: newSettingsEnabled } = useSplitEnabled(
    'account-settings-refresh',
  )

  if (!newSettingsEnabled) {
    console.log(
      'Attempted to access new settings, but split is disabled. Redirecting to old preferences.',
    )
    /** @todo: Should we redirect to the Account app instead? */
    return <Redirect to={preferencesGeneral.route} />
  }

  return (
    <Sidebar.Provider>
      <SettingsSidebar />
      <Route path="/settings" exact>
        <ProfileSettings />
      </Route>
      <Route path="/settings/preferences" exact>
        <Preferences />
      </Route>
      <Route path="/settings/notifications" exact>
        <EmailNotifications />
      </Route>
    </Sidebar.Provider>
  )
}

export default Settings
