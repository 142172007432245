import React, { useEffect, useState, useContext } from 'react'
import debounce from 'lodash/debounce'
import Text from '@bufferapp/ui/Text'
import Switch from '@bufferapp/ui/Switch'
import Button from '@bufferapp/ui/Button'
import Checkmark from '@bufferapp/ui/Icon/Icons/Checkmark'
import Notice from '@bufferapp/ui/Notice'
import { isValidChannelCountForPlan } from '../../../../common/utils/validation'
import {
  PlanContext,
  PlanContextDefaultValue,
} from '../../../../common/context/Plan'

import useUpdateSubscriptionPlan from '../../../../common/hooks/Subscription/useUpdateSubscriptionPlan'
import useChannelsCounter from '../../../../common/hooks/ChannelsCounter/useChannelsCounter'
import { freePlan } from '../../../../common/mocks/freePlan'
import useButtonOptions from './hooks/useButtonOptions'
import useHeaderLabel from './hooks/useHeaderLabel'
import { useSplitEnabled } from '@buffer-mono/features'

import Summary from '../Summary'
import { SelectionScreen } from './components/SelectionScreen'

import useSelectedPlan from './hooks/useSelectedPlan'
import useCreateCheckoutSession from './hooks/useCheckoutSession'

import {
  ButtonContainer,
  SwitchContainer,
  PlanSelectorHeader,
  Right,
  Left,
  Container,
  AbsoluteSavings,
  HeaderLeft,
  DowngradeMessage,
  NoticeWrapper,
  LoadingContainer,
} from './style'
import useInterval from './hooks/useInterval'

import { Error } from '../../../../common/components/Error'

import FreePlanSection from './components/FreePlanSection'
import AgencyPlanSection from './components/AgencyPlanSection'

import {
  calculateTotalSlotsPrice,
  getAbsoluteSavings,
  getAvailablePlansForDisplay,
  getBillingDataForPlanSelector,
  getDefaultSelectedPlan,
  getUserDataForPlanSelector,
  isEmailVerificationError,
} from '../../utils'

import { MODALS, PlanInterval } from '../../../../common/types'
import type { ErrorState } from '../../../../common/hooks/Subscription/types'
import { isBillingResponse } from '../../../../common/types/graphQL/Billing'
import EmailVerificationError from '../../../../common/components/EmailVerificationError'
import { UserContext } from '../../../../common/context/User'
import { Loader } from '@bufferapp/ui'
import PlanSelectorTracking from '../../../../tracking/PlanSelector'
import type { ModalData } from '../../../../common/events/types'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'
import { useMediaQuery } from '@buffer-mono/popcorn'

const loadingContainer = (
  <LoadingContainer>
    <Loader />
  </LoadingContainer>
)

const PlanSelector = (): JSX.Element => {
  const { isEnabled: isDefaultPlanNewLogic } = useSplitEnabled(
    'geid-default-plan-new-logic',
  )

  const { isEnabled: isChurnSurveyModalEnabled } =
    useSplitEnabled('geid-churn-survey')

  const [planInfoContext, setPlanInfoContext] = useState(
    PlanContextDefaultValue,
  )

  const user = useContext(UserContext)

  const { isEnabled: isShowTotalCostEnabled } = useSplitEnabled(
    'geid-show-total-cost-in-plan-selector',
    { email: user?.email },
  )

  const {
    organizationId,
    isAFreeUser,
    isActiveTrial,
    userHasPaymentDetails,
    planOptions,
    isAgencyUser,
    isPaidRevenueCat,
    billingData,
    currentQuantity,
    initialChannelQuantity,
    hasTeamMembers,
    isPayingWithBank,
  } = getUserDataForPlanSelector(user)

  if (!billingData) return loadingContainer

  const [error, setError] = useState<ErrorState>(null)
  const [emailVerificationError, setEmailVerificationError] =
    useState<ErrorState>(null)
  const [showAgencyPlan, setShowAgencyPlan] = useState(isAgencyUser)
  const [previousPlanId, setPreviousPlanId] = useState('')
  const {
    modalKey: modal,
    modalData,
    openModal,
    dismissModal: closeAction,
  } = useModalManager()

  const { cta, upgradePathName } = modalData || {}

  const openSuccess = (newData: ModalData): void => {
    closeAction()
    openModal({ key: MODALS.success, data: newData })
  }

  const openChurnSurveyModal = (): void => {
    closeAction()
    openModal({
      key: MODALS.churnSurvey,
      data: {
        isOnTrial: isActiveTrial,
      },
    })
  }

  const defaultSelectedPlan = getDefaultSelectedPlan(
    planOptions,
    isDefaultPlanNewLogic && isAFreeUser,
    hasTeamMembers,
    cta,
  )

  const { selectedPlan, updateSelectedPlan } = useSelectedPlan(
    planOptions,
    defaultSelectedPlan,
  )

  /* Monthly billing is true if defaultSelectedPlan is monthly */
  const { monthlyBilling, setBillingInterval } =
    useInterval(defaultSelectedPlan)

  const { currentPlan, currentPlanInterval } =
    getBillingDataForPlanSelector(billingData)
  if (!currentPlan || !currentPlanInterval) return loadingContainer

  const currentPlanId = currentPlan.id

  const {
    flatFee: selectedPlanFlatFee,
    pricePerQuantity: selectedPlanPricePerQuantity,
    minimumQuantity: selectedPlanMinimumQuantity,
  } = selectedPlan.channelSlotDetails

  const {
    channelsCount,
    increaseCounter,
    decreaseCounter,
    setChannelsCounterValue,
    channelCountMessageStatus,
  } = useChannelsCounter(
    selectedPlan.planId,
    initialChannelQuantity,
    selectedPlanMinimumQuantity,
  )

  const newPrice = calculateTotalSlotsPrice(
    selectedPlan.planId,
    channelsCount,
    selectedPlanPricePerQuantity,
    selectedPlanMinimumQuantity,
    selectedPlanFlatFee,
  )

  const availablePlans = getAvailablePlansForDisplay(
    user,
    planOptions,
    showAgencyPlan,
    isShowTotalCostEnabled,
    channelsCount,
  )

  const { checkoutSession, error: checkoutError } = useCreateCheckoutSession({
    organizationId,
    planName: selectedPlan.planId,
    interval: selectedPlan.planInterval,
    quantity: channelsCount,
    cta,
    upgradePathName,
    initialQuantity: initialChannelQuantity,
    initialSelectedPlan: selectedPlan.planId,
    initialInterval: monthlyBilling ? 'month' : 'year',
  })

  const {
    updateSubscriptionPlan: updatePlan,
    data,
    error: subscriptionError,
    processing,
  } = useUpdateSubscriptionPlan({
    cta,
    user,
    plan: selectedPlan,
    hasPaymentMethod: true,
    channelsQuantity: channelsCount,
    upgradePathName,
  })

  const { label, action, updateButton, ctaButton } = useButtonOptions({
    currentPlanId,
    selectedPlan,
    updatePlan,
    hasPaymentDetails: userHasPaymentDetails,
    isActiveTrial,
    currentChannelQuantity: currentQuantity,
    updatedChannelQuantity: channelsCount,
    checkoutSession,
    openChurnSurveyModal,
    isChurnSurveyModalEnabled,
  })

  const isSmallScreen = useMediaQuery('(width < 1200px)')

  const { headerLabel } = useHeaderLabel(
    isActiveTrial,
    planOptions,
    isAFreeUser,
    isSmallScreen,
  )

  const isValidChannelsCount = isValidChannelCountForPlan(
    selectedPlan.planId,
    channelsCount,
    selectedPlanMinimumQuantity,
  )

  const {
    trackViewed,
    trackPlanClicked,
    trackChannelCountChanged,
    trackPlanCycleClicked,
    trackCTAClicked,
  } = PlanSelectorTracking(user)

  const debouncedTrackBillingCycleToggle = React.useCallback(
    debounce(trackPlanCycleClicked, 3000),
    [],
  )

  const debouncedTrackChannelCountChanged = React.useCallback(
    debounce(trackChannelCountChanged, 3000),
    [],
  )

  const isStripeCheckout = !userHasPaymentDetails
  const shouldShowDowngradeToFree = !isPaidRevenueCat // Don't show the downgrade to free section when a user is on mobile
  const shouldIncludeAgencyPlan = isAgencyUser
  const shouldShowMobileNotice = isPaidRevenueCat
  const shouldShowActionButton = !isPaidRevenueCat

  const isDowngradingToFreePlan =
    previousPlanId !== 'free' &&
    !isActiveTrial &&
    selectedPlan.planId === 'free'

  const disableSumbitButton =
    processing ||
    !action ||
    !isValidChannelsCount ||
    shouldShowMobileNotice ||
    isPayingWithBank

  useEffect(() => {
    setPlanInfoContext({
      selectedPlanId: selectedPlan.planId,
      selectedPlanName: selectedPlan.planName,
      selectedPlanMinimumQuantity,
      currentPlanChannelQuantity: currentQuantity,
    })

    trackViewed({
      currentPlanId,
      currentPlanInterval,
      screenName: headerLabel,
      isStripeCheckout,
      cta,
      ctaButton,
      initialChannelQuantity,
      initialCycle: monthlyBilling ? PlanInterval.month : PlanInterval.year,
      initialSelectedPlan: selectedPlan.planId,
      isAutomaticTaxEnabled:
        user?.currentOrganization?.billing?.isAutomaticTaxEnabled,
      upgradePathName,
    })
  }, [])

  useEffect(() => {
    const newInterval = monthlyBilling ? PlanInterval.month : PlanInterval.year
    const planString = `${selectedPlan.planId}_${newInterval}`
    updateSelectedPlan(planString)
  }, [monthlyBilling])

  useEffect(() => {
    updateButton(selectedPlan, channelsCount)
    setPlanInfoContext({
      selectedPlanId: selectedPlan.planId,
      selectedPlanName: selectedPlan.planName,
      selectedPlanMinimumQuantity,
      currentPlanChannelQuantity: currentQuantity,
    })
  }, [selectedPlan])

  useEffect(() => {
    updateButton(selectedPlan, channelsCount)

    // When the channel count changes and we are on the Free plan
    // We do this to automatically switch the user to the essentials plan
    // as Free plans can have a max of 3 channels
    if (
      selectedPlan.planId === 'free' &&
      channelsCount > selectedPlanMinimumQuantity
    ) {
      const newInterval = monthlyBilling ? 'month' : 'year'
      const planString = `essentials_${newInterval}`
      updateSelectedPlan(planString)
    }
  }, [channelsCount])

  useEffect(() => {
    if (
      isBillingResponse(data?.billingUpdateSubscriptionPlan) &&
      data?.billingUpdateSubscriptionPlan?.success
    ) {
      openSuccess({
        selectedPlan,
        stayedOnSamePlan: previousPlanId === selectedPlan.planId,
        isDowngradingToFreePlan,
        scheduledUpdate: data?.billingUpdateSubscriptionPlan?.scheduledUpdate,
        currentPlanId: currentPlanId,
        currentPlanChannelQuantity: currentQuantity,
      })
    }

    if (isEmailVerificationError(subscriptionError)) {
      setEmailVerificationError(subscriptionError)
    } else if (subscriptionError) {
      setError(subscriptionError)
    }

    if (isEmailVerificationError(checkoutError)) {
      setEmailVerificationError(checkoutError)
    } else if (checkoutError) {
      setError(checkoutError)
    }
  }, [data, subscriptionError, checkoutError, isDowngradingToFreePlan])

  return (
    <PlanContext.Provider value={planInfoContext}>
      <Container
        id="plan_selector_container"
        data-testid="plan_selector_modal"
        numOfAvailablePlans={availablePlans.length / 2}
      >
        <Left>
          <PlanSelectorHeader>
            <Text type="h2" role="heading" aria-level="1">
              {headerLabel}
            </Text>
            {selectedPlan.planId !== 'free' && (
              <HeaderLeft>
                <SwitchContainer>
                  <Switch
                    isOn={!monthlyBilling}
                    handleSwitch={(): void => {
                      setBillingInterval(!monthlyBilling)
                      debouncedTrackBillingCycleToggle({
                        cycle: !monthlyBilling ? 'month' : 'year',
                        channelQuantity: channelsCount,
                      })
                    }}
                    label="Monthly"
                    id="switch-off"
                  />
                  <p>Yearly</p>
                </SwitchContainer>
                <AbsoluteSavings aria-label="Savings" role="textbox">
                  {getAbsoluteSavings(channelsCount, selectedPlan, planOptions)}
                </AbsoluteSavings>
              </HeaderLeft>
            )}
          </PlanSelectorHeader>
          {selectedPlan.downgradedMessage && (
            <DowngradeMessage>
              <Checkmark />
              <Text>{selectedPlan.downgradedMessage}</Text>
            </DowngradeMessage>
          )}
          {error && <Error error={error} isInline={false} />}
          {emailVerificationError && (
            <EmailVerificationError error={emailVerificationError} />
          )}
          {shouldShowMobileNotice && (
            <NoticeWrapper>
              <Notice type="warning" disableAnimation>
                <Text>
                  You will need to use the Buffer mobile app to make changes to
                  your paid subscription and your channels
                </Text>
              </Notice>
            </NoticeWrapper>
          )}
          {isPayingWithBank && (
            <NoticeWrapper>
              <Notice type="warning" disableAnimation>
                <Text>
                  To make changes to your plan, please contact support at{' '}
                  <a href="mailto:hello@buffer.com">hello@buffer.com</a>.
                </Text>
              </Notice>
            </NoticeWrapper>
          )}
          <SelectionScreen
            planOptions={availablePlans}
            selectedPlan={selectedPlan}
            updateSelectedPlan={updateSelectedPlan}
            monthlyBilling={monthlyBilling}
            isMobileBillingUser={isPaidRevenueCat}
            trackPlanClicked={trackPlanClicked}
            channelsCount={channelsCount}
          />
          {!shouldIncludeAgencyPlan && !showAgencyPlan && (
            <AgencyPlanSection
              ctaAction={(): void => {
                updateSelectedPlan(
                  `agency_${monthlyBilling ? 'month' : 'year'}`,
                )
                setShowAgencyPlan(true)
                trackPlanClicked({
                  selectedPlanName: 'Agency',
                  channelQuantity: channelsCount,
                })
              }}
            />
          )}
          {shouldShowDowngradeToFree &&
            (shouldIncludeAgencyPlan || showAgencyPlan) && (
              <FreePlanSection
                ctaAction={(): void => {
                  setShowAgencyPlan(false)
                  updateSelectedPlan(
                    !isAFreeUser
                      ? `${freePlan.planId}_${
                          monthlyBilling ? 'month' : 'year'
                        }`
                      : `${defaultSelectedPlan.planId}_${
                          monthlyBilling ? 'month' : 'year'
                        }`,
                  )
                }}
              />
            )}
        </Left>
        <Right>
          <Summary
            selectedPlan={selectedPlan}
            channelsCount={channelsCount}
            increaseCounter={(): void => {
              increaseCounter()
              debouncedTrackChannelCountChanged(channelsCount + 1)
            }}
            decreaseCounter={(): void => {
              decreaseCounter()
              debouncedTrackChannelCountChanged(channelsCount - 1)
            }}
            setChannelsCounterValue={(value): void => {
              setChannelsCounterValue(parseInt(value))
              debouncedTrackChannelCountChanged(parseInt(value))
            }}
            newPrice={newPrice}
            channelCounterMessageStatus={channelCountMessageStatus}
            currentChannelQuantity={currentQuantity}
            isStripeCheckout={isStripeCheckout}
          />
          {shouldShowActionButton && (
            <ButtonContainer>
              <Button
                id="confirm_and_pay"
                data-test-id={`confirm_and_pay_disabled:${disableSumbitButton}`}
                type="primary"
                onClick={(): void => {
                  // @ts-expect-error TS(2721) FIXME: Cannot invoke an object which is possibly 'null'.
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  action({
                    plan: selectedPlan,
                    cta,
                    ctaView: modal,
                    newPrice,
                    channelCounterMessageStatus: channelCountMessageStatus,
                    currentChannelQuantity: currentQuantity,
                    channelsCount,
                  })
                  setPreviousPlanId(currentPlanId)
                  trackCTAClicked({
                    ctaButtonText: label,
                    cycle: monthlyBilling
                      ? PlanInterval.month
                      : PlanInterval.year,
                    selectedPlanName: selectedPlan.planId,
                    channelQuantity: channelsCount,
                    projectedCost: newPrice,
                    cta,
                    isAutomaticTaxEnabled:
                      user?.currentOrganization?.billing?.isAutomaticTaxEnabled,
                    initialChannelQuantity,
                    initialSelectedPlan: selectedPlan.planId,
                    initialCycle: monthlyBilling
                      ? PlanInterval.month
                      : PlanInterval.year,
                    upgradePathName,
                  })
                }}
                label={processing ? 'Processing...' : label}
                fullWidth
                disabled={disableSumbitButton}
              />
            </ButtonContainer>
          )}
        </Right>
      </Container>
    </PlanContext.Provider>
  )
}

export default PlanSelector
