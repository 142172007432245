import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import {
  Badge,
  Card,
  ChannelIcon,
  Heading,
  IconButton,
  InfoIcon,
  NewBadge,
  Text,
} from '@buffer-mono/popcorn'
import type { ConnectableService, Service } from '../../../../../gql/graphql'

import styles from './ChannelStoreFrontItem.module.css'

function ChannelStoreFrontItem({
  connectableService,
  handleOpenServiceDetails,
  handleConnectService,
  canAddChannels,
}: {
  connectableService: ConnectableService
  handleOpenServiceDetails: (connectableService: ConnectableService) => void
  handleConnectService: (connectableService: Service) => void
  canAddChannels: boolean
}): JSX.Element {
  type SplitConfig = {
    channelsBadgeCopy: string
  }

  const isNew = connectableService.serviceStatus.label === 'new'

  const { isEnabled: isTikTokUsBanEnabled, config } =
    useSplitEnabled('tiktok-us-ban')
  const badgeConfig = config as SplitConfig

  function onKeyDownCard(event: React.KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      handleConnectService(connectableService.service.toLowerCase() as Service)
    }
  }

  return (
    <Card
      aria-label={`Connect ${connectableService.title}`}
      role="button"
      tabIndex={0}
      className={styles.card}
      onClick={(): void => {
        if (canAddChannels) {
          handleConnectService(
            connectableService.service.toLowerCase() as Service,
          )
        } else {
          handleOpenServiceDetails(connectableService)
        }
      }}
      onKeyDown={onKeyDownCard}
    >
      <ChannelIcon
        className={styles.channelIcon}
        color="inverted"
        type={connectableService.service}
        size="medium"
      />
      <div className={styles.wrapper}>
        <IconButton
          className={styles.infoIcon}
          data-testid={`info-${connectableService.service}`}
          label="More Details"
          onClick={(event): void => {
            event.stopPropagation()
            handleOpenServiceDetails(connectableService)
          }}
          tooltip="More Details"
          variant="tertiary"
        >
          <InfoIcon size="small" />
        </IconButton>

        <Heading size="small" align="center">
          {connectableService.title}
          {isNew && <NewBadge className={styles.newBadge} />}
        </Heading>
        <div className={styles.textWrapper}>
          <Text className={styles.subtitle} align="center" color="subtle">
            {connectableService.subtitle}
          </Text>

          {isTikTokUsBanEnabled &&
            badgeConfig?.channelsBadgeCopy &&
            connectableService.service === 'tiktok' && (
              <Badge
                size="xsmall"
                className={styles.infoBadge}
                onClick={(event): void => {
                  event.stopPropagation()
                  handleOpenServiceDetails(connectableService)
                }}
              >
                <strong>{badgeConfig?.channelsBadgeCopy}</strong>
              </Badge>
            )}
        </div>
      </div>
    </Card>
  )
}

export default ChannelStoreFrontItem
