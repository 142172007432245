import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import React, { useState } from 'react'
import { useAccount } from '~publish/legacy/accountContext'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import useDismissBanner, {
  BannerTypes,
} from '~publish/legacy/profile-page/hooks/useDismissBanner'
import { useAppSelector } from '~publish/legacy/store'

export const TwitterThreadsPromoBanner = ({
  onCreateThreadClick,
}: {
  onCreateThreadClick: () => void
}): JSX.Element | null => {
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )

  const { account } = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null

  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.twitterThreadsPromo,
  })

  const [dismissed, setDismissed] = useState(false)

  const onDismissClicked = (): void => {
    BufferTracker.bannerDismissed({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      bannerName: 'twitterThreadsPromoBanner',
    })
    onDismissBanner()
    setDismissed(true)
  }

  if (dismissed) return null

  return (
    <PromotionalBanner onDismiss={onDismissClicked}>
      <PromotionalBanner.Content>
        <PromotionalBanner.Heading>
          Boost your reach and engagement with Twitter Threads
        </PromotionalBanner.Heading>
        <PromotionalBanner.Description>
          Create and schedule Twitter Threads (because Twitter`s algorithm loves
          them)
        </PromotionalBanner.Description>
        <PromotionalBanner.Actions>
          <Button
            variant="primary"
            onClick={(): void => {
              onCreateThreadClick()
              BufferTracker.cTAClicked({
                organizationId,
                clientName: 'publishWeb',
                product: 'publish',
                cta: 'publish-queue-banner-startThread-1',
                upgradePathName: 'threadsLimit-upgrade',
                ...commonTrackingProperties,
              })
            }}
          >
            Start Thread
          </Button>
        </PromotionalBanner.Actions>
      </PromotionalBanner.Content>
      <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/twitter-threads-promo-banner-image.svg" />
    </PromotionalBanner>
  )
}
