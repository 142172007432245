import React, { useRef, useState } from 'react'
import { useMutation } from '@apollo/client'

import {
  Avatar,
  Button,
  CriticalIcon,
  Flex,
  Heading,
  ImageIcon,
  Paragraph,
  Text,
  VisuallyHidden,
  toast,
} from '@buffer-mono/popcorn'

import {
  FileUploadConfig,
  UploadSource,
  useUploader,
} from '@buffer-mono/uploader'

import { useAccount } from '~publish/legacy/accountContext'
import { useAppSelector } from '~publish/legacy/store'
import { selectUserId } from '~publish/legacy/user/selectors'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { graphql } from '~publish/gql'

import { cropToCanvas } from '../util/cropToCanvas'
import style from '../ProfileSettings.module.css'

const avatarPhotoRestrictions = {
  maxNumberOfFiles: 1,
  allowedFileTypes: ['.jpg', '.jpeg', '.png', '.webp'],
  uploadConfig: FileUploadConfig.IMAGE,
}

const MAX_FILE_SIZE = 20 * 1024 * 1024

export const UPDATE_ACCOUNT_AVATAR = graphql(/* GraphQL */ `
  mutation UpdateAccountAvatar($input: UpdateAccountAvatarInput!) {
    updateAccountAvatar(input: $input) {
      __typename
      ... on UpdateAccountAvatarSuccess {
        _empty
      }
      ... on UnexpectedError {
        message
      }
      ... on InvalidInputError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`)

export const Photo = (): JSX.Element => {
  const [avatarError, setAvatarError] = useState('')
  const [avatarLoading, setAvatarLoading] = useState(false)
  const [updateAccountAvatar] = useMutation(UPDATE_ACCOUNT_AVATAR)

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click() // Trigger the hidden input
    }
  }

  const {
    account: { avatar },
    refetchAccount,
  } = useAccount()

  const userId = useAppSelector(selectUserId)
  const organizationId = useAppSelector(selectCurrentOrganizationId)

  const uploader = useUploader({
    id: 'account-avatar-uploader',
    bucket: 'buffer-account-avatars-bucket',
    userId,
    organizationId,
    fileRestrictions: avatarPhotoRestrictions,
  })

  return (
    <Flex gap="sm" align="center" className={style.fullWidth}>
      <button className={style.photoAvatarButton} onClick={handleButtonClick}>
        <Avatar size="medium" alt="Account photo" src={avatar ?? undefined} />
      </button>
      <label className={style.photoDescription} htmlFor="avatar-uploader">
        <Heading size="small" as="h2">
          Photo
        </Heading>
        <Paragraph color="subtle">
          Please choose a photo that is at least 180x180 pixels in size.
        </Paragraph>
        {avatarError && (
          <Flex gap="2xs" align="center">
            <CriticalIcon size="xsmall" color="critical" />
            <Text size="sm" color="critical">
              {avatarError}
            </Text>
          </Flex>
        )}
      </label>
      <div>
        <VisuallyHidden
          as="input"
          type="file"
          ref={fileInputRef}
          id="avatar-uploader"
          accept={avatarPhotoRestrictions.allowedFileTypes
            .map((fileType) => `image/${fileType.replace('.', '')}`)
            .join(',')}
          multiple={false}
          onChange={async (event): Promise<void> => {
            setAvatarLoading(true)
            setAvatarError('')

            if (event.target.files?.length) {
              const file = event.target.files[0]

              if (file.size > MAX_FILE_SIZE) {
                setAvatarError('Your profile photo must be under 20 MB.')
                toast.error('Your profile photo must be under 20 MB.')
                setAvatarLoading(false)
                uploader.uppyInstance.reset()
                event.target.value = ''
                return
              }
              const fileAsObjectUrl = URL.createObjectURL(file)

              const croppedBlob =
                (await cropToCanvas(fileAsObjectUrl, 200)) ?? file

              const result = await uploader.upload(
                [
                  new File([croppedBlob], file.name, {
                    type: croppedBlob.type,
                  }),
                ],
                {
                  source: UploadSource.filePicker(),
                },
              )

              if (result.successful.length) {
                const updateResult = await updateAccountAvatar({
                  variables: {
                    input: {
                      url: result.successful[0].uploadURL,
                    },
                  },
                })
                if (
                  updateResult.data?.updateAccountAvatar?.__typename !==
                  'UpdateAccountAvatarSuccess'
                ) {
                  setAvatarError(
                    'We had trouble updating your account photo. Please try again.',
                  )
                  toast.error(
                    'We had trouble updating your account photo. Please try again.',
                  )
                } else {
                  if (refetchAccount) {
                    refetchAccount()
                  }
                  setAvatarError('')
                  toast.success('Your account photo has been updated!')
                }
              } else {
                setAvatarError('Photo upload failed. Please try again.')
                toast.error('Photo upload failed. Please try again.')
              }
            } else {
              setAvatarError('')
            }

            setAvatarLoading(false)
            uploader.uppyInstance.reset()
            event.target.value = ''
          }}
        ></VisuallyHidden>
        <Button
          size="medium"
          variant="secondary"
          disabled={avatarLoading}
          onClick={handleButtonClick}
        >
          <ImageIcon />
          {avatarLoading ? 'Uploading…' : 'Upload Photo'}
        </Button>
      </div>
    </Flex>
  )
}

export default Photo
