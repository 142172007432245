import { useQuery } from '@apollo/client'
import { graphql } from '~publish/graphql'
import { useOrganizationId } from '~publish/legacy/accountContext'

const GetIdeaGroupsQuery = graphql(/* GraphQL */ `
  query GetIdeaGroups($organizationId: ID!) {
    ideaGroups(input: { organizationId: $organizationId }) {
      id
      name
      isLocked
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useIdeaGroups = () => {
  const { data, loading, error } = useQuery(GetIdeaGroupsQuery, {
    variables: { organizationId: useOrganizationId() },
    fetchPolicy: 'cache-first',
  })

  return { groups: data?.ideaGroups, loading, error }
}
