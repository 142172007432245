import * as React from 'react'

const StreakIcon = React.forwardRef(
  (
    {
      size,
      stroke,
      ...props
    }: Omit<React.SVGAttributes<SVGElement>, 'children' | 'stroke'> & {
      size?: number
      stroke?: number
    },
    ref: React.ForwardedRef<SVGSVGElement>,
  ) => {
    const iconName = 'streak'

    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ color: 'currentColor' }}
        data-icon={iconName}
        aria-hidden="true"
        strokeWidth={stroke ?? 2}
        width={size ?? 24}
        height={size ?? 24}
        ref={ref}
        {...props}
      >
        <path
          d="M20 4.5C20 8.49375 17.0258 11.795 13.1727 12.3047C12.923 10.4273 12.0969 8.73281 10.877 7.40742C12.2234 5.00273 14.7969 3.375 17.75 3.375H18.875C19.4973 3.375 20 3.87773 20 4.5ZM2 6.75C2 6.12773 2.50273 5.625 3.125 5.625H4.25C8.59883 5.625 12.125 9.15117 12.125 13.5V20.25C12.125 20.8723 11.6223 21.375 11 21.375C10.3777 21.375 9.875 20.8723 9.875 20.25V14.625C5.52617 14.625 2 11.0988 2 6.75Z"
          fill="currentColor"
        />
      </svg>
    )
  },
)

StreakIcon.displayName = 'StreakIcon'

export default StreakIcon
