import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import {
  ideaDetailsRoute,
  ideaEditRoute,
  ideaNewRoute,
} from '~publish/legacy/routes'

import { IdeaCreationDialog } from './IdeaCreationDialog'
import { IdeaDetailsDialog } from './IdeaDetailsDialog'
import { IdeaEditDialog } from './IdeaEditDialog'

/**
 * Router component used to manage IdeaCreationDialog, IdeaEditDialog, and IdeaDetailsDialog.
 * Accepts a parent route that is prefixed to the dialog routes.
 * This allows selectively rendering the dialogs where necessary
 * without needing them to be completely global.
 */
export function IdeaManagementRouter(): JSX.Element {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${match.path}${ideaNewRoute.route}`}
        key="idea-creation-dialog"
      >
        <IdeaCreationDialog />
      </Route>
      <Route
        path={`${match.path}${ideaEditRoute.route}`}
        key="idea-edit-dialog"
      >
        <IdeaEditDialog />
      </Route>
      <Route
        path={`${match.path}${ideaDetailsRoute.route}`}
        key="idea-details-dialog"
      >
        <IdeaDetailsDialog />
      </Route>
    </Switch>
  )
}
