import React from 'react'
import { NewspaperIcon, ChevronDownIcon } from '@buffer-mono/popcorn'
import {
  type FeedCollectionForDetails,
  type RssFeedConnectionMetadata,
  type NewsSearchConnectionMetadata,
  useUrlOrigin,
} from '../../hooks'
import styles from './ManageSubscriptionTrigger.module.css'

type RssFeedNameProps = {
  feed: FeedCollectionForDetails['feeds'][number]
  connectionMetadata: Pick<RssFeedConnectionMetadata, 'url'>
}

const RSSFeedName = (props: RssFeedNameProps): JSX.Element => {
  const { feed, connectionMetadata } = props

  // Use root domain rather than RSS feed, sicne it is typically xml
  // and therefore does not contain common metadata
  const [origin] = useUrlOrigin(connectionMetadata.url)

  return (
    <>
      {origin && (
        <img
          src={`http://www.google.com/s2/favicons?domain=${origin}`}
          alt={`${feed.name} icon`}
          className={styles.feedNameFavicon}
        />
      )}
      {feed.name}
      <ChevronDownIcon />
    </>
  )
}

type NewsFeedNameProps = {
  connectionMetadata: Pick<NewsSearchConnectionMetadata, 'query'>
}

const NewsFeedName = (props: NewsFeedNameProps): JSX.Element => {
  const { connectionMetadata } = props

  return (
    <>
      <NewspaperIcon className={styles.feedNameIcon} />
      {`Search for “${connectionMetadata.query}”`}
      <ChevronDownIcon />
    </>
  )
}

type ManageSubscriptionTriggerProps = {
  feed: FeedCollectionForDetails['feeds'][number]
}

export const ManageSubscriptionTrigger = (
  props: ManageSubscriptionTriggerProps,
): JSX.Element => {
  const { feed } = props

  const connectionMetadata = feed.connectionMetadata

  if (!connectionMetadata || !connectionMetadata.__typename) {
    return <div>Malformed feed: {feed.name}</div>
  }

  if (connectionMetadata.__typename === 'RSSFeedConnectionMetadata') {
    return <RSSFeedName feed={feed} connectionMetadata={connectionMetadata} />
  }

  if (connectionMetadata.__typename === 'NewsSearchConnectionMetadata') {
    return <NewsFeedName connectionMetadata={connectionMetadata} />
  }

  const _exhaustiveCheck: never = connectionMetadata.__typename
  throw new Error(`Unknown feed type ${_exhaustiveCheck}`)
}
