import { Heading, Link, Text, UnstyledButton } from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'

import { graphql } from '~publish/gql'
import type { PostCardContent_AnnotationFragment } from '~publish/gql/graphql'
import { useTruncatedText } from '~publish/hooks/useTruncatedText'

import styles from './PostCardContent.module.css'

const CLAMP_TEXT_LINES_THRESHOLD = 3

export const PostCardContent_Annotation = graphql(/* GraphQL */ `
  fragment PostCardContent_Annotation on Annotation {
    type
    indices
    content
    text
    url
  }
`)

const generateTextWithAnnotations = ({
  text,
  annotations,
}: {
  text: string
  annotations?: readonly PostCardContent_AnnotationFragment[] | null
}): Array<JSX.Element | string> => {
  const elements: Array<JSX.Element | string> = []
  let currentIndex = 0

  if (!annotations || annotations.length === 0) {
    elements.push(text)
    return elements
  }

  annotations.forEach((annotation) => {
    const startIndex = annotation.indices[0]
    // Support for partial annotations if text is truncated
    const endIndex =
      annotation.indices[1] < text.length ? annotation.indices[1] : text.length

    if (startIndex < text.length) {
      if (currentIndex !== startIndex) {
        elements.push(text.substring(currentIndex, startIndex))
      }

      elements.push(
        <Link
          href={annotation.url}
          key={elements.length}
          external
          className={styles.link}
        >
          {annotation.text}
        </Link>,
      )
      currentIndex = endIndex
    }
  })

  if (currentIndex !== text.length) {
    elements.push(text.substring(currentIndex))
  }

  return elements
}

export const PostCardContent = ({
  title,
  text,
  annotations,
  expanded = false,
  lineClamp = CLAMP_TEXT_LINES_THRESHOLD,
  className,
  onSeeMoreClick,
}: {
  text: string
  annotations: readonly PostCardContent_AnnotationFragment[]
  title?: string
  expanded?: boolean
  lineClamp?: number
  className?: string
  onSeeMoreClick?: () => void
}): JSX.Element | null => {
  const textRef = React.useRef<HTMLParagraphElement>(null)
  const [textManuallyExpanded, setTextManuallyExpanded] =
    React.useState(expanded)

  const handleSeeMoreClick = React.useCallback((): void => {
    if (onSeeMoreClick) {
      onSeeMoreClick()
      return
    }

    setTextManuallyExpanded(true)
  }, [onSeeMoreClick])

  const [truncatedText, didTruncate] = useTruncatedText({
    text,
    lineClamp,
    container: textRef,
    expansionText: '... see more',
    expansionTextBufferMultiplier: 1.5,
  })

  if (!text && !title) {
    return null
  }

  const isShowingFullText = textManuallyExpanded || !didTruncate

  const textElements = generateTextWithAnnotations({
    text: textManuallyExpanded ? text : truncatedText,
    annotations,
  })

  const lastChar = truncatedText[truncatedText.length - 1]
  const readModeAfterText = !(
    lastChar === '\n' ||
    lastChar === ' ' ||
    lastChar === '\t'
  )

  return (
    <div className={clsx(styles.content, className)}>
      {title && (
        <Heading as="h3" size="small">
          {title}
        </Heading>
      )}
      {text && (
        <Text as="p" ref={textRef} className={styles.textContent}>
          <Text
            data-no-dnd
            className={styles.annotatedText}
            aria-expanded={isShowingFullText}
          >
            {textElements}
          </Text>
          {!isShowingFullText && (
            <>
              {readModeAfterText && <span>...&nbsp;</span>}
              <UnstyledButton
                onClick={handleSeeMoreClick}
                className={styles.readMoreButton}
              >
                see more
              </UnstyledButton>
            </>
          )}
        </Text>
      )}
    </div>
  )
}

PostCardContent.displayName = 'PostCardContent'
