import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type IdeasMultiSelectContextType = {
  selecting: boolean
  selectedIds: string[]
  selectedCount: number
  selectIdea: (id: string) => void
  clearSelection: () => void
}

const IdeasMultiSelectContext = createContext<
  IdeasMultiSelectContextType | undefined
>(undefined)

export const IdeasMultiSelectProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const selectIdea = useCallback(
    (ideaId: string): void => {
      setSelectedIds((ids: string[]) => {
        if (ids.includes(ideaId)) {
          return ids.filter((id) => id !== ideaId)
        }

        return [...ids, ideaId]
      })
    },
    [setSelectedIds],
  )

  const clearSelection = useCallback((): void => {
    setSelectedIds([])
  }, [setSelectedIds])

  const value = useMemo(
    () => ({
      selecting: selectedIds.length > 0,
      selectedIds,
      selectedCount: selectedIds.length,
      selectIdea,
      clearSelection,
    }),
    [selectedIds, selectIdea, clearSelection],
  )

  return (
    <IdeasMultiSelectContext.Provider value={value}>
      {children}
    </IdeasMultiSelectContext.Provider>
  )
}

export const useIdeasMultiSelect = (): IdeasMultiSelectContextType => {
  const context = useContext(IdeasMultiSelectContext)
  if (!context) {
    throw new Error(
      'useIdeasMultiSelect must be used within IdeasMultiSelectProvider',
    )
  }
  return context
}
