import { graphql } from '~publish/gql'
import { useMutation } from '@apollo/client'
import type {
  UpsertFeedMutationVariables,
  UpsertFeedMutation,
} from '~publish/gql/graphql'

const UpsertFeed = graphql(/* GraphQL */ `
  mutation UpsertFeed($input: CreateFeedInput!) {
    upsertFeed(input: $input) {
      __typename
      ... on FeedActionSuccess {
        feed {
          id
        }
      }
    }
  }
`)

export const useUpsertFeed = (): ((
  input: UpsertFeedMutationVariables['input'],
) => Promise<
  UpsertFeedMutation['upsertFeed'] | undefined | null
>) => {
  const [create] = useMutation(UpsertFeed)

  return async (input: UpsertFeedMutationVariables['input']) => {
    const data = await create({ variables: { input } })
    return data.data?.upsertFeed
  }
}
