import React, { useState, useEffect, useRef, useMemo } from 'react'

import { Button, CoachMark } from '@buffer-mono/popcorn'
import { useLazyQuery } from '@apollo/client'

import type {
  GetCalendarAndPostListQuery,
  GetCalendarAndPostListQueryVariables,
} from '~publish/gql/graphql'
import { getFragmentData } from '~publish/gql'
import { GetCalendarAndPostList } from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'
import { useOrganizationId } from '~publish/legacy/accountContext'
import type { FragmentType } from '~publish/gql/fragment-masking'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { usePostComposer } from '~publish/hooks/usePostComposer'

import { useChannels } from './useChannels'
import { ChannelItem_Channel } from './ChannelItem'
import LinkedinCoachmarkBanner from './LinkedInPromoCoachmarkBanner.png'

import style from './LinkedInPromoCoachmark.module.css'

const LinkedInPromoCoachmarkWrapper = React.memo(
  ({
    channel: passedChannel,
    children,
  }: {
    channel: FragmentType<typeof ChannelItem_Channel>
    children: React.ReactNode
  }): JSX.Element => {
    const channel = getFragmentData(ChannelItem_Channel, passedChannel)
    const [isVisibleInViewport, setIsVisible] = useState(false)
    const [hasPostInLastWeek, setHasPostInLastWeek] = useState<
      boolean | undefined
    >(undefined)
    const hasFetched = useRef(false)
    const childRef = useRef<HTMLDivElement | null>(null)
    const { channels } = useChannels()
    const allLinkedInChannelIds = useMemo(() => {
      return channels.filter((c) => c.service === 'linkedin').map((c) => c.id)
    }, [])
    const organizationId = useOrganizationId() ?? ''

    const { createNewPostInComposer } = usePostComposer()

    // Query to see if there's at least one linked in post in the last week
    const [getCalendarAndPostList, { data, loading }] = useLazyQuery<
      GetCalendarAndPostListQuery,
      GetCalendarAndPostListQueryVariables
    >(GetCalendarAndPostList)
    useEffect(() => {
      if (data?.posts.edges?.length) {
        setHasPostInLastWeek(true)
      }
    }, [data])

    // Handle visibility of the coachmark based on an intersection
    // observer (if it's in the viewport)
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries
          setIsVisible(entry.isIntersecting)
        },
        { threshold: 0.5 }, // 50% of the element is visible
      )

      const currentChildRef = childRef.current

      if (currentChildRef) {
        observer.observe(currentChildRef)
      }

      return () => {
        if (currentChildRef) {
          observer.unobserve(currentChildRef)
        }
      }
    }, [channel.service])

    // Use the dismissable banner logic
    const bannerId = 'linkedInVideoPromo'
    const banner = useDismissableBanner(bannerId, {
      trackDismiss: true,
    })

    // Trigger the query to get the last week's posts
    // But only if the banner is active and the coachmark is enabled
    // (To avoid unnecessary queries)
    useEffect(() => {
      if (!hasFetched.current && banner.isActive) {
        getCalendarAndPostList({
          variables: {
            startDate: new Date(
              Date.now() - 7 * 24 * 60 * 60 * 1000, // 7 days ago
            ).toISOString(),
            endDate: new Date().toISOString(),
            channelIds: allLinkedInChannelIds,
            organizationId,
            postsLimit: 1,
            tagIds: [],
            status: ['sent'],
          },
        })
        hasFetched.current = true
      }
    }, [
      getCalendarAndPostList,
      allLinkedInChannelIds,
      organizationId,
      banner.isActive,
    ])

    function close(): void {
      banner.dismiss()
      window.__closedLiCm = true
    }

    function closeAndCreatePost(): void {
      close()
      createNewPostInComposer({
        channels: allLinkedInChannelIds,
        cta: 'publish-sidebar-coachmark-linkedInVideoPromo-1',
      })
    }

    const shouldShowCoachmark =
      window.__closedLiCm !== true &&
      banner.isActive &&
      isVisibleInViewport &&
      hasPostInLastWeek !== undefined &&
      !hasPostInLastWeek &&
      !loading

    const tracked = useRef(false)
    useEffect(() => {
      console.log({
        shouldShowCoachmark,
      })
      if (!shouldShowCoachmark) return
      if (tracked.current) return
      tracked.current = true
    }, [shouldShowCoachmark, organizationId])

    return (
      <CoachMark open={shouldShowCoachmark} onDismiss={close}>
        <CoachMark.Overlay>
          <CoachMark.Spotlight />
          <div ref={childRef}>{children}</div>
        </CoachMark.Overlay>
        <CoachMark.Content
          align="start"
          side="right"
          className={style.popupOffset}
          showCloseIcon={false}
        >
          <div>
            <img
              src={LinkedinCoachmarkBanner}
              alt="LinkedIn"
              className={style.bannerImage}
            />
          </div>
          <CoachMark.Title>LinkedIn Tip ✨</CoachMark.Title>
          <CoachMark.Description>
            Did you know that video posts on LinkedIn get 1.4x more engagement
            than other formats?
          </CoachMark.Description>
          <CoachMark.Footer>
            <Button variant="secondary" onClick={close}>
              Got it
            </Button>
            {/* <NewPostComposerTrigger
                channels={allLinkedInChannelIds}
                cta={'publish-sidebar-coachmark-linkedInVideoPromo-1'}
              > */}
            <Button onClick={closeAndCreatePost}>Create a Video Post</Button>
            {/* </NewPostComposerTrigger> */}
          </CoachMark.Footer>
        </CoachMark.Content>
      </CoachMark>
    )
  },
)

LinkedInPromoCoachmarkWrapper.displayName = 'LinkedInPromoCoachmarkWrapper'

export { LinkedInPromoCoachmarkWrapper }
