import React, { useState } from 'react'
import { IconButton, EllipsisIcon, DropdownMenu } from '@buffer-mono/popcorn'
import type { FeedCollection } from '../../hooks'
import { RenameCollectionDialog } from '../FeedSettings/RenameCollectionDialog'
import { DeleteCollectionConfirmationDialog } from '../FeedSettings/DeleteCollectionConfirmationDialog'

type ActiveDialog = 'rename' | 'delete'

type FeedCollectionSettingsDropdownProps = {
  feedCollection: FeedCollection
}

export function FeedCollectionSettingsDropdown(
  props: FeedCollectionSettingsDropdownProps,
): JSX.Element {
  const { feedCollection } = props
  const [activeDialog, setActiveDialog] = useState<ActiveDialog | null>(null)
  const clickHandler =
    (dialog: ActiveDialog) =>
    (event: React.MouseEvent<HTMLDivElement>): void => {
      // prevent from triggering the navigation
      event.stopPropagation()
      setActiveDialog(dialog)
    }
  return (
    <>
      {activeDialog === 'rename' && (
        <RenameCollectionDialog
          feedCollection={feedCollection}
          open={true}
          onOpenChange={(open: boolean): void =>
            setActiveDialog(open ? 'rename' : null)
          }
        />
      )}
      {activeDialog === 'delete' && (
        <DeleteCollectionConfirmationDialog
          feedCollection={feedCollection}
          open={true}
          onOpenChange={(open: boolean): void =>
            setActiveDialog(open ? 'delete' : null)
          }
        />
      )}
      <DropdownMenu>
        <DropdownMenu.Trigger>
          <IconButton label="More" size="small" variant="tertiary">
            <EllipsisIcon />
          </IconButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Label>Collection settings</DropdownMenu.Label>
          <DropdownMenu.Item onClick={clickHandler('rename')}>
            Rename
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={clickHandler('delete')}>
            Delete
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu>
    </>
  )
}
