import { useState, useMemo, useEffect, useCallback } from 'react'
import { Temporal } from '@js-temporal/polyfill'

import { useQueryParam } from '~publish/hooks/useQueryParam'
import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

const DEFAULT_TIMEZONE = getCurrentTimeZone()

/**
 * @param {number} [timestamp] timestamp in milliseconds since epoch as initial value
 * @param {{timezone: string}} [options]
 * @param {string} [options.timezone] timezone to use
 * @returns {[Temporal.ZonedDateTime, (value: Temporal.ZonedDateTime | undefined) => void]} currentDate and setCurrentDate
 */
export const useSelectedDate = (
  timestamp?: number,
  { timezone }: { timezone: string } = { timezone: DEFAULT_TIMEZONE },
): [
  Temporal.ZonedDateTime,
  (value: Temporal.ZonedDateTime | undefined) => void,
] => {
  const [dateParam, setDateParam] = useQueryParam<string>('date')

  const initialDate = useMemo(() => {
    if (timestamp) {
      return Temporal.Instant.fromEpochMilliseconds(
        timestamp,
      ).toZonedDateTimeISO(timezone)
    } else if (dateParam) {
      const paramTimestamp = parseInt(dateParam, 10)
      if (!isNaN(paramTimestamp)) {
        return Temporal.Instant.fromEpochMilliseconds(
          paramTimestamp,
        ).toZonedDateTimeISO(timezone)
      }
    }
    return Temporal.Now.zonedDateTimeISO(timezone)
  }, [timestamp, dateParam, timezone])

  const [selectedDate, setSelectedDate] =
    useState<Temporal.ZonedDateTime>(initialDate)

  const setTemporalDateParam = useCallback(
    (newDate: Temporal.ZonedDateTime | undefined) => {
      setDateParam(newDate?.toInstant().epochMilliseconds.toString())
    },
    [setDateParam],
  )

  const now = useMemo(() => Temporal.Now.zonedDateTimeISO(timezone), [timezone])

  useEffect(() => {
    if (dateParam) {
      const paramTimestamp = parseInt(dateParam, 10)
      if (!isNaN(paramTimestamp)) {
        setSelectedDate(
          Temporal.Instant.fromEpochMilliseconds(
            paramTimestamp,
          ).toZonedDateTimeISO(timezone),
        )
      }
    } else {
      setSelectedDate(now)
    }
  }, [dateParam, timezone, setTemporalDateParam, now])

  return [selectedDate, setTemporalDateParam]
}
