import React, { useCallback, useMemo, useState } from 'react'

import {
  Heading,
  Flex,
  Sidebar,
  Button,
  MessageCircleHeartIcon,
} from '@buffer-mono/popcorn'
import { FeedbackWidget } from '~publish/components/FeedbackWidget'
import { ContentFeedContent } from './ContentFeedContent'
import { ContentFeedEmptyState } from './ContentFeedEmptyState'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { PageLayout } from '~publish/components/PageLayout'

import { useFeedItems } from '../../hooks/useFeedItems'
import { useFeedCollection, useUpdateFeedCollection } from '../../hooks'
import { SubscribeToFeedDialog } from '../FeedSettings/SubscribeToFeedDialog'
import { ManageSubscriptionDropdown } from '../FeedSettings/ManageSubscriptionDropdown'
import { usePageTitle } from '~publish/hooks/usePageTitle'

type ContentFeedProps = {
  feedCollectionId: string
}

const feedLimit = 3
export const ContentFeed = (props: ContentFeedProps): JSX.Element => {
  const organizationId = useOrganizationId()
  const feedCollection = useFeedCollection(props.feedCollectionId)
  const updateFeedCollection = useUpdateFeedCollection()
  const [isSubscribeToFeedDialogOpen, setIsSubscribeToFeedDialogOpen] =
    useState(false)
  const feedData = useFeedItems(props.feedCollectionId, organizationId)
  const { refetch } = feedData

  const feedName = feedCollection.feedCollection?.name
  usePageTitle(
    feedName ? `${feedCollection.feedCollection?.name} (Feed)` : 'Feeds',
  )

  const feeds = useMemo(
    () => feedCollection.feedCollection?.feeds ?? [],
    [feedCollection],
  )
  const feedIds = useMemo(() => feeds.map((feed) => feed.id), [feeds])

  const onToggleSubscribe = useCallback(
    async (id: string, subscribe: boolean): Promise<void> => {
      if (!feedCollection.feedCollection) {
        return
      }
      const newFeedIds = feedIds.filter((feedId) => feedId !== id)
      if (subscribe) {
        newFeedIds.push(id)
      }

      await updateFeedCollection({
        feedCollectionId: feedCollection.feedCollection.id,
        feedIds: newFeedIds,
      })

      refetch()
    },
    [feedIds, feedCollection, updateFeedCollection, refetch],
  )

  const collection = feedCollection.feedCollection

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.HeaderRow size="narrow">
          <Flex gap="sm" align="center">
            <Sidebar.Trigger />
            <Flex align="start" direction="column">
              <Heading size="large">{collection?.name}</Heading>
              <Flex gap="xs" wrap="wrap" style={{ marginLeft: -8 }}>
                {collection?.feeds.map((feed) => (
                  <ManageSubscriptionDropdown
                    key={feed.id}
                    feed={feed}
                    onToggleSubscribe={onToggleSubscribe}
                  />
                ))}
                <SubscribeToFeedDialog
                  onToggleSubscribe={onToggleSubscribe}
                  disabledReason={
                    feeds.length >= feedLimit ? 'limitReached' : null
                  }
                  existingIds={feedIds}
                  isOpen={isSubscribeToFeedDialogOpen}
                  setIsOpen={setIsSubscribeToFeedDialogOpen}
                  feedCollectionId={props.feedCollectionId}
                />
              </Flex>
            </Flex>
          </Flex>
          <PageLayout.Actions>
            <FeedbackWidget id="all-channels-1" source="publish">
              <Button variant="tertiary" size="large">
                <MessageCircleHeartIcon />
                Share Feedback
              </Button>
            </FeedbackWidget>
          </PageLayout.Actions>
        </PageLayout.HeaderRow>
      </PageLayout.Header>
      <PageLayout.Container size="narrow">
        <ContentFeedEmptyState
          feedData={feedData}
          collectionData={feedCollection}
          onShowNewFeedDialog={(): void => setIsSubscribeToFeedDialogOpen(true)}
        />
        <ContentFeedContent
          feedCollectionId={props.feedCollectionId}
          feedData={feedData}
        />
      </PageLayout.Container>
    </PageLayout>
  )
}
