import React, { useMemo } from 'react'

import styles from './ContentFeed.module.css'

const isSame = (
  a: Date,
  b: Date,
): {
  day: boolean
  week: boolean
  month: boolean
  year: boolean
} => {
  const year = a.getFullYear() === b.getFullYear()
  const month = year && a.getMonth() === b.getMonth()
  const [newer, older] = a.getDate() > b.getDate() ? [a, b] : [b, a]
  const week = month && newer.getDate() - older.getDate() < 7 && a.getDay() > 0
  const day = month && a.getDate() === b.getDate()
  return {
    day,
    week,
    month,
    year,
  }
}

type ContentFeedDateHeaderProps = {
  previousDate?: string
  nextDate: string
}

export const ContentFeedDateHeader = (
  props: ContentFeedDateHeaderProps,
): JSX.Element => {
  const { previousDate, nextDate } = props
  const isNewDay = useMemo(() => {
    if (!previousDate) {
      return true
    }

    return !isSame(new Date(nextDate), new Date(previousDate)).day
  }, [nextDate, previousDate])

  const newDay = useMemo(() => {
    const now = new Date()
    const yesterday = new Date(now.getTime() - 1000 * 60 * 60 * 24)
    const postDate = new Date(nextDate)
    const same = isSame(postDate, now)
    if (same.day) {
      return 'Today'
    }
    if (isSame(postDate, yesterday).day) {
      return 'Yesterday'
    }
    if (same.week) {
      return postDate.toLocaleDateString('en-US', {
        weekday: 'long',
      })
    }
    if (same.year) {
      return postDate.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      })
    }
    return postDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    })
  }, [nextDate])

  if (!isNewDay) {
    return <></>
  }

  return (
    <h2 className={styles.dateSegment}>
      <time dateTime={new Date(nextDate).toISOString()}>{newDay}</time>
    </h2>
  )
}
