import React from 'react'
import {
  Sidebar,
  Heading,
  IdeasIcon,
  BetaBadge,
  Flex,
} from '@buffer-mono/popcorn'
import { NavLink } from 'react-router-dom'

import styles from './CreatePageLayout.module.css'
import { FeedCollectionsNav } from '../../Feed/components/FeedCollectionsNav/FeedCollectionsNav'

type Props = {
  children: React.ReactNode
}

export function CreatePageLayout(props: Props): JSX.Element {
  return (
    <Sidebar.Provider
      className={styles.sidebarContainer}
      mobileFallback={true}
      data-temporary-hack-disable-root-scroll
    >
      <Sidebar collapsible="offcanvas" className={styles.sidebar}>
        <Sidebar.Header data-nohover className={styles.sidebarHeader}>
          <Heading
            as="h3"
            size="small"
            align="center"
            className={styles.createHeadingTitle}
          >
            Create
          </Heading>
        </Sidebar.Header>
        <Sidebar.Content>
          <Sidebar.Group>
            <Sidebar.GroupContent>
              <Sidebar.List>
                <Sidebar.ListItem>
                  <Sidebar.Button
                    prefix={<IdeasIcon />}
                    as={NavLink}
                    exact
                    to="/create"
                  >
                    Ideas
                  </Sidebar.Button>
                </Sidebar.ListItem>
              </Sidebar.List>
            </Sidebar.GroupContent>
          </Sidebar.Group>
          <Sidebar.Group>
            <Sidebar.GroupLabel>
              <Flex direction="row" align="center" justify="start" gap="sm">
                <span>Feed Collections</span>
                <BetaBadge size="xsmall" />
              </Flex>
            </Sidebar.GroupLabel>
            <Sidebar.GroupContent>
              <Sidebar.List>
                <FeedCollectionsNav />
              </Sidebar.List>
            </Sidebar.GroupContent>
          </Sidebar.Group>
        </Sidebar.Content>
        <Sidebar.Rail />
      </Sidebar>
      <div className={styles.createPageContent}>{props.children}</div>
    </Sidebar.Provider>
  )
}
