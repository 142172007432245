import type { ModalData } from './events/types'

export enum MODALS {
  channelConnectionPrompt = 'channelConnectionPrompt' /* the one used in Analyze and Engage */,
  paywall = 'paywall',
  planSelector = 'planSelector',
  quantityUpdate = 'quantityUpdate',
  startTrial = 'startTrial',
  startTrialNoClick = 'startTrialNoClick',
  startTrialReactive = 'startTrialReactive',
  success = 'success',
  trialExpired = 'trialExpired',
  subscriptionUpdate = 'subscriptionUpdate',
  connectChannelUpgrade = 'connectChannelUpgrade',
  channelConnectionSuccessModal = 'channelConnectionSuccessModal',
  channelRefreshSuccessModal = 'channelRefreshSuccessModal',
  checklistComplete = 'checklistComplete',
  instagramFacebookReAuth = 'instagramFacebookReAuth',
  instagramSelectAccountType = 'instagramSelectAccountType',
  featurePaywall = 'featurePaywall',
  churnSurvey = 'churnSurvey',
  confirmCancellation = 'confirmCancellation',
  trialSignupWelcomeModal = 'trialSignupWelcomeModal',
  downloadApp = 'downloadApp',
  setupNotifications = 'setupNotifications',
  /* used to inform a team member has lost access due to a downgrade in the organization */
  teamMemberDowngrade = 'teamMemberDowngrade',
  quantityUpdateTieredPricing = 'quantityUpdateTieredPricing',
  postingPlanConfiguration = 'postingPlanConfiguration',
}

// Define modal priorities with a default of 1
export const modalPriorities: Record<MODALS, number> = {
  // Set default priority for all modals
  ...Object.values(MODALS).reduce((acc, modal) => {
    acc[modal] = 10
    return acc
  }, {} as Record<MODALS, number>),

  /* Analyze and Enagage paywalls */
  [MODALS.paywall]: 20,
  [MODALS.featurePaywall]: 20,
  [MODALS.downloadApp]: 20,

  /* modals triggered by a user action  */
  [MODALS.planSelector]: 30,
  [MODALS.quantityUpdate]: 30,
  [MODALS.quantityUpdateTieredPricing]: 30,
  [MODALS.churnSurvey]: 30,
  [MODALS.setupNotifications]: 30,
  [MODALS.confirmCancellation]: 30,
  [MODALS.subscriptionUpdate]: 30,
  [MODALS.success]: 30,
  [MODALS.startTrial]: 30,

  /* modals related to channel connection are top priority */
  [MODALS.connectChannelUpgrade]: 40,
  [MODALS.channelConnectionSuccessModal]: 40,
  [MODALS.channelRefreshSuccessModal]: 40,
  [MODALS.instagramFacebookReAuth]: 40,
  [MODALS.instagramSelectAccountType]: 40,
}

export type ModalProps = {
  closeAction: () => void
}

export type ModalReturn = {
  data: ModalData | null
  modal: string | null
  openModal: (modalKey: string | null, modalData?: ModalData) => void
}

export enum BillingState {
  free = 'free',
  trial = 'trial',
  paid = 'paid',
}

export type SplitState = {
  name: string
  attributes: object
  treatment: string
  isOK: boolean
  config: any
}

export type CreditCardDetails = {
  brand: string
  expMonth: string
  expYear: string
  last4: string
}

export type PaymentDetails = {
  hasPaymentDetails: boolean
  creditCard?: CreditCardDetails
  paymentMethod?: string
}

export enum GatewayPlatform {
  web = 'web',
  apple = 'apple',
  android = 'android',
}

export type GatewayDetails = {
  gatewayType: string
  gatewayId: string
  gatewayPlatform: GatewayPlatform
}

export type TrialStatus = {
  isActive: boolean
  remainingDays: number
  isAwaitingUserAction: boolean
  startDate: string
  endDate: string
}

export type Plan = {
  id: string
  name: string
}

export enum PlanInterval {
  month = 'month',
  year = 'year',
}

export enum Gateway {
  stripe = 'stripe',
  apple = 'apple',
  android = 'android',
  none = 'none',
  paypal = 'paypal',
  gift = 'gift',
  check = 'check',
  bank = 'bank',
  revenuecat = 'revenuecat',
}

export type Subscription = {
  quantity: number
  interval: PlanInterval
  periodEnd: string | null
  isCanceledAtPeriodEnd: boolean
  trial: TrialStatus
  plan: Plan
  isPaymentPastDue?: boolean | null
}

export type MPSubscription = {
  interval: string
  plan: string
  product: string
  trial: {
    isActive: boolean
  }
}

export type ChannelSlotDetails = {
  flatFee: number
  currentQuantity: number
  chargableQuantity: number
  pricePerQuantity: number
  minimumQuantity: number
}

export type PricingTier = {
  flatFee: number
  unitPrice: number
  upperBound: number
}

export type ChangePlanOption = {
  planId: string
  planName?: string
  planInterval: PlanInterval
  description?: string
  isCurrentPlan?: boolean
  highlightsHeading?: string
  highlights?: string[]
  currency?: string
  basePrice?: number
  totalPrice?: number
  priceNote?: string
  absoluteSavings?: string
  summary?: {
    details: string[]
  }
  isRecommended?: boolean
  downgradedMessage?: string
  channelSlotDetails: ChannelSlotDetails
  pricingTiers?: Array<PricingTier>
}

export type SuggestedPlanInfo = {
  planId: string
  planInterval: PlanInterval
}

export type TaxDetails = {
  automaticTax: string
}

export type Billing = {
  id: string
  canAccessAnalytics: boolean
  canAccessEngagement: boolean
  canAccessPublishing: boolean
  canStartTrial?: boolean
  paymentDetails: PaymentDetails
  subscription?: Subscription
  subscriptions?: MPSubscription[]
  gateway?: GatewayDetails
  channelSlotDetails?: ChannelSlotDetails
  pricingTiers?: Array<PricingTier>
  changePlanOptions?: ChangePlanOption[]
  billingRedirectUrl?: string
  isAutomaticTaxEnabled?: boolean
  taxDetails?: TaxDetails
  taxExempt?: string
}

export type Channel = {
  id: string
  isLocked: boolean
  name: string
  organizationId: string
  service: string
  serviceId: string
  type?: string
  avatar?: string
  serverUrl?: string
  shouldShowPinterestUpgradeBanner?: boolean
  shouldShowLinkedInUpgradeBanner?: boolean
  shouldShowTiktokUpgradeBanner?: boolean
}

export type AccountPrivileges = {
  canEdit?: boolean
  canView?: boolean
  canManageAllNotes?: boolean
  canManageBilling?: boolean
  canManageTeamMembers?: boolean
  canPublishStartPages?: boolean
  canManageChannels?: boolean
}

export type Product = {
  name: string
  userId: string
}

export type CommonTrackingProperties = {
  atEventBillingState: BillingState
  atEventBillingPlan: string
  atEventBillingCycle: PlanInterval
  atEventBillingGateway: Gateway
  atEventIsNewBuffer: boolean
  atEventChannelQty: number
}

export type DismissedObject = {
  id: string
  expiresAt?: Date
}

export type LifetimeConnectedChannelCount = {
  service: string
  serviceIds: string[]
}

export type Organization = {
  billing?: Billing
  canEdit?: boolean
  channelRecommendations?: string[]
  channels?: Channel[]
  createdAt?: string
  featureFlips?: string[]
  id?: string
  channelLimit?: number
  isOneBufferOrganization?: boolean
  name?: string
  privileges?: AccountPrivileges
  role?: string
  shouldDisplayInviteCTA?: boolean
  commonTrackingProperties?: CommonTrackingProperties
  dismissedObjects?: DismissedObject[]
  isBetaEnabled?: boolean
  isAlphaEnabled?: boolean
  lifetimeConnectedChannelsCount?: LifetimeConnectedChannelCount[]
  shouldShowTeamMemberOrganizationDowngradedBanner?: boolean
  ownerEmail?: string
  members?: {
    totalCount: number
  }
  weeklyPostingStreak?: {
    streakCount: number
    status: string
  }
}

export type Account = {
  avatar?: string
  channels?: Channel[]
  createdAt?: string
  currentOrganization?: Organization
  email?: string
  hasVerifiedEmail?: boolean
  helpScoutBeaconSignature?: string | null
  featureFlips?: string[]
  id?: string
  name?: string
  isImpersonation?: boolean
  organizations?: Organization[]
  products?: Product[]
  shouldShowEmailVerificationCommunication?: boolean
  shouldShowEmailVerificationLinkRequest?: boolean
  splits?: SplitState[]
  dismissedBanners?: string[]
  hellonextSsoToken: string
}

export type PreviewUpcomingInvoiceReturn = {
  previewCustomUpcomingInvoice: {
    chargeAmount: number
    creditAmount: number
    isScheduledChange: boolean
    nextBillingDate: Date
  }
}

export type PromiseCB = () => Promise<void>
