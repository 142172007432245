import { graphql } from '~publish/gql'
import { useMutation } from '@apollo/client'
import type {
  CreateNewFeedCollectionMutationVariables,
  CreateNewFeedCollectionMutation,
} from '~publish/gql/graphql'
import { insertNewFeedCollectionToCache } from './useFeedCollections'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

const CreateNewFeedCollection = graphql(/* GraphQL */ `
  mutation CreateNewFeedCollection($input: CreateFeedCollectionInput!) {
    createFeedCollection(input: $input) {
      __typename
      ... on FeedCollectionActionSuccess {
        feedCollection {
          id
          name
          organizationId
        }
      }
    }
  }
`)

export const useCreateNewFeedCollection = (): ((
  input: CreateNewFeedCollectionMutationVariables['input'],
) => Promise<
  CreateNewFeedCollectionMutation['createFeedCollection'] | undefined | null
>) => {
  const [create] = useMutation(CreateNewFeedCollection, {
    update(cache, { data }) {
      if (
        data?.createFeedCollection?.__typename === 'FeedCollectionActionSuccess'
      ) {
        const feedCollection = data.createFeedCollection.feedCollection
        insertNewFeedCollectionToCache(cache, feedCollection)
      }
    },
  })

  return async (input: CreateNewFeedCollectionMutationVariables['input']) => {
    const data = await create({ variables: { input } })
    if (
      data.data?.createFeedCollection?.__typename ===
      'FeedCollectionActionSuccess'
    ) {
      const feedCollection = data.data.createFeedCollection.feedCollection
      // Track feed collection created event
      BufferTracker.feedCollectionCreated({
        product: 'publish',
        organizationId: feedCollection.organizationId,
        feedCollectionId: feedCollection.id,
        feedCollectionName: feedCollection.name,
        clientName: 'publishWeb',
      })
    }
    return data.data?.createFeedCollection
  }
}
