import { useSplitEnabled } from '@buffer-mono/features'
import { useOncePerTimePeriod, useLocalStorage } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useAccount } from '~publish/legacy/accountContext'

export const NEW_PUBLISH_KEY = 'preference:single-channel-new-ui-v2'
export const NEW_PUBLISH_SPLIT_NAME = 'publish-revamp'
const PUBLISH_REVAMP_OVERRIDE_SPLIT = 'publish-revamp-override'
const LAST_RECORDED_SPLIT = 'analytics:last-recorded-split'

const AN_HOUR = 60 * 60 * 1000

const noop = (): void => undefined

type PublishRevamp = [
  boolean,
  {
    setPreference: (value: 'enabled' | 'disabled') => void
    isReady: boolean
  },
]

export function usePublishRevamp(): PublishRevamp {
  const { account } = useAccount()
  const signUpDate = account?.createdAt
    ? new Date(account?.createdAt).getTime()
    : undefined

  const {
    isEnabled: isOverrideSplitEnabled,
    isReady: isOverrideSplitReady,
    hasClient: isOverrideSplitHasClient,
  } = useSplitEnabled(PUBLISH_REVAMP_OVERRIDE_SPLIT)

  const [savedPreference] = useLocalStorage(NEW_PUBLISH_KEY, 'enabled')

  const {
    isEnabled: isPublishRevampEnabled,
    isReady: isPublishRevampReady,
    hasClient: isPublishRevampHasClient,
  } = useSplitEnabled(NEW_PUBLISH_SPLIT_NAME, { signUpDate })

  const { isEnabled: isOptInEnabled } = useSplitEnabled(
    'publish-revamp-opt-in-banner',
  )

  const isEnabledThroughOptIn = isOptInEnabled && savedPreference === 'enabled'

  const isEnabledThroughSplit =
    isPublishRevampEnabled &&
    (savedPreference === 'enabled' || savedPreference === null)

  const isEnabled = isEnabledThroughOptIn || isEnabledThroughSplit
  const coercedIsEnabled = isEnabled || isOverrideSplitEnabled

  const didCoerce = isEnabled !== coercedIsEnabled
  const isReady = isPublishRevampReady && isOverrideSplitReady
  const hasClient = isPublishRevampHasClient && isOverrideSplitHasClient

  const ctaButtonString = `isPublishRevampEnabled: ${isPublishRevampEnabled}, savedPreference: ${savedPreference}, isOptInEnabled: ${isOptInEnabled}, isReady: ${isReady}, hasClient: ${hasClient}`

  useOncePerTimePeriod(
    LAST_RECORDED_SPLIT,
    AN_HOUR,
    () => {
      BufferTracker.pageViewed({
        name: 'New Publish Revamp',
        product: 'publish',
        cta: `Hook | ${coercedIsEnabled ? 'Enabled' : 'Disabled'}${
          didCoerce ? ' (Override)' : ''
        }`,
        ctaButton: ctaButtonString,
        path: window.location.pathname || null,
      })
    },
    [isEnabled],
  )

  return [
    true,
    {
      setPreference: noop,
      isReady: true,
    },
  ]
}
