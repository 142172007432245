/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { HoverCard, UnstyledButton, VisuallyHidden } from '@buffer-mono/popcorn'

import { graphql, getFragmentData } from '~publish/gql'
import type { FragmentType } from '~publish/gql'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { CalendarPostHoveredCard } from '~publish/pages/Calendar/CalendarPostHoverPreview/PostCardHoverCard'

import PostContent from '../PostContent/PostContent'
import { isLastThirdDayOfWeek, isLastTwoDaysOfWeek } from './utils'
import { useIsPostHovered } from './useIsPostHovered'
import { PostPreviewHoverWrapper, PostWrapper } from './styles'

import styles from './PostItem.module.css'
import { useSplitEnabled } from '@buffer-mono/features'
import { CalendarPostPopover } from '~publish/pages/Calendar/CalendarPostPopover'
import { PostMiniature } from '~publish/pages/Calendar/PostMiniature/PostMiniature'

export const CalendarPostCard_Post = graphql(/* GraphQL */ `
  fragment CalendarPostCard_Post on Post {
    id
    status
    tags {
      id
      color
    }
    metadata {
      ... on CommonPostMetadata {
        type
      }
      ... on GoogleBusinessPostMetadata {
        title
      }
    }
    assets {
      __typename
      ...PostMediaAsset_Asset
    }
    dueAt
    schedulingType
    notificationStatus
    text
    channelId
    channelService
    channel {
      id
      serviceId
      type
      name
    }
  }
`)

type PostItemContentProps = {
  post: FragmentType<typeof CalendarPostCard_Post>
  small: boolean
  scheduledDay: string
  inLastTwoRows: boolean
  canEdit: boolean
  weekStartsOn: number
  setOpenModal:
    | ((openModal: {
        open: boolean
        ctaString: string
        service: string
      }) => void)
    | null
}

const PostItemContent: React.FC<PostItemContentProps> = ({
  post: maskedPost,
  small,
  scheduledDay,
  inLastTwoRows,
  canEdit,
  weekStartsOn,
  setOpenModal,
}) => {
  const dispatch = useDispatch()
  const { isHovered, onHoverChange } = useIsPostHovered()
  const { isEnabled: isCalendarImprovementsEnabled } = useSplitEnabled(
    'CT-calendar-post-card',
  )
  const post = getFragmentData(CalendarPostCard_Post, maskedPost)

  const hasError = post.status === 'error'
  const ariaLabel = useMemo(() => {
    if (!post || !post.dueAt) return ''
    const channelService = post.channelService
    const formattedTime = format(new Date(post.dueAt), 'HH:mm')
    const error = hasError ? ' with an error' : ''
    return `${channelService} post scheduled for ${scheduledDay}, ${formattedTime}${error}`
  }, [post, hasError, scheduledDay])

  const dueAt = post?.dueAt ? new Date(post?.dueAt) : null

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ): void => {
    if (canEdit) {
      event.preventDefault()
      dispatch(composerPopoverActions.handleEditClick(post.id))
    }
  }

  if (isCalendarImprovementsEnabled) {
    return (
      <CalendarPostPopover postId={post.id}>
        <UnstyledButton
          aria-label={ariaLabel}
          className={styles.postTriggerButton}
        >
          <PostMiniature post={post} condensed={small} />
        </UnstyledButton>
      </CalendarPostPopover>
    )
  }

  return (
    <HoverCard open={isHovered} onOpenChange={onHoverChange}>
      <HoverCard.Trigger>
        <PostPreviewHoverWrapper canEdit={canEdit} onClick={handleOnClick}>
          <PostWrapper small={small} tags={post?.tags} status={post?.status}>
            {post && <PostContent post={post} small={small} />}
            {canEdit && (
              <VisuallyHidden>
                <button type="button" onClick={handleOnClick}>
                  {ariaLabel}
                </button>
              </VisuallyHidden>
            )}
          </PostWrapper>
        </PostPreviewHoverWrapper>
      </HoverCard.Trigger>
      <HoverCard.Content className={styles.hoverWrapper} side="left">
        {isHovered && (
          <CalendarPostHoveredCard
            postId={post?.id}
            inLastTwoRows={inLastTwoRows}
            isEndOfWeek={isLastTwoDaysOfWeek(dueAt, { weekStartsOn })}
            isLastThirdDayOfWeek={isLastThirdDayOfWeek(dueAt, { weekStartsOn })}
            setOpenModal={setOpenModal}
          />
        )}
      </HoverCard.Content>
    </HoverCard>
  )
}

export default PostItemContent
