import gql from 'graphql-tag'

export const SET_CURRENT_ORGANIZATION = gql`
  mutation AccountSetCurrentOrganization($organizationId: String) {
    accountSetCurrentOrganization(organizationId: $organizationId)
  }
`

export const ACCOUNT_INITIATE_EMAIL_VERIFICATION = gql`
  mutation AccountInitiateEmailVerification {
    accountInitiateEmailVerification {
      ... on AccountInitiateEmailVerificationResponse {
        success
      }
      ... on AccountInitiateEmailVerificationError {
        userFriendlyMessage
      }
    }
  }
`

export const DISMISS_BANNER = gql`
  mutation DismissBanner($banner: String!) {
    dismissBanner(banner: $banner) {
      ... on DismissBannerResponse {
        success
      }
      ... on DismissBannerError {
        cause
      }
    }
  }
`

export const DISMISS_OBJECT = gql`
  mutation DismissObject(
    $organizationId: String!
    $dismissedObject: DismissedObjectInput!
  ) {
    addOrUpdateDismissedObject(
      organizationId: $organizationId
      dismissedObject: $dismissedObject
    ) {
      ... on AddOrUpdateDismissedObjectResponse {
        success
      }
      ... on AddOrUpdateDismissedObjectError {
        cause
        userFriendlyMessage
      }
    }
  }
`

export const QUERY_ACCOUNT = gql`
  query Account {
    account {
      id
      channels {
        id
        organizationId
        isLocked
        serverUrl
      }
      email
      hasVerifiedEmail
      helpScoutBeaconSignature
      avatar
      createdAt
      featureFlips
      isImpersonation
      shouldShowEmailVerificationCommunication
      shouldShowEmailVerificationLinkRequest
      dismissedBanners
      hellonextSsoToken
      currentOrganization {
        id
        name
        canEdit
        channelRecommendations
        privileges {
          canManageBilling
        }
        role
        members {
          totalCount
        }
        createdAt
        isOneBufferOrganization
        channelLimit
        isBetaEnabled
        isAlphaEnabled
        lifetimeConnectedChannelsCount {
          service
          serviceIds
        }
        dismissedObjects {
          id
        }
        commonTrackingProperties {
          atEventBillingState
          atEventBillingPlan
          atEventBillingCycle
          atEventBillingGateway
          atEventIsNewBuffer
          atEventChannelQty
        }
        weeklyPostingStreak {
          streakCount
          status
        }
        shouldDisplayInviteCTA
        featureFlips
        channels {
          id
          avatar
          name
          service
          serviceId
          type
          organizationId
          isLocked
          shouldShowPinterestUpgradeBanner
          shouldShowLinkedInUpgradeBanner
          shouldShowTiktokUpgradeBanner
          serverUrl
          hasActiveMemberDevice
        }
        ownerEmail
        shouldShowTeamMemberOrganizationDowngradedBanner
        billing {
          id
          canAccessAnalytics
          canAccessEngagement
          canAccessPublishing
          paymentDetails {
            hasPaymentDetails
            creditCard {
              brand
              last4
              expMonth
              expYear
            }
            paymentMethod
          }
          isAutomaticTaxEnabled
          taxDetails {
            automaticTax
          }

          ... on MPBilling {
            taxExempt
            billingRedirectUrl
            subscriptions {
              interval
              plan
              product
              trial {
                isActive
              }
            }
          }
          ... on OBBilling {
            canStartTrial
            hasNeverBeenPayingCustomer
            taxExempt
            subscription {
              quantity
              interval
              periodEnd
              isCanceledAtPeriodEnd
              trial {
                isActive
                remainingDays
                isAwaitingUserAction
                startDate
                endDate
              }
              plan {
                id
                name
              }
              isPaymentPastDue
            }
            gateway {
              gatewayType
              gatewayId
              gatewayPlatform
            }
            channelSlotDetails {
              flatFee
              currentQuantity
              chargableQuantity
              pricePerQuantity
              minimumQuantity
            }
            pricingTiers {
              flatFee
              unitPrice
              upperBound
            }

            changePlanOptions {
              planId
              planName
              planInterval
              description
              isCurrentPlan
              highlightsHeading
              highlights
              currency
              basePrice
              totalPrice
              priceNote
              absoluteSavings
              summary {
                details
              }
              isRecommended
              downgradedMessage
              channelSlotDetails {
                flatFee
                currentQuantity
                chargableQuantity
                pricePerQuantity
                minimumQuantity
              }
              pricingTiers {
                flatFee
                unitPrice
                upperBound
              }
            }
          }
        }
      }
      organizations {
        id
        name
        canEdit
        channelRecommendations
        privileges {
          canManageBilling
        }
        role
        createdAt
        isOneBufferOrganization
        isBetaEnabled
        dismissedObjects {
          id
        }
        commonTrackingProperties {
          atEventBillingState
          atEventBillingPlan
          atEventBillingCycle
          atEventBillingGateway
          atEventIsNewBuffer
          atEventChannelQty
        }
        shouldDisplayInviteCTA
        featureFlips
        channels {
          id
          avatar
          name
          service
          serviceId
          organizationId
          isLocked
          shouldShowPinterestUpgradeBanner
          shouldShowLinkedInUpgradeBanner
          serverUrl
        }
        billing {
          id
          canAccessAnalytics
          canAccessEngagement
          canAccessPublishing
          paymentDetails {
            hasPaymentDetails
            creditCard {
              brand
              last4
              expMonth
              expYear
            }
          }
          isAutomaticTaxEnabled
          taxDetails {
            automaticTax
          }

          ... on MPBilling {
            taxExempt
            billingRedirectUrl
            subscriptions {
              interval
              plan
              product
              trial {
                isActive
              }
            }
          }
          ... on OBBilling {
            canStartTrial
            hasNeverBeenPayingCustomer
            taxExempt
            subscription {
              quantity
              interval
              periodEnd
              isCanceledAtPeriodEnd
              trial {
                isActive
                remainingDays
                isAwaitingUserAction
                startDate
                endDate
              }
              plan {
                id
                name
              }
              isPaymentPastDue
            }
            gateway {
              gatewayType
              gatewayId
            }
            channelSlotDetails {
              flatFee
              currentQuantity
              chargableQuantity
              pricePerQuantity
              minimumQuantity
            }

            changePlanOptions {
              planId
              planName
              planInterval
              description
              isCurrentPlan
              highlightsHeading
              highlights
              currency
              basePrice
              totalPrice
              priceNote
              absoluteSavings
              summary {
                details
              }
              isRecommended
              downgradedMessage
              channelSlotDetails {
                flatFee
                currentQuantity
                chargableQuantity
                pricePerQuantity
                minimumQuantity
              }
            }
          }
        }
      }
      products {
        name
        userId
      }
    }
  }
`

export const SEND_TEST_NOTIFICATION = gql`
  mutation SendTestNotification {
    sendTestNotification {
      ... on SendTestNotificationError {
        code
      }
    }
  }
`
