import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import React from 'react'
import { capitalize } from '~publish/helpers/capitalize'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import {
  useCurrentOrganization,
  useHasChannelConnected,
} from '~publish/legacy/accountContext'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { CtaButtonWrapper } from '../../../components/CtaButtonWrapper'
import { RegisteredBanner } from '../../../components/RegisteredBanner'
import linkedinIllustration from './linkedin_illustration.png'
import { Service } from '@buffer-mono/app-shell/src/exports/Orchestrator/channelConnections/types'

const useCanDisplayBanner = (bannerId: string): boolean => {
  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })
  const hasLinkedInChannel = useHasChannelConnected('linkedin')
  const selectedChannel = useSelectedChannel()
  const supportedChannels = ['instagram', 'tiktok', 'youtube']
  const isCurrentChannelSupported =
    !!selectedChannel && supportedChannels.includes(selectedChannel.service)

  return banner.isActive && !hasLinkedInChannel && isCurrentChannelSupported
}

export const LinkedInVideoPromotionalBanner = (): JSX.Element | null => {
  const { role } = useCurrentOrganization()
  const bannerId = 'linkedInVideoPromo'
  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })
  const selectedChannel = useSelectedChannel()

  const connectLinkedInCta =
    `publish-queue-banner-linkedInVideoPromo${capitalize(
      selectedChannel?.service ?? '',
    )}-1` as const

  const onLinkedInConnectClick = (): void => {
    window.appshell.actions.connectChannel({
      selectedService: Service.linkedin,
      cta: connectLinkedInCta,
      role,
    })
  }

  const canRenderBanner = useCanDisplayBanner(bannerId)

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <PromotionalBanner onDismiss={banner.dismiss}>
        <PromotionalBanner.Content>
          <PromotionalBanner.Heading>
            Cross-post your videos to LinkedIn
          </PromotionalBanner.Heading>
          <PromotionalBanner.Description>
            Since you&apos;re already sharing videos on other platforms, why not
            consider LinkedIn too? Videos yield up to 1.4x more engagement than
            other formats.
          </PromotionalBanner.Description>
          <PromotionalBanner.Actions>
            <CtaButtonWrapper
              cta={connectLinkedInCta}
              options={{ upgradePathName: 'accountChannels-upgrade' }}
            >
              <Button variant="primary" onClick={onLinkedInConnectClick}>
                Connect LinkedIn
              </Button>
            </CtaButtonWrapper>
          </PromotionalBanner.Actions>
        </PromotionalBanner.Content>
        <PromotionalBanner.Image src={linkedinIllustration} />
      </PromotionalBanner>
    </RegisteredBanner>
  )
}
