import React from 'react'

import {
  Flex,
  Heading,
  Link,
  Paragraph,
  Separator,
  Switch,
} from '@buffer-mono/popcorn'

import SettingsPageLayout from './components/SettingsPageLayout'
import style from './ProfileSettings.module.css'

import Photo from './ProfileSettings/Photo'
import Name from './ProfileSettings/Name'
import Email, { EmailVerificationBanner } from './ProfileSettings/Email'
import Password from './ProfileSettings/Password'
import Delete from './ProfileSettings/DeleteAccount'

const TwoFactor = (): JSX.Element => {
  return (
    <Flex as="section" gap="xs" align="start" className={style.fullWidth}>
      <div>
        <Heading
          id="two-factor-heading"
          as="h2"
          size="small"
          style={{
            marginBottom: 'var(--space-xs)',
          }}
        >
          Two Factor Authentication
        </Heading>
        <Paragraph color="subtle">
          Two factor authentication adds an extra layer of security for your
          Buffer account. Whenever you log in to your account, after entering
          your username and password, you will be asked for a second
          authentication code that was sent to your mobile phone via text or a
          free mobile app.{' '}
          <Link
            external
            href="https://support.buffer.com/article/503-enabling-two-factor-authentication?utm_source=buffer&utm_medium=learn-more-link&utm_campaign=learn-more"
          >
            Learn more
          </Link>
        </Paragraph>
      </div>
      <div>
        <Switch disabled id="two-factor" aria-labelledby="two-factor-heading" />
      </div>
    </Flex>
  )
}

export const ProfileSettings = (): JSX.Element => {
  return (
    <SettingsPageLayout>
      <section>
        <Flex gap="xl" direction="column">
          <Heading as="h1" size="large">
            Profile
          </Heading>
          <EmailVerificationBanner />
          <Photo />
          <Name />
          <Email />
          <Password />
        </Flex>
      </section>
      <Separator />
      <TwoFactor />
      <Separator />
      <Delete />
    </SettingsPageLayout>
  )
}

export default ProfileSettings
