import { useEffect, useMemo, useState } from 'react'
import {
  useCalendarAndPostsList,
  type PostForCalendarHookResponse,
} from './useCalendarAndPostsList'
import { useNavigation, type UseNavigationReturn } from './useNavigation'
import { useCalendarFilters, type CalendarFilters } from './useCalendarFilters'
import { useActiveFilter } from '../CalendarHeader/TypeFilter/useFilterState'

export type CalendarState = {
  postsQuery: PostForCalendarHookResponse
  navigation: UseNavigationReturn
  filters: CalendarFilters
}

export const useCalendarState = (): CalendarState => {
  const navigation = useNavigation()
  const filters = useCalendarFilters()
  const statusFilter = useActiveFilter()

  const currentDate = useDelayedValue(navigation.currentDate.getTime(), 500)

  const postsQuery = useCalendarAndPostsList({
    currentDate,
    channelsSelected: filters.channelsFilter.queryParams,
    calendarMode: navigation.granularity,
    tagIds: filters.tagsFilter.queryParams,
    status: statusFilter.status,
  })

  return useMemo(
    () => ({
      postsQuery,
      navigation,
      filters,
    }),
    [postsQuery, navigation, filters],
  )
}

const useDelayedValue = <T>(value: T, delay: number): T => {
  const [delayedValue, setDelayedValue] = useState(value)
  useEffect(() => {
    const id = setTimeout(() => setDelayedValue(value), delay)
    return () => clearTimeout(id)
  }, [value, delay])
  return delayedValue
}
