import { Temporal } from '@js-temporal/polyfill'
import { format as dateFnsFormat, type FormatOptions } from 'date-fns'

// Check functions
export function isToday(day: Temporal.ZonedDateTime): boolean {
  return day.toPlainDate().equals(getZonedNow(day.timeZoneId).toPlainDate())
}

export function isTomorrow(day: Temporal.ZonedDateTime): boolean {
  return isSameDay(day, getZonedNow(day.timeZoneId).add({ days: 1 }))
}

export function isYesterday(day: Temporal.ZonedDateTime): boolean {
  return isSameDay(day, getZonedNow(day.timeZoneId).subtract({ days: 1 }))
}

export function isPast(day: Temporal.ZonedDateTime): boolean {
  return isBefore(day, getZonedNow(day.timeZoneId))
}

export function isFuture(day: Temporal.ZonedDateTime): boolean {
  return isAfter(day, getZonedNow(day.timeZoneId))
}

export function isBefore(
  day: Temporal.ZonedDateTime,
  otherDay: Temporal.ZonedDateTime,
): boolean {
  return Temporal.ZonedDateTime.compare(day, otherDay) < 0
}

export function isAfter(
  day: Temporal.ZonedDateTime,
  otherDay: Temporal.ZonedDateTime,
): boolean {
  return Temporal.ZonedDateTime.compare(day, otherDay) > 0
}

export function isSameDay(
  day: Temporal.ZonedDateTime,
  otherDay: Temporal.ZonedDateTime,
): boolean {
  return day.toPlainDate().equals(otherDay.toPlainDate())
}

export function isSameHour(
  day: Temporal.ZonedDateTime,
  otherDay: Temporal.ZonedDateTime,
): boolean {
  return (
    day.toPlainDate().equals(otherDay.toPlainDate()) &&
    day.hour === otherDay.hour
  )
}

// Creation functions
export function getZonedNow(timezone: string): Temporal.ZonedDateTime {
  return Temporal.ZonedDateTime.from(Temporal.Now.zonedDateTimeISO(timezone))
}

export function startOfHour(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  return date.startOfDay().add({ hours: date.hour })
}

export function endOfHour(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  return date
    .startOfDay()
    .add({ hours: date.hour + 1 })
    .subtract({ nanoseconds: 1 })
}

export function startOfWeek(
  date: Temporal.ZonedDateTime,
  { weekStartsOn = 0 }: { weekStartsOn?: 0 | 1 } = {},
): Temporal.ZonedDateTime {
  const dayOfWeek = (date.dayOfWeek - weekStartsOn + 7) % 7

  return startOfHour(date).subtract({ days: dayOfWeek })
}

export function endOfWeek(
  date: Temporal.ZonedDateTime,
  { weekStartsOn = 0 }: { weekStartsOn?: 0 | 1 } = {},
): Temporal.ZonedDateTime {
  const weekStartDate = startOfWeek(date, { weekStartsOn })
  return endOfHour(weekStartDate).add({ days: 6 })
}

export function startOfMonth(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  return startOfHour(date).subtract({ days: date.day - 1 })
}

export function endOfMonth(
  date: Temporal.ZonedDateTime,
): Temporal.ZonedDateTime {
  return endOfHour(date).add({ days: date.daysInMonth - date.day })
}

// Format functions
export function format(
  date: Temporal.ZonedDateTime,
  formatStr: string,
  options?: FormatOptions,
): string {
  return dateFnsFormat(date.toPlainDateTime().toString(), formatStr, options)
}
