import React from 'react'

const StreakIllustration: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="80" height="80" rx="40" fill="#D7F0A8" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00031 63.9818C15.7066 54.8131 27.1695 49 39.9693 49C52.7691 49 64.2321 54.8132 71.9384 63.9819C64.2064 73.6202 52.7522 79.7233 39.9694 79.7233C27.1865 79.7233 15.7323 73.6202 8.00031 63.9818Z"
      fill="#004042"
      fillOpacity="0.08"
    />
    <path
      d="M25 32C25.5926 33.634 27.7556 37.5882 31.6667 40.3333"
      stroke="#D7F0A8"
      strokeWidth="1.7069"
      strokeLinecap="round"
    />
    <defs>
      <clipPath id="clip0_1777_1185">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(20 20)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default StreakIllustration
