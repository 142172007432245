// @TODO: use a new method like EditorStateProxy.createEmpty
import { MediaTypes } from '~publish/legacy/constants'
import type { ComposerProfile, UserTag } from '../stores/types'
import type { FormattedProfileData } from './types'
import type { Document } from './Document'

export interface MediaSource {
  name: string
  trigger: string
  id?: string
}

export type Image = {
  url: string
  width: number | null
  height: number | null
  altText?: string | null
  source?: MediaSource | null
  mediaType: string
  sourceLink?: string
  originalUrl?: string | null
  userTags?: UserTag[]
}

export type Gif = {
  url: string
  stillGifUrl: string
  mediaType: string
  width: number | null
  height: number | null
}

export type Video = {
  uploadId: string | null
  name: string
  duration: number
  durationMs: number
  size: number
  width: number
  height: number
  frameRate?: number
  url: string
  originalUrl: string
  thumbnail: string
  availableThumbnails: string[]
  thumbOffset: number | string
  mediaType: string
  wasEdited: boolean
}

export type Media = Image | Gif | Video | Document

export type Link = {
  url: string
  expandedUrl: string | null
  title: string | null
  description: string | null
  thumbnail: Image | null
  thumbnailHttps: string | null
  availableThumbnails: string[] | null
  wasEdited: boolean
}

export type SourceLink = {
  url: string
  availableImages: Image[]
}

export type Retweet = {
  text: string
  tweetId: number
  userId: string
  userName: string
  userDisplayName: string
  tweetUrl: string
  avatarUrl: string
}

// Link Attachment factory
export const getNewLink = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'url' implicitly has an 'any' type... Remove this comment to see the full error message
  url,
  // @ts-expect-error TS(7031) FIXME: Binding element 'expandedUrl' implicitly has an 'a... Remove this comment to see the full error message
  expandedUrl,
  title = null,
  description = null,
  thumbnail = null,
  thumbnailHttps = null,
  availableThumbnails = null,
  wasEdited = false,
}): Link => ({
  url,
  expandedUrl,
  title,
  description,
  thumbnail,
  thumbnailHttps,
  availableThumbnails,
  wasEdited,
})

// SourceLink Attachment factory (source url and its available images)
// @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
export const getNewSourceLink = (url): SourceLink => ({
  url,
  availableImages: [],
})

// Available images factory
// @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
export const getNewAvailableImage = (image, sourceLink): Image => ({
  url: image.url,
  mediaType: MediaTypes.IMAGE,
  sourceLink,
  width: image.width,
  height: image.height,
})

// Image factory
export const getNewImage = ({
  url,
  width = null,
  height = null,
  altText = null,
  source = null,
  originalUrl = null,
}: {
  url: string
  width?: number | null
  height?: number | null
  altText?: string | null
  source?: MediaSource | null
  originalUrl?: string | null
}): Image => ({
  url,
  mediaType: MediaTypes.IMAGE,
  width,
  height,
  altText,
  source,
  originalUrl,
})

// Gif factory
export const getNewGif = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'gifUrl' implicitly has an 'any' type.
  gifUrl,
  // @ts-expect-error TS(7006) FIXME: Parameter 'gifStillUrl' implicitly has an 'any' ty... Remove this comment to see the full error message
  gifStillUrl,
  width = null,
  height = null,
): Gif => ({
  url: gifUrl,
  stillGifUrl: gifStillUrl,
  mediaType: MediaTypes.GIF,
  width,
  height,
})

// Video factory
export const getNewVideo = ({
  uploadId = null,
  // @ts-expect-error TS(7031) FIXME: Binding element 'name' implicitly has an 'any' typ... Remove this comment to see the full error message
  name,
  // @ts-expect-error TS(7031) FIXME: Binding element 'duration' implicitly has an 'any'... Remove this comment to see the full error message
  duration,
  // @ts-expect-error TS(7031) FIXME: Binding element 'durationMs' implicitly has an 'an... Remove this comment to see the full error message
  durationMs,
  // @ts-expect-error TS(7031) FIXME: Binding element 'size' implicitly has an 'any' typ... Remove this comment to see the full error message
  size,
  // @ts-expect-error TS(7031) FIXME: Binding element 'width' implicitly has an 'any' ty... Remove this comment to see the full error message
  width,
  // @ts-expect-error TS(7031) FIXME: Binding element 'height' implicitly has an 'any' t... Remove this comment to see the full error message
  height,
  // @ts-expect-error TS(7031) FIXME: Binding element 'url' implicitly has an 'any' type... Remove this comment to see the full error message
  url,
  // @ts-expect-error TS(7031) FIXME: Binding element 'originalUrl' implicitly has an 'a... Remove this comment to see the full error message
  originalUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'thumbnail' implicitly has an 'any... Remove this comment to see the full error message
  thumbnail,
  // @ts-expect-error TS(7031) FIXME: Binding element 'availableThumbnails' implicitly h... Remove this comment to see the full error message
  availableThumbnails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'thumbOffset' implicitly has an 'a... Remove this comment to see the full error message
  thumbOffset,
}): Video => ({
  uploadId,
  name,
  duration,
  durationMs,
  size,
  width,
  height,
  url,
  originalUrl,
  thumbnail,
  availableThumbnails,
  thumbOffset,
  mediaType: MediaTypes.VIDEO,
  wasEdited: false,
})

// Retweet factory
export const getNewRetweet = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
  text,
  // @ts-expect-error TS(7031) FIXME: Binding element 'tweetId' implicitly has an 'any' ... Remove this comment to see the full error message
  tweetId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'userId' implicitly has an 'any' t... Remove this comment to see the full error message
  userId,
  // @ts-expect-error TS(7031) FIXME: Binding element 'userName' implicitly has an 'any'... Remove this comment to see the full error message
  userName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'userDisplayName' implicitly has a... Remove this comment to see the full error message
  userDisplayName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'tweetUrl' implicitly has an 'any'... Remove this comment to see the full error message
  tweetUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'avatarUrl' implicitly has an 'any... Remove this comment to see the full error message
  avatarUrl,
}): Retweet => ({
  text,
  tweetId,
  userId,
  userName,
  userDisplayName,
  tweetUrl,
  avatarUrl,
})

export const getNewInstagramFeedbackObj = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
  message,
  composerId = 'instagram',
  code = undefined,
}) => ({ message, composerId, code })

export const getNewProfile = (data: FormattedProfileData): ComposerProfile => ({
  id: data.id,
  apiVersion: data.apiVersion,
  service: {
    name: data.serviceName,
    username: data.serviceUsername,
    formattedUsername: data.serviceFormattedUsername,
    isPinterestBusiness: data.isPinterestBusiness,
  },
  images: {
    avatar: data.imagesAvatar,
  },
  serviceName: data.serviceName,
  imagesAvatar: data.imagesAvatar,
  serviceUsername: data.serviceUsername,
  serviceFormattedUsername: data.serviceFormattedUsername,
  timezone: data.timezone,
  shouldBeAutoSelected: data.shouldBeAutoSelected,
  isSelected: false,
  isDisabled: data.isDisabled,
  disabledMessage: data.disabledMessage,
  subprofiles: data.subprofiles, // Data structure in getNewSubprofile()
  selectedSubprofileId: null,
  serviceType: data.serviceType,
  serviceId: data.serviceId,
  isContributor: data.isContributor,
  isManager: data.isManager,
  subprofilesOrignatedFromAPI: null,
  instagramDirectEnabled:
    data.instagramDirectEnabled && data.serviceName === 'instagram',
  canPostComment: data.canPostComment,
  isPinterestBusiness: data.isPinterestBusiness,
  isTwitterPremium: data.isTwitterPremium,
  canAssociateLocation: data.canAssociateLocation,
  hasPushNotifications: data.hasPushNotifications,
  canQueueMoreThreads: data.canQueueMoreThreads,
  profileHasPostingSchedule: data.profileHasPostingSchedule,
  defaultToReminders: data.defaultToReminders,
  displayName: data.displayName,
  serverUrl: data.serverUrl,
  // Profile-specific app state
  appState: {
    isSubprofileCreationPending: false,
  },
  shorteningDomain: data.shorteningDomain,
  isFake: data.isFake,
})

// @ts-expect-error TS(7031) FIXME: Binding element 'id' implicitly has an 'any' type.
export const getNewProfileGroup = ({ id, name, profileIds }) => ({
  id,
  name,
  profileIds,
})

// @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
export const getNewUserData = (data) => ({
  id: data.id,
  features: data.features,
  s3UploadSignature: data.s3UploadSignature,
  uses24hTime: data.uses24hTime,
  profileGroups: data.profileGroups.map(getNewProfileGroup),
  weekStartsMonday: data.weekStartsMonday,
  shouldAlwaysSkipEmptyTextAlert: data.shouldAlwaysSkipEmptyTextAlert,
  profilesSchedulesSlots: data.profilesSchedulesSlots,
})

// @ts-expect-error TS(7031) FIXME: Binding element 'avatar' implicitly has an 'any' t... Remove this comment to see the full error message
export const getNewSubprofile = ({ avatar, id, name, isShared }) => ({
  avatar,
  id,
  name,
  isShared,
})

export const getNewPreventsSavingObj = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
  message,
  composerId = null,
  code = undefined,
}) => ({ message, composerId, code })

export const isMediaImage = (media: Media): media is Image => {
  return media.mediaType === MediaTypes.IMAGE
}

export const isMediaVideo = (media: Media): media is Video => {
  return media.mediaType === MediaTypes.VIDEO
}
