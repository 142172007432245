import React from 'react'

import {
  Card,
  ChannelIcon,
  Flex,
  NotifyMeIcon,
  NotifyMePublished,
  Text,
} from '@buffer-mono/popcorn'
import { Temporal } from '@js-temporal/polyfill'
import { PostMediaAsset } from '~publish/components/PostCard/PostMedia/PostMediaAsset'
import { ApprovalBadge, DraftBadge } from '~publish/components/PostStatusBadge'
import { PostTypeIcon } from '~publish/components/PostType'
import type {
  CalendarPostCard_PostFragment,
  PostStatus,
} from '~publish/gql/graphql'
import { format } from '~publish/helpers/temporal'
import { useTimezone } from '~publish/hooks/useTimezone'
import { useTwentyFourHourTimeFormat } from '../hooks/useTwentyFourHourTimeFormat'
import styles from './PostMiniature.module.css'

const DEFAULT_MAX_TAGS = 3

const calculateGridTemplateAreas = ({
  renderAssets,
  renderBody,
  condensed,
  status,
}: {
  renderAssets: boolean
  renderBody: boolean
  condensed: boolean
  status: PostStatus
}): string => {
  /** Default */
  let templateAreas = `
    'header header'
    'body body'
  `

  /** With assets */
  if (renderAssets) {
    templateAreas = `
      'header header'
      'body assets'
    `
  }

  /** Condensed variations */
  if (condensed) {
    templateAreas = `
      'header assets'
      'body assets'
    `

    /** Only header */
    if (!renderBody && !renderAssets) {
      templateAreas = "'header header'"
    }

    /** Body, no assets */
    if (renderBody && !renderAssets) {
      templateAreas = `
        'header header'
        'body body'
      `
    }

    /** Assets, no body */
    if (!renderBody && renderAssets) {
      templateAreas = "'header assets'"
    }
  }

  /** With status */
  if (status === 'draft' || status === 'needs_approval') {
    if (condensed) {
      templateAreas = "'status assets' " + templateAreas
    } else {
      templateAreas = "'status status' " + templateAreas
    }
  }
  return templateAreas
}

interface PostMiniatureProps {
  condensed?: boolean
  post: CalendarPostCard_PostFragment
}

export const PostMiniature: React.FC<PostMiniatureProps> = ({
  condensed,
  post,
}) => {
  const { assets, channelService, dueAt, metadata, tags } = post
  const [timeZone] = useTimezone()
  const is24Hour = useTwentyFourHourTimeFormat()

  const hasAssets = assets.length > 0
  const hasTags = tags.length > 0

  const lineClamp = React.useMemo(() => {
    if (hasTags) return 1
    if (hasAssets && !hasTags) return 2
    if (!hasAssets && !hasTags) return 2
    return 1
  }, [hasAssets, hasTags])

  const formattedTime = React.useMemo(() => {
    if (!dueAt) return ''
    const zonedDueAt = Temporal.Instant.from(dueAt).toZonedDateTimeISO(timeZone)

    return format(zonedDueAt, is24Hour ? 'HH:mm' : 'h:mm a')
  }, [dueAt, is24Hour, timeZone])

  const tagsToRender = tags.slice(0, DEFAULT_MAX_TAGS)
  const tagsRemainder = tags.slice(DEFAULT_MAX_TAGS)

  const renderBody = hasTags || !condensed
  const renderAssets = !!hasAssets && !!assets?.[0]

  /**
   * Calculate grid areas based on the content to render and status of post
   */
  const gridTemplateAreas = React.useMemo(
    () =>
      calculateGridTemplateAreas({
        renderAssets,
        renderBody,
        condensed: !!condensed,
        status: post.status,
      }),
    [renderAssets, renderBody, post.status, condensed],
  )

  const { schedulingType, status, notificationStatus } = post
  const notifyIcon = React.useMemo(() => {
    if (schedulingType === 'notification') {
      if (status === 'sent' && notificationStatus === 'markedAsPublished') {
        return <NotifyMePublished size="xsmall" className={styles.notifyIcon} />
      }
      return <NotifyMeIcon size="xsmall" className={styles.notifyIcon} />
    }
    return null
  }, [schedulingType, status, notificationStatus])

  return (
    <Card
      padding="none"
      className={styles.card}
      data-condensed={condensed}
      data-status={post.status}
      style={
        {
          '--post-miniature-text-line-clamp': lineClamp,
          '--post-miniature-grid-areas': gridTemplateAreas,
        } as React.CSSProperties
      }
    >
      {(post.status === 'draft' || post.status === 'needs_approval') && (
        <div className={styles.statusBadge}>
          {post.status === 'needs_approval' && <ApprovalBadge size="xsmall" />}
          {post.status === 'draft' && <DraftBadge size="xsmall" />}
        </div>
      )}
      <div className={styles.header}>
        <Flex align="center" gap="2xs" className={styles.headerContent}>
          <span className={styles.channelIcon} data-channel={channelService}>
            <ChannelIcon type={channelService} color="inverted" size={10} />
          </span>
          <Text as="time" size="sm" weight="medium" className={styles.dueAt}>
            {formattedTime}
          </Text>
          {notifyIcon}
        </Flex>
        <PostTypeIcon
          type={metadata?.type}
          size="small"
          className={styles.postType}
        />
      </div>
      {renderBody && (
        <div className={styles.body}>
          {!condensed && (
            <Text className={styles.textContent}>{post.text}</Text>
          )}
          {hasTags && (
            <Flex gap="2xs" align="end" className={styles.tags}>
              {tagsToRender.map((tag) => (
                <div
                  key={tag.id}
                  className={styles.tag}
                  style={
                    {
                      '--tag-color': tag.color,
                    } as React.CSSProperties
                  }
                />
              ))}
              {tagsRemainder.length > 0 && (
                <span className={styles.extraTagsText}>
                  +{tagsRemainder.length}
                </span>
              )}
            </Flex>
          )}
        </div>
      )}
      {renderAssets && (
        <PostMediaAsset asset={assets[0]} className={styles.assets} />
      )}
    </Card>
  )
}
