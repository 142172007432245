import styled, { css } from 'styled-components'
import { white, black, grayDark } from '@bufferapp/ui/style/colors'
import VolumeOffIcon from '@bufferapp/ui/Icon/Icons/VolumeOff'

import { PostWrapper as PostWrapperCommon } from '../../../Common/styles'
import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'
import { VideoWrapper as VideoWrapperReels } from '../Reel/styles'
import { fontCss, IconCss } from '../../styles'

export const PostWrapper = styled(PostWrapperCommon)`
  border: none;
  border-radius: 8px;
  height: 626px;
  background-color: ${black};
  position: relative;

  ${({ hasImage }: { hasImage: boolean }) =>
    hasImage &&
    `
    display: flex;
    justify-content: center;
    padding-bottom: var(--space-lg);
  `}
`

export const Header = styled.div`
  position: absolute;
  top: 20px;
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
`

export const PreviewHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4px;
`

export const HeaderDetailsWrapper = styled.div`
  display: flex;
  flex: 1;
`

export const HeaderVolumeWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const UsernameTimeWrapper = styled.div`
  display: flex;
  margin: 0 0 3px 6px;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  width: calc(100% - 16px);
  display: flex;
  margin-left: 8px;
  align-items: center;
  justify-content: space-between;
`

export const Field = styled.div`
  display: flex;
  align-items: center;
  width: 198px;
  height: 29px;
  border: 0.726303px solid ${grayDark};
  border-radius: 72.6303px;
  padding-left: 15px;
  ${fontCss};
  font-size: 10.8945px;
  line-height: 13px;
  letter-spacing: 0.363152px;
`

export const BarsWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const Bar = styled.div`
  border: 1.45261px solid ${white};
  width: 206px;
  margin-bottom: 5px;
`

export const BarTransparent = styled(Bar)`
  opacity: 0.5;
  width: auto;
  flex-grow: 1;
`

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Time = styled.div`
  ${fontCss};
  line-height: 12px;
  margin-left: 6px;
`

export const Username = styled.span`
  display: inline-block;
  ${fontCss};
  font-weight: 600;
  line-height: 12px;
`

export const Music = styled.span`
  display: flex;
  margin-left: 6px;
  align-items: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 9px;
  color: ${white};

  svg {
    margin-right: 4px;
  }

  animation: fadeInSlideDown 0.2s ease;

  @keyframes fadeInSlideDown {
    from {
      opacity: 0;
      transform: translateY(-4px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const VideoWrapper = css`
  ${VideoWrapperReels};
  height: 536px;
  top: 12px;
`

export const VideoPlayer = styled(VideoPlayerCommon)`
  ${VideoWrapper}
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  top: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
`

export const VolumeOff = styled(VolumeOffIcon)<{ visible: boolean }>`
  ${IconCss};
  width: 16px;
  height: 16px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`

const StickerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  z-index: 1;
  transition: opacity 0.2s ease;

  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(12px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: slideUp 0.2s ease;

  img {
    height: 40px;
  }
`

export const StickerLinkWrapper = styled.div`
  ${StickerStyles}
  bottom: 220px;
`

export const StickerMusicWrapper = styled.div`
  ${StickerStyles}
  bottom: 160px;
`
