import React, { useState } from 'react'
import { Dialog, useMediaQuery } from '@buffer-mono/popcorn'
import { useDispatch, useSelector } from 'react-redux'

import type { OrchestratorRootState } from '../../../../../common/events/types'
import { useUser } from '../../../../../common/context/User'
import { trackChannelConnectionAborted } from '../../tracking'
import { resetChannelConnectionsState } from '../../../store/channelConnectionsReducer'
import ChannelStoreFront from '../ChannelStoreFront/ChannelStoreFront'
import BlueskyAuth from '../BlueskyAuth/BlueskyAuth'
import { type onRedirectParams, Service } from '../../types'
import InstagramSelectAccountType from '../InstagramSelectAccountType/InstagramSelectAccountType'
import { SimpleModal } from '@bufferapp/ui'
import ServerSelection from '../ServerSelection/ServerSelection'
import AccountTypeSelection from '../AccountTypeSelection/AccountTypeSelection'
import InstagramPreAuthenticate from '../InstagramPreAuthenticate'

import styles from './ChannelConnectionsDialog.module.css'
import { useSplitEnabled } from '@buffer-mono/features'
import { ExitChannelConnectionDialog } from '../ExitChannelConnectionDialog/ExitChannelConnectionDialog'

interface ChannelConnectionsDialogProps {
  onRedirect: (args: onRedirectParams) => void
}

function ChannelConnectionsDialog({
  onRedirect,
}: ChannelConnectionsDialogProps): JSX.Element {
  const user = useUser()
  const {
    selectedService,
    name,
    selectedRefreshChannelId,
    isRefreshingChannelConnection,
    showChannelStorefront,
  } = useSelector((state: OrchestratorRootState) => state.channelConnections)

  const dispatch = useDispatch()

  function cancelConnection(): void {
    if (selectedService) {
      trackChannelConnectionAborted({
        account: user,
        service: selectedService,
      })
    }
    dispatch(resetChannelConnectionsState({}))
  }
  const { isEnabled: isChannelStorefrontEnabled } = useSplitEnabled(
    'growth-new-channel-store-front',
  )
  const { isEnabled: isInstagramLoginEnabled } =
    useSplitEnabled('instagram-login')

  const showBlueskyAuth = selectedService === Service.bluesky
  const showMastodonAuth = selectedService === Service.mastodon
  const showFacebookAuth =
    selectedService === Service.facebook && !isRefreshingChannelConnection

  const showNewInstagramAuth =
    (selectedService === Service.instagram ||
      selectedService === Service.instagram_login_api) &&
    !showChannelStorefront &&
    isInstagramLoginEnabled

  const showInstagramAuth =
    selectedService === Service.instagram && !isInstagramLoginEnabled

  const screensWithNewUI = showNewInstagramAuth || showChannelStorefront
  const shouldUsePopcornDialog = screensWithNewUI

  const isMobile = useMediaQuery('(width < 641px)')
  const dialogSize = isMobile ? 'maximize' : 'xlarge'

  const [showExitChannelConnectionDialog, setShowExitChannelConnectionDialog] =
    useState(false)

  // TODO: This is a temporary conditional
  // Can be deleted after all pre authentication modals have been updated to use new Popcorn UI
  if (shouldUsePopcornDialog) {
    return (
      <Dialog
        open={shouldUsePopcornDialog}
        onOpenChange={
          showChannelStorefront && isChannelStorefrontEnabled
            ? cancelConnection
            : (): void => setShowExitChannelConnectionDialog(true)
        }
      >
        <Dialog.Content className={styles.dialog} size={dialogSize}>
          {showChannelStorefront && isChannelStorefrontEnabled && (
            <ChannelStoreFront />
          )}
          {showNewInstagramAuth && showExitChannelConnectionDialog && (
            <ExitChannelConnectionDialog
              setShowExitChannelConnectionDialog={
                setShowExitChannelConnectionDialog
              }
              onOpenChange={cancelConnection}
            />
          )}
          {showNewInstagramAuth && (
            <InstagramSelectAccountType onRedirect={onRedirect} />
          )}
        </Dialog.Content>
      </Dialog>
    )
  }

  return (
    <SimpleModal closeAction={cancelConnection}>
      {showBlueskyAuth && (
        <BlueskyAuth
          onClose={cancelConnection}
          isRefreshingChannelConnection={isRefreshingChannelConnection}
          name={name}
          channelId={selectedRefreshChannelId}
        />
      )}
      {showInstagramAuth && (
        <InstagramPreAuthenticate
          onRedirect={onRedirect}
          cancelConnection={cancelConnection}
        />
      )}
      {showMastodonAuth && (
        <ServerSelection
          onRedirect={onRedirect}
          cancelConnection={cancelConnection}
        />
      )}
      {showFacebookAuth && (
        <AccountTypeSelection
          onRedirect={onRedirect}
          closeAction={cancelConnection}
        />
      )}
    </SimpleModal>
  )
}

export default ChannelConnectionsDialog
