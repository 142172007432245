import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import React from 'react'
import { CtaButtonWrapper } from '~publish/components/CtaButtonWrapper'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import {
  useCurrentOrganization,
  useHasChannelConnected,
} from '~publish/legacy/accountContext'
import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { RegisteredBanner } from '../../../components/RegisteredBanner'
import { Service } from '@buffer-mono/app-shell/src/exports/Orchestrator/channelConnections/types'

const useCanDisplayBanner = (bannerId: string): boolean => {
  const banner = useDismissableBanner(bannerId)
  const hasTiktokChannel = useHasChannelConnected('tiktok')

  const selectedChannel = useSelectedChannel()
  const supportedChannels = ['instagram']
  const isCurrentChannelSupported =
    !!selectedChannel && supportedChannels.includes(selectedChannel.service)

  return banner.isActive && !hasTiktokChannel && isCurrentChannelSupported
}

export const TiktokImagesPromotionalBanner = (): JSX.Element | null => {
  const { role } = useCurrentOrganization()
  const bannerId = 'tiktokImagesPromo'

  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })

  const ctaName = `publish-queue-banner-connectTiktok-1` as const

  const onTiktokConnectClick = (): void => {
    window.appshell.actions.connectChannel({
      selectedService: Service.tiktok,
      cta: ctaName,
      role,
    })
  }

  const canRenderBanner = useCanDisplayBanner(bannerId)

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <PromotionalBanner onDismiss={banner.dismiss}>
        <PromotionalBanner.Content>
          <PromotionalBanner.Heading>
            New! Schedule Images To TikTok
          </PromotionalBanner.Heading>
          <PromotionalBanner.Description color="subtle">
            Connect your account to crosspost images to Instagram and TikTok.
          </PromotionalBanner.Description>
          <PromotionalBanner.Actions>
            <CtaButtonWrapper
              cta={ctaName}
              options={{ upgradePathName: 'accountChannels-upgrade' }}
            >
              <Button variant="primary" onClick={onTiktokConnectClick}>
                Connect TikTok
              </Button>
            </CtaButtonWrapper>
          </PromotionalBanner.Actions>
        </PromotionalBanner.Content>
        <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/tiktok-images-promo.png" />
      </PromotionalBanner>
    </RegisteredBanner>
  )
}
