import React, { useState } from 'react'
import {
  Sidebar,
  PlusIcon,
  HashIcon,
  Badge,
  UpgradeBadge,
  Text,
} from '@buffer-mono/popcorn'
import { useParams } from 'react-router-dom'

import { useFeedCollections } from '../../hooks/useFeedCollections'
import { SidebarLink } from '../../../components/SidebarLink/SidebarLink'
import { getAccountURL } from '~publish/legacy/utils/formatters/getURL'
import { feedCollectionPage } from '~publish/legacy/routes'
import { NewFeedCollectionDialog } from '../FeedSettings/NewFeedCollectionDialog'
import {
  useCurrentOrganization,
  useAccount,
} from '~publish/legacy/accountContext'
import { useCta } from '~publish/hooks/useCta'
import { isFreeUser } from '~publish/helpers/user'
import { FeedCollectionSettingsDropdown } from './FeedCollectionSettingsDropdown'

export function FeedCollectionsNav(): JSX.Element {
  const { feedCollectionId } = useParams<{ feedCollectionId?: string }>()
  const [isNewFeedDialogOpen, setIsNewFeedDialogOpen] = useState(false)
  const collections = useFeedCollections()
  const account = useAccount()
  const currentOrganization = useCurrentOrganization()
  const hasPaymentDetails =
    currentOrganization.billing?.paymentDetails?.hasPaymentDetails ?? null
  const isFree = isFreeUser(account.account)
  const isAtFreeLimit = isFree && collections.items.length >= 3
  const isAtHardLimit = collections.items.length >= 20
  const isAtLimit = isAtFreeLimit || isAtHardLimit

  const showBadge: 'hard' | 'free' | 'none' = isAtHardLimit
    ? 'hard'
    : isAtFreeLimit
    ? 'free'
    : 'none'

  const { clicked: ctaClicked } = useCta('publish-feed-collections-upgrade-1', {
    upgradePathName: 'feed-collections-upgrade',
    extraProperties: {
      currentCollectionsCount: collections.items.length,
      hasPaymentDetails,
      isFreeUser: isFree,
    },
  })

  const displayedCollections = hasPaymentDetails
    ? collections.items
    : collections.items.slice(0, 5)

  return (
    <>
      {isNewFeedDialogOpen && (
        <NewFeedCollectionDialog
          open={isNewFeedDialogOpen}
          onOpenChange={setIsNewFeedDialogOpen}
        />
      )}
      {showBadge !== 'free' && (
        <Sidebar.Button
          prefix={<PlusIcon />}
          suffix={
            showBadge === 'hard' && (
              <Badge size="small">Collection limit reached</Badge>
            )
          }
          onClick={(): void => setIsNewFeedDialogOpen(true)}
          disabled={isAtLimit}
        >
          Add Collection
        </Sidebar.Button>
      )}
      {showBadge === 'free' && (
        <Sidebar.Button
          as="a"
          href={`${getAccountURL()}/channels/connect`}
          prefix={<PlusIcon />}
          suffix={<UpgradeBadge />}
          onClick={ctaClicked}
          disabled={isAtLimit}
        >
          <div>
            <div>Add Collection</div>
            <Text size="sm">Upgrade to add more</Text>
          </div>
        </Sidebar.Button>
      )}
      {displayedCollections.map((collection) => (
        <SidebarLink
          key={collection.id}
          icon={<HashIcon />}
          suffix={
            <FeedCollectionSettingsDropdown feedCollection={collection} />
          }
          selected={feedCollectionId === collection.id}
          to={feedCollectionPage.linkTo(collection.id)}
        >
          {collection.name}
        </SidebarLink>
      ))}
    </>
  )
}
