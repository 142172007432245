export function cropToCanvas(
  imageSrc: string,
  size = 200,
): Promise<Blob | null> {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = imageSrc

    img.onload = (): void => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = size
      canvas.height = size

      const cropSize = Math.min(img.width, img.height)
      const startX = (img.width - cropSize) / 2
      const startY = (img.height - cropSize) / 2

      ctx?.drawImage(img, startX, startY, cropSize, cropSize, 0, 0, size, size)
      canvas.toBlob((blob) => resolve(blob), 'image/png')
    }
  })
}
