/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react'

import '../../types/global.d.ts' // Import global types

import { useQuery } from '@apollo/client'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { FeaturesWrapper, useSplitEnabled } from '@buffer-mono/features'

import NavBar from '../../components/NavBar'
import ModalManager from '../../components/ModalManager'
import { UserContext } from '../../common/context/User'

import { QUERY_ACCOUNT } from '../../common/graphql/account'
import trackUser from '../../common/utils/trackUser'
import { ORGANIZATION_ACTIONS } from '../../common/events/orgEvents'
import { ORCHESTRATOR_ACTIONS } from '../../common/events/orchestratorEvents'
import { MODAL_ACTIONS } from '../../common/events/modalEvents'
import { ACCOUNT_ACTIONS } from '../../common/events/accountEvents'
import { ENGAGE_ACTIONS } from '../../common/events/engageEvents'
import { MODALS } from '../../common/types'
import ErrorBoundary, { bugsnagClient } from './ErrorBoundary'
import { Orchestrator, COMPONENTS } from '../Orchestrator'
import { PopcornProvider } from '@buffer-mono/popcorn'
import { getLoginUrl, isInPublish } from '../../common/utils/urls'

import store from '../Orchestrator/store/orchestratorStore'
import {
  AppshellEventKeys,
  OrganizationActionKeys,
} from '../../common/events/types'
import Launcher from '../../components/Launcher'
import BannerContainer from './components/BannerContainer/BannerContainer'
import APIGatewayProvider from './providers'
import useHelpScoutBeacon from '../../common/hooks/useHelpScoutBeacon'
import PreAuthenticationRedirect from '../Orchestrator/channelConnections/components/PreAuthenticationRedirect'
import type { ProductNames } from '../../components/NavBar/components/NavBarProducts/NavBarProducts'
import { useProductHistory } from './utils/useProductHistory'
import {
  handleOrchestratorActionsOnload,
  triggerChannelConnectBasedOnUrl,
} from './utils/handleOrchestratorActionsOnload'
import { getActiveProductFromUrl } from './utils/getActiveProductFromUrl'
import { handleModalEvent } from '../../components/ModalManager/ModalManagerUtils'
import { i18n } from '../../common/i18n/index.js'
import { I18nextProvider } from 'react-i18next'

interface AppShellProps {
  hideNavigation: boolean
  hideBanners: boolean
}
export const AppShell = ({
  hideNavigation = false,
  hideBanners = false,
}: AppShellProps): JSX.Element => {
  window.onhashchange = triggerChannelConnectBasedOnUrl

  return (
    <APIGatewayProvider>
      <FeaturesWrapper>
        <PopcornProviderWrapper>
          <div
            id="portals-container-app-shell"
            style={{ zIndex: '9999' }}
          ></div>
          <ReduxProvider store={store}>
            <Navigator
              hideNavigation={hideNavigation}
              hideBanners={hideBanners}
            />
          </ReduxProvider>
        </PopcornProviderWrapper>
      </FeaturesWrapper>
    </APIGatewayProvider>
  )
}

// REVIEW: We need Navigator with a redux provider to be able to consume it on publish
export const ReduxWrappedNavigator = ({
  hideNavigation = false,
  hideBanners = false,
}: AppShellProps): JSX.Element => {
  window.onhashchange = triggerChannelConnectBasedOnUrl

  return (
    <ReduxProvider store={store}>
      <Navigator hideNavigation={hideNavigation} hideBanners={hideBanners} />
    </ReduxProvider>
  )
}

interface NavigatorProps {
  hideNavigation: boolean
  hideBanners: boolean
}
export const Navigator = React.memo(
  ({
    hideNavigation = false,
    hideBanners = false,
  }: NavigatorProps): JSX.Element => {
    const { data, loading, error, refetch } = useQuery(QUERY_ACCOUNT, {
      errorPolicy: 'all',
    })
    const [activeProduct, setActiveProduct] = useState<ProductNames | null>(
      getActiveProductFromUrl(),
    )
    const { history } = useProductHistory()

    useEffect(() => {
      const unlisten = history?.listen(() => {
        setActiveProduct(getActiveProductFromUrl())
      })
      return () => unlisten?.()
    }, [history])

    window.__userData = {
      data,
      loading,
      error,
    }

    window.appshell = {
      eventKeys: { ...AppshellEventKeys },
      actions: {
        ...MODAL_ACTIONS,
        ...ORCHESTRATOR_ACTIONS,
        ...ORGANIZATION_ACTIONS,
        ...ACCOUNT_ACTIONS,
        ...ENGAGE_ACTIONS,
      },
      actionKeys: {
        ...OrganizationActionKeys,
      },
      MODALS,
      COMPONENTS,
      orchestratorStore: store,
    }

    const user = React.useMemo(() => {
      return loading || !data
        ? {
            name: '...',
            email: '...',
            products: [],
            featureFlips: [],
            organizations: [],
            currentOrganization: {},
            isImpersonation: false,
            loading: true,
          }
        : {
            ...data.account,
          }
    }, [loading, data])

    useEffect(() => {
      if (!loading) {
        handleOrchestratorActionsOnload()
        trackUser(user)
      }
    }, [user, loading])

    useEffect(() => {
      if (error) {
        bugsnagClient.notify(error)
      }
    }, [error])

    const { isEnabled: shouldEnableBeaconAiAnswers } = useSplitEnabled(
      'beacon-ai-answers-web',
    )
    const showBeaconInHelpMenu = useSplitEnabled('move-beacon-to-help-menu')
    const loadBeaconWidget = useSplitEnabled('beacon-web-widget')

    useHelpScoutBeacon(
      user,
      shouldEnableBeaconAiAnswers,
      showBeaconInHelpMenu,
      loadBeaconWidget,
    )

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error (TS2339) Property 'result' does not exist on type 'Error | ServerParseError | ServerError'.
    const networkErrors = error?.networkError?.result?.errors
    if (
      networkErrors?.some(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (err: any) => err.extensions?.code === 'UNAUTHENTICATED',
      )
    ) {
      window.location.assign(getLoginUrl(window.location.href))
    }

    // This function allows us to call a refetch on our GetAccount query
    // This was added to fix the data sync when
    // the ChannelConnectionUpgradeModal was being call by the account app
    // We needed to make sure we were getting the latest data and not looking in our cache
    const handleRefetching = useCallback<() => Promise<void>>(() => {
      return new Promise((resolve) => {
        refetch().then(() => {
          resolve()
        })
      })
    }, [refetch])

    useEffect(() => {
      window.addEventListener(AppshellEventKeys.MODAL_EVENT_KEY, (e) =>
        handleModalEvent(e, handleRefetching),
      )

      window.addEventListener(
        AppshellEventKeys.BILLING_EVENT_KEY,
        handleRefetching,
      )

      window.addEventListener(
        AppshellEventKeys.ACCOUNT_EVENT_KEY,
        handleRefetching,
      )

      return function cleanup(): void {
        window.removeEventListener(
          AppshellEventKeys.BILLING_EVENT_KEY,
          handleRefetching,
        )

        window.removeEventListener(
          AppshellEventKeys.ACCOUNT_EVENT_KEY,
          handleRefetching,
        )

        window.removeEventListener(AppshellEventKeys.MODAL_EVENT_KEY, (e) =>
          handleModalEvent(e, handleRefetching),
        )
      }
    }, [handleRefetching])

    return (
      <I18nextProvider i18n={i18n}>
        <UserContext.Provider value={user}>
          <Launcher>
            {!hideNavigation && <NavBar activeProduct={activeProduct} />}
            {!loading && !hideBanners && <BannerContainer />}
            {!user.loading && <ModalManager />}
            <Orchestrator />
            <PreAuthenticationRedirect />
          </Launcher>
        </UserContext.Provider>
      </I18nextProvider>
    )
  },
)

Navigator.displayName = 'Navigator'

export default (): void => {
  const navigator = document.getElementById('navigator')
  const hideNavigation = !!navigator?.hasAttribute('data-hide-navigation')
  const hideBanners = !!navigator?.hasAttribute('data-hide-banners')
  if (navigator) {
    ReactDOM.createRoot(navigator).render(
      <ErrorBoundary>
        <React.StrictMode>
          <AppShell hideNavigation={hideNavigation} hideBanners={hideBanners} />
        </React.StrictMode>
      </ErrorBoundary>,
    )
  }
}

// TODO: This wrapper is necessary to have split.io properly setup to enable, the
// theme switcher, remove once the theme switcher is disabled or becomes GA
function PopcornProviderWrapper({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const { isEnabled: isThemeSwitcherEnabled = false } = useSplitEnabled(
    'buffer-test-theme-switcher',
  )
  return (
    <PopcornProvider
      theme="light"
      portalContainerId={'portals-container-app-shell'}
      themeSwitcherEnabled={isThemeSwitcherEnabled}
      // Disable toaster in Publish since the toaster in Publish will pick up the toast notifications
      // TODO: Remove this once AppShell is fully integrated with Publish
      _toaster={!isInPublish()}
    >
      {children}
    </PopcornProvider>
  )
}
