import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import {
  ChannelFieldConfiguration,
  PostFields,
} from '@buffer-mono/reminders-config'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'

export function getStickersToDisplay(args: {
  service: string
  updateType: string
}): PostFields[] {
  const { service, updateType } = args

  const serviceConfig =
    ChannelFieldConfiguration?.[service]?.postTypes?.[updateType]

  if (!serviceConfig) {
    return []
  }

  const { reminderFields, postFields } = serviceConfig

  const isAnyInstagramPersonalProfileSelected =
    AppStore.getSelectedProfiles().some(
      (profile) =>
        profile.serviceName === Service.Instagram &&
        profile.serviceType === 'profile',
    )

  return (
    reminderFields
      .filter((field) => !postFields.includes(field))
      // TODO: remove this after introducing channel type to ChannelFieldConfiguration
      .filter(
        (field) =>
          !(isAnyInstagramPersonalProfileSelected && field === 'products'),
      )
  )
}

function getFilteredFields(args: {
  service: string
  updateType: string
  isReminder: boolean
  isInstagramLogin: boolean
  serviceType: string
}): PostFields[] {
  const { service, updateType, isReminder, isInstagramLogin, serviceType } =
    args

  const serviceConfig =
    ChannelFieldConfiguration[service]?.postTypes[updateType]

  if (!serviceConfig) {
    return []
  }

  const { reminderFields, postFields } = serviceConfig

  if (!isReminder) {
    return postFields
  }

  const fields = reminderFields.filter((field) => postFields.includes(field))

  if (service === 'instagram') {
    if (isInstagramLogin || serviceType === 'profile') {
      return fields.filter(
        (field) =>
          ![PostFields.LOCATION_NAME, PostFields.LOCATION_ID].includes(field),
      )
    }
  }

  return fields
}

const getSelectedInstagramProfileData = (): {
  serviceType: string
  apiVersion: string | undefined | null
} => {
  const selectedProfiles = AppStore.getSelectedProfiles().filter(
    (profile) => profile.service.name === 'instagram',
  )
  return {
    serviceType: selectedProfiles[0]?.serviceType,
    apiVersion: selectedProfiles[0]?.apiVersion,
  }
}

export function getFieldsToDisplay(args: { draft: Draft }): PostFields[] {
  const { draft } = args

  const { serviceType, apiVersion } = getSelectedInstagramProfileData()

  const isReminder = draft.isReminder ?? false

  const fieldsToDisplay = getFilteredFields({
    service: draft.id,
    updateType: draft.updateType ?? 'post',
    isReminder,
    isInstagramLogin: apiVersion === 'instagramLogin',
    serviceType,
  })
  const stickersToDisplay = isReminder
    ? draft.selectedStickers.filter((selectedSticker) =>
        ChannelFieldConfiguration[draft.id]?.postTypes[
          draft.updateType ?? 'post'
        ].reminderFields.includes(selectedSticker),
      )
    : []

  return [...stickersToDisplay, ...fieldsToDisplay]
}
