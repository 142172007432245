import { graphql } from '~publish/gql'
import { useQuery, type ApolloError } from '@apollo/client'
import type {
  GetFeedDetailsPageQuery,
  GetFeedDetailsPageQueryVariables,
  RssFeedConnectionMetadata,
  NewsSearchConnectionMetadata,
} from '~publish/gql/graphql'

export type FeedCollectionForDetails = GetFeedDetailsPageQuery['feedCollection']

export { RssFeedConnectionMetadata, NewsSearchConnectionMetadata }

export const GetFeedDetailsPage = graphql(/* GraphQL */ `
  query GetFeedDetailsPage($feedCollectionId: FeedCollectionId!) {
    feedCollection(input: { id: $feedCollectionId }) {
      id
      name
      feeds {
        id
        type
        name
        homepage
        connectionMetadata {
          ... on RSSFeedConnectionMetadata {
            url
          }
          ... on NewsSearchConnectionMetadata {
            query
          }
        }
      }
    }
  }
`)

type UseFeedCollectionResult = {
  loading: boolean
  error?: ApolloError
  feedCollection: FeedCollectionForDetails | null
}

export const useFeedCollection = (id: string): UseFeedCollectionResult => {
  const { data, loading, error } = useQuery<
    GetFeedDetailsPageQuery,
    GetFeedDetailsPageQueryVariables
  >(GetFeedDetailsPage, {
    variables: {
      feedCollectionId: id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  return {
    loading,
    error,
    feedCollection: data?.feedCollection ?? null,
  }
}
