import { gql, useApolloClient } from '@apollo/client'
import { useCallback, useEffect } from 'react'

const TTL_IN_MS = 5 * 60 * 1000

export type UserS3UploadSignature = {
  signature: string
  base64policy: string
  credentials: string
  date: string
  expires: string
  algorithm: string
  bucket: string
  actionStatus: string
  temporarySecurityToken: string
}

export type KnownBuckets =
  | 'buffer-media-uploads'
  | 'buffer-channel-avatars-bucket'
  | 'buffer-account-avatars-bucket'

export const GET_S3_SIGNATURE = gql`
  query GetS3Signature($input: S3SignatureInput!) {
    s3Signature(input: $input) {
      bucket
      signature
      base64policy
      actionStatus
      algorithm
      credentials
      date
      expires
      temporarySecurityToken
    }
  }
`

export function useS3Signature(
  bucket: KnownBuckets = 'buffer-media-uploads',
): () => Promise<UserS3UploadSignature> {
  const client = useApolloClient()

  useEffect(() => {
    const interval = setInterval(() => {
      client.cache.evict({
        fieldName: 's3Signature',
        args: { input: { bucket } },
      })
    }, TTL_IN_MS)

    return () => {
      client.cache.evict({
        fieldName: 's3Signature',
        args: { input: { bucket } },
      })
      clearInterval(interval)
    }
  }, [client.cache])

  return useCallback(
    () =>
      client
        .query({
          query: GET_S3_SIGNATURE,
          variables: {
            input: {
              bucket,
            },
          },
        })
        .then((result) => {
          return result.data.s3Signature
        }),
    [client],
  )
}
