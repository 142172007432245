import { Button, Notice } from '@buffer-mono/popcorn'
import React, { type MouseEventHandler } from 'react'

import { useCta } from '~publish/hooks/useCta'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import useQueueLimitBar from '~publish/legacy/queue/components/QueueLimitBar/useQueueLimitBar'

import { useRegisteredBanners } from '~publish/components/RegisteredBanner'
import styles from './QueueLimitNotice.module.css'

const PLAN_SELECTOR_ID = 'plan_selector_container'
const CTA = SEGMENT_NAMES.QUEUE_LIMIT_BANNER_UPGRADE
const UPGRADE_PATH_NAME = 'queueLimit-upgrade'

export const QueueLimitNotice = (): JSX.Element | null => {
  // TODO: useQueueLimitBar should be updated to use GraphQL
  const { numberOfScheduledPosts, postLimit, shouldShow } = useQueueLimitBar()
  const cta = useCta(CTA as CTA, {
    upgradePathName: UPGRADE_PATH_NAME,
  })

  // Suppress banners if this notice is visible
  const { suppressBanners, unsuppressBanners } = useRegisteredBanners()
  React.useLayoutEffect(() => {
    if (shouldShow) {
      suppressBanners()
    }
    return () => unsuppressBanners()
  }, [shouldShow, suppressBanners, unsuppressBanners])

  if (!shouldShow) {
    return null
  }

  const handleUpgradeClick: MouseEventHandler<HTMLButtonElement> = (
    evt,
  ): void => {
    evt.preventDefault()
    cta.clicked()

    const { MODALS, actions } = window?.appshell || {}
    actions.openModal(MODALS.planSelector, {
      cta: CTA,
      upgradePathName: UPGRADE_PATH_NAME,
    })
  }

  const percentage = (numberOfScheduledPosts / postLimit) * 100
  const postsRemaining = postLimit - numberOfScheduledPosts
  const critical = percentage >= 90

  return (
    <Notice
      data-testid="queue-limit-notice"
      variant={critical ? 'error' : 'info'}
      data-state={critical ? 'error' : 'info'}
      className={styles.notice}
    >
      <Notice.Heading className={styles.heading}>
        <span>
          {postsRemaining <= 0 ? 0 : postsRemaining}{' '}
          {postsRemaining > 1 ? 'Posts' : 'Post'} left to schedule on free plan,
          including scheduled drafts.
        </span>
        <Button
          variant={critical ? 'critical' : 'primary'}
          onClick={handleUpgradeClick}
          aria-haspopup="dialog"
          aria-controls={PLAN_SELECTOR_ID}
        >
          Upgrade for Unlimited Posts
        </Button>
      </Notice.Heading>
    </Notice>
  )
}
