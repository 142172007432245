import { graphql } from '~publish/gql'
import { useMutation } from '@apollo/client'

const DeleteFeedCollection = graphql(/* GraphQL */ `
  mutation DeleteFeedCollection($input: DeleteFeedCollectionInput!) {
    deleteFeedCollection(input: $input) {
      __typename
    }
  }
`)

export const useDeleteFeedCollection = (): ((
  feedCollectionId: string,
) => Promise<void>) => {
  const [deleteFeedCollections] = useMutation(DeleteFeedCollection, {
    update(cache, _, { variables }) {
      const ids = variables?.input.feedCollectionId ?? []
      for (const id of ids) {
        const normalizedId = cache.identify({
          id,
          __typename: 'FeedCollection',
        })
        cache.evict({ id: normalizedId })
      }
      if (ids.length > 0) {
        cache.gc()
      }
    },
  })

  return async (feedCollectionId: string) => {
    await deleteFeedCollections({ variables: { input: { feedCollectionId } } })
  }
}
