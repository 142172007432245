import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import type { StartOfWeek } from '~publish/gql/graphql'
import { GetAccountPreferences } from './usePreferences'

export const UpdateStartOfWeekPreferenceMutation = graphql(/* GraphQL */ `
  mutation updateStartOfWeekPreference(
    $input: UpdateStartOfWeekPreferenceInput!
  ) {
    updateStartOfWeekPreference(input: $input) {
      __typename
      ... on UpdateStartOfWeekPreferenceSuccess {
        _empty
      }
      ... on InvalidInputError {
        message
      }
      ... on NotFoundError {
        message
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

type UpdateStartOfWeekPreference = ({
  startOfWeek,
}: {
  startOfWeek: StartOfWeek
}) => Promise<void>

type UpdateStartOfWeekPreferenceResponse = {
  updateStartOfWeekPreference: UpdateStartOfWeekPreference
  isLoading: boolean
  hasError: boolean
}

/**
 * Custom hook to update and Accounts start of week preference mutation.
 * @returns {UpdateStartOfWeekPreferenceResponse} The function to trigger updating the start of week preference.
 */
export const useUpdateStartOfWeekPreference =
  (): UpdateStartOfWeekPreferenceResponse => {
    const [executeUpdateStartOfWeekPreference, { loading, error }] =
      useMutation(UpdateStartOfWeekPreferenceMutation)

    const updateStartOfWeekPreference = useCallback(
      async ({ startOfWeek }: { startOfWeek: StartOfWeek }) => {
        try {
          const { data, errors } = await executeUpdateStartOfWeekPreference({
            variables: {
              input: {
                startOfWeek,
              },
            },
            refetchQueries: [GetAccountPreferences],
          })

          if (
            data?.updateStartOfWeekPreference?.__typename ===
              'UnexpectedError' ||
            data?.updateStartOfWeekPreference?.__typename ===
              'InvalidInputError' ||
            data?.updateStartOfWeekPreference?.__typename === 'NotFoundError'
          ) {
            throw new Error(data.updateStartOfWeekPreference.message)
          }

          // Handle GraphQL errors if any
          if (
            errors?.length ||
            data?.updateStartOfWeekPreference?.__typename !==
              'UpdateStartOfWeekPreferenceSuccess'
          ) {
            const unexpectedError = new Error(
              errors?.[0]?.message || 'Unexpected API response',
            )
            logError(unexpectedError, { metaData: { data, errors } })
            throw unexpectedError
          }
        } catch (error) {
          logError(error as Error, {
            metaData: { variables: { startOfWeek } },
          })
          throw error
        }
      },
      [executeUpdateStartOfWeekPreference],
    )

    return {
      updateStartOfWeekPreference,
      isLoading: loading,
      hasError: !!error,
    }
  }
