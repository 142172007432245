import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDismissBanner, {
  BannerTypes,
} from '~publish/legacy/profile-page/hooks/useDismissBanner'
import { Button, PromotionalBanner } from '@buffer-mono/popcorn'
import styled from 'styled-components'
import { greenDark, greenLightest } from '@bufferapp/ui/style/colors'
import BufferIcon from '@bufferapp/ui/Icon/Icons/Buffer'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { useAccount } from '~publish/legacy/accountContext'
import ThreadsIcon from '@bufferapp/ui/Icon/Icons/Threads'
import threadsBannerSvg from './threads-banner.svg'
import capitalize from 'lodash/capitalize'
import { Service } from '@buffer-mono/app-shell/src/exports/Orchestrator/channelConnections/types'

const BufferXThreads = styled.div`
  padding: 2px;
  background: ${greenLightest};
  color: ${greenDark};
  border-radius: 16px;
  width: 70px;
  font-size: 16px;
  font-weight: 100;
  align-items: center;
  justify-content: space-around;
  display: flex;
  margin-bottom: 8px;
`

const MetaThreadsPromoBanner = ({
  service,
}: {
  service: string
}): JSX.Element | null => {
  const { t } = useTranslation()
  const [dismissed, setDismissed] = useState(false)
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )
  const { account } = useAccount()
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.threadsByMetaPromo,
  })

  const serviceName = capitalize(service)

  const onThreadsConnectClick = (): void => {
    BufferTracker.cTAClicked({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      cta: `publish-queue-banner-connectThreads${serviceName}-1`,
      upgradePathName: 'accountChannels-upgrade',
      ...commonTrackingProperties,
    })
    window.appshell.actions.connectChannel({
      selectedService: Service.threads,
      cta: `publish-queue-banner-connectThreads${serviceName}-1`,
      role: account?.currentOrganization?.role,
    })
  }

  const onDismiss = (): void => {
    BufferTracker.bannerDismissed({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      bannerName: 'metaThreadsPromoBanner',
    })
    onDismissBanner()
    setDismissed(true)
  }

  if (dismissed) return null

  return (
    <PromotionalBanner onDismiss={onDismiss}>
      <PromotionalBanner.Content>
        <BufferXThreads>
          <BufferIcon color={greenDark} />
          &#215;
          <ThreadsIcon color={greenDark} />
        </BufferXThreads>
        <PromotionalBanner.Heading>
          {t('metaThreadsBanner.headline')}
        </PromotionalBanner.Heading>
        <PromotionalBanner.Description>
          {t('metaThreadsBanner.description')}
        </PromotionalBanner.Description>
        <PromotionalBanner.Actions>
          <Button variant="primary" onClick={onThreadsConnectClick}>
            {t('metaThreadsBanner.primaryLinkText')}
          </Button>
          <Button
            variant="secondary"
            onClick={(): void => {
              BufferTracker.cTAClicked({
                organizationId,
                clientName: 'publishWeb',
                product: 'publish',
                cta: `publish-queue-banner-readBeginnersGuideThreads-1`,
                upgradePathName: null,
                ...commonTrackingProperties,
              })

              window.open(
                `https://buffer.com/resources/how-to-use-threads/`,
                '_blank',
              )
            }}
          >
            {t('metaThreadsBanner.secondaryLinkText')}
          </Button>
        </PromotionalBanner.Actions>
      </PromotionalBanner.Content>
      <PromotionalBanner.Image src={threadsBannerSvg} />
    </PromotionalBanner>
  )
}

export { MetaThreadsPromoBanner }
