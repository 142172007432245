import React from 'react'

interface StreakBackgroundProps extends React.SVGProps<SVGSVGElement> {
  width?: number
  height?: number
}

const StreakBackground = ({
  width = 212,
  height = 66,
  ...rest
}: StreakBackgroundProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 212 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g opacity="0.5">
        <g clipPath="url(#clip0_1764_317)">
          <rect width="212" height="66" rx="8" fill="#E1F5BC" />
          <rect
            width="247.265"
            height="127.454"
            rx="1.96481"
            transform="matrix(0.820035 0.572313 -0.820035 0.572313 9.57983 -12.5938)"
            fill="#EBF7D4"
            fillOpacity="0.8"
          />
          <rect
            width="247.265"
            height="38.627"
            rx="1.96481"
            transform="matrix(0.820035 0.572313 -0.820035 0.572313 -63.2617 38.2461)"
            fill="#E1F5BC"
          />
          <ellipse cx="72.9913" cy="-11" rx="61.8333" ry="67" fill="#F9FDEA" />
          <ellipse cx="72.9912" cy="-11" rx="54.3947" ry="59" fill="#D7F0A8" />
          <ellipse
            cx="126.456"
            cy="122.5"
            rx="65.0877"
            ry="62.5"
            fill="#EBF7D4"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1764_317">
          <rect width="212" height="66" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StreakBackground
