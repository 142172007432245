import {
  type AnyAction,
  createEntityAdapter,
  createNextState,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import { actionTypes as dataFetchActionTypes } from '@buffer-mono/async-data-fetch'
import type { PublishingNote, PublishingPost } from '~publish/gql/graphql'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import { parseNotes } from '~publish/legacy/duplicate-server/parsers/postParser'
import type { RootState } from '~publish/legacy/store'
import type { RpcUpdate } from './types'

const postsAdapter = createEntityAdapter<PublishingPost | RpcUpdate>()

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const postFetchReducerAction = (state, action) => {
  postsAdapter.setMany(state, action.result.updates)
}

export interface NotesPusherPayload {
  // Supply profileId for use in queue reducers
  profileId: string
  postId: string
  notes: PublishingNote[]
}
const replaceNotes = (
  state: ReturnType<typeof postsAdapter.getInitialState>,
  action: PayloadAction<NotesPusherPayload>,
): ReturnType<typeof postsAdapter.getInitialState> => {
  const { postId, notes } = action.payload
  return createNextState(state, (draftState) => {
    const post = draftState.entities?.[postId]
    if (post) post.notes = parseNotes(notes)
    return draftState
  })
}

export const postsSlice = createSlice({
  name: 'posts',
  initialState: postsAdapter.getInitialState(),
  reducers: {
    postAdded: postsAdapter.addOne,
    postUpdated: postsAdapter.updateOne,
    postsReceived: postsAdapter.setMany,
    noteAdded: replaceNotes,
    noteUpdated: replaceNotes,
    noteDeleted: replaceNotes,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        `draftPosts_${dataFetchActionTypes.FETCH_SUCCESS}`,
        (state, action: AnyAction) => {
          postsAdapter.setAll(state, action.result.drafts)
        },
      )
      .addCase(
        `sentPosts_${dataFetchActionTypes.FETCH_SUCCESS}`,
        postFetchReducerAction,
      )
      .addCase(
        `pendingTiktok_${dataFetchActionTypes.FETCH_SUCCESS}`,
        postFetchReducerAction,
      )
      .addCase(
        `pastRemindersPosts_${dataFetchActionTypes.FETCH_SUCCESS}`,
        postFetchReducerAction,
      )
      .addCase(
        `${queueActionTypes.POST_UPDATED}`,
        (state, action: AnyAction) => {
          postsAdapter.setOne(state, action.post)
        },
      )
      .addCase(
        `getCampaign_${dataFetchActionTypes.FETCH_SUCCESS}`,
        (state, action: AnyAction) => {
          postsAdapter.setMany(state, action.result.items)
        },
      )
  },
})

export default postsSlice.reducer

const { postsReceived, noteAdded, noteUpdated, noteDeleted } =
  postsSlice.actions

// globalized selectors
const { selectById: selectPostById } = postsAdapter.getSelectors(
  (state: RootState) => state[postsSlice.name],
)

export { postsReceived, noteAdded, noteUpdated, noteDeleted, selectPostById }
