import React from 'react'
import { useSplitEnabled } from './useSplitEnabled'

type SplitProtectedProps = {
  name: string
  children: React.ReactNode
  attributes?: Record<string, unknown>
}

/**
 * Use this component to restrict part of the UI behind the split feature flag.
 *
 * @example
 * ```tsx
 * <SplitProtected name="mySplit">
 *  <MyComponent />
 * </SplitProtected>
 * ````
 */
const SplitProtected = ({
  name,
  children,
  attributes = {},
}: SplitProtectedProps): JSX.Element | null => {
  const { isEnabled } = useSplitEnabled(name, attributes)

  if (isEnabled) {
    return <>{children}</>
  }

  return null
}

export { SplitProtected }
export type { SplitProtectedProps }
