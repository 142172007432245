import {
  ShapesIcon,
  AlphaBadge,
  CheckCircleIcon,
  CopyPlusIcon,
  DropdownMenu,
  EllipsisIcon,
  IconButton,
  TrashIcon,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'
import type { IdeaCard_IdeaFragment as IdeaFragment } from '~publish/gql/graphql'
import type { Idea } from '~publish/pages/Create/types'

import { useRouteMatch } from 'react-router-dom'
import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { tagTab } from '~publish/legacy/routes'
import { DeleteIdeasDialog } from '~publish/components/DeleteIdeasDialog'
import styles from './IdeaCardActions.module.css'
import { VIEWS } from '~publish/pages/Create/IdeasPage'
import {
  UNASSIGNED_GROUP_NAME,
  UNASSIGNED_GROUP_ID,
} from '../Board/helpers/helpers'
import { useIdeaGroups } from './IdeaCardActions/useIdeaGroups'

export const DuplicateIdeaMenuItem = ({
  idea,
}: {
  idea: Idea | IdeaFragment
}): JSX.Element => {
  const { createIdeaWithComposer } = useIdeaComposer()

  const isTagsPage = !!useRouteMatch(tagTab.route)
  const [view] = useQueryParam('view')

  const viewSource = React.useMemo(() => {
    if (view === VIEWS.BOARD) {
      return 'board'
    }
    if (isTagsPage) {
      return 'tagsPage'
    }
    return 'gallery'
  }, [view, isTagsPage])

  const handleDuplicateIdea = React.useCallback((): void => {
    createIdeaWithComposer({
      source: `publish-${viewSource}-ideaCard-duplicateButton-1`,
      text: idea?.content?.text ?? '',
      media: idea?.content?.media?.map((media) => media.url),
      title: idea?.content?.title ?? '',
      aiAssisted: idea?.content?.aiAssisted ? 'true' : 'false',
      tags: idea?.content?.tags?.map((tag) => tag.id),
      groupId: idea?.groupId ?? undefined,
      placeAfterId: idea?.groupId ? idea?.id : undefined, // If the idea is in a group, place the duplicate after it
      duplicate: true,
    })
  }, [createIdeaWithComposer, idea, viewSource])

  return (
    <DropdownMenu.Item
      onClick={(e): void => {
        e.stopPropagation()
      }}
      onSelect={handleDuplicateIdea}
    >
      <CopyPlusIcon />
      Duplicate
    </DropdownMenu.Item>
  )
}

export const SelectIdeaMenuItem = ({
  onSelect,
}: {
  onSelect: () => void
}): JSX.Element => {
  return (
    <DropdownMenu.Item
      onClick={(e): void => {
        e.stopPropagation()
      }}
      onSelect={onSelect}
    >
      <CheckCircleIcon />
      Select
      <AlphaBadge size="small" />
    </DropdownMenu.Item>
  )
}

export const DeleteCardMenuItem = ({
  onDelete,
}: {
  onDelete: () => Promise<void>
}): JSX.Element => {
  const handleDelete = async (): Promise<void> => {
    await onDelete()
  }

  return (
    <DeleteIdeasDialog onDelete={handleDelete}>
      <DropdownMenu.Item
        variant="critical"
        onSelect={(e): void => {
          e.preventDefault()
        }}
        onClick={(e): void => {
          e.stopPropagation()
        }}
      >
        <TrashIcon />
        Delete
      </DropdownMenu.Item>
    </DeleteIdeasDialog>
  )
}

export const MoveIdeaMenuItem = ({
  idea,
  onSelect,
}: {
  idea: Idea | IdeaFragment
  onSelect: (groupId: string | null) => void
}): JSX.Element => {
  const { groups } = useIdeaGroups()

  if (!groups) {
    return <></>
  }

  const handleSelect = (groupId: string): void => {
    onSelect(groupId === UNASSIGNED_GROUP_ID ? null : groupId)
  }

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>
        <ShapesIcon />
        Move to Group
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubMenu>
        <DropdownMenu.RadioGroup
          value={idea.groupId ?? UNASSIGNED_GROUP_ID}
          onChange={handleSelect}
        >
          {/* Unassignd group is a special case */}
          <DropdownMenu.RadioItem
            key={UNASSIGNED_GROUP_ID}
            value={UNASSIGNED_GROUP_ID}
          >
            {UNASSIGNED_GROUP_NAME}
          </DropdownMenu.RadioItem>
          {groups.map((group) => (
            <DropdownMenu.RadioItem
              key={group.id}
              value={group.id}
              disabled={group.isLocked}
            >
              {group.name}
            </DropdownMenu.RadioItem>
          ))}
        </DropdownMenu.RadioGroup>
      </DropdownMenu.SubMenu>
    </DropdownMenu.Sub>
  )
}

export type IdeaCardActionsProps = {
  idea: Idea | IdeaFragment
  onSelect?: () => void
  onDelete: () => Promise<void>
  onMoveIntoGroup?: (groupId: string | null) => void
  className?: string
}

export const IdeaCardActions = ({
  idea,
  className,
  onDelete,
  onSelect,
  onMoveIntoGroup,
}: IdeaCardActionsProps): JSX.Element => {
  return (
    <DropdownMenu
      trigger={
        <IconButton label="Actions" className={clsx(styles.menu, className)}>
          <EllipsisIcon />
        </IconButton>
      }
    >
      {onSelect && <SelectIdeaMenuItem onSelect={onSelect} />}
      {onMoveIntoGroup && (
        <MoveIdeaMenuItem idea={idea} onSelect={onMoveIntoGroup} />
      )}
      <DuplicateIdeaMenuItem idea={idea} />
      <DropdownMenu.Separator />
      <DeleteCardMenuItem onDelete={onDelete} />
    </DropdownMenu>
  )
}
