import { blue } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'
import { PLAN_SELECTOR_BREAKPOINT } from '../style'

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  width: 100%;
  padding: 15px;
  margin-bottom: 16px;

  button {
    padding: 2px;
    color: ${blue};
  }

  @media (max-width: ${PLAN_SELECTOR_BREAKPOINT}) {
    margin-bottom: 10;

    Button {
      height: auto;
    }
  }
`
