const isValidCtaString = (splitCta: string[]): boolean => splitCta.length === 5

interface CtaProperties {
  cta: string
  ctaApp?: string
  ctaView?: string
  ctaLocation?: string
  ctaButton?: string
  ctaVersion?: string
}

const getCtaProperties = (cta: string): CtaProperties => {
  if (cta) {
    const splitCta = cta.split('-')
    if (isValidCtaString(splitCta)) {
      const [ctaApp, ctaView, ctaLocation, ctaButton, ctaVersion] = splitCta
      return {
        cta,
        ctaApp,
        ctaView,
        ctaLocation,
        ctaButton,
        ctaVersion,
      }
    }
  }
  return { cta }
}

export default getCtaProperties
