import React, { type RefObject, useState } from 'react'
import './css/InstagramThumbnailSlider.css'

// NOTE: for some reason, certain videos with will fail to publish if the last
// frame or close to the last frame are choosen. To avoid this, the code below
// sets the max allowable limit to 9980 (roughly 99.8% of the video's
// duration) which allows posts to publish.
const RANGE_ALLOWABLE_MAX_LIMIT = 9980
const RANGE_TOTAL_MAX = 10000

function InstagramThumbnailSlider({
  videoRef,
}: {
  videoRef: RefObject<HTMLVideoElement>
}): JSX.Element | null {
  const [value, setValue] = useState(0)

  return (
    <div id="videoControls">
      <input
        onChange={(event): void => {
          if (!videoRef.current) {
            return
          }

          // moves the video & seek bar to correct place
          const time =
            videoRef.current.duration *
            (event.target.valueAsNumber / RANGE_TOTAL_MAX)

          if (isFinite(time)) {
            videoRef.current.currentTime = time
          }

          setValue(event.target.valueAsNumber)
        }}
        value={value}
        type="range"
        min="0"
        max={RANGE_ALLOWABLE_MAX_LIMIT}
      />
    </div>
  )
}

export default InstagramThumbnailSlider
