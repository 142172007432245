import React, { useEffect, useMemo, useRef } from 'react'

import { Button, CoachMark } from '@buffer-mono/popcorn'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import { usePostComposer } from '~publish/hooks/usePostComposer'

import { useChannels } from './useChannels'

import style from './LinkedInPromoCoachmark.module.css'

const BlueskyPromoCoachmarkWrapper = React.memo(
  ({ children }: { children: React.ReactNode }): JSX.Element => {
    const childRef = useRef<HTMLDivElement | null>(null)
    const { channels } = useChannels()
    const allBlueskyChannelIds = useMemo(() => {
      return channels.filter((c) => c.service === 'bluesky').map((c) => c.id)
    }, [channels])
    const organizationId = useOrganizationId() ?? ''

    const { createNewPostInComposer } = usePostComposer()

    // Use the dismissable banner logic
    const bannerId = 'blueskyVideoPromoCoachma'
    const banner = useDismissableBanner(bannerId, {
      trackDismiss: true,
    })

    function close(): void {
      banner.dismiss()
    }

    function closeAndCreatePost(): void {
      close()
      createNewPostInComposer({
        channels: allBlueskyChannelIds,
        cta: 'publish-sidebar-coachmark-blueskyVideoPromo-1',
      })
    }

    const shouldShowCoachmark = banner.isActive

    const tracked = useRef(false)
    useEffect(() => {
      if (!shouldShowCoachmark) return
      if (tracked.current) return
      tracked.current = true
    }, [shouldShowCoachmark, organizationId])

    return (
      <CoachMark open={shouldShowCoachmark} onDismiss={close}>
        <CoachMark.Overlay>
          <CoachMark.Spotlight />
          <div ref={childRef}>{children}</div>
        </CoachMark.Overlay>
        <CoachMark.Content
          align="start"
          side="right"
          className={style.popupOffset}
          showCloseIcon={false}
        >
          <CoachMark.Title>Share videos on Bluesky</CoachMark.Title>
          <CoachMark.Description>
            Add clips up to 60 seconds and take your posts to the next level!
          </CoachMark.Description>
          <CoachMark.Footer>
            <Button variant="secondary" onClick={close}>
              Got it
            </Button>
            <Button onClick={closeAndCreatePost}>Create Post</Button>
          </CoachMark.Footer>
        </CoachMark.Content>
      </CoachMark>
    )
  },
)

BlueskyPromoCoachmarkWrapper.displayName = 'BlueskyPromoCoachmarkWrapper'

export { BlueskyPromoCoachmarkWrapper }
