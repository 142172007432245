import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import LocationFinder from './utils/LocationFinder'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import AppStore from '../../stores/AppStore'
import * as ReminderFieldsStyled from '~publish/legacy/reminders/components/new-reminders/components/forms/field/styles'
import type { ServiceLocation } from '~publish/legacy/composer/composer/components/location-bar/entities/ServiceLocation'
import { gray, grayLight } from '@bufferapp/ui/style/colors'
import { ChevronDownIcon } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

interface LocationOption {
  value: string
  label: string
  formattedAddress?: string
}

interface LocationComposerBarProps {
  draft: Draft
}

const InputWrapper = styled.div`
  position: relative;
`

const StyledInput = styled.input`
  width: 100%;
  padding: 5px 8px 4px 8px;
  font-size: 12px;
  border: 1px solid ${grayLight};
  border-radius: 4px;

  &::placeholder {
    color: ${gray};
  }
`

const StyledChevronIcon = styled(ChevronDownIcon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`

const DropdownWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid ${grayLight};
  border-radius: 4px;
  z-index: 1000;
`

const OptionItem = styled.div`
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  &:hover {
    background-color: ${grayLight};
  }
`

const getSelectedInstagramProfileId = (): string => {
  const selectedProfiles = AppStore.getSelectedProfiles().filter(
    (profile) => profile.service.name === 'instagram',
  )
  return selectedProfiles.length > 0 ? selectedProfiles[0].id : ''
}

const LocationComposerBar: React.FC<LocationComposerBarProps> = ({ draft }) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<LocationOption[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] =
    useState<LocationOption | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const profileId = getSelectedInstagramProfileId()

  useEffect(() => {
    const locationId =
      draft?.channelData?.instagram?.service_geolocation_id ?? null
    const locationName =
      draft?.channelData?.instagram?.service_geolocation_name ?? ''
    if (locationId && locationName) {
      setSelectedLocation({ value: locationId, label: locationName })
      setInputValue(locationName)
    }
  }, [draft])

  const loadOptions = useCallback(
    async (input: string) => {
      if (!input) {
        return []
      }

      if (!profileId) {
        return []
      }

      try {
        const locations = await LocationFinder.findLocations(profileId, input)
        return locations.map((loc: ServiceLocation) => ({
          value: loc.id,
          label: `${loc.name} - ${loc.formattedAddress}`,
          formattedAddress: loc.formattedAddress,
        }))
      } catch (error) {
        console.error('Failed to load locations:', error)
        return []
      }
    },
    [profileId],
  )

  const debouncedLoadOptions = useCallback(
    debounce(async (input: string) => {
      const newOptions = await loadOptions(input)
      setOptions(newOptions)
      setIsOpen(true)
    }, 300),
    [loadOptions],
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value
    setInputValue(value)
    setSelectedLocation(null) // Clear selected location when input changes
    debouncedLoadOptions(value)
  }

  const handleOptionClick = (option: LocationOption): void => {
    setSelectedLocation(option)
    setInputValue(option.label)
    setIsOpen(false)
    ComposerActionCreators.updateDraftInstagramData({
      ...draft.channelData?.instagram,
      service_geolocation_id: option.value,
      service_geolocation_name: option.label,
    })
  }

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
        // Revert to selected location if available, or clear if not
        if (selectedLocation) {
          setInputValue(selectedLocation.label)
        } else {
          setInputValue('')
        }
      }
    },
    [selectedLocation],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  // New composer preview mode Split check
  // We consider the new preview mode enabled if the user is in the preview mode and the split is enabled
  const { isEnabled: isNewPreviewSplitEnabled } = useSplitEnabled(
    'geid-new-composer-preview',
  )
  const isNewPreviewModeEnabled =
    AppStore.getIsPreviewMode() && isNewPreviewSplitEnabled
  const profile = AppStore.getProfile(profileId)

  if (
    !draft.service?.isInstagram() ||
    draft.isStoryPost() ||
    isNewPreviewModeEnabled ||
    profile?.apiVersion === 'instagramLogin' ||
    profile?.serviceType === 'profile'
  ) {
    return null
  }

  return (
    <ReminderFieldsStyled.Wrapper>
      <ReminderFieldsStyled.LabelWrapper>
        <ReminderFieldsStyled.Label>Location</ReminderFieldsStyled.Label>
      </ReminderFieldsStyled.LabelWrapper>
      <ReminderFieldsStyled.InputWrapper>
        <InputWrapper>
          <StyledInput
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type the location"
          />
          <StyledChevronIcon />
          {isOpen && options.length > 0 && (
            <DropdownWrapper ref={dropdownRef}>
              {options.map((option) => (
                <OptionItem
                  key={option.value}
                  onClick={(): void => handleOptionClick(option)}
                >
                  {option.label}
                </OptionItem>
              ))}
            </DropdownWrapper>
          )}
        </InputWrapper>
      </ReminderFieldsStyled.InputWrapper>
    </ReminderFieldsStyled.Wrapper>
  )
}

export default LocationComposerBar
