// component vs. container https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0
import { connect } from 'react-redux'
import { getProfilesParams, profileTabPages } from '~publish/legacy/routes'
import { actions as dataFetchActions } from '@buffer-mono/async-data-fetch'
import ProfilePage from './components/ProfilePage'

const requestName = (tabId: string): string | undefined =>
  ({
    queue: 'queuedPosts',
    drafts: 'draftPosts',
    approvals: 'draftPosts',
    grid: 'gridPosts',
    sentPosts: 'sentPosts',
    pastReminders: 'pastRemindersPosts',
    default: 'queuedPosts',
  }[tabId])

const getRequestMaxCount = (tabId: string): number | undefined =>
  ({
    queue: 300,
  }[tabId])

export const getRequestName = (tabId: string): string | undefined =>
  requestName(tabId) || requestName('default')

// default export = container
export default connect(
  (state, ownProps) => {
    const params = getProfilesParams({
      // @ts-expect-error TS(2339) FIXME: Property 'history' does not exist on type '{}'.
      pathname: ownProps.history.location.pathname,
    })

    const { tabId, profileId, childTabId } = params || {}

    // With sentPosts, the reducer state name doesnt match the tabId
    let reducerName =
      tabId === 'sentPosts' && (!childTabId || childTabId === 'posts')
        ? 'sent'
        : tabId

    if (tabId === 'approvals') reducerName = 'drafts'
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (state?.[reducerName]?.byProfileId?.[profileId]) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const currentQueue = state[reducerName].byProfileId[profileId]
      return {
        loadingMore: currentQueue.loadingMore,
        moreToLoad: currentQueue.moreToLoad,
        page: currentQueue.page,
        // @ts-expect-error TS(2339) FIXME: Property 'profileNav' does not exist on type 'Defa... Remove this comment to see the full error message
        profileNavTabs: state.profileNav.profileNavTabs,
      }
    }
    return {
      // @ts-expect-error TS(2339) FIXME: Property 'profileNav' does not exist on type 'Defa... Remove this comment to see the full error message
      profileNavTabs: state.profileNav.profileNavTabs,
    }
  },
  (dispatch) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
    onChangeTab: ({ profileId }) => {
      dispatch(
        profileTabPages.goTo({
          profileId,
          tabId: 'queue',
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
    onLoadMore: ({ profileId, page, tabId, includeReminders = false }) => {
      const args = {
        profileId,
        page,
        isFetchingMore: true,
        needsApproval: tabId === 'approvals',
        includeReminders,
      }

      if (getRequestMaxCount(tabId)) {
        // @ts-expect-error TS(2339) FIXME: Property 'count' does not exist on type '{ profile... Remove this comment to see the full error message
        args.count = getRequestMaxCount(tabId)
      }
      dispatch(
        dataFetchActions.fetch({
          name: getRequestName(tabId) as string,
          args,
        }),
      )
    },
  }),
)(ProfilePage)
