import React from 'react'
import { EmptyState, RssIcon, BellIcon, Button } from '@buffer-mono/popcorn'
import type { useFeedItems, useFeedCollection } from '../../hooks'

type ContentFeedEmptyStateProps = {
  feedData: ReturnType<typeof useFeedItems>
  collectionData: ReturnType<typeof useFeedCollection>
  onShowNewFeedDialog: () => void
}
export const ContentFeedEmptyState = (
  props: ContentFeedEmptyStateProps,
): JSX.Element => {
  const { feedData, collectionData, onShowNewFeedDialog } = props
  const { loading: loadingFeed, items } = feedData
  const { loading: loadingCollection, feedCollection } = collectionData

  const loading = loadingFeed || loadingCollection
  const noFeeds =
    !loading && !items?.length && feedCollection?.feeds?.length === 0

  if (!noFeeds) {
    return <></>
  }

  return (
    <EmptyState size="xlarge">
      <EmptyState.Icon>
        <RssIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>No feeds</EmptyState.Heading>
      <EmptyState.Description>
        You’re not subscribed to any feeds
      </EmptyState.Description>
      <EmptyState.Actions>
        <Button size="large" variant="primary" onClick={onShowNewFeedDialog}>
          <BellIcon />
          Subscribe to a Feed
        </Button>
      </EmptyState.Actions>
    </EmptyState>
  )
}
