import React, { useCallback, useMemo } from 'react'
import {
  DropdownMenu,
  IconButton,
  HelpIcon,
  StatusDot,
} from '@buffer-mono/popcorn'

import type { NavDropdownItem } from '../../utils/dropdown-items'

import styles from './HelpMenu.module.css'

type HandlersMap = {
  [key: string]: (e: Event) => void
}

type HelpMenuProps = {
  hideNewTag: () => Promise<void>
  showNewBadge: boolean
  items: Array<NavDropdownItem>
}

const HelpMenu = ({
  hideNewTag,
  showNewBadge,
  items,
}: HelpMenuProps): JSX.Element => {
  const handleOpenChange = (open: boolean): void => {
    if (open) {
      hideNewTag()
    }
  }

  /* using useCallback and useMemo to avoid unnecessary re-renders */
  const handleItemClick = useCallback(
    async (e: Event, onItemClick?: () => void) => {
      e.preventDefault()
      if (onItemClick) onItemClick()
    },
    [],
  )

  const itemClickHandlers = useMemo(() => {
    return items.reduce<HandlersMap>((acc, item) => {
      acc[item.id] = (e: Event): Promise<void> =>
        handleItemClick(e, item?.onItemClick)
      return acc
    }, {})
  }, [items, handleItemClick])

  return (
    <DropdownMenu
      modal={false}
      onOpenChange={handleOpenChange}
      trigger={
        <IconButton
          variant="tertiary"
          label="Help Menu"
          size="large"
          className={styles.triggerButton}
        >
          <>
            <HelpIcon />
            {showNewBadge && (
              <StatusDot data-testid="new-badge" className={styles.statusDot} />
            )}
          </>
        </IconButton>
      }
    >
      {items.map((item) =>
        item.subItems ? (
          <DropdownMenu.Sub key={item.id}>
            <DropdownMenu.SubTrigger>{item.title}</DropdownMenu.SubTrigger>
            <DropdownMenu.SubMenu>
              {item.subItems.map((subItem) => (
                <DropdownMenu.Item
                  key={subItem.id}
                  id={subItem.id}
                  onSelect={itemClickHandlers[subItem.id]}
                  shortcut={subItem?.shortcuts?.join(' ').toUpperCase()}
                  asChild
                >
                  <a href={subItem.url} target="_blank" rel="noreferrer">
                    {subItem.title}
                    {subItem.new && (
                      <StatusDot
                        className={styles.statusDotInMenuItem}
                        data-testid="help-menu-item-new-badge"
                      />
                    )}
                  </a>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.SubMenu>
          </DropdownMenu.Sub>
        ) : (
          <DropdownMenu.Item
            key={item.id}
            id={item.id}
            onSelect={itemClickHandlers[item.id]}
            shortcut={item?.shortcuts?.join(' ').toUpperCase()}
            asChild
          >
            <a href={item.url} target="_blank" rel="noreferrer">
              {item.title}
              {item.new && (
                <StatusDot
                  className={styles.statusDotInMenuItem}
                  data-testid="help-menu-item-new-badge"
                />
              )}
            </a>
          </DropdownMenu.Item>
        ),
      )}
    </DropdownMenu>
  )
}

export default HelpMenu
