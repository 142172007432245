import React, { useEffect } from 'react'

import { EmptyState, SimpleSpinner } from '@buffer-mono/popcorn'

import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { history } from '~publish/legacy/store'
import { calendarPage } from '~publish/legacy/routes'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'

/**
 * New Post Page
 * This page technically doesn't do anything
 * However it is used as a url which can be redirected/navigated to in order to automatically open the Composer
 * with the option to pass pre selected channels to open the Composer with.
 *
 * The main use case for this is the Global Plus button and keyboard shortcuts
 * This route/page only renders for a moment
 * and then the user is taken to the calendar page with the composer opened
 *
 * pre selecting channels with the composer can be done by passing channel[]=channelid
 * inside the query params. If no ids are provided, the composer preselects all available channels by default
 */
export const NewPostPage = (): JSX.Element => {
  const { createNewPostInComposer } = usePostComposer()

  const [channelIdsFromParams = []] = useQueryParam<string[]>('channelId')
  const selectedTags = useSelectedTags()

  useEffect(() => {
    const selectedProfileIds = channelIdsFromParams || []
    history.push(calendarPage.route)
    createNewPostInComposer({
      cta: SEGMENT_NAMES.CALENDAR_CREATE_A_POST as CTA,
      channels: selectedProfileIds,
      prefillPostData: {
        tags: selectedTags,
      },
    })
  }, [channelIdsFromParams, createNewPostInComposer, selectedTags])

  return (
    <EmptyState size="medium">
      <EmptyState.Icon>
        <SimpleSpinner size="medium" />
      </EmptyState.Icon>
      <EmptyState.Heading>Create a post</EmptyState.Heading>
      <EmptyState.Description>
        You will be redirected shortly
      </EmptyState.Description>
    </EmptyState>
  )
}
