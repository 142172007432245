import React from 'react'
import styled from 'styled-components'

import { Text } from '@bufferapp/ui'
import { redLightest, blue, blueLightest } from '@bufferapp/ui/style/colors'
import { borderRadius } from '@bufferapp/ui/style/borders'
import { fontWeightMedium } from '@bufferapp/ui/style/fonts'

import { useAccount } from '~publish/legacy/accountContext'
import Donut from './Donut'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import useQueueLimitBar from './useQueueLimitBar'

const Wrapper = styled.div<{ warning: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background: ${(props): string =>
    props.warning ? redLightest : blueLightest}80;
  border-radius: ${borderRadius};
  & > p {
    font-weight: ${fontWeightMedium};
  }
  margin-bottom: 1rem;
`

const DonutWrapper = styled.div`
  margin-right: 8px;
  height: 16px;
`

const Link = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: ${blue};
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
`

function useTracking(args: { cta: string }): { trackCTAClicked: () => void } {
  const { cta } = args
  const { account } = useAccount()
  const commonTrackingProps =
    account?.currentOrganization?.commonTrackingProperties || null

  function trackCTAClicked(): void {
    BufferTracker.cTAClicked({
      clientName: 'publishWeb',
      cta,
      upgradePathName: 'queueLimit-upgrade',
      product: 'publish',
      ...commonTrackingProps,
    })
  }

  return {
    trackCTAClicked,
  }
}

const QueueLimitBar = (args: { cta: string }): JSX.Element | null => {
  const { cta } = args
  const { numberOfScheduledPosts, postLimit, shouldShow } = useQueueLimitBar()
  const { trackCTAClicked } = useTracking({ cta })

  if (!shouldShow) {
    return null
  }

  const percentage = (numberOfScheduledPosts / postLimit) * 100
  const postsRemaining = postLimit - numberOfScheduledPosts
  const warning = percentage >= 90

  return (
    <Wrapper warning={warning}>
      <DonutWrapper>
        <Donut percentage={percentage} warning={warning} />
      </DonutWrapper>
      <Text type="p">
        {postsRemaining <= 0 ? 0 : postsRemaining} Posts scheduled left,
        including scheduled drafts.{' '}
        <Link
          onClick={(): void => {
            trackCTAClicked()
            const { MODALS, actions } = window?.appshell || {}
            actions.openModal(MODALS.planSelector, {
              cta,
              upgradePathName: 'queueLimit-upgrade',
            })
          }}
        >
          Upgrade to go unlimited.
        </Link>
      </Text>
    </Wrapper>
  )
}

export default QueueLimitBar
