import { useEffect } from 'react'
import mixpanel, { type Mixpanel } from 'mixpanel-browser'

export function mixpanelSegmentMiddleware(mixpanel: Mixpanel): void {
  // Middleware to add Mixpanel's session recording properties to Segment events
  // https://docs.mixpanel.com/docs/session-replay/session-replay-web#segment-analyticsjs
  if (window?.analytics?.addSourceMiddleware) {
    window.analytics.addSourceMiddleware(({ payload, next }) => {
      if (payload.obj.type === 'track' || payload.obj.type === 'page') {
        if (mixpanel) {
          const segmentDeviceId = payload.obj.anonymousId
          // -------------------------------------------
          // Comment out one of the below mixpanel.register methods depending on your ID Management Version
          // Original ID Merge
          mixpanel.register({
            $device_id: segmentDeviceId,
            distinct_id: segmentDeviceId,
          })
          // Simplified ID Merge
          // mixpanel.register({
          //   $device_id: segmentDeviceId,
          //   distinct_id: '$device:' + segmentDeviceId,
          // })
          // -------------------------------------------
          const sessionReplayProperties =
            mixpanel.get_session_recording_properties()
          payload.obj.properties = {
            ...payload.obj.properties,
            ...sessionReplayProperties,
          }
        }
      }

      if (payload.obj.type === 'identify') {
        if (mixpanel && payload.obj.userId) {
          const userId = payload.obj.userId
          mixpanel.identify(userId)
        }
      }
      next(payload)
    })
  }
}

export function useMixpanelSessionReplay(): void {
  useEffect(() => {
    const isProduction: boolean =
      !window.location.hostname.includes('.local') &&
      !window.location.hostname.includes('.dev')

    if (isProduction) {
      const mixpanelProjectToken = '90f73cfa0608e74231d66fdf4f31e120'
      mixpanel.init(mixpanelProjectToken, {
        record_mask_text_selector: '',
        record_block_selector: '',
        record_sessions_percent: 5,
        // @ts-expect-error - This property is not in the Mixpanel types yet
        record_min_ms: 5000,
      })
      mixpanelSegmentMiddleware(mixpanel)
      mixpanel.start_session_recording()
    }
  }, [])
}
