import React from 'react'
import {
  Combobox,
  EmptyState,
  SearchIcon,
  ShapesIcon,
} from '@buffer-mono/popcorn'

import type { FilterByIdeaGroup_IdeaGroupFragment } from '~publish/gql/graphql'
import { type FragmentType, graphql, getFragmentData } from '~publish/gql'
import { FilterButton } from '~publish/components/FilterButton'

export const FilterByIdeaGroup_IdeaGroup = graphql(/* GraphQL */ `
  fragment FilterByIdeaGroup_IdeaGroup on IdeaGroup {
    id
    name
  }
`)

/**
 * Masked type provided by parent query result.
 * Use `useFilterByTagFragment` to get the unmasked type.
 */
export type MaskedFilterByIdeaGroupFragment = readonly FragmentType<
  typeof FilterByIdeaGroup_IdeaGroup
>[]

/**
 * Unmasked type extracted from parent query result.
 */
export type FilterByIdeaGroupFragment = FilterByIdeaGroup_IdeaGroupFragment
/**
 * List of unmasked tag filter fragments extracted from parent query result.
 */
export type FilterByIdeaGroupFragments =
  readonly FilterByIdeaGroup_IdeaGroupFragment[]

/**
 * Unmask tag filters from the parent query result into typed
 *  fragments.
 * @param tags masked tag filter fragments from the parent query result
 * @returns
 */
export const useFilterByIdeaGroupFragment = (
  groups: MaskedFilterByIdeaGroupFragment,
): FilterByIdeaGroupFragments =>
  getFragmentData(FilterByIdeaGroup_IdeaGroup, groups)

type FilterByIdeaGroupProps = {
  groups: MaskedFilterByIdeaGroupFragment
  /**
   * Selected group ids
   */
  value: string[]
  onSelect: (groupIds: string[]) => void
}

/**
 * Component to enable filtering by idea groups, using Combobox
 * It exposes fragment to fetch idea groups, idea groups array and selected idea groups
 */
export const FilterByIdeaGroup = ({
  groups: passedGroups,
  value,
  onSelect,
  ...props
}: FilterByIdeaGroupProps): JSX.Element => {
  const groups = getFragmentData(FilterByIdeaGroup_IdeaGroup, passedGroups)

  return (
    <Combobox multiple value={value} onChange={onSelect}>
      <Combobox.Trigger {...props}>
        <FilterButton
          icon={<ShapesIcon />}
          count={value.length}
          variant="secondary"
          size="large"
        >
          Groups
        </FilterButton>
      </Combobox.Trigger>
      <Combobox.Content>
        <Combobox.Input placeholder="Search groups" prefix={<SearchIcon />} />
        <Combobox.List>
          <Combobox.Empty>
            {groups.length === 0 ? (
              <EmptyState size="small">
                <EmptyState.Icon>
                  <ShapesIcon />
                </EmptyState.Icon>
                <EmptyState.Heading>No groups</EmptyState.Heading>
                <EmptyState.Description>
                  Use groups to organise your ideas
                </EmptyState.Description>
              </EmptyState>
            ) : (
              <EmptyState size="small">
                <EmptyState.Icon>
                  <SearchIcon />
                </EmptyState.Icon>
                <EmptyState.Description>No groups found</EmptyState.Description>
              </EmptyState>
            )}
          </Combobox.Empty>
          {groups.map(({ id, name }) => (
            <Combobox.Item key={id} value={id} keywords={[name]}>
              {name}
            </Combobox.Item>
          ))}
        </Combobox.List>
      </Combobox.Content>
    </Combobox>
  )
}
