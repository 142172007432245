import React from 'react'

import StreakBackground from '../images/StreakBackground'
import StreakIllustration from '../images/StreakIllustration'

import styles from './StreakHeader.module.css'

const StreakHeaderRoot = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return <div className={styles.streakBackgroundContainer}>{children}</div>
}

const StreakHeaderBackground = (): JSX.Element => {
  return (
    <div className={styles.streakBackground}>
      <StreakBackground />
    </div>
  )
}

const StreakHeaderContent = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    <div className={styles.streakIllustrationContainer}>
      <StreakIllustration />
      <div className={styles.streakContent}>{children}</div>
    </div>
  )
}

export const StreakHeader = Object.assign(StreakHeaderRoot, {
  Background: StreakHeaderBackground,
  Content: StreakHeaderContent,
})
