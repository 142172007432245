import React from 'react'
import { AlertDialog, Dialog } from '@buffer-mono/popcorn'
import SimpleModal from '@bufferapp/ui/SimpleModal'
import NonDismissibleModal from '@bufferapp/ui/NonDismissibleModal'

import { MODALS } from '../../common/types'

import Confirmation from './modals/Confirmation/Confirmation'
import ChannelConnectionPrompt from './modals/ChannelConnectionPrompt'
import PlanSelector from './modals/PlanSelector'
import { StartTrialModal } from './modals/StartTrial'
import { StartTrialModalReactive } from './modals/StartTrialPromptReactive'
import StickyModal from './modals/StickyModal'
import TrialExpired from './modals/TrialExpired'
import { TrialExpiredContainer } from './modals/TrialExpired/styles'
import QuantityUpdate from './modals/QuantityUpdate'
import ConnectChannelUpgrade from './modals/ConnectChannelUpgrade'
import ChecklistComplete from './modals/ChecklistComplete'
import { Paywall } from './modals/Paywall'
import ChannelConnectionOnboarding from './modals/ChannelConnectionOnboarding/ChannelConnectionOnboarding'
import ChannelRefreshSuccessModal from './modals/ChannelRefreshSuccess'
import { FeaturePaywall } from './modals/FeaturePaywall'
import DownloadAppModal from './modals/DownloadApp/DownloadAppModal'
import { InstagramFacebookReAuth } from './modals/InstagramFacebookReAuth'
import InstagramSelectAccountType from './modals/InstagramSelectAccountType/InstagramSelectAccountType'
import ChurnSurveyModal from './modals/ChurnSurvey'
import ConfirmCancellationModal from './modals/ConfirmCancellation'
import TrialSignupWelcomeModal from './modals/TrialSignupWelcomeModal'
import RemindersWizard from './modals/RemindersWizard/components/RemindersWizard/RemindersWizard'
import { TeamMemberDowngradeModal } from './modals/TeamMemberDowngradeModal'
import QuantityUpdateTieredPricing from './modals/QuantityUpdateTieredPricing'
import PostingPlanConfiguration from './modals/PostingPlanConfiguration'
import { useModalManager } from '../ModalManager/hooks/useModalManager'
import { useUser } from '../../common/context/User'
import {
  hasEditPermission,
  isFreeUser,
  isOnActiveTrial,
} from '../../common/utils/user'
import {
  getShouldShowChannelConnectionModal,
  hasSeenChecklistCompleteModal,
  shouldShowChannelConnectionOnboardingModal,
  shouldShowPaywallModal,
  shouldShowTrialSignupWelcomeModal,
} from './utils'
import { isOnboardingPage } from '../../exports/Navigator/utils/getActiveProductFromUrl'

export const ModalContent = (): JSX.Element | null => {
  const {
    modalKey: modal,
    modalData,
    openModal,
    dismissModal: closeAction,
  } = useModalManager()
  const user = useUser()

  if (!modal) {
    return null
  }

  const isOnTrial = isOnActiveTrial(user)
  const userHasEditPermissions = hasEditPermission(user)

  // In some Organization billing states we want the same CTA
  // to either open the Quantity Update or the Plan Selector.
  // Those are when the user is on the Free plan or trialling.
  // In both case we specify this with a flag "shouldPickModalOnOrganizationState"
  // This property is being used by the MODALS.subscriptionUpdate that
  // is a wrapper modal for the 2 subscription update modals.
  const shouldUsePlanSelector = !modalData?.shouldPickModalOnOrganizationState
    ? true
    : isFreeUser(user) || isOnTrial

  const shouldShowChannelConnectionOnboarding =
    shouldShowChannelConnectionOnboardingModal(user, modalData?.channelIds)

  const shouldShowConnectChannelModal = getShouldShowChannelConnectionModal({
    user,
    modalData,
  })

  const handleOpenPaywall = (): void => {
    closeAction()
    if (shouldShowPaywallModal(user)) {
      openModal({ key: MODALS.paywall })
    }
  }

  switch (modal) {
    case MODALS.downloadApp:
      return <DownloadAppModal closeAction={closeAction} />

    case MODALS.channelConnectionPrompt:
      return (
        <StickyModal closeAction={closeAction}>
          <ChannelConnectionPrompt closeAction={closeAction} />
        </StickyModal>
      )
    case MODALS.planSelector:
      if (userHasEditPermissions) {
        return (
          <SimpleModal closeAction={handleOpenPaywall}>
            <PlanSelector />
          </SimpleModal>
        )
      }
      return null

    case MODALS.instagramFacebookReAuth:
      return <InstagramFacebookReAuth />

    case MODALS.instagramSelectAccountType:
      return <InstagramSelectAccountType />

    case MODALS.success:
      if (!isOnboardingPage()) {
        return <Confirmation />
      }
      return null

    case MODALS.startTrial:
    case MODALS.startTrialNoClick:
      if (userHasEditPermissions) {
        return <StartTrialModal />
      }
      return null

    case MODALS.startTrialReactive:
      return <StartTrialModalReactive />

    case MODALS.trialExpired:
      return (
        <TrialExpiredContainer>
          <NonDismissibleModal>
            <TrialExpired />
          </NonDismissibleModal>
        </TrialExpiredContainer>
      )

    case MODALS.quantityUpdate:
      return (
        <SimpleModal closeAction={closeAction}>
          <QuantityUpdate />
        </SimpleModal>
      )

    case MODALS.quantityUpdateTieredPricing:
      return (
        <SimpleModal closeAction={closeAction}>
          <QuantityUpdateTieredPricing />
        </SimpleModal>
      )

    case MODALS.paywall:
      return (
        <NonDismissibleModal>
          <Paywall />
        </NonDismissibleModal>
      )

    case MODALS.featurePaywall:
      return (
        <SimpleModal closeAction={closeAction}>
          <FeaturePaywall />
        </SimpleModal>
      )

    case MODALS.subscriptionUpdate:
      return (
        <SimpleModal closeAction={handleOpenPaywall}>
          {shouldUsePlanSelector ? <PlanSelector /> : <QuantityUpdate />}
        </SimpleModal>
      )

    case MODALS.channelConnectionSuccessModal:
      if (
        shouldShowChannelConnectionOnboarding &&
        !shouldShowConnectChannelModal
      ) {
        return <ChannelConnectionOnboarding />
      }
      if (shouldShowConnectChannelModal) {
        return (
          <AlertDialog open={true} onOpenChange={(): void => closeAction()}>
            <ConnectChannelUpgrade />
          </AlertDialog>
        )
      }
      return null

    case MODALS.channelRefreshSuccessModal:
      if (
        shouldShowChannelConnectionOnboarding &&
        !shouldShowConnectChannelModal
      ) {
        return <ChannelRefreshSuccessModal />
      }
      return null

    // NOTE: We don't actually call this modal directly via apps any more.
    // We use the above MODALS.channelConnectionSuccessModal event to decide if this modal is shown
    // Keeping this logic here for now to make it easier to test.
    // REFACTOR: after consuming appshell directly in products and testing can be done in app we should then delete this.
    case MODALS.connectChannelUpgrade:
      if (shouldShowConnectChannelModal) {
        return (
          <AlertDialog open={true} onOpenChange={(): void => closeAction()}>
            <ConnectChannelUpgrade />
          </AlertDialog>
        )
      }
      return null

    case MODALS.checklistComplete:
      if (!hasSeenChecklistCompleteModal(user)) {
        return (
          <SimpleModal closeAction={closeAction}>
            <ChecklistComplete closeAction={closeAction} />
          </SimpleModal>
        )
      }
      return null

    case MODALS.churnSurvey:
      return (
        <SimpleModal closeAction={closeAction}>
          <ChurnSurveyModal />
        </SimpleModal>
      )

    case MODALS.confirmCancellation:
      return (
        <SimpleModal closeAction={closeAction}>
          <ConfirmCancellationModal />
        </SimpleModal>
      )

    case MODALS.trialSignupWelcomeModal:
      if (shouldShowTrialSignupWelcomeModal(user)) {
        return (
          <Dialog open={true} onOpenChange={(): void => closeAction()}>
            <Dialog.Content>
              <TrialSignupWelcomeModal closeAction={closeAction} />
            </Dialog.Content>
          </Dialog>
        )
      }
      return null

    case MODALS.setupNotifications:
      return (
        <RemindersWizard
          source={modalData?.source}
          onContinue={modalData?.onContinue}
          onDismiss={modalData?.onDismiss}
          closeAction={closeAction}
        />
      )

    case MODALS.teamMemberDowngrade:
      return <TeamMemberDowngradeModal closeAction={closeAction} />

    case MODALS.postingPlanConfiguration:
      if (modalData?.channelId) {
        return <PostingPlanConfiguration channelId={modalData?.channelId} />
      }
      return null

    default:
      return null
  }
}
