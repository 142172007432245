import React from 'react'
import { Button, Dialog, Form, Input } from '@buffer-mono/popcorn'

const ConfirmPasswordModal = ({
  onCancel,
  onConfirm,
  description,
  children,
}: {
  onCancel?: () => void
  onConfirm: (password: string) => void
  description: string | JSX.Element
  children: React.ReactNode
}): JSX.Element => {
  const [password, setPassword] = React.useState('')
  const dialogContentRef = React.useRef<HTMLDivElement | null>(null)
  return (
    <Dialog onOpenChange={onCancel} modal>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content
        ref={dialogContentRef}
        onPointerDownOutside={(e): void => {
          if (!dialogContentRef.current) return
          const contentRect = dialogContentRef.current.getBoundingClientRect()
          // Detect if click actually happened within the bounds of content.
          // This can happen if click was on an absolutely positioned element overlapping content,
          // such as the 1Password extension icon in the text input.
          // https://github.com/radix-ui/primitives/issues/1280#issuecomment-1319109163
          const actuallyClickedInside =
            e.detail.originalEvent.clientX > contentRect.left &&
            e.detail.originalEvent.clientX <
              contentRect.left + contentRect.width &&
            e.detail.originalEvent.clientY > contentRect.top &&
            e.detail.originalEvent.clientY <
              contentRect.top + contentRect.height
          if (actuallyClickedInside) {
            e.preventDefault()
          }
        }}
      >
        <Dialog.Header>
          <Dialog.Title>Authentication Required</Dialog.Title>
          <Dialog.Description>{description}</Dialog.Description>
        </Dialog.Header>
        <Dialog.Body>
          <Form.Field name="current-password">
            <Form.Label id="current-password">Password</Form.Label>
            <Form.Control>
              <Input
                type="password"
                placeholder="Password"
                name="current-password"
                onChange={(e): void => setPassword(e.target.value)}
              />
            </Form.Control>
          </Form.Field>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Close>
            <Button variant="tertiary" size="large">
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button
              variant="primary"
              size="large"
              onClick={(): void => {
                onConfirm(password)
              }}
            >
              Submit
            </Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

export default ConfirmPasswordModal
