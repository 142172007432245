import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import CampaignForm from '~publish/legacy/campaign-form'
import ViewCampaign from '~publish/legacy/campaign'
import ListCampaigns from '~publish/legacy/campaigns-list'
import {
  campaignTab,
  tagTab,
  allChannels,
  campaignsPage,
  campaignCreate,
  campaignEdit,
  tagEdit,
  newCalendarAllChannels,
  newCalendarSingleChannel,
  calendarMonth,
  calendarWeek,
  blueprintsPage,
  tagCreate,
  tagsPage,
  channel,
  channelSettings,
  channelShopGrid,
  channelGroups,
} from '~publish/legacy/routes'
import PageWithSidebarWrapper from '~publish/legacy/app-pages/components/PageWithSidebarWrapper'
import Calendar from '~publish/legacy/calendar'
import { AllChannelsPage } from '~publish/pages/AllChannels'
import Blueprints from '~publish/legacy/blueprints'
import { usePageTitle } from '~publish/hooks/usePageTitle'
import { ChannelPage } from '~publish/pages/Channel'
import { ChannelSettings } from '~publish/pages/Channel/ChannelSettings'
import { ChannelShopGrid } from '~publish/pages/Channel/ChannelShopGrid'
import CalendarPage from '~publish/pages/Calendar/CalendarPage'
import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'
import { ChannelGroups } from '~publish/pages/ChannelGroups'
import { IdeaManagementRouter } from '~publish/components/IdeaManagementRouter'

const ScrollableContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 1px;
`

const PagesWithSidebar = (): JSX.Element => {
  const enabledFeatures = ['content-generation-enabled'] // Class names we need to append so they can be targeted by Pendo.

  usePageTitle('Publish')
  const [isPublishRevamp] = usePublishRevamp()

  return (
    <PageWithSidebarWrapper className={enabledFeatures.join(' ')}>
      <ScrollableContainer>
        <Switch>
          {isPublishRevamp && (
            <Route
              path={[
                newCalendarAllChannels.route,
                newCalendarSingleChannel.route,
              ]}
            >
              <CalendarPage />
            </Route>
          )}
          {isPublishRevamp && (
            <Route
              path={[calendarMonth.route]}
              render={({ location }): JSX.Element => {
                return (
                  <Redirect
                    to={{
                      ...location,
                      pathname: newCalendarAllChannels.getRoute('month'),
                    }}
                  />
                )
              }}
            />
          )}
          {isPublishRevamp && (
            <Route
              path={[calendarWeek.route]}
              render={({ location }): JSX.Element => {
                return (
                  <Redirect
                    to={{
                      ...location,
                      pathname: newCalendarAllChannels.getRoute('week'),
                    }}
                  />
                )
              }}
            />
          )}
          <Route path={allChannels.route}>
            <AllChannelsPage />
          </Route>
          {/* TODO: this is a child route of channel, it should be moved inside Channel page */}
          <Route path={channelSettings.route}>
            <ChannelSettings />
          </Route>
          <Route path={channelShopGrid.route}>
            <ChannelShopGrid />
          </Route>
          <Route path={channel.route}>
            <ChannelPage />
          </Route>
          <Route path={[campaignCreate.route, tagCreate.route]}>
            <CampaignForm />
          </Route>
          <Route
            path={[campaignEdit.route, tagEdit.route]}
            render={(props): JSX.Element => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error TS(2322) FIXME: Type '{ editMode: true; history: History<unknown>;... Remove this comment to see the full error message
              // eslint-disable-next-line react/jsx-props-no-spreading
              <CampaignForm {...props} editMode />
            )}
          />
          <Route path={[campaignTab.route, tagTab.route]}>
            <ViewCampaign />
            <IdeaManagementRouter />
          </Route>
          <Route path={[campaignsPage.route, tagsPage.route]}>
            <ListCampaigns />
          </Route>
          <Route path={[channelGroups.route]}>
            <ChannelGroups />
          </Route>
          {!isPublishRevamp && (
            <Route path={[calendarMonth.route, calendarWeek.route]}>
              <Calendar />
            </Route>
          )}
          <Route path={blueprintsPage.route}>
            <Blueprints />
          </Route>
        </Switch>
      </ScrollableContainer>
    </PageWithSidebarWrapper>
  )
}

export default PagesWithSidebar
