import React, { useCallback } from 'react'

import { Button, AlertDialog } from '@buffer-mono/popcorn'
import { useHistory } from 'react-router-dom'
import { createPage } from '~publish/legacy/routes'
import { useDeleteFeedCollection, type FeedCollection } from '../../hooks'

type DeleteCollectionConfirmationDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  feedCollection: FeedCollection
}

export const DeleteCollectionConfirmationDialog = (
  props: DeleteCollectionConfirmationDialogProps,
): JSX.Element => {
  const { feedCollection, open, onOpenChange } = props

  const deleteFeedCollection = useDeleteFeedCollection()

  const history = useHistory()

  const handleDelete = useCallback(async (): Promise<void> => {
    if (feedCollection) {
      await deleteFeedCollection(feedCollection.id)
      history.push(createPage.route)
    }
  }, [feedCollection, deleteFeedCollection, history])

  if (!open) {
    return <></>
  }

  return (
    <AlertDialog onOpenChange={onOpenChange} open={true}>
      <AlertDialog.Content>
        <AlertDialog.Title>Are you sure?</AlertDialog.Title>
        <AlertDialog.Description>
          This collection will be permanently deleted.
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary">Cancel</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button variant="critical" onClick={handleDelete}>
              Confirm
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}
