import { Text } from '@bufferapp/ui'
import { AltTag, Canva, Pencil } from '@bufferapp/ui/Icon'
import PersonIcon from '@bufferapp/ui/Icon/Icons/Person'

import React from 'react'
import type { Draft } from '../../entities/Draft'
import type { UserTag } from '../../stores/types'
import {
  HoverEditButton,
  HoverEditButtonWrapper,
  StyledIconWrapper,
  StyledTagWrapper,
} from './styles'
import { TempTooltip } from '~publish/legacy/shared-components/TempTooltip/TempTooltip'

export const renderTagOption = (
  tagCount: number | null,
  onClick: () => void,
): JSX.Element => (
  // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
  <TempTooltip content="Tag" sideOffset={0} delay={0} arrow>
    <StyledTagWrapper aria-label="Tag" onClick={onClick}>
      <StyledIconWrapper>
        <PersonIcon size="medium" />
      </StyledIconWrapper>
      {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: nu... Remove this comment to see the full error message */}
      <Text>{tagCount}</Text>
    </StyledTagWrapper>
  </TempTooltip>
)

export const renderCanvaEditOption = (onClick: () => void): JSX.Element => (
  // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
  <TempTooltip content="Edit Canva Design" sideOffset={0} delay={0} arrow>
    <StyledTagWrapper aria-label="Edit Canva Design" onClick={onClick}>
      <StyledIconWrapper>
        <Canva size="medium" />
      </StyledIconWrapper>
    </StyledTagWrapper>
  </TempTooltip>
)

export const renderEditOption = (
  onClick: () => void,
  isImageTooBig: boolean,
): JSX.Element => (
  // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
  <TempTooltip
    content={
      isImageTooBig
        ? 'Images larger than 75MP are not supported by the editor'
        : 'Edit'
    }
    sideOffset={0}
    delay={0}
    arrow
  >
    <StyledTagWrapper
      aria-label="Edit"
      aria-disabled={isImageTooBig}
      onClick={isImageTooBig ? undefined : onClick}
      $disabled={isImageTooBig}
    >
      <StyledIconWrapper>
        <Pencil size="medium" />
      </StyledIconWrapper>
    </StyledTagWrapper>
  </TempTooltip>
)

export const renderAltOption = (onClick: () => void): JSX.Element => (
  // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
  <TempTooltip content="Edit description" sideOffset={0} delay={0} arrow>
    <StyledTagWrapper aria-label="Alt" onClick={onClick}>
      <StyledIconWrapper>
        <AltTag size="medium" />
      </StyledIconWrapper>
    </StyledTagWrapper>
  </TempTooltip>
)

export const renderHoverEditOption = (onClick: () => void): JSX.Element => (
  <HoverEditButtonWrapper>
    <HoverEditButton onClick={onClick}>
      <StyledIconWrapper>Edit Image</StyledIconWrapper>
    </HoverEditButton>
  </HoverEditButtonWrapper>
)

export const getTags = (
  draft: Draft,
  imageUrl: string,
): UserTag[] | undefined => {
  const media = draft.getImageByUrl(imageUrl)
  return media ? media.userTags : undefined
}
