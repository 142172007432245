import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUser } from '../../../../../common/context/User'
import {
  ArrowLeftIcon,
  CloseIcon,
  Dialog,
  Flex,
  IconButton,
  ScrollArea,
  useMediaQuery,
} from '@buffer-mono/popcorn'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'

import { GetConnectableServices } from './query'
import {
  ClientPlatform,
  Service,
  type ConnectableService,
} from '../../../../../gql/graphql'

import ChannelStoreFrontItem from './ChannelStoreFrontItem'
import ServiceDetails from './ServiceDetails'
import ChannelStoreFrontSkeleton from './ChannelStoreFrontSkeleton'
import { sanitizeNullableArray } from '../../../../../helpers/typeGuards'
import {
  setStartConnection,
  resetChannelConnectionsState,
} from '../../../store/channelConnectionsReducer'
import useScrollPositionThreshold from '../../../../../common/hooks/useScrollPositionThreshold'

import styles from './ChannelStoreFront.module.css'
import { serviceRequiresFurtherConfiguration } from '../../utils'
import type { Service as ServiceLegacy } from '../../types'
import RequestAChannel from './components/RequestAChannel/RequestAChannel'
import trackChannelStorefrontViewed from '../../../../../tracking/ChannelStorefrontDialog/trackChannelStorefrontViewed'
import type { OrchestratorRootState } from '../../../../../common/events/types'
import trackChannelStorefrontMoreDetailsClicked from '../../../../../tracking/ChannelStorefrontDialog/trackChannelStorefrontMoreDetailsClicked'
import { getStartPageUrl } from '../../../../../common/utils/urls'

function ChannelList({
  connectableServices,
  handleOpenServiceDetails,
  handleConnectService,
  isLoading,
  canAddChannels,
}: {
  connectableServices: ConnectableService[]
  handleOpenServiceDetails: (connectableService: ConnectableService) => void
  handleConnectService: (connectableService: Service) => void
  isLoading: boolean
  canAddChannels: boolean
}): JSX.Element {
  const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(
    null,
  )
  const [headerElement, setHeaderElement] = useState<HTMLDivElement | null>(
    null,
  )
  const isPastThreshold = useScrollPositionThreshold({
    element: scrollElement,
    threshold: 1,
  })

  const isMobile = useMediaQuery('(width < 641px)')

  const wrapperHeight = useMemo(() => {
    return isMobile
      ? `calc(${window.innerHeight}px - ${headerElement?.clientHeight}px)`
      : '552px'
  }, [isMobile, headerElement])

  if (isLoading) {
    return <ChannelStoreFrontSkeleton />
  }

  return (
    <Flex direction="column" align="center">
      <Dialog.Header
        ref={(newRef: HTMLDivElement): void => setHeaderElement(newRef)}
        className={styles.header}
      >
        <Dialog.Title>Connect a New Channel</Dialog.Title>
      </Dialog.Header>
      {isPastThreshold && <Dialog.Separator />}
      <div className={clsx(styles.wrapper)} style={{ height: wrapperHeight }}>
        <ScrollArea
          ref={(newRef: HTMLDivElement): void => setScrollElement(newRef)}
        >
          <div className={clsx(styles.innerWrapper)}>
            {connectableServices.length > 0 &&
              connectableServices.map((serviceData: ConnectableService) => (
                <ChannelStoreFrontItem
                  key={serviceData.service}
                  connectableService={serviceData}
                  handleOpenServiceDetails={handleOpenServiceDetails}
                  handleConnectService={handleConnectService}
                  canAddChannels={canAddChannels}
                />
              ))}
            <RequestAChannel />
          </div>
        </ScrollArea>
      </div>
    </Flex>
  )
}
function ChannelStoreFront(): JSX.Element | null {
  const [selectedService, setSelectedService] =
    useState<ConnectableService | null>(null)
  const user = useUser()
  const dispatch = useDispatch()
  const {
    cta,
    skipCelebrationModal,
    prefillDataAfterConnection,
    selectedService: selectedServiceState,
  } = useSelector((state: OrchestratorRootState) => state.channelConnections)
  const organizationId = user.currentOrganization?.id
  const canAddChannels = user.currentOrganization?.role === 'admin'

  const {
    data,
    error,
    loading: isLoading,
  } = useQuery(GetConnectableServices, {
    variables: {
      input: { organization: organizationId, client: ClientPlatform.Web },
    },
    onCompleted: (data) => {
      if (
        error ||
        data?.connectableServices.__typename === 'ConnectableServicesError'
      ) {
        return
      }

      if (selectedServiceState) {
        const selectedService = data?.connectableServices.services.find(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore 2 enums with the same values
          (service) => service.service === selectedServiceState,
        )

        if (selectedService) {
          setSelectedService(selectedService)
        }
      }
    },
  })

  useEffect(() => {
    const payload = {
      cta,
    }
    trackChannelStorefrontViewed({
      user,
      payload,
    })
  }, [cta, user])

  if (
    error ||
    data?.connectableServices.__typename === 'ConnectableServicesError'
  ) {
    return null
  }

  const connectableServices = sanitizeNullableArray(
    data?.connectableServices.services,
  )

  function handleClose(): void {
    dispatch(resetChannelConnectionsState({}))
  }

  function handleOpenServiceDetails(selectedService: ConnectableService): void {
    setSelectedService(selectedService)
    trackChannelStorefrontMoreDetailsClicked({
      user,
      payload: {
        cta,
        service: selectedService.service,
      },
    })
  }

  function handleBackClick(): void {
    setSelectedService(null)
  }

  function handleConnectService(service: Service): void {
    if (service === Service.StartPage.toLocaleLowerCase()) {
      const startPageOnboardingUrl = getStartPageUrl('onboarding')
      window.location.href = startPageOnboardingUrl
      return
    }

    dispatch(
      setStartConnection({
        selectedService: service,
        destinationUrl: window.location.href,
        cta,
        skipCelebrationModal,
        prefillDataAfterConnection,
        serviceRequiresFurtherConfiguration:
          serviceRequiresFurtherConfiguration({
            service: service as unknown as ServiceLegacy,
            isRefreshingConnection: false,
          }),
      }),
    )
    setSelectedService(null)
  }

  return (
    <>
      <div>
        <Dialog.Close>
          <IconButton
            label="Close"
            variant="tertiary"
            size="small"
            className={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Dialog.Close>
        {selectedService && (
          <IconButton
            className={styles.backIcon}
            variant="tertiary"
            label="Go back to Channels List"
            onClick={handleBackClick}
            size="medium"
          >
            <ArrowLeftIcon />
          </IconButton>
        )}
      </div>
      {!selectedService && (
        <ChannelList
          connectableServices={connectableServices}
          handleOpenServiceDetails={handleOpenServiceDetails}
          handleConnectService={handleConnectService}
          isLoading={isLoading}
          canAddChannels={canAddChannels}
        />
      )}
      {selectedService && (
        <ServiceDetails
          connectableService={selectedService}
          handleConnectService={handleConnectService}
          canAddChannels={canAddChannels}
        />
      )}
    </>
  )
}

export default ChannelStoreFront
