import {
  AttachmentTypes,
  ImageDimensions,
  MediaAttachmentTypes,
  MediaTypes,
  UploadTypes,
} from './attachments'
import { Services } from './services'

export {
  Services,
  AttachmentTypes,
  MediaAttachmentTypes,
  ImageDimensions,
  MediaTypes,
  UploadTypes,
}

export const SERVICE_TWITTER = 'twitter'
export const SERVICE_FACEBOOK = 'facebook'
export const SERVICE_LINKEDIN = 'linkedin'
export const SERVICE_GOOGLE = 'google'
export const SERVICE_GOOGLEBUSINESS = 'googlebusiness'
export const SERVICE_PINTEREST = 'pinterest'
export const SERVICE_INSTAGRAM = 'instagram'
export const SERVICE_TIKTOK = 'tiktok'
export const SERVICE_STARTPAGE = 'startPage'
export const SERVICE_MASTODON = 'mastodon'
export const SERVICE_YOUTUBE = 'youtube'
export const SERVICE_THREADS = 'threads'
export const SERVICE_BLUESKY = 'bluesky'
export const QUEUE_PAGE = 'queue'
export const CALENDAR_PAGE = 'calendar'

export const PRO_PLAN_ID = '5'
export const SOLO_PREMIUM_BUSINESS_PLAN_ID = '8'
export const PREMIUM_BUSINESS_PLAN_ID = '9'
export const SMALL_PLAN_ID = '10'

// segment naming convention: APP-VIEW-LOCATION-BUTTON-VERSION
// https://www.notion.so/buffer/CTA-Parameter-Conventions-Call-to-Action-WIP-0ae87bdd99574e3888afb2fe6b75035a
const CALENDAR_CREATE_A_POST = 'publish-calendar-header-createAPost-1'
const ALL_POSTS_CREATE_A_POST = 'publish-allPosts-createAPost-1'
const EXTENSION_CREATE_A_POST = 'publish-extension-composePage-createAPost-1'
const CAMPAIGNS_CREATE_CAMPAIGN =
  'publish-campaigns-createCampaignPage-createCampaign-1'
const CAMPAIGNS_CREATE_POST_EMPTY_STATE_SCHEDUAL =
  'publish-campaigns-emptyScheduledTab-createAPost-1'
const CAMPAIGNS_CREATE_POST_CAMPAIGN_HEADER =
  'publish-campaigns-campaignHeader-createAPost-1'
const CAMPAIGNS_CREATE_DRAFT_TAB =
  'publish-campaigns-emptyDraftsTab-createADraft-1'
const CREATE_A_POST = 'publish-queue-dashboard-createAPost-1'
const CREATE_A_POST_VIA_AUTO_OPEN_COMPOSER =
  'publish-queue-dashboard-createAPost-viaAutoOpenComposer-1'
const CREATE_A_THREAD = 'publish-queue-dashboard-createAThread-1'
const CREATE_A_POST_SLOT = 'publish-queue-dashboard-createAPost-timeSlot-1'
const CREATE_A_DRAFT = 'publish-draft-page-createADraft-1'
const CTA_BANNER_PREMIUM_UPGRADE = 'publish-app-ctaBanner-premiumUpgrade-1'
const CTA_BANNER_SMALL_UPGRADE = 'publish-app-ctaBanner-smallUpgrade-1'
const CTA_BANNER_PRO_UPGRADE = 'publish-app-ctaBanner-proUpgrade-1'
const CTA_BANNER_BUSINESS_UPGRADE = 'publish-app-ctaBanner-businessUpgrade-1'
const DRAFTS_SBP_TRIAL = 'publish-drafts-trialCard-sbpTrial-1'
const DRAFTS_BUSINESS_UPGRADE = 'publish-drafts-upgradeCard-businessUpgrade-1'
const EXPIRED_TRIAL_PRO_UPGRADE = 'publish-app-expiredTrialModal-proUpgrade-1'
const EXPIRED_TRIAL_PREMIUM_UPGRADE =
  'publish-app-expiredTrialModal-premiumUpgrade-1'
const EXPIRED_TRIAL_BUSINESS_UPGRADE =
  'publish-app-expiredTrialModal-businessUpgrade-1'
const HEADER_PRO_TRIAL = 'publish-app-header-proTrial-1'
const HEADER_PRO_UPGRADE = 'publish-app-header-proUpgrade-1'
const IG_FIRST_COMMENT_PRO_TRIAL =
  'publish-composer-instagramFirstCommentTrialModal-proTrial-1'
const IG_FIRST_COMMENT_PRO_UPGRADE =
  'publish-composer-instagramFirstCommentUpgradeModal-proUpgrade-1'
const PROFILE_LIMIT_PRO_UPGRADE =
  'publish-orgAdminConnect-profileLimitUpgrade-proUpgrade-1'
const QUEUE_LIMIT_PRO_TRIAL =
  'publish-composer-queueLimitNotification-proTrial-1'
const QUEUE_LIMIT_PRO_UPGRADE =
  'publish-composer-queueLimitNotification-proUpgrade-1'
const PINTEREST_PRO_UPGRADE =
  'publish-orgAdminConnect-upgradeToConnectPinterest-proUpgrade-1'
const REMINDERS_BANNER = 'publish-queue-remindersBanner-setReminders-1'
const REMINDERS_COMPOSER = 'publish-composer-setReminders-1'
const REMINDERS_SETTINGS = 'publish-queueSettings-setReminders-1'
const REMINDERS_INSTAGRAM_GUIDE_COMPLETED =
  'publish-composer-instagramGuideCompleted-1'
const REMINDERS_POST = 'publish-queue-remindersPost-setReminders-1'
const REMINDERS_STORIES = 'publish-stories-remindersButton-setReminders-1'
const PLAN_OVERVIEW_PRO_UPGRADE =
  'publish-orgAdminConnect-planOverview-proUpgrade-1'
const PLANS_SOLO_PREMIUM_UPGRADE =
  'publish-plans-switchPlansModal-premium35Upgrade-1'
const PLANS_SOLO_PREMIUM_DOWNGRADE =
  'publish-plans-switchPlansModal-premium35Downgrade-1'
const PLANS_PRO_DOWNGRADE = 'publish-plans-switchPlansModal-proDowngrade-1'
const PLANS_PREMIUM_UPGRADE = 'publish-plans-switchPlansModal-premiumUpgrade-1'
const PLANS_PREMIUM_DOWNGRADE =
  'publish-plans-switchPlansModal-premiumDowngrade-1'
const PLANS_SMALL_UPGRADE = 'publish-plans-switchPlansModal-smallUpgrade-1'
const PLANS_SMALL_DOWNGRADE = 'publish-plans-switchPlansModal-smallDowngrade-1'
const STORIES_PREVIEW_COMPOSER = 'publish-stories-composer-preview-1'
const STORIES_PREVIEW_QUEUE = 'publish-stories-queue-preview-1'
const STORIES_COMPOSER_ADD_NOTE = 'publish-stories-composer-addNote-1'
const STORIES_PREVIEW_QUEUE_ADD_NOTE = 'publish-stories-queuePreview-addNote-1'
const STORIES_PREVIEW_COMPOSER_ADD_NOTE =
  'publish-stories-composerPreview-addNote-1'
const STORIES_IMAGE_ASPECT_RATIO_UPLOADED =
  'publish-stories-composer-imageAspectRatioUploaded-1'
const IDEA_DISCARD_ON_SAVE_POST = 'publish-ideas-discard-savePost-1'
const IDEA_KEEP_ON_SAVE_POST = 'publish-ideas-keep-savePost-1'

// For plan selectors upgrade plans, we are prefixing the cta with the product name in the app-shell
const DRAFTS_BANNER_UPGRADE = 'dratfs-banner-upgrade-1'
const CAMPAIGNS_UPGRADE = 'campaigns-emptyState-upgrade-1'
const CAMPAIGNS_UPGRADE_TO_MIGRATION_HUB = 'campaigns-migration-cta'
const CAMPAIGN_CREATE_DRAFT = 'publish-campaign-page-createADraft-1'
const CAMPAIGN_CREATE_POST = 'publish-campaign-page-createAPost-1'
const COMPOSER_QUEUE_LIMIT = 'composer-profileQueueLimit-showPaidPlans-1'
const COMPOSER_REQUEST_APPROVAL_UPSELL = 'composer-requestApproval-upsell-1'
const APPROVALS_UPSELL = 'queue-approvals-upsell-1'
const APPROVALS_UPSELL_TO_MIGRATION_HUB = 'approvals-migration-cta'
const APPROVALS_QUEUETABS_UPGRADE = 'approvals-queueTabs-upgrade-1'
const PROFILE_LIMIT_SIDEBAR_UPGRADE =
  'profileSidebar-addChannelButton-upgrade-1'
const PROFILE_SUGGESTION_SIDEBAR_NEW_POST = 'sidebar-createAPost-1'
const HASHTAG_MANAGER_UPGRADE = 'composer-hashtagManager-upgrade-1'
const HASHTAG_MANAGER_UPGRADE_TO_MIGRATION_HUB = 'hashtagManager-migration-cta'
const AWAITING_APPROVAL_PAYWALL_UPGRADE = `awaitingApproval-paywall-upgrade-1`
const DRAFTS_PAYWALL_UPGRADE = `drafts-paywall-upgrade-1`
const IDEAS_LIMIT_TRIAL = 'ideas-upgradeModal-startTrial-1'
const IDEAS_LIMIT_UPGRADE = 'ideas-upgradeModal-upgrade-1'
const QUEUE_LIMIT_BANNER_UPGRADE = 'queue-banner-upgradeQueueLimit-1'
const DRAFTS_LIMIT_BANNER_UPGRADE = 'drafts-banner-upgradeQueueLimit-1'
const TAGS_LIMIT_UPGRADE = 'tags-upgradeModal-upgrade-1'
const TAGS_REPORT_PRO_TRIAL = 'publish-tagDetail-header-proTrial-1'
const TAGS_REPORT_PRO_UPGRADE = 'publish-tagDetail-header-proUpgrade-1'
const TAGS_LIST_LIMIT_TRIAL = 'publish-tagsList-header-proTrial-1'
const TAGS_LIST_LIMIT_UPGRADE = 'publish-tagsList-header-proUpgrade-1'
const TAGS_LIST_REPORT_PRO_TRIAL = 'publish-tagsList-selector-proTrial-1'
const TAGS_LIST_REPORT_PRO_UPGRADE = 'publish-tagsList-selector-proUpgrade-1'
const FIRST_COMMENT_PAYWALL_UPGRADE_INSTAGRAM =
  'composer-firstComment-upgrade-1'
const FIRST_COMMENT_PAYWALL_UPGRADE_LINKEDIN =
  'composer-firstCommentLinkedin-upgrade-1'
const FIRST_COMMENT_PAYWALL_UPGRADE_TO_MIGRATION_HUB =
  'firstComment-migration-cta'
const FIRST_COMMENT_LINKEDIN_PAYWALL_UPGRADE_TO_MIGRATION_HUB =
  'firstCommentLinkedin-migration-cta'
const BITLY_CONNECT_CLICKED = 'publish-profile-settings-bitlyConnect-1'
const BITLY_DISCONNECT_CLICKED = 'publish-profile-settings-bitlyDisconnect-1'
const LINK_UNSORTENED_CLICKED = 'publish-composer-linkUnshorten-1'
const LINK_SORTENED_CLICKED = 'publish-composer-linkShorten-1'

export const enum NotesCta {
  QUEUE_CARD_HEADER_LEGACY_MIGRATION = 'publish-queue-notesFeed-legacyMigration-2',
  SENT_POSTS_CARD_HEADER_LEGACY_MIGRATION = 'publish-sentPosts-notesFeed-legacyMigration-2',
  DRAFTS_CARD_HEADER_LEGACY_MIGRATION = 'publish-drafts-notesFeed-legacyMigration-2',
  APPROVALS_CARD_HEADER_LEGACY_MIGRATION = 'publish-approvals-notesFeed-legacyMigration-2',
  CAMPAIGNS_CARD_HEADER_LEGACY_MIGRATION = 'publish-campaigns-notesFeed-legacyMigration-2',
}

export const AppEnvironments = {
  EXTENSION: 'EXTENSION',
  WEB_DASHBOARD: 'WEB_DASHBOARD',
  ONBOARDING: 'ONBOARDING',
  CALENDAR: 'CALENDAR',
  CAMPAIGNS: 'CAMPAIGNS',
  CHANNEL: 'CHANNEL',
  ALL_CHANNELS: 'ALL_CHANNELS',
} as const

export const PUSHER = {
  API_KEY: 'bd9ba9324ece3341976e',
  CLUSTER: 'mt1',
  AUTH_ENDPOINT: '/pusher_receiver/auth',
}

export const Features = {
  NewSlateComposer: 'new_slate_composer',
  GlobalDraftsV0: 'global_drafts_v0',
}

export const PostType = {
  POST: 'post',
  STORY: 'story',
  DRAFT: 'draft',
}

export const PostValidationError = {
  MISSING_IMAGE_OR_VIDEO: 'missing_image_or_video',
  MISSING_SOURCE_URL: 'missing_source_url',
  MISSING_TEXT: 'missing_text',
}

export const PostValidationErrorMessage = {
  [PostValidationError.MISSING_IMAGE_OR_VIDEO]: 'Please add an image or video',
  [PostValidationError.MISSING_SOURCE_URL]: 'Please provide a source url',
  [PostValidationError.MISSING_TEXT]: 'Please provide a text message',
}

export const SERVICE_NAMES = [
  SERVICE_TWITTER,
  SERVICE_FACEBOOK,
  SERVICE_LINKEDIN,
  SERVICE_GOOGLE,
  SERVICE_PINTEREST,
  SERVICE_INSTAGRAM,
  SERVICE_TIKTOK,
  SERVICE_MASTODON,
  SERVICE_GOOGLEBUSINESS,
  SERVICE_STARTPAGE,
  SERVICE_YOUTUBE,
  SERVICE_THREADS,
]

export const PLAN_IDS = {
  PRO_PLAN_ID,
  SOLO_PREMIUM_BUSINESS_PLAN_ID,
  PREMIUM_BUSINESS_PLAN_ID,
  SMALL_PLAN_ID,
}

// List of services where we don't support the Share Again action for
// sent posts fetched from the service (natively published posts there).
// This is because we don't have a way to fetch the media again from the service.
// So we don't want to show the action.
export const SERVICES_WITHOUT_SHARE_AGAIN_ACTION = [
  SERVICE_YOUTUBE,
  SERVICE_MASTODON,
  SERVICE_INSTAGRAM,
  SERVICE_TIKTOK,
  SERVICE_FACEBOOK,
  SERVICE_PINTEREST,
  SERVICE_TWITTER,
  SERVICE_LINKEDIN,
  SERVICE_THREADS,
]

export const TAGS_REPORT_PAYWALL_DATA = {
  paywalledFeature: 'tagsReport',
  featurePaywallProperties: {
    headline: 'One dashboard for your social media analytics and reporting',
    description:
      'Access in-depth insights on how to grow your following, reach more people, and boost your engagement.',
    body: [
      'Get recommendations to grow your presence',
      'Measure social media performance',
      'Create gorgeous reports',
    ],
  },
}

export const SEGMENT_NAMES = {
  AWAITING_APPROVAL_PAYWALL_UPGRADE,
  CALENDAR_CREATE_A_POST,
  ALL_POSTS_CREATE_A_POST,
  EXTENSION_CREATE_A_POST,
  CAMPAIGNS_CREATE_CAMPAIGN,
  CAMPAIGNS_CREATE_POST_EMPTY_STATE_SCHEDUAL,
  CAMPAIGNS_CREATE_POST_CAMPAIGN_HEADER,
  CAMPAIGNS_CREATE_DRAFT_TAB,
  CAMPAIGNS_UPGRADE,
  CAMPAIGNS_UPGRADE_TO_MIGRATION_HUB,
  CAMPAIGN_CREATE_DRAFT,
  CAMPAIGN_CREATE_POST,
  CREATE_A_POST,
  CREATE_A_POST_VIA_AUTO_OPEN_COMPOSER,
  CREATE_A_THREAD,
  CREATE_A_POST_SLOT,
  CREATE_A_DRAFT,
  CTA_BANNER_PREMIUM_UPGRADE,
  CTA_BANNER_SMALL_UPGRADE,
  CTA_BANNER_PRO_UPGRADE,
  CTA_BANNER_BUSINESS_UPGRADE,
  DRAFTS_BUSINESS_UPGRADE,
  DRAFTS_PAYWALL_UPGRADE,
  DRAFTS_BANNER_UPGRADE,
  DRAFTS_SBP_TRIAL,
  EXPIRED_TRIAL_PRO_UPGRADE,
  EXPIRED_TRIAL_PREMIUM_UPGRADE,
  EXPIRED_TRIAL_BUSINESS_UPGRADE,
  HEADER_PRO_TRIAL,
  HEADER_PRO_UPGRADE,
  IG_FIRST_COMMENT_PRO_TRIAL,
  IG_FIRST_COMMENT_PRO_UPGRADE,
  PINTEREST_PRO_UPGRADE,
  REMINDERS_BANNER,
  REMINDERS_COMPOSER,
  REMINDERS_SETTINGS,
  REMINDERS_INSTAGRAM_GUIDE_COMPLETED,
  REMINDERS_POST,
  REMINDERS_STORIES,
  PLAN_OVERVIEW_PRO_UPGRADE,
  PLANS_SOLO_PREMIUM_UPGRADE,
  PLANS_SOLO_PREMIUM_DOWNGRADE,
  PLANS_PRO_DOWNGRADE,
  PLANS_PREMIUM_UPGRADE,
  PLANS_PREMIUM_DOWNGRADE,
  PLANS_SMALL_UPGRADE,
  PLANS_SMALL_DOWNGRADE,
  PROFILE_LIMIT_SIDEBAR_UPGRADE,
  PROFILE_SUGGESTION_SIDEBAR_NEW_POST,
  HASHTAG_MANAGER_UPGRADE,
  HASHTAG_MANAGER_UPGRADE_TO_MIGRATION_HUB,
  STORIES_PREVIEW_COMPOSER,
  STORIES_PREVIEW_QUEUE,
  PROFILE_LIMIT_PRO_UPGRADE,
  QUEUE_LIMIT_PRO_TRIAL,
  QUEUE_LIMIT_PRO_UPGRADE,
  STORIES_COMPOSER_ADD_NOTE,
  STORIES_PREVIEW_COMPOSER_ADD_NOTE,
  STORIES_PREVIEW_QUEUE_ADD_NOTE,
  STORIES_IMAGE_ASPECT_RATIO_UPLOADED,
  COMPOSER_QUEUE_LIMIT,
  COMPOSER_REQUEST_APPROVAL_UPSELL,
  APPROVALS_UPSELL,
  APPROVALS_UPSELL_TO_MIGRATION_HUB,
  APPROVALS_QUEUETABS_UPGRADE,
  IDEAS_LIMIT_TRIAL,
  IDEAS_LIMIT_UPGRADE,
  IDEA_DISCARD_ON_SAVE_POST,
  IDEA_KEEP_ON_SAVE_POST,
  QUEUE_LIMIT_BANNER_UPGRADE,
  DRAFTS_LIMIT_BANNER_UPGRADE,
  TAGS_LIMIT_UPGRADE,
  TAGS_LIST_LIMIT_TRIAL,
  TAGS_LIST_LIMIT_UPGRADE,
  TAGS_LIST_REPORT_PRO_TRIAL,
  TAGS_LIST_REPORT_PRO_UPGRADE,
  TAGS_REPORT_PRO_TRIAL,
  TAGS_REPORT_PRO_UPGRADE,
  FIRST_COMMENT_PAYWALL_UPGRADE_INSTAGRAM,
  FIRST_COMMENT_PAYWALL_UPGRADE_LINKEDIN,
  FIRST_COMMENT_PAYWALL_UPGRADE_TO_MIGRATION_HUB,
  FIRST_COMMENT_LINKEDIN_PAYWALL_UPGRADE_TO_MIGRATION_HUB,
  BITLY_CONNECT_CLICKED,
  BITLY_DISCONNECT_CLICKED,
  LINK_UNSORTENED_CLICKED,
  LINK_SORTENED_CLICKED,
} as const

export const CLIENT_NAMES = {
  web: 'publishWeb',
} as const
