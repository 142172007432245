import {
  Badge,
  GbpEventIcon,
  GbpOfferIcon,
  GbpWhatsNewIcon,
  IgReelIcon,
  IgStoryIcon,
  Tooltip,
  YtShortIcon,
  type IconProps,
} from '@buffer-mono/popcorn'
import React from 'react'

import type { PostType } from '~publish/gql/graphql'

import { ChannelInfo } from './ChannelInfo'

import styles from './PostCardHeader.module.css'

const postTypeMap: Partial<
  Record<PostType, { icon: React.ComponentType<IconProps>; label: string }>
> = {
  story: { icon: IgStoryIcon, label: 'Story' },
  reel: { icon: IgReelIcon, label: 'Reel' },
  short: { icon: YtShortIcon, label: 'Short' },
  event: { icon: GbpEventIcon, label: 'Event' },
  offer: { icon: GbpOfferIcon, label: 'Offer' },
  whats_new: { icon: GbpWhatsNewIcon, label: "What's New" },
}

export const PostTypeDisplay = ({
  type,
  condensed,
}: {
  type?: PostType
  condensed?: boolean
}): JSX.Element => {
  if (type && postTypeMap[type] !== undefined) {
    const { icon: Icon, label } = postTypeMap[type]

    if (condensed) {
      return (
        <Tooltip content={label}>
          <Badge tabIndex={0}>
            <Icon color="subtle" />
          </Badge>
        </Tooltip>
      )
    }

    return (
      <Badge>
        <Icon color="subtle" />
        {label}
      </Badge>
    )
  }

  return <></>
}

export const PostCardHeader = ({
  type,
  condensed = false,
}: {
  type?: PostType
  condensed?: boolean
}): JSX.Element | null => {
  return (
    <header className={styles.header}>
      <ChannelInfo />
      <PostTypeDisplay type={type} condensed={condensed} />
    </header>
  )
}

PostCardHeader.displayName = 'PostCardHeader'
