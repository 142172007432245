import { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type QueryResult, useLazyQuery } from '@apollo/client'
import { startOfDay, endOfDay } from 'date-fns'

import { useSplitEnabled } from '@buffer-mono/features'

import { graphql, getFragmentData } from '~publish/gql'
import { actions as calendarActions } from '~publish/legacy/calendar/reducer'
import type {
  GetCalendarAndPostListQueryVariables,
  GetCalendarAndPostListQuery,
  CalendarPostCard_PostFragment,
} from '~publish/gql/graphql'
import { CalendarPostCard_Post } from '~publish/legacy/calendar/components/PostItem/components/PostItem/PostItemContent'
import { useOrganizationId } from '~publish/legacy/accountContext'
import { useWeeklyDates } from '~publish/pages/Calendar/hooks/useWeeklyDates'
import { useMonthlyDates } from '~publish/pages/Calendar/hooks/useMonthlyDates'
import {
  type ManyQueriesResult,
  type SingleQueryResult,
  useLazyManyQueries,
} from '~publish/hooks/useLazyManyQueries'

export type QueryVariables = GetCalendarAndPostListQueryVariables
export type Data = GetCalendarAndPostListQuery

// Constants for pagination limits
const PAGINATION_LIMITS = {
  DEFAULT: 200,
  EXTENDED: 1000,
  MULTI_DAY_FIRST_QUERY: 100,
  MULTI_DAY_FETCH: 30,
} as const

export const GetCalendarAndPostList = graphql(/* GraphQL */ `
  query GetCalendarAndPostList(
    $startDate: DateTime!
    $endDate: DateTime!
    $channelIds: [ChannelId!]
    $organizationId: OrganizationId!
    $postsLimit: Int!
    $tagIds: [TagId!]
    $status: [PostStatus!]
    $after: String
  ) {
    posts(
      first: $postsLimit
      after: $after
      input: {
        organizationId: $organizationId
        filter: {
          channelIds: $channelIds
          dueAt: { start: $startDate, end: $endDate }
          tagIds: $tagIds
          status: $status
        }
        sort: [
          { field: dueAt, direction: asc }
          { field: createdAt, direction: desc }
        ]
      }
    ) {
      edges {
        node {
          id
          dueAt
          ...CalendarPostCard_Post
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export type PostForCalendarHookResponse = {
  data: GetCalendarAndPostListQuery | undefined
  loading: boolean
  refetch: () => void
  variables: GetCalendarAndPostListQueryVariables
}

type PostForCalendarHookProps = {
  currentDate: number
  calendarMode: 'week' | 'month'
  channelsSelected: string[] | void
  tagIds: string[] | void
  status: string[] | void
}

// TODO replace with fragment
type NewPostType = NonNullable<
  GetCalendarAndPostListQuery['posts']['edges']
>[number]['node']

export const asPostsFragment = (
  data: GetCalendarAndPostListQuery | null | undefined,
): (NewPostType | undefined | null)[] => {
  return data?.posts.edges?.map((edge) => edge.node) ?? []
}

export const usePostsFragment = asPostsFragment

export const getPosts = (
  data: GetCalendarAndPostListQuery | null | undefined,
): CalendarPostCard_PostFragment[] => {
  return (
    data?.posts.edges?.map((edge) =>
      getFragmentData(CalendarPostCard_Post, edge.node),
    ) ?? []
  )
}

/**
 * Hook that manages the data fetching for calendar posts view, supporting both weekly and monthly displays.
 * Integrates with Redux for refetch signaling and feature flags for query behavior.
 *
 * - Calculates date ranges based on calendar mode (week/month)
 * - Handles post fetching with pagination and interval generation
 * - Manages refetch cycles through Redux state monitoring
 *
 * @param {Object} props - Calendar view configuration
 * @param {number} props.currentDate - Unix timestamp for current view's date
 * @param {('week'|'month')} props.calendarMode - Calendar display mode
 * @param {string[]|undefined} props.channelsSelected - Array of channel IDs to filter by
 * @param {string[]|undefined} props.tagIds - Array of tag IDs to filter by
 * @param {string[]|undefined} props.status - Array of post statuses to filter by
 *
 * @returns {Object} Calendar data and controls
 * @returns {GetCalendarAndPostListQuery|undefined} returns.data - GraphQL query results
 * @returns {IntervalsResponse} returns.intervals - Time intervals for the calendar grid
 * @returns {boolean} returns.loading - Loading state for post data
 * @returns {Function} returns.refetch - Trigger to reload post data
 * @returns {QueryVariables} returns.variables - Current GraphQL variables
 *
 * @effect Monitors Redux state for refetch signals
 * @effect Dispatches Redux action to clear refetch flag after reload
 * @dependencies Uses organization context, browser timezone, and feature flags
 */
export const useCalendarAndPostsList = (
  props: PostForCalendarHookProps,
): PostForCalendarHookResponse => {
  const { currentDate, channelsSelected, calendarMode, tagIds, status } = props
  const weekDates = useWeeklyDates(currentDate)
  const monthDates = useMonthlyDates(currentDate)

  const [startDate, endDate] =
    calendarMode === 'week'
      ? [weekDates.weekStart.toISOString(), weekDates.weekEnd.toISOString()]
      : [
          monthDates.calendarStart.toISOString(),
          monthDates.calendarEnd.toISOString(),
        ]
  const organizationId = useOrganizationId() ?? ''

  const variables = useVariables({
    startDate,
    endDate,
    organizationId,
    channelsSelected,
    tagIds,
    status,
  })

  const { data, loading, refetch } = useClientPaginatedPostList(variables)

  const dispatch = useDispatch()

  // TODO: type here is coerced. Ideally we delete this as we move off of redux
  const shouldRefetch = useSelector(
    (state: { calendar: { shouldRefetch: boolean } }) =>
      state.calendar.shouldRefetch,
  )
  useEffect(() => {
    if (shouldRefetch) {
      refetch()
      dispatch(calendarActions.clearShouldRefetch())
    }
  }, [shouldRefetch, dispatch, refetch])

  return useMemo(
    () => ({
      data,
      loading,
      refetch,
      variables,
    }),
    [data, loading, refetch, variables],
  )
}

type ClientPaginatedPostListResult = Omit<
  QueryResult<GetCalendarAndPostListQuery, QueryVariables>,
  | 'observable'
  | 'startPolling'
  | 'stopPolling'
  | 'subscribeToMore'
  | 'updateQuery'
  | 'reobserve'
  | 'fetchMore'
  | 'refetch'
> & {
  refetch:
    | ManyQueriesResult<GetCalendarAndPostListQuery, QueryVariables>['refetch']
    | QueryResult<GetCalendarAndPostListQuery, QueryVariables>['refetch']
}

/**
 * Hook implementing a two-mode strategy for calendar post fetching based on feature flags.
 *
 * Legacy Mode (feature flag off):
 * - Single legacy query
 * - Uses cache-and-network fetch policy
 *
 * Date-Segmented Mode (feature flag 'new-calendar-query' on):
 * - Splits date range into daily segments
 * - Initial fetch of 30 posts per day
 * - Background fetch more posts per day when hasNextPage is true
 * - Deduplicates posts by ID when merging results
 *
 * @param {Object} variables - GraphQL query variables
 * @param {string} variables.startDate - ISO string start date
 * @param {string} variables.endDate - ISO string end date
 * @param {string} variables.organizationId - Organization identifier
 * @param {string[]|undefined} variables.channelIds - Channel filters
 * @param {string[]|undefined} variables.tagIds - Tag filters
 * @param {string[]|undefined} variables.status - Status filters
 *
 * @returns {Object} Combined query results
 * @returns {GetCalendarAndPostListQuery} returns.data - Merged post data
 * @returns {boolean} returns.loading - Loading state
 * @returns {QueryVariables} returns.variables - Current query variables
 * @returns {Function} returns.refetch - Function to reload data
 *
 * @effect Initiates background fetches for pages with hasNextPage true
 * @featureFlags 'new-calendar-query' - Toggles between legacy/segmented modes
 * @featureFlags 'extend-calendar-post-list' - Controls pagination limits in legacy mode
 */
function useClientPaginatedPostList(
  variables: QueryVariables,
): ClientPaginatedPostListResult {
  const { isEnabled: hybridQueriesEnabled } =
    useSplitEnabled('new-calendar-query')
  const { isEnabled: extendCalendarPostList } = useSplitEnabled(
    'extend-calendar-post-list',
  )

  const [fetchCalendarAndPostList, lazyQueryResult] = useLazyQuery<
    GetCalendarAndPostListQuery,
    QueryVariables
  >(GetCalendarAndPostList, {
    variables: {
      ...variables,
      postsLimit: PAGINATION_LIMITS.DEFAULT,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  const [manyQueriesResultsOnCompleted, setManyQueriesResultsFirstLoad] =
    useState<SingleQueryResult<GetCalendarAndPostListQuery, QueryVariables>[]>(
      [],
    )
  const [firstLoad, setFirstLoad] = useState(true)
  const [
    executeQueries,
    { results: manyQueriesResults, ...manyQueriesResultRest },
  ] = useLazyManyQueries<GetCalendarAndPostListQuery, QueryVariables>({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ results }) => {
      setManyQueriesResultsFirstLoad(results)
      setFirstLoad(false)
    },
  })

  useEffect(() => {
    async function fetchPosts(): Promise<void> {
      if (!hybridQueriesEnabled) {
        await fetchCalendarAndPostList({
          variables: {
            ...variables,
            postsLimit: extendCalendarPostList
              ? PAGINATION_LIMITS.EXTENDED
              : PAGINATION_LIMITS.DEFAULT,
          },
        })
        return
      }

      const dates = getDatesInRange(
        new Date(variables.startDate),
        new Date(variables.endDate),
      )

      const queriesToExecutePerDay = dates.map((date) => ({
        query: GetCalendarAndPostList,
        variables: {
          ...variables,
          startDate: date.start,
          endDate: date.end,
          postsLimit: PAGINATION_LIMITS.MULTI_DAY_FETCH,
        },
      }))
      await executeQueries(queriesToExecutePerDay)
    }

    fetchPosts()
  }, [
    hybridQueriesEnabled,
    variables,
    fetchCalendarAndPostList,
    executeQueries,
    extendCalendarPostList,
  ])

  useEffect(() => {
    if (!hybridQueriesEnabled) return

    // Fetch more posts for queries that have a next page
    requestIdleCallback(() => {
      manyQueriesResults
        .filter((res) => res?.data?.posts.pageInfo.hasNextPage)
        .forEach(({ fetchMore, variables }) => fetchMore({ variables }))
    })
  }, [hybridQueriesEnabled, manyQueriesResults])

  return hybridQueriesEnabled
    ? {
        data: mergeResults(
          lazyQueryResult,
          // To avoid days loading one by one, we use the results from the
          // onCompleted hook on the first load. After the first load, we use the
          // results from the useLazyManyQueries hook to take advantage of the cache.
          firstLoad ? manyQueriesResultsOnCompleted : manyQueriesResults,
        ),
        variables,
        ...manyQueriesResultRest,
      }
    : lazyQueryResult
}

function mergeResults(
  firstQueryResult: QueryResult<GetCalendarAndPostListQuery, QueryVariables>,
  results: SingleQueryResult<GetCalendarAndPostListQuery, QueryVariables>[],
): GetCalendarAndPostListQuery {
  // Merge multiple single query results into a single query result
  const edges = [
    ...(firstQueryResult?.data?.posts.edges ?? []),
    ...results.flatMap((res) => res?.data?.posts.edges ?? []),
  ]

  const uniqueEdges = edges.filter(
    (edge, index, self) =>
      index === self.findIndex((t) => t.node.id === edge.node.id),
  )
  return {
    posts: {
      edges: uniqueEdges,
      pageInfo: {
        hasNextPage: false,
        endCursor: null,
      },
    },
  }
}

/**
 * Custom hook that generates stable query variables for the GraphQL query.
 * Implements memoization to prevent unnecessary re-renders and query refetches.
 * Uses a stable key generation strategy for arrays by sorting and joining values.
 */
function useVariables(variables: {
  startDate: string
  endDate: string
  organizationId: string
  channelsSelected?: string[] | void
  tagIds?: string[] | void
  status?: string[] | void
}): QueryVariables {
  const {
    startDate,
    endDate,
    organizationId,
    channelsSelected,
    tagIds,
    status,
  } = variables
  return useMemo(
    () =>
      ({
        startDate,
        endDate,
        organizationId,
        channelIds: channelsSelected,
        tagIds,
        status,
      } as QueryVariables),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // HACK: use a stable key to avoid recreating the variables object
      // TODO: Review the creation of filters on useCalendarState since it is not stable
      startDate,
      endDate,
      organizationId,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      channelsSelected?.sort().join(','),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tagIds?.sort().join(','),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      status?.sort().join(','),
    ],
  )
}

/**
 * Generates a list of dates in range between startDate and endDate
 * @param startDate - Start date
 * @param endDate - End date
 * @returns List of dates in range as { start: string, end: string }
 */
function getDatesInRange(
  startDate: Date,
  endDate: Date,
): { start: string; end: string }[] {
  const dates = []
  for (
    let date = new Date(startDate);
    date <= endDate;
    date = new Date(date.setDate(date.getDate() + 1))
  ) {
    dates.push({
      start: startOfDay(date).toISOString(),
      end: endOfDay(date).toISOString(),
    })
  }
  return dates
}
