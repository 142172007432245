import React from 'react'

import {
  Button,
  ExternalLinkIcon,
  DropdownMenu,
  BellIcon,
} from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import type { FeedCollectionForDetails } from '../../hooks'
import { ManageSubscriptionTrigger } from './ManageSubscriptionTrigger'

const trackPageView = (): void => {
  BufferTracker.pageViewed({
    name: 'Feed Settings Menu',
    product: 'publish',
    path: window.location.pathname,
    clientName: 'publishWeb',
  })
}

type Feed = FeedCollectionForDetails['feeds'][number]

type ManageSubscriptionDropdownProps = {
  feed: Feed
  onToggleSubscribe: (id: string, subscribe: boolean) => Promise<void>
}

export const ManageSubscriptionDropdown = (
  props: ManageSubscriptionDropdownProps,
): JSX.Element => {
  const { feed, onToggleSubscribe } = props

  return (
    <DropdownMenu
      onOpenChange={(open): void => {
        if (open) {
          trackPageView()
        }
      }}
    >
      <DropdownMenu.Trigger>
        <Button size="small" variant="tertiary">
          <ManageSubscriptionTrigger feed={feed} />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        {feed.homepage && (
          <DropdownMenu.Item asChild>
            <a href={feed.homepage} target="_blank" rel="noopener noreferrer">
              <ExternalLinkIcon />
              Visit {feed.name}
            </a>
          </DropdownMenu.Item>
        )}
        <DropdownMenu.Sub>
          <DropdownMenu.SubTrigger>
            <BellIcon /> Subscribed
          </DropdownMenu.SubTrigger>
          <DropdownMenu.SubMenu>
            <DropdownMenu.CheckboxItem checked>
              Subscribed
            </DropdownMenu.CheckboxItem>
            <DropdownMenu.Item
              onClick={(): Promise<void> => onToggleSubscribe(feed.id, false)}
              variant="critical"
            >
              Unsubscribe
            </DropdownMenu.Item>
          </DropdownMenu.SubMenu>
        </DropdownMenu.Sub>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
