import { useEffect, useCallback, type DependencyList } from 'react'
import { useLocalStorage } from './useLocalStorage'

const isInLastTimePeriod = (lastImpression: number, timePeriod: number) => {
  return Date.now() - lastImpression < timePeriod
}

/**
 * A React hook that executes a callback function once within a specified time period.
 * The hook uses local storage to track when the callback was last executed, ensuring
 * it only runs once within the given time window across page reloads and render cycles.
 *
 * This hook will trigger AT MOST one callback execution per time period, and does not
 * automatically set timers to track the time period.
 *
 * @param key - Unique identifier used to store the last execution timestamp in local storage
 * @param timePeriod - Time period in milliseconds during which the callback should only execute once
 * @param callback - Function to be executed once per time period
 * @param dependencies - Optional array of dependencies of the callback
 *
 * @example
 * ```tsx
 * useOncePerTimePeriod(
 *   'page-view-metric',
 *   24 * 60 * 60 * 1000, // 24 hours in ms
 *   () => trackPageView(),
 *   [trackPageView]
 * );
 * ```
 */
export function useOncePerTimePeriod(
  key: string,
  timePeriod: number,
  callback: () => void,
  dependencies: DependencyList = [],
) {
  const [lastImpression, setLastImpression] = useLocalStorage(key, 0)

  const isWithinTimePeriod = isInLastTimePeriod(lastImpression, timePeriod)

  const cb = useCallback(() => {
    callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, setLastImpression, ...dependencies])

  useEffect(() => {
    if (isWithinTimePeriod) {
      return
    }

    setLastImpression((current) => {
      // re-check if it's within the time period by re-checking the localStorage value
      // to avoid re-executing the callback if multiple hooks used
      // in same render cycle - eg if multiple components are consuming a hook
      // and we want to call a page view metric
      // Local storage is synchronized across tabs/windows, so we don't need to worry about
      // race conditions between tabs
      if (isInLastTimePeriod(current, timePeriod)) {
        return current
      }
      cb()
      return Date.now()
    })
  }, [
    key,
    isWithinTimePeriod,
    lastImpression,
    timePeriod,
    setLastImpression,
    cb,
  ])
}
