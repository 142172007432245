import React, { forwardRef, useState } from 'react'
import clsx from 'clsx'
import { useDroppable } from '@dnd-kit/core'
import { Flex, useIdleMemo } from '@buffer-mono/popcorn'
// TODO: we should export this from @buffer-mono/popcorn
import { mergeRefs } from '@buffer-mono/popcorn/src/hooks/useMergeRefs'

import {
  endOfHour,
  format,
  getZonedNow,
  startOfHour,
} from '~publish/helpers/temporal'

import { AddButton } from './AddButton'
import { DraggableItem } from './DraggableItem'
import { useCalendarContext } from './context'
import { Item } from './Item'
import ShowMoreLessButtons from './ShowMoreLessButtons'

import styles from './Hour.module.css'
import type { TimeInRange } from './types'

const HOUR_FORMAT = {
  '24': 'H:00',
  '12': 'h a',
}
const DEFAULT_ITEMS_LIMIT = 3
const FIVE_MINUTES = 300

type HourProps = {
  hour: TimeInRange
  showLabel: boolean
  isFirstLoad: boolean
}

const Hour = forwardRef<React.ElementRef<'td'>, HourProps>(
  ({ hour, isFirstLoad, showLabel = false }, forwardedRef): JSX.Element => {
    const { items, readOnly, is24HourFormat, timezone } = useCalendarContext()
    const [limit, setLimit] = useState(DEFAULT_ITEMS_LIMIT)

    const memoData = useIdleMemo(() => {
      const now = getZonedNow(timezone).round('minute')

      const startOfHourTimestamp = startOfHour(hour.time).toInstant()
        .epochMilliseconds
      let endOfHourTimestamp = endOfHour(hour.time).toInstant()
        .epochMilliseconds

      // Check if adding an hour to the end of the current hour results in the same hour.
      // This handles edge cases like daylight saving time transitions where an hour might be repeated.
      // If true, we extend the endOfHourTimestamp by an additional hour to include the repeated hour.
      if (
        endOfHour(hour.time).add({ hours: 1 }).hour ===
        endOfHour(hour.time).hour
      ) {
        endOfHourTimestamp = endOfHour(hour.time)
          .add({ hours: 1 })
          .toInstant().epochMilliseconds
      }

      const currentTimestamp = now.toInstant().epochMilliseconds

      const creationTimestamp =
        startOfHourTimestamp > currentTimestamp
          ? startOfHourTimestamp
          : currentTimestamp + FIVE_MINUTES

      const hourLabel = format(
        hour.time,
        HOUR_FORMAT[is24HourFormat ? '24' : '12'],
      )

      const addButtonLabel = `${format(
        hour.time,
        'eeee, d MMMM y',
      )} at ${hourLabel}`

      const hourTimeStamp = hour.time.toInstant().epochMilliseconds

      return {
        now,
        addButtonLabel,
        endOfHourTimestamp,
        startOfHourTimestamp,
        creationTimestamp,
        hourLabel,
        hourTimeStamp,
      }
    }, [timezone])

    const {
      now,
      endOfHourTimestamp,
      startOfHourTimestamp,
      creationTimestamp,
      hourLabel,
      hourTimeStamp,
      addButtonLabel,
    } = memoData ?? {}

    const dropDisabled = hour.comparisonToNow === 'past' || readOnly

    const itemsToRender = useIdleMemo(() => {
      if (!items || !startOfHourTimestamp || !endOfHourTimestamp) {
        return []
      }
      return items?.filter((item) => {
        return (
          item.timestamp >= startOfHourTimestamp &&
          item.timestamp <= endOfHourTimestamp
        )
      })
    }, [items, startOfHourTimestamp, endOfHourTimestamp])

    const showHourLabel = showLabel && hour.time.hour % 2 === 0

    const { setNodeRef, isOver, active } = useDroppable({
      id: hourTimeStamp ?? '',
      disabled: dropDisabled,
      data: {
        type: 'hour',
        timestamp: hourTimeStamp,
      },
    })

    const showAddButton = !dropDisabled && !active && !isFirstLoad

    return (
      <td
        className={clsx(styles.hour, {
          [styles.disabled]: dropDisabled,
          [styles.draggingOver]: isOver,
          [styles.dragHappening]: Boolean(active),
          [styles.loading]: isFirstLoad,
        })}
        ref={mergeRefs(forwardedRef, setNodeRef)}
        data-datetime={hour.toString()}
        data-hour-label={showHourLabel ? hourLabel : ''}
      >
        {now &&
          hourTimeStamp &&
          itemsToRender?.slice(0, limit).map((item, index) => {
            return (
              <DraggableItem
                key={item.id}
                id={item.id}
                index={index}
                timestamp={hourTimeStamp}
                className={clsx(styles.item, {
                  [styles.activeItem]: active?.id === item.id,
                })}
                shouldDisableDrag={
                  readOnly || item.shouldDisableDrag === undefined
                    ? item.timestamp < now.epochSeconds
                    : item.shouldDisableDrag
                }
              >
                <Item {...item} index={index} />
              </DraggableItem>
            )
          })}
        <Flex gap="xs" justify="between">
          <ShowMoreLessButtons
            itemsToRender={itemsToRender ?? []}
            limit={limit}
            setLimit={setLimit}
          />
          {showAddButton && creationTimestamp && addButtonLabel && (
            <AddButton
              timestamp={creationTimestamp}
              labelDate={addButtonLabel ?? ''}
              className={clsx(styles.addButton, styles.newAddButton)}
            />
          )}
        </Flex>
      </td>
    )
  },
)

Hour.displayName = 'Hour'

const memoHour = React.memo(Hour)
export { memoHour as Hour }
