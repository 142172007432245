import React, { Fragment, useCallback } from 'react'

import { CheckCircleIcon, SimpleSpinner } from '@buffer-mono/popcorn'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import { ContentFeedDateHeader } from './ContentFeedDateHeader'

import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'
import { useFeedCollection } from '../../hooks'
import type { FeedItem, useFeedItems } from '../../hooks/useFeedItems'
import styles from './ContentFeed.module.css'
import { ContentFeedItem } from './FeedItem'

type ContentFeedContentProps = {
  feedCollectionId: string
  feedData: ReturnType<typeof useFeedItems>
}
export const ContentFeedContent = (
  props: ContentFeedContentProps,
): JSX.Element => {
  const feedCollection = useFeedCollection(props.feedCollectionId)
  const { lastElementRef, items, loading, fetchingMore } = props.feedData
  const lastItemId = items?.at(-1)?.id

  const { createNewPostInComposer } = usePostComposer()
  const { createIdeaWithComposer } = useIdeaComposer()

  const handleCreatePost = useCallback(
    (item: FeedItem & { imageUrls: string[] }): void => {
      createNewPostInComposer({
        cta: 'create-feedCollection-feedItem-createPost-1',
        channels: 'all',
        prefillPostData: {
          text: item.contentTextPreview + ' ' + item.articleUrl,
          imageUrls: item.imageUrls,
        },
      })
    },
    [createNewPostInComposer],
  )

  const handleCreateIdea = useCallback(
    (item: FeedItem & { imageUrls: string[] }): void => {
      createIdeaWithComposer({
        source: 'create-feedCollection-feedItem-createIdea-1',
        title: item.title,
        text: `${item.title}\n\n${item.contentTextPreview}\n\n${item.articleUrl}`,
        media: item.imageUrls,
      })
    },
    [createIdeaWithComposer],
  )

  return (
    <>
      <div className={styles.contentList} role="feed">
        {items?.map((item, index) => (
          // Some feed items seem to be included twice, possibly from
          // different feeds/searches. Index helps prevent duplicate keys
          <Fragment key={`${item.id}-${index}`}>
            <ContentFeedDateHeader
              previousDate={items?.[index - 1]?.createdAt}
              nextDate={item.createdAt}
            />
            <ContentFeedItem
              ref={item.id === lastItemId ? lastElementRef : null}
              item={item}
              feed={feedCollection.feedCollection?.feeds.find(
                (feed) => feed.id === item.feed.id,
              )}
              handleCreatePost={handleCreatePost}
              handleCreateIdea={handleCreateIdea}
              feedCollectionId={props.feedCollectionId}
            />
          </Fragment>
        ))}
      </div>
      {fetchingMore && (
        <div className={styles.caughtUp}>
          <SimpleSpinner size="medium" />
        </div>
      )}
      {!loading && !fetchingMore && (items?.length || 0) > 0 && (
        <div className={styles.caughtUp}>
          <div className={styles.caughtUpBody}>
            <CheckCircleIcon />
            <span>You’re all caught up!</span>
          </div>
        </div>
      )}
    </>
  )
}
