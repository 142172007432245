import React from 'react'
import { CreatePageLayout } from '../components/Layout/CreatePageLayout'

import { Feeds } from '../Feed/Feeds'
import { IdeaManagementRouter } from '~publish/components/IdeaManagementRouter'

export function FeedsPage(): JSX.Element {
  return (
    <CreatePageLayout>
      <Feeds />
      <IdeaManagementRouter />
    </CreatePageLayout>
  )
}
