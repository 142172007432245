/* eslint-disable jsx-a11y/label-has-for */

import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@bufferapp/ui'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAccount } from '~publish/legacy/accountContext'
import { NotificationTypes } from '../AppConstants'
import UpgradePath from '../../../upgrade-paths/index'
import CloseButton from './shared/CloseButton'
import styles from './css/Notification.module.css'
import NotificationActionCreators from '../action-creators/NotificationActionCreators'

// @ts-expect-error TS(7031) FIXME: Binding element 'cta' implicitly has an 'any' type... Remove this comment to see the full error message
const NotificationCta = ({ cta }) => {
  const { isUpgradePath, label, action } = cta
  const { account } = useAccount()
  const commonTrackingProps =
    account?.currentOrganization?.commonTrackingProperties || null

  return (
    <div className={styles.ctaContainer}>
      {isUpgradePath ? (
        <UpgradePath>
          {(onUpgradeClick) => (
            // @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; label: any; ic... Remove this comment to see the full error message
            <Button
              type="primary"
              size="small"
              label={label}
              icon={<FlashIcon color="white" size="medium" />}
              // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
              onClick={(e) => {
                e.preventDefault()
                BufferTracker.cTAClicked({
                  clientName: 'publishWeb',
                  cta: 'publish-queue-composer-showPaidPlans-1',
                  upgradePathName: 'queueLimit-upgrade',
                  product: 'publish',
                  ...commonTrackingProps,
                })
                onUpgradeClick({
                  cta: SEGMENT_NAMES.COMPOSER_QUEUE_LIMIT,
                  upgradePathName: 'queueLimit-upgrade',
                })
              }}
            />
          )}
        </UpgradePath>
      ) : (
        // @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; label: any; on... Remove this comment to see the full error message
        <Button type="primary" size="small" label={label} onClick={action} />
      )}
    </div>
  )
}

NotificationCta.propTypes = {
  cta: PropTypes.shape({
    isUpgradePath: PropTypes.bool,
    label: PropTypes.string.isRequired,
    action: PropTypes.func,
  }).isRequired,
}

class Notification extends React.Component {
  onCloseButtonClick = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'Readonly<{}>... Remove this comment to see the full error message
    const { id, showSoftAndHardCloseOptions, onClose } = this.props
    const data = showSoftAndHardCloseOptions
      ? {
          // @ts-expect-error TS(2339) FIXME: Property 'hardCloseCheckbox' does not exist on typ... Remove this comment to see the full error message
          isHardCloseCheckboxChecked: this.hardCloseCheckbox.checked,
          shouldCloseVisibleNotification: true,
        }
      : {}
    NotificationActionCreators.removeNotification(id, data)
    onClose()
  }

  onCheckboxChange = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'Readonly<{}>... Remove this comment to see the full error message
    const { id, showSoftAndHardCloseOptions, onClose } = this.props
    const data = showSoftAndHardCloseOptions
      ? {
          // @ts-expect-error TS(2339) FIXME: Property 'hardCloseCheckbox' does not exist on typ... Remove this comment to see the full error message
          isHardCloseCheckboxChecked: this.hardCloseCheckbox.checked,
          shouldCloseVisibleNotification: false,
        }
      : {}
    // @ts-expect-error TS(2339) FIXME: Property 'hardCloseCheckbox' does not exist on typ... Remove this comment to see the full error message
    if (this.hardCloseCheckbox.checked) {
      NotificationActionCreators.removeNotification(id, data)
      onClose()
      // @ts-expect-error TS(2339) FIXME: Property 'hardCloseCheckbox' does not exist on typ... Remove this comment to see the full error message
    } else if (this.hardCloseCheckbox.checked === false) {
      // if a user unchecks the notification, remove the cookie that was added
      NotificationActionCreators.removeNotificationCookie(id)
    }
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      type,
      // @ts-expect-error TS(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
      message,
      // @ts-expect-error TS(2339) FIXME: Property 'showCloseIcon' does not exist on type 'R... Remove this comment to see the full error message
      showCloseIcon,
      // @ts-expect-error TS(2339) FIXME: Property 'showSoftAndHardCloseOptions' does not ex... Remove this comment to see the full error message
      showSoftAndHardCloseOptions,
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339) FIXME: Property 'classNames' does not exist on type 'Read... Remove this comment to see the full error message
      classNames,
      // @ts-expect-error TS(2339) FIXME: Property 'cta' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
      cta,
      // @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type 'Re... Remove this comment to see the full error message
      children,
    } = this.props

    const htmlMessage = { __html: message }

    const notificationClassNamesMap = {
      [NotificationTypes.ERROR]: styles.errorNotification,
      [NotificationTypes.SUCCESS]: styles.successNotification,
      [NotificationTypes.INFO]: styles.infoNotification,
    }

    let closeIconClassName

    if (showCloseIcon) {
      closeIconClassName = showSoftAndHardCloseOptions
        ? styles.notificationWithCloseButtonAndCheckbox
        : styles.notificationWithCloseButton
    } else {
      closeIconClassName = styles.notification
    }

    let queueLimitClassName
    if (cta?.label === 'Upgrade' && cta?.isUpgradePath) {
      queueLimitClassName = styles.queueLimitReached
    }

    const notificationClassName = [
      closeIconClassName,
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      notificationClassNamesMap[type],
      classNames.notification || className,
      queueLimitClassName,
    ].join(' ')

    const closeButtonClassName = [
      styles.closeButton,
      classNames.closeButton,
    ].join(' ')

    return (
      <div className={notificationClassName}>
        <div
          className={styles.notificationContent}
          dangerouslySetInnerHTML={htmlMessage}
        />
        {cta && <NotificationCta cta={cta} />}

        {showCloseIcon && (
          <CloseButton
            onClick={this.onCloseButtonClick}
            className={closeButtonClassName}
          />
        )}

        {children}

        {showSoftAndHardCloseOptions && (
          <div className={styles.hardCloseCheckboxContainer}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className={styles.hardCloseCheckboxLabel}>
              <input
                type="checkbox"
                className={styles.hardCloseCheckbox}
                onChange={this.onCheckboxChange}
                ref={(el) => {
                  // @ts-expect-error TS(2339) FIXME: Property 'hardCloseCheckbox' does not exist on typ... Remove this comment to see the full error message
                  this.hardCloseCheckbox = el
                }}
              />
              Don&apos;t show this message again
            </label>
          </div>
        )}
      </div>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Notification.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  showCloseIcon: PropTypes.bool.isRequired,
  showSoftAndHardCloseOptions: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    notification: PropTypes.string,
    closeButton: PropTypes.string,
  }),
  cta: PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
    isUpgradePath: PropTypes.bool,
  }),
  children: PropTypes.node,
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Notification.defaultProps = {
  onClose: () => {},
  className: null,
  classNames: {
    notification: null,
    closeButton: null,
  },
  cta: null,
  children: null,
}

export default Notification
