import React, { useState, useCallback } from 'react'

import {
  Button,
  Input,
  Dialog,
  Label,
  Flex,
  Text,
  Heading,
  RssIcon,
  Textarea,
  useOncePerTimePeriod,
} from '@buffer-mono/popcorn'
import { useHistory } from 'react-router-dom'
import { useCreateNewFeedCollection, useUpsertFeed } from '../../hooks'
import { feedCollectionPage } from '~publish/legacy/routes'
import { OpmlUploader } from './OpmlUploader'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAccount, useOrganizationId } from '~publish/legacy/accountContext'

import styles from './NewFeedCollectionDialog.module.css'

type NewFeedCollectionDialogProps = {
  onOpenChange: (open: boolean) => void
  open?: boolean
}
export const NewFeedCollectionDialog = (
  props: NewFeedCollectionDialogProps,
): JSX.Element => {
  const { onOpenChange, open } = props
  const [feedback, setFeedback] = useState('')
  const [didSubmitFeedback, setDidSubmitFeedback] = useState(false)

  const organizationId = useOrganizationId()
  const { account } = useAccount()

  const createFeedCollection = useCreateNewFeedCollection()

  const [name, setName] = useState('')
  const history = useHistory()
  const [newRSSUrls, setNewRSSUrls] = useState<string[]>([])
  const upsertFeed = useUpsertFeed()

  // Track page view when modal is opened
  useOncePerTimePeriod(
    'feed-collection-modal-view',
    60 * 60 * 1000, // 1 hour
    () => {
      if (open) {
        BufferTracker.pageViewed({
          name: 'Create Feed Collection Modal',
          product: 'publish',
          path: window.location.pathname || null,
          clientName: 'publishWeb',
        })
      }
    },
    [open],
  )

  const handleCreateFeed = useCallback(async (): Promise<void> => {
    let feedIds: string[] = []
    if (newRSSUrls.length > 0) {
      const feeds = await Promise.all(
        newRSSUrls.map((url) =>
          upsertFeed({
            organizationId,
            rssAtom: {
              url,
            },
          }),
        ),
      )
      feedIds = feeds
        .filter((feed) => feed?.__typename === 'FeedActionSuccess')
        .map((feed) => feed.feed.id)
    }
    const feed = await createFeedCollection({
      name,
      organizationId,
      feedIds,
    })
    if (feed?.__typename === 'FeedCollectionActionSuccess') {
      history.push(feedCollectionPage.linkTo(feed.feedCollection.id))
      onOpenChange(false)
    }
  }, [
    name,
    createFeedCollection,
    organizationId,
    history,
    onOpenChange,
    upsertFeed,
    newRSSUrls,
  ])

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <Dialog.Content size="xlarge" className={styles.dialogContent}>
        <div className={styles.dialogLeft}>
          <Dialog.Header>
            <Dialog.Title>Create Feed Collection</Dialog.Title>
          </Dialog.Header>
          <Dialog.Body className={styles.dialogBody}>
            <Flex direction="column" gap="md">
              <div>
                <Label htmlFor="feed-collection-name">
                  Feed Collection Name
                </Label>
                <Input
                  id="feed-collection-name"
                  size="large"
                  value={name}
                  onChange={(e): void => setName(e.target.value)}
                />
              </div>
              <OpmlUploader
                setNewRSSUrls={setNewRSSUrls}
                newRSSUrls={newRSSUrls}
              />
            </Flex>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              variant="primary"
              disabled={!name}
              onClick={handleCreateFeed}
            >
              Save
            </Button>
          </Dialog.Footer>
        </div>
        <div className={styles.dialogRight}>
          <div className={styles.feedIconJumbo}>
            <RssIcon className={styles.feedIconJumboIcon} />
          </div>
          <Heading>Feeds Beta</Heading>
          <Text as="p">
            We&apos;re really glad you&apos;re here! We&apos;re working hard on
            new features to help users like you get inspiration for their
            content.
          </Text>
          <Text as="p">We&apos;d love to hear your thoughts!</Text>
          {didSubmitFeedback && (
            <Text as="p" className={styles.feedbackSubmittedText}>
              <Text as="p" className={styles.feedbackSubmittedIcon}>
                ❤️
              </Text>
              <Text as="p" className={styles.feedbackSubmittedTextBody}>
                Thank you for your feedback!
              </Text>
            </Text>
          )}
          {!didSubmitFeedback && (
            <>
              <Label htmlFor="feedback" className={styles.feedbackLabel}>
                What features would you like to see?
              </Label>
              <Textarea
                id="feedback"
                value={feedback}
                onChange={(e): void => setFeedback(e.target.value)}
              ></Textarea>
              <div className={styles.submitFeedbackButtonBar}>
                <Button
                  variant={feedback ? 'primary' : 'tertiary'}
                  disabled={!feedback}
                  size="large"
                  onClick={(): void => {
                    BufferTracker.feedbackFormSubmitted({
                      clientName: 'publishWeb',
                      organizationId: organizationId ?? '',
                      userId: account.id ?? '',
                      widgetId: 'content_new_feed_collection',
                      source: 'create',
                      text: feedback,
                    })
                    setDidSubmitFeedback(true)
                    setFeedback('')
                  }}
                >
                  Submit Feedback
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog.Content>
    </Dialog>
  )
}
