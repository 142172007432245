import React from 'react'
import { AlertDialog, Button, toast } from '@buffer-mono/popcorn'

type DeleteIdeasDialogProps = {
  children: React.ReactNode
  count?: number
  onDelete: () => Promise<void>
}

export const DeleteIdeasDialog = ({
  onDelete,
  children,
  count = 1,
}: DeleteIdeasDialogProps): JSX.Element => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const handleDelete = async (): Promise<void> => {
    setIsDeleting(true)

    try {
      await onDelete()
      const message =
        count === 1
          ? 'The idea was deleted!'
          : `The ${count} ideas were deleted!`
      toast.success(message)
    } catch (error) {
      const message =
        count === 1
          ? 'The idea could not be deleted. Please try again!'
          : 'The ideas could not be deleted. Please try again!'
      toast.error(message)
    }

    setIsDeleting(false)
    setOpen(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialog.Trigger>{children}</AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Title>
          {count === 1 ? 'Delete Idea?' : `Delete ${count} Ideas?`}
        </AlertDialog.Title>
        <AlertDialog.Description>
          {count === 1
            ? 'This idea will be permanently deleted.'
            : `These ${count} ideas will be permanently deleted.`}{' '}
          This action cannot be undone.
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary">Cancel</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="critical"
              loading={isDeleting}
              onClick={handleDelete}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}
