import React, { useCallback, useEffect } from 'react'

import {
  Flex,
  Heading,
  Select,
  Separator,
  Skeleton,
  Text,
  toast,
} from '@buffer-mono/popcorn'

import SettingsPageLayout from './components/SettingsPageLayout'

import styles from './Settings.module.css'
import type {
  TimeFormat as TimeFormatEnum,
  StartOfWeek as StartOfWeekEnum,
} from '~publish/gql/graphql'
import { usePreferences } from './hooks/usePreferences'
import { useUpdateTimeFormatPreference } from './hooks/useUpdateTimeFormatPreference'
import { useUpdateStartOfWeekPreference } from './hooks/useUpdateStartOfWeekPreference'

const TimeFormat = ({
  timeFormat,
  isLoading,
}: {
  timeFormat: TimeFormatEnum | null
  isLoading: boolean
}): JSX.Element => {
  const timeFormatOptions = [
    { label: '12hour', value: 'twelveHour', timeFormat: '12' },
    { label: '24hour', value: 'twentyFourHour', timeFormat: '24' },
  ]

  const timeFormatLabel =
    timeFormatOptions.find((option) => option.timeFormat === timeFormat)
      ?.label || '12hour'

  const {
    updateTimeFormatPreference,
    isLoading: isUpdatingTimeFormat,
    hasError,
  } = useUpdateTimeFormatPreference()

  const handleOnChange = useCallback(
    (value: string): void => {
      updateTimeFormatPreference({ timeFormat: value as TimeFormatEnum }).then(
        () => {
          toast.success('Great! Your preferences have been saved')
        },
      )
    },
    [updateTimeFormatPreference],
  )

  useEffect(() => {
    if (hasError) {
      toast.error(
        'Sorry! There was a problem updating your selected time format. Please try again.',
      )
    }
  }, [hasError])

  return (
    <Flex gap="xs" justify="between" fullWidth>
      <Flex gap="xs" align="baseline" direction="column">
        <Heading as="h2" size="xsmall" id="time-format-heading">
          Time Format
        </Heading>
        <Text id="time-format-desc" color="subtle">
          Set the first day of the week for the Calendar and date picker
        </Text>
      </Flex>
      <Skeleton
        show={isLoading || !timeFormat}
        data-testid="time-format-skeleton"
      >
        <Select onValueChange={handleOnChange}>
          <Select.Trigger
            id="time-format"
            aria-labelledby="time-format-heading"
            aria-describedby="time-format-desc"
            placeholder={timeFormatLabel}
            disabled={isUpdatingTimeFormat}
          />
          <Select.Content>
            {timeFormatOptions.map((option) => (
              <Select.Item key={option.value} value={option.value}>
                {option.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Skeleton>
    </Flex>
  )
}

const StartOfWeek = ({
  startOfWeek,
  isLoading,
}: {
  startOfWeek: StartOfWeekEnum | null
  isLoading: boolean
}): JSX.Element => {
  const {
    updateStartOfWeekPreference,
    isLoading: isUpdatingStartOfWeek,
    hasError,
  } = useUpdateStartOfWeekPreference()

  const startOfWeekOptions = [
    { label: 'Monday', value: 'monday' },
    { label: 'Sunday', value: 'sunday' },
  ]

  const startOfWeekLabel =
    startOfWeekOptions.find((option) => option.value === startOfWeek)?.label ||
    'Monday'

  // TODO: Update the start of week with new mutation
  const handleOnChange = useCallback(
    (value: string): void => {
      updateStartOfWeekPreference({
        startOfWeek: value as StartOfWeekEnum,
      }).then(() => {
        toast.success('Great! Your preferences have been saved')
      })
    },
    [updateStartOfWeekPreference],
  )

  useEffect(() => {
    if (hasError) {
      toast.error(
        'Sorry! There was a problem updating your selected start of week. Please try again.',
      )
    }
  }, [hasError])

  return (
    <Flex gap="xs" justify="between" fullWidth>
      <Flex gap="xs" align="baseline" direction="column">
        <Heading as="h2" size="xsmall" id="start-of-week-heading">
          Start of Week
        </Heading>
        <Text id="start-of-week-desc" color="subtle">
          Set the first day of the week for the Calendar and date picker
        </Text>
      </Flex>
      <Skeleton
        show={isLoading || !startOfWeek}
        data-testid="start-of-week-skeleton"
      >
        <Select onValueChange={handleOnChange}>
          <Select.Trigger
            id="start-of-week"
            aria-labelledby="start-of-week-heading"
            aria-describedby="start-of-week-desc"
            placeholder={startOfWeekLabel}
            disabled={isUpdatingStartOfWeek}
          />
          <Select.Content>
            {startOfWeekOptions.map((option) => (
              <Select.Item key={option.value} value={option.value}>
                {option.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Skeleton>
    </Flex>
  )
}

export const Preferences = (): JSX.Element => {
  const { preferences, isLoading } = usePreferences()

  const timeFormat = preferences?.timeFormat as TimeFormatEnum
  const startOfWeek = preferences?.startOfWeek as StartOfWeekEnum

  return (
    <SettingsPageLayout>
      <Flex
        gap="xl"
        direction="column"
        data-testid="email-notifications-skeleton"
      >
        <Heading as="h1" size="large">
          Preferences
        </Heading>
        <section className={styles.fullWidth}>
          <TimeFormat timeFormat={timeFormat} isLoading={isLoading} />
          <Separator />
          <StartOfWeek startOfWeek={startOfWeek} isLoading={isLoading} />
        </section>
      </Flex>
    </SettingsPageLayout>
  )
}

export default Preferences
