import React from 'react'

import { type IconProps, NotifyMeIcon } from '@buffer-mono/popcorn'

import styles from './RemindersIcon.module.css'
import clsx from 'clsx'

export function RemindersIcon({
  disabled,
  className,
  ...props
}: {
  disabled?: boolean
} & IconProps): JSX.Element {
  return (
    <NotifyMeIcon
      className={clsx(styles.wrapper, className)}
      {...props}
      color={disabled ? 'subtle' : 'currentColor'}
    />
  )
}
