import React from 'react'
import { Flex, Skeleton, SkeletonText } from '@buffer-mono/popcorn'

const EmailNotificationsSkeleton = (): JSX.Element => {
  return (
    <Flex gap="xl" align="end" fullWidth style={{ padding: 'var(--space-sm)' }}>
      <Flex gap="sm" align="start" direction="column" justify="between">
        <SkeletonText lines={1} width={100} />
        <SkeletonText lines={1} width={400} />
      </Flex>
      <Skeleton height={20} rounded width={60} />
    </Flex>
  )
}

export default EmailNotificationsSkeleton
