import { graphql } from '~publish/gql'
import { toast } from '@buffer-mono/popcorn'
import { useQuery, NetworkStatus, type ApolloError } from '@apollo/client'
import type {
  GetCollectionFeedItemsQuery,
  GetCollectionFeedItemsQueryVariables,
} from '~publish/gql/graphql'
import { useInfiniteScrollPagination } from '~publish/hooks/useInfiniteScrollPagination'

export type FeedItem = NonNullable<
  GetCollectionFeedItemsQuery['feedItems']['edges']
>[number]['node']

export const GetCollectionFeedItems = graphql(/* GraphQL */ `
  query GetCollectionFeedItems(
    $feedCollectionId: FeedCollectionId!
    $organizationId: OrganizationId!
    $after: String
  ) {
    feedItems(
      first: 20
      after: $after
      input: {
        organizationId: $organizationId
        filter: { feedCollectionIds: [$feedCollectionId] }
      }
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          title
          contentTextPreview
          articleUrl
          feed {
            id
            type
            name
          }
        }
      }
    }
  }
`)

type UseFeedCollectionItemResult = {
  loading: boolean
  error?: ApolloError
  items: FeedItem[] | null
  fetchingMore: boolean
  refetch: () => void
  lastElementRef: (node: HTMLElement | null) => void
}

export const useFeedItems = (
  feedCollectionId: string,
  organizationId: string,
): UseFeedCollectionItemResult => {
  const { data, error, fetchMore, networkStatus, refetch } = useQuery<
    GetCollectionFeedItemsQuery,
    GetCollectionFeedItemsQueryVariables
  >(GetCollectionFeedItems, {
    variables: {
      feedCollectionId,
      organizationId,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const loading = networkStatus === NetworkStatus.loading
  const fetchingMore = networkStatus === NetworkStatus.fetchMore

  const items = data?.feedItems?.edges?.map((edge) => edge.node) ?? null

  const [lastElementRef] = useInfiniteScrollPagination({
    loading: fetchingMore,
    hasNextPage: data?.feedItems.pageInfo.hasNextPage,
    fetchMore: async () => {
      try {
        await fetchMore({
          variables: {
            after: data?.feedItems.pageInfo.endCursor,
          },
        })
      } catch (error) {
        toast.error('Error loading more feed items')
        throw new Error(
          'Failed to fetch more feed items:' + (error as Error).message,
        )
      }
    },
  })

  return {
    loading,
    lastElementRef,
    fetchingMore,
    error,
    items,
    refetch,
  }
}
