import { useMemo } from 'react'
import { useQueryParam } from '~publish/hooks/useQueryParam'

export type MultiSelectFilterState<T> = {
  onSelect: (value: string[]) => void
  selected: T[]
  selectedIds: string[]
  sanitized: string[]
  queryParams: undefined | string[]
}

const EMPTY_ARRAY: never[] = []

export const useMultiSelectFilter = <T extends { id: string }>(
  key: string,
  available: readonly T[],
): MultiSelectFilterState<T> => {
  const [queryParams, setQueryParams] = useQueryParam<string[]>(key)
  const [sanitized, selected, selectedIds] = useMemo(() => {
    const isEmpty =
      queryParams === null ||
      queryParams === undefined ||
      queryParams.length === 0
    const selected = isEmpty
      ? EMPTY_ARRAY
      : available.filter((item) => queryParams?.includes(item.id))
    return [
      isEmpty ? EMPTY_ARRAY : queryParams,
      selected,
      selected.map((item) => item.id),
    ]
  }, [queryParams, available])

  return {
    sanitized,
    selected,
    selectedIds,
    queryParams,
    onSelect: (value: string[]) => setQueryParams(value),
  }
}
