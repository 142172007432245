import React, { useCallback } from 'react'
import { Sidebar } from '@buffer-mono/popcorn'
import { useHistory } from 'react-router-dom'

type SidebarLinkProps = {
  icon: React.ReactNode
  selected: boolean
  suffix?: React.ReactNode
  to: string
  children?: React.ReactNode | string
}

export function SidebarLink(props: SidebarLinkProps): JSX.Element {
  const { icon, selected, suffix, to, children } = props
  const history = useHistory()
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      history.push(to)
      e.preventDefault()
    },
    [history, to],
  )
  return (
    <Sidebar.Button
      prefix={icon}
      suffix={suffix}
      as="a"
      selected={selected}
      href={to}
      onClick={handleClick}
    >
      {children}
    </Sidebar.Button>
  )
}
