import React from 'react'
import clsx from 'clsx'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { UniqueIdentifier } from '@dnd-kit/core'
import {
  Button,
  EmptyState,
  Flex,
  GripHorizontalIcon,
  LockIcon,
  PlusIcon,
  Text,
  toast,
  UnstyledButton,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import useDeleteIdeaGroup from './hooks/useDeleteIdeaGroup'
import useUpdateIdeaGroup from './hooks/useUpdateIdeaGroup'
import SortableItem from './SortableItem'
import { ColumnHeader } from './ColumnHeader'
import { IdeaBoardGroupLimitPaywall } from './IdeaBoardGroupLimitPaywall'
import { useDraggableColumn } from './hooks/useDraggableColumn'

import styles from './Column.module.css'
import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'
import { useIdeasMultiSelect } from '../IdeasMultiSelect'

type ColumnProps = {
  id: UniqueIdentifier
  name: string
  items: UniqueIdentifier[]
  editable?: boolean
  locked?: boolean
  showLockOverlay?: boolean
  renderItem(id: UniqueIdentifier): React.ReactElement | null
  className?: string
}

const Column = ({
  id,
  items,
  name,
  editable = true,
  locked = false,
  renderItem,
  className,
  showLockOverlay,
}: ColumnProps): JSX.Element => {
  const updateIdeaGroup = useUpdateIdeaGroup(id as string)
  const deleteIdeaGroup = useDeleteIdeaGroup()
  const { createIdeaWithComposer } = useIdeaComposer()
  const { selecting } = useIdeasMultiSelect()

  const {
    setNodeRef,
    style,
    dragging,
    draggable,
    setActivatorNodeRef,
    listeners: { onKeyDown, ...mouseListeners } = {},
    attributes,
  } = useDraggableColumn()

  const handleCreateIdea = (): void => {
    createIdeaWithComposer({
      groupId: `${id}`,
      source: 'create-board-group-plus-1',
    })
  }

  const handleCreateIdeaAtBottom = (): void => {
    createIdeaWithComposer({
      source: 'create-board-group-newIdea-1',
      groupId: `${id}` || undefined,
      placeAfterId: items.at(-1) ? `${items.at(-1)}` : undefined,
    })
  }

  const handleFormSubmit = async (newName: string): Promise<void> => {
    const cleanName = newName.trim()
    if (cleanName === name || cleanName === '') {
      return
    }
    try {
      await updateIdeaGroup(newName)
      toast.success('Column name updated')
    } catch (error) {
      toast.error('Error updating column name')
    }
  }

  const handleDeleteGroup = async (): Promise<void> => {
    try {
      await deleteIdeaGroup(id as string)
      toast.success('Column deleted')
    } catch (error) {
      toast.error('Error deleting column')
    }
  }

  return (
    <section
      id={id as string}
      data-testid="ideas-column"
      className={clsx(
        styles.wrapper,
        {
          [styles.locked]: locked,
          [styles.dragging]: dragging,
        },
        className,
      )}
      ref={setNodeRef}
      style={style}
    >
      <div className={styles.headerWrapper} {...mouseListeners}>
        {draggable && (
          <ColumnDragHandle
            {...attributes}
            label={`Reorder ${name} column`}
            ref={setActivatorNodeRef}
            onKeyDown={onKeyDown}
          />
        )}
        <ColumnHeader
          name={name}
          count={items.length}
          editable={editable}
          locked={locked}
          onCreateIdea={handleCreateIdea}
          onNameChange={handleFormSubmit}
          onDelete={handleDeleteGroup}
        />
      </div>

      <SortableContext
        id={id as string}
        items={locked ? [] : items}
        strategy={verticalListSortingStrategy}
      >
        <div className={styles.content}>
          <ol className={styles.list}>
            {items.map((id) => (
              <SortableItem key={id} id={id} disabled={selecting}>
                {renderItem(id)}
              </SortableItem>
            ))}
          </ol>
          {!locked && (
            <Button
              variant="tertiary"
              size="large"
              className={styles.newIdeaButton}
              onClick={handleCreateIdeaAtBottom}
            >
              <PlusIcon />
              New Idea
            </Button>
          )}
          {locked && items.length === 0 && <LockEmptyState />}
        </div>
      </SortableContext>
      {showLockOverlay && <LockOverlay />}
    </section>
  )
}

Column.displayName = 'Column'

type ColumnDragHandleProps = Omit<
  React.ComponentProps<typeof UnstyledButton>,
  'className'
> & {
  label: string
}

const ColumnDragHandle = React.forwardRef<
  HTMLButtonElement,
  ColumnDragHandleProps
>(({ label, ...props }, ref) => {
  return (
    <UnstyledButton ref={ref} className={styles.dragHandle} {...props}>
      <VisuallyHidden>{label}</VisuallyHidden>
      <GripHorizontalIcon />
    </UnstyledButton>
  )
})

ColumnDragHandle.displayName = 'ColumnDragHandle'

const LockEmptyState = (): JSX.Element => (
  <EmptyState>
    <EmptyState.Icon>
      <LockIcon />
    </EmptyState.Icon>
    <EmptyState.Heading>This group is locked</EmptyState.Heading>
    <EmptyState.Description>
      You&apos;ve hit the limit of 3 free groups. Upgrade to unlock this group.
    </EmptyState.Description>
    <EmptyState.Actions>
      <IdeaBoardGroupLimitPaywall
        cta={{ location: 'lockedColumnColumn', button: 'upgrade' }}
      >
        <Button variant="secondary">Upgrade</Button>
      </IdeaBoardGroupLimitPaywall>
    </EmptyState.Actions>
  </EmptyState>
)

LockEmptyState.displayName = 'LockEmptyState'

const LockOverlay = (): JSX.Element => (
  <Flex
    className={styles.lockOverlay}
    align="center"
    justify="center"
    direction="column"
    gap="xs"
  >
    <LockIcon size={24} />
    <Text align="center">
      You cannot move cards to locked groups. Upgrade to unlock.
    </Text>
  </Flex>
)

LockOverlay.displayName = 'LockOverlay'

export { Column }
