import { useState, useRef, useCallback, useEffect } from 'react'
import {
  type ApolloClient,
  ApolloError,
  type DocumentNode,
  type OperationVariables,
  type WatchQueryOptions,
  type FetchMoreQueryOptions,
  type ApolloQueryResult,
  NetworkStatus,
  useApolloClient,
} from '@apollo/client'
import { useCallbackRef } from '@buffer-mono/popcorn'

/**
 * Configuration object for a single GraphQL query.
 */
export interface QueryConfig<TVariables extends OperationVariables> {
  /** The GraphQL query document */
  query: DocumentNode
  /** Variables to be passed to the query */
  variables?: TVariables
}

/**
 * Options for fetching additional data, extending Apollo's FetchMoreQueryOptions
 */
export interface FetchMoreOptions<TData, TVariables>
  extends Omit<FetchMoreQueryOptions<TVariables, TData>, 'query'> {
  /** Function to update the cached query result with new data */
  updateQuery?: (
    previousResult: TData,
    options: {
      fetchMoreResult: TData
      variables: TVariables
    },
  ) => TData
}

/**
 * Result object for a single query execution.
 */
export interface SingleQueryResult<
  TData,
  TVariables extends OperationVariables,
> {
  /** The data returned from the query */
  data?: TData
  /** Variables for the query */
  variables?: TVariables
  /** Current loading state */
  loading: boolean
  /** Error object if query failed */
  error?: ApolloError
  /** Network status of the query */
  networkStatus: NetworkStatus
  /** Function to refetch the query with optional new variables */
  refetch: (
    variables?: Partial<TVariables>,
  ) => Promise<ApolloQueryResult<TData>>
  /** Function to fetch more data, typically used for pagination */
  fetchMore: (
    options: FetchMoreOptions<TData, TVariables>,
  ) => Promise<ApolloQueryResult<TData>>
}

/**
 * State object containing the current state of all queries
 */
export interface ManyQueriesResult<
  TData,
  TVariables extends OperationVariables,
> {
  /** Individual results for each query */
  results: SingleQueryResult<TData, TVariables>[]
  /** True if any query is loading */
  loading: boolean
  /** Error object if any query has failed */
  error?: ApolloError
  /** True if queries have been executed */
  called: boolean
  /** Network status considering all queries */
  networkStatus: NetworkStatus
  /** The Apollo Client instance being used */
  client: ApolloClient<any>
  /** Function to refetch all queries */
  refetch: (
    variables?: Partial<TVariables>[],
  ) => Promise<ApolloQueryResult<TData>[]>
}

/**
 * Options for lazy execution of multiple queries.
 */
export interface LazyManyQueriesHookOptions<
  TData,
  TVariables extends OperationVariables,
> extends Omit<WatchQueryOptions<TVariables, TData>, 'query' | 'variables'> {
  /** Apollo Client instance to use. If not provided, the context client will be used */
  client?: ApolloClient<any>
  /** Callback when all queries complete successfully */
  onCompleted?: (results: ManyQueriesResult<TData, TVariables>) => void
  /** Callback when any query encounters an error */
  onError?: (error: ApolloError) => void
}

type Subscription = ReturnType<
  ReturnType<ApolloClient<any>['watchQuery']>['subscribe']
>

/**
 * Execute function type that matches Apollo's pattern
 */
export type LazyManyQueriesExecFunction<
  TData,
  TVariables extends OperationVariables,
> = (
  queries: QueryConfig<TVariables>[],
  options?: Partial<LazyManyQueriesHookOptions<TData, TVariables>>,
) => Promise<ManyQueriesResult<TData, TVariables>>

/**
 * React hook for executing and managing multiple GraphQL queries simultaneously.
 * Follows Apollo's useLazyQuery pattern for familiarity. Each query is watched
 * and updated as its results change, leveraging Apollo's cache where possible.
 *
 * The core flow:
 * 1. We watch each query using `client.watchQuery()` to ensure reactive updates.
 * 2. We create subscriptions for each watched query; any update triggers a re-render.
 * 3. The hook returns an execute function, which sets up all queries at once.
 * 4. The hook tracks loading/error/called states for all queries collectively.
 * 5. Optional refetch and fetchMore methods are provided for each query.
 *
 * @template TData The expected shape of all query results.
 * @template TVariables The shape of variables for all queries.
 * @param options Default options to control how the queries are executed.
 * @returns A tuple containing the execute function and the current state of queries.
 *
 * @example
 * ```typescript
 * const GET_USER = gql`
 *   query GetUser($id: ID!) {
 *     user(id: $id) {
 *       id
 *       name
 *     }
 *   }
 * `
 *
 * function UserList({ userIds }) {
 *   const [executeQueries, { results, loading, error }] = useLazyManyQueries({
 *     fetchPolicy: 'cache-and-network',
 *     notifyOnNetworkStatusChange: true,
 *     onCompleted: (data) => console.log('All queries completed:', data),
 *     onError: (error) => console.error('Query error:', error)
 *   })
 *
 *   useEffect(() => {
 *     const queries = userIds.map(id => ({
 *       query: GET_USER,
 *       variables: { id }
 *     }))
 *     executeQueries(queries)
 *   }, [userIds])
 *
 *   if (loading) return <div>Loading...</div>
 *   if (error) return <div>Error: {error.message}</div>
 *
 *   return (
 *     <div>
 *       {results.map(({ data }) => (
 *         <div key={data?.user.id}>{data?.user.name}</div>
 *       ))}
 *     </div>
 *   )
 * }
 * ```
 */
export function useLazyManyQueries<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  options: LazyManyQueriesHookOptions<TData, TVariables> = {},
): [
  LazyManyQueriesExecFunction<TData, TVariables>,
  ManyQueriesResult<TData, TVariables>,
] {
  // If no client is passed in through options, useApolloClient() hooks into the default context client.
  const contextClient = useApolloClient()
  const client = options.client || contextClient

  /**
   * We store all query results in one state object, including a combined
   * loading/error state and an array of individual query results.
   */
  const [state, setState] = useState<
    Omit<ManyQueriesResult<TData, TVariables>, 'client' | 'refetch'>
  >({
    results: [],
    loading: false,
    error: undefined,
    called: false,
    networkStatus: NetworkStatus.ready,
  })

  /**
   * References to the underlying Apollo observables (one per query)
   * and their corresponding subscriptions. We store them in refs
   * so they persist across re-renders.
   */
  const observablesRef = useRef<any[]>([])
  const subscriptionsRef = useRef<Subscription[]>([])

  /**
   * Cleanup function to unsubscribe from all current queries.
   * This helps avoid memory leaks when the component unmounts
   * or new queries are fired.
   */
  const cleanup = useCallback(() => {
    subscriptionsRef.current.forEach((sub) => sub.unsubscribe())
    subscriptionsRef.current = []
    observablesRef.current = []
  }, [])

  /**
   * On unmount, automatically clean up all subscriptions.
   */
  useEffect(() => cleanup, [cleanup])

  /**
   * The executeQueries function is what initiates all watchers.
   * It is wrapped in useCallbackRef (like a stable reference that captures the latest closure).
   */
  const executeQueries: LazyManyQueriesExecFunction<TData, TVariables> =
    useCallbackRef(
      async (
        queries: QueryConfig<TVariables>[],
        execOptions?: Partial<LazyManyQueriesHookOptions<TData, TVariables>>,
      ) => {
        // Clean up any previous queries/subscriptions before starting new ones.
        cleanup()

        // Merge default hook options with the ones provided at execution time.
        const mergedOptions = { ...options, ...execOptions }

        // Prepare an array of SingleQueryResult placeholders, one for each query.
        const newResults: SingleQueryResult<TData, TVariables>[] = new Array(
          queries.length,
        ).fill(undefined)

        // Track how many queries are still in a loading state.
        let loading = queries.length
        // Flag that will be set to true if any query fails.
        let hasError = false

        // Initialize state to a loading state for all queries.
        setState((prev) => ({
          ...prev,
          loading: true,
          called: true,
          error: undefined,
          networkStatus: NetworkStatus.loading,
          results: newResults.map((result) => ({
            ...result,
            loading: true,
            networkStatus: NetworkStatus.loading,
            refetch: async () => ({
              data: undefined as any,
              loading: false,
              networkStatus: NetworkStatus.ready,
            }),
            fetchMore: async () => ({
              data: undefined as any,
              loading: false,
              networkStatus: NetworkStatus.ready,
            }),
          })),
        }))

        try {
          // Return a promise that resolves when all queries finish or rejects on error.
          return new Promise<ManyQueriesResult<TData, TVariables>>(
            (resolve, reject) => {
              // Create a subscription for each query in queries.
              const newSubscriptions = queries.map((queryConfig, index) => {
                /**
                 * For each query, build the final watchQueryOptions
                 * by merging the config with the mergedOptions.
                 */
                const watchQueryOptions: WatchQueryOptions<TVariables, TData> =
                  {
                    ...mergedOptions,
                    query: queryConfig.query,
                    variables: queryConfig.variables,
                  }

                // Create an observable from Apollo Client to watch this query.
                const observable = client.watchQuery<TData, TVariables>(
                  watchQueryOptions,
                )

                // Keep a reference to the observable so we can refetch or fetchMore later.
                observablesRef.current[index] = observable

                // Define a refetch method for this specific query
                const refetch = async (
                  variables?: Partial<TVariables>,
                ): Promise<ApolloQueryResult<TData>> => {
                  return observable.refetch(variables)
                }

                // Define a fetchMore method for this specific query
                const fetchMore = async (
                  fetchMoreOptions: FetchMoreOptions<TData, TVariables>,
                ): Promise<ApolloQueryResult<TData>> => {
                  return observable.fetchMore({
                    ...fetchMoreOptions,
                    query: queryConfig.query,
                  })
                }

                // Initialize the SingleQueryResult for this query.
                newResults[index] = {
                  variables: queryConfig.variables,
                  loading: true,
                  networkStatus: NetworkStatus.loading,
                  refetch,
                  fetchMore,
                }

                // Subscribe to changes on this query; Apollo notifies us whenever new data arrives or an error occurs.
                return observable.subscribe({
                  next: ({ data, loading: queryLoading, networkStatus }) => {
                    // Update the result for this query with the new data/status.
                    newResults[index] = {
                      ...newResults[index],
                      data,
                      loading: queryLoading,
                      networkStatus,
                    }

                    // If this particular query finished loading, decrement the loading count.
                    if (!queryLoading && data !== undefined) {
                      loading--
                    }

                    // If all queries are done loading, allComplete = true
                    const allComplete = loading === 0

                    // Update the hook state with the new results for the relevant query.
                    setState((prev) => {
                      const updatedResults = [...prev.results]

                      updatedResults[index] = {
                        ...newResults[index],
                        data: queryLoading ? updatedResults[index].data : data,
                        loading: queryLoading,
                        networkStatus,
                      }

                      return {
                        ...prev,
                        results: updatedResults,
                        loading: loading > 0,
                        networkStatus: allComplete
                          ? NetworkStatus.ready
                          : NetworkStatus.loading,
                      }
                    })

                    // If all queries have finished and we haven't encountered an error, call onCompleted and resolve.
                    if (allComplete) {
                      const response = {
                        results: newResults,
                        loading: false,
                        called: true,
                        networkStatus: NetworkStatus.ready,
                        client,
                        refetch: async (
                          variables?: Partial<TVariables>[],
                        ): Promise<ApolloQueryResult<TData>[]> => {
                          /**
                           * The refetch returned by the resolved promise
                           * refetches each observable with optional new variables.
                           */
                          const refetchPromises = observablesRef.current.map(
                            (obs, idx) => obs.refetch(variables?.[idx]),
                          )
                          return Promise.all(refetchPromises)
                        },
                      }

                      if (!hasError && mergedOptions.onCompleted) {
                        mergedOptions.onCompleted(response)
                      }

                      resolve(response)
                    }
                  },
                  error: (error: ApolloError) => {
                    // If any query fails, mark hasError to avoid onCompleted calls.
                    hasError = true

                    // Notify the onError callback if provided.
                    if (mergedOptions.onError) {
                      mergedOptions.onError(error)
                    }

                    // Set the global state to error.
                    setState((prev) => ({
                      ...prev,
                      error,
                      loading: false,
                      networkStatus: NetworkStatus.error,
                    }))

                    // Reject the entire promise chain, ending execution for the rest.
                    reject(error)
                  },
                })
              })

              // Keep track of these new subscriptions in a ref so we can unsubscribe later.
              subscriptionsRef.current = newSubscriptions
            },
          )
        } catch (error: unknown) {
          /**
           * Handle catastrophic errors outside of Apollo's standard flow
           * (e.g., if watchQuery itself throws).
           */
          const apolloError =
            error instanceof ApolloError
              ? error
              : new ApolloError({
                  errorMessage: (error as Error).message ?? 'Unknown error',
                })

          setState((prev) => ({
            ...prev,
            error: apolloError,
            loading: false,
            networkStatus: NetworkStatus.error,
          }))
          throw apolloError
        }
      },
    )

  /**
   * refetch function for refetching all currently active queries at once.
   * If called before executeQueries, it returns an empty array because there
   * are no subscriptions yet.
   */
  const refetch = useCallback(
    async (variables?: OperationVariables[]) => {
      if (observablesRef.current.length === 0 || !state.called) {
        return []
      }

      setState((prev) => ({
        ...prev,
        loading: true,
        networkStatus: NetworkStatus.refetch,
      }))

      try {
        // Kick off refetches for every observable, passing optional new variables if provided.
        const refetchPromises = observablesRef.current.map(
          (observable, index) => {
            return observable.refetch(variables?.[index])
          },
        )

        const results = await Promise.all(refetchPromises)

        // Once refetching is done, update the global state back to ready.
        setState((prev) => ({
          ...prev,
          loading: false,
          networkStatus: NetworkStatus.ready,
        }))

        return results
      } catch (error) {
        const apolloError =
          error instanceof ApolloError
            ? error
            : new ApolloError({
                errorMessage: (error as Error).message ?? 'Unknown error',
              })
        setState((prev) => ({
          ...prev,
          error: apolloError,
          loading: false,
          networkStatus: NetworkStatus.error,
        }))
        throw apolloError
      }
    },
    [state.called],
  )

  /**
   * Finally, return a tuple like [executeFunction, resultState].
   * The user calls executeQueries with an array of queries,
   * and the resultState tracks everything about loading, errors, and data.
   */
  return [
    executeQueries,
    {
      ...state,
      client,
      refetch,
    },
  ]
}
