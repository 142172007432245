import React, { useEffect, useMemo, useRef } from 'react'

import type { TimeInRange } from './types'
import { Hour } from './Hour'
import { useCalendarContext } from './context'

type WeekProps = {
  hours: TimeInRange[]
}

const Week = ({ hours }: WeekProps): JSX.Element => {
  const { items, isFirstLoad } = useCalendarContext()
  const currentHourRef = useRef<HTMLTableCellElement>(null)
  const hasScrolledRef = useRef<boolean>(false)

  useEffect(() => {
    // Scroll into view only if items are loaded and we never scrolled into view before
    if (currentHourRef?.current && items && !hasScrolledRef.current) {
      // Schedule the scroll into view to be executed after react has finished rendering
      setTimeout(() => {
        currentHourRef.current?.scrollIntoView()
        hasScrolledRef.current = true
      }, 0)
    }
  }, [items])

  const hoursByDayHour = useMemo(() => groupHoursByDayHour(hours), [hours])

  return (
    <>
      {hoursByDayHour.map((hours) => {
        return (
          <tr
            key={`weekly-row-${hours[0].time.toString()}`}
            data-testid={`weekly-row-${hours[0].time.toString()}`}
          >
            {hours.map((hour, index) => (
              <Hour
                key={hour.time.toString()}
                isFirstLoad={isFirstLoad}
                hour={hour}
                showLabel={index === 0}
                ref={hour.comparisonToNow === 'current' ? currentHourRef : null}
              />
            ))}
          </tr>
        )
      })}
    </>
  )
}

// An array of arrays of hours grouped by hour of the day
// e.g. [[day1-00:00, day2-00:00], [day1-01:00, day2-01:00], [day1-02:00, day2-02:00], ...]
function groupHoursByDayHour(hours: TimeInRange[]): TimeInRange[][] {
  const hoursByHourOfDayCheck = new Map<number, Set<string>>()
  const hoursByHourOfDay = new Map<number, TimeInRange[]>()

  for (const hour of hours) {
    const hourKey = hour.time.hour
    const existingHours =
      hoursByHourOfDayCheck.get(hourKey) || new Set<string>()
    hoursByHourOfDayCheck.set(hourKey, existingHours)

    // Check if the current hour is already in the existingHours array
    // This prevents duplicate entries for the same hour and day
    // (e.g. daylight saving time transitions where an hour might be repeated)
    const key = `${hour.time.day}-${hour.time.hour}`
    if (!existingHours.has(key)) {
      existingHours.add(key)
      const existingHoursArray = hoursByHourOfDay.get(hourKey) || []
      existingHoursArray.push(hour)
      hoursByHourOfDay.set(hourKey, existingHoursArray)
    }
  }

  return Array.from(hoursByHourOfDay.entries()).map(
    ([, hourHours]) => hourHours,
  )
}

const memoWeek = React.memo(Week)
export { memoWeek as Week }
