import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { UpgradeLinkButton } from './styles'
import { useTracking } from '../../tracking/useTracking'

export const TagsUpgradePathLink = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { trackUpgradePathViewed, trackCTAClicked } = useTracking()
  const ctaAttributes = {
    cta: `publish-tagsSelector-tagsList-upgradeLink-1`,
    upgradePathName: 'tagsLimit-upgrade',
  }

  useEffect(() => {
    trackUpgradePathViewed({ upgradePathName: ctaAttributes.upgradePathName })
  }, [])

  const showTagsLimitUpgradeModal = (): void => {
    trackCTAClicked({
      ...ctaAttributes,
    })
    dispatch(modalActions.showTagsLimitUpgradeModal())
  }

  return (
    <UpgradeLinkButton type="button" onClick={showTagsLimitUpgradeModal}>
      {t('campaigns.limits.ctaLinkLabel')}
    </UpgradeLinkButton>
  )
}
