import React, { useCallback, useState, useMemo } from 'react'
import {
  Heading,
  Text,
  Button,
  ArrowUpIcon,
  Flex,
  CheckboxCard,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@buffer-mono/popcorn'
import { parseOpml, type OPML } from '../../helpers/parseOPML'

import styles from './OpmlUploader.module.css'

const PAGE_SIZE = 6

type OpmlUploaderProps = {
  setNewRSSUrls: React.Dispatch<React.SetStateAction<string[]>>
  newRSSUrls: string[]
}

export const OpmlUploader = ({
  setNewRSSUrls,
  newRSSUrls,
}: OpmlUploaderProps): JSX.Element => {
  const [opml, setOpml] = useState<OPML | undefined>()
  const newRSSUrlsAsSet = useMemo(() => new Set(newRSSUrls), [newRSSUrls])
  const [feedPage, setFeedPage] = useState(0)
  const paginatedFeeds = useMemo(
    () =>
      opml?.feeds.slice(feedPage * PAGE_SIZE, (feedPage + 1) * PAGE_SIZE) ?? [],
    [opml, feedPage],
  )
  const hasMoreFeeds = useMemo(
    () => (opml?.feeds.length ?? 0) > (feedPage + 1) * PAGE_SIZE,
    [opml, feedPage],
  )
  const handleOpmlUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (files && files[0]) {
        const reader = new FileReader()
        reader.onload = (readerLoad): void => {
          const raw = readerLoad.target?.result
          if (raw) {
            const opml = parseOpml(raw.toString())
            setOpml(opml)
            setNewRSSUrls([])
            setFeedPage(0)
          }
        }
        reader.readAsText(files[0])
      }
    },
    [setNewRSSUrls],
  )

  const hasMaxedOut = useMemo(() => newRSSUrls.length >= 3, [newRSSUrls])

  if (opml) {
    return (
      <Flex direction="column" gap="sm">
        <div>
          <Heading size="xsmall">Upload Collection</Heading>
          <Text size="sm" color="subtle">
            Get started with a collection from Feedly or other sources that
            support <b>OPML</b>.
          </Text>
        </div>
        <CheckboxCard.Group
          aria-label="Select feeds to subscribe to"
          className={styles.feedContainer}
        >
          {paginatedFeeds.map((feed) => (
            <CheckboxCard
              className={styles.feedItem}
              key={feed.url}
              checked={newRSSUrlsAsSet.has(feed.url)}
              disabled={hasMaxedOut && !newRSSUrlsAsSet.has(feed.url)}
              onCheckedChange={(checked): void => {
                if (checked) {
                  setNewRSSUrls([...newRSSUrls, feed.url])
                } else {
                  setNewRSSUrls(newRSSUrls.filter((url) => url !== feed.url))
                }
              }}
            >
              <Flex direction="row" gap="sm" className={styles.feedItemLayout}>
                <img
                  src={`http://www.google.com/s2/favicons?domain=${feed.url}`}
                  alt={feed.title}
                  className={styles.feedIcon}
                />
                <Flex
                  direction="column"
                  gap="xs"
                  className={styles.feedTitleContainer}
                >
                  <Text size="md" className={styles.feedTitle}>
                    {feed.title}
                  </Text>
                  <Text size="sm" color="subtle" className={styles.feedUrl}>
                    {feed.url}
                  </Text>
                </Flex>
              </Flex>
            </CheckboxCard>
          ))}
        </CheckboxCard.Group>
        <Flex direction="row" gap="sm" align="center">
          <Button
            variant="tertiary"
            size="small"
            onClick={(): void => setFeedPage(feedPage - 1)}
            disabled={feedPage === 0}
          >
            <ChevronLeftIcon size="xsmall" />
            Previous
          </Button>
          <Button
            variant="tertiary"
            size="small"
            onClick={(): void => setFeedPage(feedPage + 1)}
            disabled={!hasMoreFeeds}
          >
            Next
            <ChevronRightIcon size="xsmall" />
          </Button>
          {hasMaxedOut && (
            <Text size="sm" color="subtle">
              You’ve reached the maximum of 3 feeds
            </Text>
          )}
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap="sm">
      <div>
        <Heading size="xsmall">Upload Collection</Heading>
        <Text size="sm" color="subtle">
          Get started with a collection from Feedly or other sources that
          support <b>OPML</b>.
        </Text>
      </div>
      <Button as="label" variant="secondary" size="small">
        <ArrowUpIcon size="xsmall" />
        Upload
        <input type="file" hidden onChange={handleOpmlUpload} />
      </Button>
    </Flex>
  )
}
