import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  InfoIcon,
  Notice,
  PromotionalBanner,
} from '@buffer-mono/popcorn'
import { useAccount } from '~publish/legacy/accountContext'
import useDismissBanner, {
  BannerTypes,
} from '~publish/legacy/profile-page/hooks/useDismissBanner'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { Service } from '@buffer-mono/app-shell/src/exports/Orchestrator/channelConnections/types'

const TwitterPremiumBanner = (): JSX.Element | null => {
  const { t } = useTranslation()
  const [dismissed, setDismissed] = useState(false)
  const { account } = useAccount()
  const isOneBufferOrg = account?.currentOrganization?.isOneBufferOrganization
  const { onDismissBanner } = useDismissBanner({
    banner: BannerTypes.twitterPremium,
  })
  const MIGRATION_HUB_URL =
    'https://account.buffer.com/new-plans?cta=publish-queue-queueBanner-twitterPremium-1'
  const commonTrackingProperties =
    account?.currentOrganization?.commonTrackingProperties || null
  const onUpgradeOptionsClick = (): void => {
    BufferTracker.cTAClicked({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      cta: `publish-queue-banner-refreshTwitter-1`,
      upgradePathName: 'twitterPremium-upgrade',
      ...commonTrackingProperties,
    })
    window.open(MIGRATION_HUB_URL, '_blank')
  }
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )

  const onDismiss = (): void => {
    BufferTracker.bannerDismissed({
      organizationId,
      clientName: 'publishWeb',
      product: 'publish',
      bannerName: 'twitterPremium',
    })
    onDismissBanner()
    setDismissed(true)
  }

  const handleRefreshTwitterConnection = (): void => {
    window.appshell.actions.connectChannel({
      selectedService: Service.twitter,
      cta: `publish-queue-banner-refreshTwitter-1`,
      isRefreshingConnection: true,
      role: account?.currentOrganization?.role,
    })
  }

  if (dismissed) return null

  if (isOneBufferOrg) {
    return (
      <Notice
        variant="info"
        style={{
          width: 'initial',
          position: 'relative',
          maxWidth: 864,
        }}
        icon={<InfoIcon />}
        onDismiss={onDismiss}
      >
        <Notice.Heading>Planning to Upgrade to X Premium?</Notice.Heading>
        <Notice.Text>
          If you do, remember to refresh your X connection in Buffer to start
          using features like extended limits
        </Notice.Text>
        <Notice.Actions>
          <Button onClick={handleRefreshTwitterConnection} size="small">
            Refresh X Connection
          </Button>
        </Notice.Actions>
      </Notice>
    )
  }

  return (
    <PromotionalBanner onDismiss={onDismiss}>
      <PromotionalBanner.Content>
        <PromotionalBanner.Heading>
          {t('twitterPremiumBanner.headline')}
        </PromotionalBanner.Heading>
        <PromotionalBanner.Description>
          {t('twitterPremiumBanner.description')}
        </PromotionalBanner.Description>
        <PromotionalBanner.Actions>
          <Button variant="primary" onClick={onUpgradeOptionsClick}>
            {t('twitterPremiumBanner.primaryLinkText')}
          </Button>
        </PromotionalBanner.Actions>
      </PromotionalBanner.Content>
      <PromotionalBanner.Image src="https://buffer-publish.s3.amazonaws.com/images/twitter-premium.png" />
    </PromotionalBanner>
  )
}

export { TwitterPremiumBanner }
