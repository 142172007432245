import React from 'react'

import { useParams } from 'react-router-dom'
import { ContentFeed } from './components/ContentFeed/ContentFeed'

export const Feeds = (): JSX.Element => {
  const { feedCollectionId } = useParams<{ feedCollectionId: string }>()
  if (!feedCollectionId) {
    return <div>Feed Collection not found</div>
  }
  return <ContentFeed feedCollectionId={feedCollectionId} />
}
