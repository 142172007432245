import React, { forwardRef, useState } from 'react'
import clsx from 'clsx'
import { useDroppable } from '@dnd-kit/core'
import { useIdleMemo } from '@buffer-mono/popcorn'

import { getZonedNow, format } from '~publish/helpers/temporal'

import { AddButton } from './AddButton'
import ShowMoreLessButtons from './ShowMoreLessButtons'
import { useCalendarContext } from './context'
import { DraggableItem } from './DraggableItem'
import { Item } from './Item'
import type { TimeInRange } from './types'

import styles from './Day.module.css'

const DEFAULT_ITEMS_LIMIT = 3
const FIVE_MINUTES = 300

interface DayProps {
  day: TimeInRange
}

const Day = forwardRef<HTMLTableCellElement, DayProps>(({ day }, ref) => {
  const { items, readOnly, selectedDate, timezone, isFirstLoad } =
    useCalendarContext()

  const [limit, setLimit] = useState(DEFAULT_ITEMS_LIMIT)

  const dropDisabled = day.comparisonToNow === 'past' || readOnly

  const { timestamp, now, dayId, dayString, dayLabel, dayNumber } =
    useIdleMemo(() => {
      const now = getZonedNow(timezone).round('minute')
      const middayTimestamp = day.time
        .startOfDay()
        .add({ hours: 12 }).epochMilliseconds
      const currentTimestamp = now.epochMilliseconds
      const timestamp =
        currentTimestamp > middayTimestamp
          ? currentTimestamp + FIVE_MINUTES
          : middayTimestamp

      return {
        timestamp,
        now,
        dayLabel: format(day.time, 'eeee, d MMMM y'),
        dayString: day.time.toString(),
        dayId: day.time.toInstant().epochMilliseconds.toString(),
        dayNumber: format(day.time, 'd'),
      }
    }, [timezone]) ?? {}

  const isCurrentMonth = day.time.month === selectedDate.month

  const itemsToRender = useIdleMemo(
    () =>
      items?.filter((item) => {
        return (
          item.timestamp >= day.time.startOfDay().epochMilliseconds &&
          item.timestamp <
            day.time.add({ days: 1 }).startOfDay().epochMilliseconds
        )
      }),
    [items, day],
  )

  const { setNodeRef, isOver, active } = useDroppable({
    id: dayId ?? '',
    disabled: dropDisabled,
    data: {
      type: 'hour',
      timestamp,
    },
  })

  const showAddButton = !dropDisabled && !active && !isFirstLoad

  return (
    <td
      data-testid={`monthly-day-${day}`}
      data-datetime={day.toString()}
      data-container-id={dayId}
      className={clsx(styles.day, {
        [styles.disabled]: dropDisabled,
        [styles.active]: day.comparisonToNow === 'current',
        [styles.loading]: isFirstLoad,
        [styles.draggingOver]: isOver,
        [styles.currentMonth]: isCurrentMonth,
        [styles.dragHappening]: Boolean(active),
      })}
      ref={ref}
    >
      {/* Hide day number during loading to communicate to the user that the data is not present yet */}
      {!isFirstLoad && <span className={styles.dayNumber}>{dayNumber}</span>}
      {timestamp && showAddButton && (
        <AddButton
          data-testid={`post-button-${dayString}`}
          labelDate={dayLabel ?? ''}
          timestamp={timestamp}
          className={styles.addButton}
        />
      )}
      <div className={styles.dayContent} ref={setNodeRef}>
        {timestamp && now && itemsToRender && itemsToRender.length > 0 && (
          <>
            <div className={styles.itemsWrapper}>
              {itemsToRender.slice(0, limit).map((item, index) => {
                return (
                  <DraggableItem
                    key={item.id}
                    id={item.id}
                    index={index}
                    timestamp={timestamp}
                    className={clsx(styles.item, {
                      [styles.activeItem]: active?.id === item.id,
                    })}
                    shouldDisableDrag={
                      readOnly || item.shouldDisableDrag === undefined
                        ? item.timestamp < now.epochSeconds
                        : item.shouldDisableDrag
                    }
                  >
                    <Item {...item} index={index} />
                  </DraggableItem>
                )
              })}
            </div>
            <ShowMoreLessButtons
              itemsToRender={itemsToRender}
              limit={limit}
              className={styles.showMoreButton}
              setLimit={setLimit}
            />
          </>
        )}
      </div>
    </td>
  )
})

Day.displayName = 'Day'

const memoDay = React.memo(Day)
export { memoDay as Day }
