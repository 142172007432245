import React, { useCallback, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import clsx from 'clsx'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  type DragEndEvent,
  type DragStartEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import { useHasElementScroll } from '~publish/hooks/useHasElementScrolled'
import { restrictToRef } from '~publish/helpers/dndkit/restrictToRefModifier'
import { DROP_ANIMATION } from '~publish/helpers/dndkit/constants'

import type { AddItemProps, CalendarItem, RenderItemFunction } from './types'
import { useCalendar } from './hooks/useCalendar'
import { Header } from './Header'
import { Week } from './Week'
import { Month } from './Month'
import { useAnimationDirection } from './hooks/useAnimationDirection'
import { CalendarContext, type CalendarContextType } from './context'
import { calendarKeyboardCoordinateGetter } from './calendarKeyboardCoordinateGetter'
import { DraggableItem } from './DraggableItem'

import styles from './Calendar.module.css'
import { getDndKitAnnouncements } from './helpers'

type CalendarProps = {
  /** Whether to use 24-hour format */
  is24HourFormat: boolean
  /** Whether the calendar is read-only */
  readOnly: boolean
  /** Array of calendar items */
  items: CalendarItem[] | undefined
  /** Timezone string */
  timezone: string
  /** Day of the week to start on (0 for Sunday, 1 for Monday) */
  weekStartsOn: 0 | 1
  /** Function to render individual calendar items */
  renderItem: RenderItemFunction
  /** Props to be added on the new item button */
  addItemProps: AddItemProps
  /** Callback function for when drag starts */
  onDragStart: (event: DragStartEvent) => void
  /** Callback function for when drag ends */
  onDragEnd: (result: DragEndEvent) => void
  /** Whether the calendar is loading for the first time */
  isFirstLoad: boolean
}

/**
 * Calendar component that displays events in a week or month view.
 *
 * @param {CalendarProps} props - The props for the Calendar component
 * @returns {JSX.Element} The rendered Calendar component
 */
export const Calendar = React.memo(
  ({
    is24HourFormat,
    readOnly,
    items,
    isFirstLoad,
    timezone,
    weekStartsOn,
    renderItem,
    addItemProps: addItem,
    onDragStart,
    onDragEnd,
  }: CalendarProps): JSX.Element => {
    const { startDate, hours, selectedDate, days, viewMode } = useCalendar({
      weekStartsOn,
      timezone,
    })
    const { showRightAnimation, showLeftAnimation, handleAnimationEnd } =
      useAnimationDirection(selectedDate)
    const { scrollingElementRef, hasScroll: hasScrolled } =
      useHasElementScroll<HTMLDivElement>()
    const [activeDraggedId, setActiveDraggedId] = useState<string | undefined>(
      undefined,
    )
    const contextValue: CalendarContextType = useMemo(
      () => ({
        renderItem,
        items,
        addItemProps: addItem,
        readOnly,
        selectedDate,
        is24HourFormat,
        timezone,
        isFirstLoad,
      }),
      [
        renderItem,
        items,
        addItem,
        readOnly,
        selectedDate,
        is24HourFormat,
        timezone,
        isFirstLoad,
      ],
    )
    const sensors = useSensors(
      useSensor(KeyboardSensor, {
        coordinateGetter: calendarKeyboardCoordinateGetter,
      }),
      useSensor(PointerSensor, { activationConstraint: { distance: 4 } }),
    )

    const handleDragStart = useCallback(
      (event: DragStartEvent): void => {
        const { active } = event
        if (!active.id) return
        setActiveDraggedId(active.id.toString())
        onDragStart?.(event)
      },
      [onDragStart],
    )

    const item = items?.find((item) => item.id === activeDraggedId)

    return (
      <CalendarContext.Provider value={contextValue}>
        <div ref={scrollingElementRef} className={styles.calendar}>
          <DndContext
            autoScroll={false}
            sensors={sensors}
            onDragStart={handleDragStart}
            modifiers={[restrictToRef(scrollingElementRef)]}
            onDragEnd={onDragEnd}
            accessibility={{
              announcements: getDndKitAnnouncements({
                timezone,
                is24HourFormat,
                viewMode,
                items,
              }),
              screenReaderInstructions: {
                draggable: `
                To pick up a item, press space or enter.
                Use the arrow keys to move the item in the calendar.
                Press space or enter again to drop the item in its new position, or press escape to cancel.
              `,
              },
            }}
          >
            <table
              className={clsx(styles.calendarTable, {
                [styles.animateRight]: showRightAnimation,
                [styles.animateLeft]: showLeftAnimation,
              })}
              onAnimationEnd={handleAnimationEnd}
            >
              <Header
                startDate={startDate}
                withDay={viewMode === 'week'}
                className={clsx({
                  [styles.headerShadow]: hasScrolled,
                })}
              />

              <tbody>
                {viewMode === 'week' ? (
                  <Week hours={hours} />
                ) : (
                  <Month days={days} />
                )}
              </tbody>
            </table>
            {item &&
              createPortal(
                <DragOverlay
                  dropAnimation={DROP_ANIMATION}
                  className={styles.dragOverlay}
                >
                  <DraggableItem
                    key={item.id}
                    id={item.id}
                    index={0}
                    onOverlay={true}
                    timestamp={item.timestamp}
                  >
                    {renderItem({ ...item, index: 0 })}
                  </DraggableItem>
                </DragOverlay>,
                document.body,
              )}
          </DndContext>
        </div>
      </CalendarContext.Provider>
    )
  },
)

Calendar.displayName = 'Calendar'
