import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { getFragmentData } from '~publish/gql/fragment-masking'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { IdeaCard_Idea } from '~publish/pages/Create/components/IdeaCard/IdeaCard'

import { stripGqlTypenames } from '~publish/helpers/stripGqlTypenames'
import useIdeasUploader from '~publish/hooks/useIdeasUploader'
import type { Idea } from '../../pages/Create/types'
import { IdeaComposer } from '../IdeaComposer'
import { GetIdea } from './IdeaDetailsDialog'
import { createPage } from '~publish/legacy/routes'

/**
 * Component used to open IdeaComposer with existing idea.
 * Renders when corresponding route is matched.
 *
 * It will attempt to fetch the idea with the provided ideaId, returning it from cache first if available.
 */
export function IdeaEditDialog(): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(true)
  const history = useHistory()
  const location = useLocation<{ returnTo?: Location }>()

  const { ideaId } = useParams<{ ideaId: string; id: string }>()
  const [source] = useQueryParam('source')
  const { data, loading } = useQuery(GetIdea, {
    variables: {
      input: {
        id: ideaId,
      },
    },
    fetchPolicy: 'cache-first',
  })
  const uploader = useIdeasUploader()

  const handleOpenChange = (open: boolean): void => {
    setIsOpen(open)
    if (!open) {
      // If the dialog was an internal navigation, return to that location
      // Otherwise, default to the create page
      history.push(location.state?.returnTo ?? createPage.route)
    }
  }

  useEffect(() => {
    if (data?.idea) {
      setIsOpen(true)
    }
  }, [data?.idea, history, source])

  if (loading) {
    return <></>
  }

  if (data?.idea?.__typename !== 'Idea' || !data?.idea) {
    handleOpenChange(false)
    return null
  }

  const idea = getFragmentData(IdeaCard_Idea, data.idea) as Idea

  return (
    <IdeaComposer
      initialIdea={stripGqlTypenames(idea) ?? undefined}
      open={isOpen}
      onOpenChange={handleOpenChange}
      uploader={uploader}
    />
  )
}
